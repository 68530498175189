import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import mims_integrate_img from '../../images/mims_integrate_img.png';
import MimsBanner from '../../images/MimsBanner.jpg';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const RequestDemoSlider = () => {
    
    const styles = {
        slide: {
            // padding: 8,
            minHeight: 360,
            color: '#fff',
            overflow: 'hidden',
            maxHeight: 100,
            // textAlign: 'center',
            // backgroundImage: MimsBanner,
            // backgroundRepeat: 'no-repeat',
        },
        slide1: {
            background: '#7EA845',
        },
    };

    const [textData, setTextData] = useState([
        {
            id: 1,
            images: mims_integrate_img,
            title: "MIMS integrated prescribing",
            description: "MIMS integration notifies drug-to-drug, drug-to-allergy, and drug-to-pregnancy interactions, to help make better decisions.",
        },
        {
            id: 2,
            images: mims_integrate_img,
            title: "Get Updated Drug  information in details.",
            description: "Get an international standard  and  updated version of drug Information from MIMS every month.",
        },
        {
            id: 3,
            mims_image: MimsBanner,
        },
    ]);


    return (
        < div className="">
            <AutoPlaySwipeableViews interval={5000}>
                {
                    textData.length > 0 && textData.map((item, id) => {
                        if (item.mims_image) {
                            return (
                                <div key={id} style={Object.assign({}, styles.slide, styles.slide1)}>
                                    <div className="mht__mims__img">
                                        <img src={item.mims_image} alt="image" />
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div key={id} style={Object.assign({}, styles.slide, styles.slide1)}>
                                <div className="container mt__req ">
                                    <div className="mb__req">
                                        <h3 className="text-center">
                                            <span className="hreq__text2">{item.title}</span>
                                        </h3>
                                    </div>
                                    <div className="mht__excited mht__rms">
                                        <div className="row g-2 ">
                                            <div className="col-md-8">
                                                <p className="mht__excited__description pt-2">{item.description}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mht__excited__img">
                                                    <img src={item.images} className="img-fluid" alt="images" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt__req__demo text-center pt-4">
                                            <Link to="/request-demo" type="text" className="request__demo__btn2">REQUEST A DEMO TODAY</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </AutoPlaySwipeableViews>
        </div>
    );
};

export default RequestDemoSlider;
