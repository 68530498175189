import React, { useState } from 'react';
import "../home/HealthEcosystem.css";
import Logo__img1 from '../../images/Logo__img1.png';

const HealthEcosystem = ({ arr }) => {

    return (
        <div className="container pt-5">
            <div className="circle-wrapper">
                {/* <h2>Health <span className="text-nowrap">Eco-System</span></h2> */}
                <div className="eco__card__img">
                    <h2>Health <span className="text-nowrap">Eco-System</span></h2>
                    <div className="eco__overlay__img">
                        <img src={Logo__img1} alt="Avatar" className="image" />
                    </div>
                </div>
                <div className="">
                    <div className="circle deg-0">
                        <div className="circle__padding">
                            <h5>{arr[0]?.title}</h5>
                        </div>
                    </div>
                    <div className="circle deg-25">
                        <div className="circle__padding">
                            <h5>{arr[1]?.title}</h5>
                        </div>
                    </div>
                    <div className="circle deg-52">
                        <div className="circle__padding">
                            <h5>{arr[2]?.title}</h5>
                        </div>
                    </div>
                    <div className="circle deg-78">
                        <div className="circle__padding">
                            <h5>{arr[3]?.title}</h5>
                        </div>
                    </div>
                    <div className="circle deg-103">
                        <div className="circle__padding">
                            <h5>{arr[4]?.title}</h5>
                        </div>
                    </div>
                    <div className="circle deg-129">
                        <div className="circle__padding">
                            <h5>{arr[5]?.title}</h5>
                        </div>
                    </div>
                    <div className="circle deg-154">
                        <div className="circle__padding">
                            <h5>{arr[6]?.title}</h5>
                        </div>
                    </div>
                    <div className="circle deg-180">
                        <div className="circle__padding">
                            <h5>{arr[7]?.title}</h5>
                        </div>
                    </div>
                    <div className="circle deg-205">
                        <div className="circle__padding">
                            <h5>{arr[8]?.title}</h5>
                        </div>
                    </div>
                    <div className="circle deg-231">
                        <div className="circle__padding">
                            <h5>{arr[9]?.title}</h5>
                        </div>
                    </div>
                    <div className="circle deg-256">
                        <div className="circle__padding">
                            <h5>{arr[10]?.title}</h5>
                        </div>
                    </div>
                    <div className="circle deg-282">
                        <div className="circle__padding">
                            <h5>{arr[11]?.title}</h5>
                        </div>
                    </div>
                    <div className="circle deg-307">
                        <div className="circle__padding">
                            <h5>{arr[12]?.title}</h5>
                        </div>
                    </div>
                    <div className="circle deg-333">
                        <div className="circle__padding">
                            <h5>{arr[13]?.title}</h5>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default HealthEcosystem;