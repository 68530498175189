
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HowItWorkingProcess from './HowItWorkingProcess';
import NewsLetter from '../home/NewsLetter';

import greatPharmaLM from '../../images/greatPharmaLM.png';
import mpm_img from '../../images/mpm_img.png';
import youtube_arrow_icons from '../../images/youtube_arrow_icons.png';
import Search_buymedicine from '../../images/final_icons/Search_buymedicine.png';
import UploadMedicineOrder from '../../images/final_icons/UploadMedicineOrder.png';
import OfferandDiscount from '../../images/final_icons/OfferandDiscount.png';
import SendFeedback from '../../images/final_icons/SendFeedback.png';
import FindDoctor from '../../images/final_icons/FindDoctor.png';
import CallUs from '../../images/final_icons/CallUs.png';
import MedicineReminder from '../../images/final_icons/MedicineReminder.png';
import OnlinePAyment from '../../images/final_icons/OnlinePAyment.png';
import PharmacyDetails from '../../images/final_icons/PharmacyDetails.png';
import CatMedicine from '../../images/final_icons/CatMedicine.png';
import TermsCondictions from '../../images/final_icons/TermsCondictions.png';
import FAQ from '../../images/final_icons/FAQ.png';
import twenty_hours from '../../images/twenty_hours.png';
import POS_soft from '../../images/POS_soft.png';
import global_payment from '../../images/global_payment.png';
import multi_platform from '../../images/multi_platform.png';
import Multi_Language from '../../images/Multi_Language.png';
import epharmacy_img from '../../images/epharmacy_img.png';
import ehr_records_img from '../../images/ehr_records_img.png';
import mental_img from '../../images/mental_img.png';
import cardiologists_img from '../../images/cardiologists_img.png';
import allied_img from '../../images/allied_img.png';
import ophthalmologists_img from '../../images/ophthalmologists_img.png';
import alternative_img from '../../images/alternative_img.png';
import search_medicine__img from '../../images/search_medicine__img.png';
import add_item__img from '../../images/add_item__img.png';
import upload_precribe__img from '../../images/upload_precribe__img.png';
import pay_track__img from '../../images/pay_track__img.png';


const EPharmacy = () => {
    const howToProcessEPharma = [
        { id: 1, images: search_medicine__img, title: "Search Medicine" },
        { id: 2, images: add_item__img, title: "Add Item To Cart" },
        { id: 3, images: upload_precribe__img, title: "Upload Prescription" },
        { id: 4, images: pay_track__img, title: "Pay & Track Your Order" },
    ];
    // console.log("arrData", howToProcessEPharma);

    const [featuresEHR, setFeaturesEHR] = useState([
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: ehr_records_img, id: 1
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: mental_img, id: 2
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: cardiologists_img, id: 3
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: cardiologists_img, id: 4
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: cardiologists_img, id: 5
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: allied_img, id: 6
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: ophthalmologists_img, id: 7
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: alternative_img, id: 8
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: ophthalmologists_img, id: 8
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: alternative_img, id: 10
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: alternative_img, id: 11
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: alternative_img, id: 12
        },

    ]);


    const [onlinePharmacyApp, setOnlinePharmacyApp] = useState([
        {
            title: "Search & Buy Medicine", description: "With the MHP App, there is an option to easily search for any type of medicine. You can even see the composition and brands of the medicine before you can put them in your cart and purchase them.",
            images: Search_buymedicine, id: 1
        },
        {
            title: "Upload Medicine Order", description: "The option to upload medicine orders directly from your phone makes it easy for clients to take a photo and upload it immediately.",
            images: UploadMedicineOrder, id: 2
        },
        {
            title: "Offers & Discount", description: "You can easily send push notifications and in-app notifications to your customers to inform them about new offers and discounts that are available via the E-pharma app.",
            images: OfferandDiscount, id: 3
        },
        {
            title: "Send Feedback", description: "The send feedback option gives you a chance to get live feedback from your clients and improve your services accordingly.",
            images: SendFeedback, id: 4
        },
        {
            title: "Find A Doctor", description: "We also help you expand your services to find a doctor which can help you network with other doctors in the area",
            images: FindDoctor, id: 5
        },
        {
            title: "Call Us", description: "The App will allow your clients to contact you anytime by pressing the call button.",
            images: CallUs, id: 6
        },
        {
            title: "Medicine Reminder", description: "Medicine reminder feature is one of the most appreciated features which send a notification or a reminder to the customers to inform them that it is time to order their medicine. This feature helps in making sure that you don't end up losing a valued client.",
            images: MedicineReminder, id: 7
        },
        {
            title: "Online Payment", description: "We make sure that the payments that are made are extremely secure. There are also integrated wallets like Bkash and Rocket. The clients will also have an option to pay through Net Banking or Credit/debit cards.",
            images: OnlinePAyment, id: 8
        },
        // {
        //     title: "Manage Account", description: "Each client can sign up with their ID and password so it is more organised and it becomes easier to manage their accounts",
        //     images: Medicine, id: 9
        // },
        {
            title: "Pharmacy Details", description: "The clients can check your pharmacy details very easily on the app so you don't worry about establishing your authenticity to clients.",
            images: PharmacyDetails, id: 10
        },
        // {
        //     title: "Share App", description: "Easy sharing of the app helps the clients to share and spread the word about your e-pharmacy to other prospective clients.",
        //     images: Medicine, id: 11
        // },
        {
            title: "Category wise Medicines", description: "We allow sorting of medicine by category which makes it easier for clients to make their selection.",
            images: CatMedicine, id: 12
        },
        {
            title: "Terms & Conditions", description: "You can give any type of terms and conditions that your company follows.",
            images: TermsCondictions, id: 13
        },
        {
            title: "FAQ", description: "You can also update a FAQ section at your end so that your customers have their basic questions answered.",
            images: FAQ, id: 14
        },
        // {
        //     title: "Order History", description: "Having an order history helps you be aware of the regular needs of your clients and hence you can create personalised promotions and deals for your clients",
        //     images: Medicine, id: 15
        // },
        // {
        //     title: "About App", description: "You can also give details about your pharmacy so the customers know more about your services, quality, facilities, etc.",
        //     images: Medicine, id: 16
        // },

    ]);

    const [specialtySpecific, setSpecialtySpecific] = useState([
        {
            id: 1,
            title: "Time Saved",
            no_of_percentance: "90%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 2,
            title: "Increase in the Productivity",
            no_of_percentance: "25%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 3,
            title: "Smart Workflow",
            no_of_percentance: "93%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
    ]);

    const OurEcommerceTSolutionsSlider = {
        className: "center",
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    const [ourEcommerceITSolutions, setOurHealthcareITSolutions] = useState([
        {
            title_name: "Multi-Platform", technologies_img: multi_platform, id: 1
        },
        {
            title_name: "Multi-LinguaL", technologies_img: Multi_Language, id: 2
        },
        {
            title_name: "Global Payments", technologies_img: global_payment, id: 3
        },
        {
            title_name: "POS Software", technologies_img: POS_soft, id: 4
        },
        {
            title_name: "24*7-SLA Support", technologies_img: twenty_hours, id: 5
        },

    ]);

    return (
        <>
            <div className="bg__body">
                <div className="specialities__banner">
                    <div className="specialities__banner__img">
                        <img src={epharmacy_img} alt="image" />
                    </div>
                    <div className="specialities__banner__text__h">
                        <h3 className="specialities__banner__title">e-Pharmacy</h3>
                        <div className="specialities__banner__text">
                            <p className="solution__banner__description">
                                If you have a pharmacy, MacroHealthPlus can help you create an online pharmacy app quickly and inexpensively.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container ">
                    <div className="row g-3  margin__top mb-2">
                        <div className="col-md-6">
                            <div className="pharmacy">
                                <h3 className="mb-2">
                                    <span className="header__text">e-Pharmacy</span>
                                </h3>
                                <p className="header__description pe-md-5">
                                    <strong className="inspiration__span"> e-Pharmacy</strong> is giving amazing medicine online shopping experience to patients while they need medicine or other products.
                                    Home delivery is ideal as they prefer. They may require bed rest and refrain from travel, or frailty prevent them
                                    from shop access. You can choose to buy medicine online from an <strong className="inspiration__span"> e-Pharmacy</strong> website or mobile app.
                                </p>
                                <div className="pt-3">
                                    <Link to="/request-demo" type="text" className="btn btn__ehr">Free Demo</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ehr__img">
                                <img src={greatPharmaLM} className="img-fluid" alt="image" border="0" />
                            </div>
                        </div>
                    </div>

                    <div className="row g-3  mpm__margin">
                        <div className="col-md-6 order-sm-first order-last">
                            <div className="mpm__img">
                                <img src={mpm_img} className="img-fluid" alt="image" border="0" />
                                <div className="mpm__overlay__text">
                                    <div className="mpm__youtube__icons">
                                        <img src={youtube_arrow_icons} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mpm">
                                <div className="mpm">
                                    <h3 className="text-center mb-2">
                                        <span className="header__text">How Medicine <strong className="inspiration__span">e-Pharmacy</strong> System Works?</span>
                                    </h3>
                                    <p className="header__description text-center">
                                        Our system is Secured, User friendly and Ease to use.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <HowItWorkingProcess arrData={howToProcessEPharma} />
                        </div>
                    </div>

                    <div className="row pt-5">
                        <div className="col-sm-12">
                            <h3 className="text-center mb-2">
                                <span className="header__text"><strong className="inspiration__span">e-Pharmacy</strong> Website or Mobile App</span>
                            </h3>
                            <div className="macro">
                                <p className="header__description solution__macro">
                                    <strong className="inspiration__span"> e-Pharmacy</strong> will  give you amazing online medicine shopping experience to patients.
                                    Home delivery is ideal as they prefer not to go outside. They may require bed rest and refrain from travel or choose to buy
                                    medicine online from an <strong className="inspiration__span"> e-Pharmacy</strong> website or mobile app.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-5">
                        <div className="col-sm-12">
                            <h3 className="text-center mb-2">
                                <span className="header__text">Key Features of Medicine <strong className="inspiration__span">e-Pharmacy</strong> App</span>
                            </h3>
                            <div className="macro">
                                <p className="header__description solution__macro">
                                    If you have a pharmacy, <strong className="inspiration__span">MacroHealthPlus</strong> can help you create an online pharmacy app quickly and inexpensively.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3 gx-sm-5 pt-4 d-flex justify-content-center">
                        {
                            onlinePharmacyApp.length > 0 && onlinePharmacyApp.map((item, id) => {
                                return (
                                    <div key={id} className="col-md-3">
                                        <div className="general__practice__card">
                                            <div className="card text-center">
                                                <div className="d-flex justify-content-center pt-4">
                                                    <div className="bg__card__img">
                                                        <img src={item.images} className="card-img-top" alt="images" />
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <h6 className="header__gp__card__subtitle">{item.title}</h6>
                                                    {/* <p className="header__card__discription">{item.description}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className="row mb-5 margin__top ">
                        <div className="col-12">
                            <h3 className="text-center mb-4">
                                <span className="header__text">Main Strengths of Medicine <strong className="inspiration__span">e-Pharmacy</strong> App Solution  </span>
                            </h3>
                            <div className="web__carousel">
                                <Slider {...OurEcommerceTSolutionsSlider}>
                                    {
                                        ourEcommerceITSolutions.length > 0 && ourEcommerceITSolutions.map((item, id) => {
                                            return (
                                                <div key={id} className="row g-2">
                                                    <div className="col-12">
                                                        <div className="healthcare__it__carousel__box__card">
                                                            <div className="card text-center">
                                                                <div className="d-flex justify-content-center pt-4">
                                                                    <img src={item.technologies_img} className="card-img-top" alt="image" style={{ width: "80px", height: "85px" }} />
                                                                </div>
                                                                <div className="card-body">
                                                                    <h6 className="header__card__subtitle mb-2">{item.title_name}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="speciality__bg">
                <div className="speciality">
                    <h3 className="header__speciality__text">Specialty-Specific All-in-One Solution</h3>
                    <div className="container speciality__specific">
                        <div className="row g-3">
                            {
                                specialtySpecific.length > 0 && specialtySpecific.map((item, id) => {
                                    return (
                                        <div key={id} className="col-md-4">
                                            <div className="specialty__specific__card">
                                                <div className="card text-center">
                                                    <div className="card-body">
                                                        <h5 className="specialty__specific__card__title">{item.title}</h5>
                                                        <h3 className="specialty__specific__card__title__percent">{item.no_of_percentance}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <NewsLetter />
            </div>
        </>
    );
};

export default EPharmacy;