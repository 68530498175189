import React, { useState } from 'react';
import data_safety_security_img from '../../images/data_safety_security_img.png';
import NewsLetter from '../home/NewsLetter';

const DataSafetySecurity = () => {
    const [dataSafetyAndSecurity, setDataSafetyAndSecurity] = useState([
        { id: 1, title: "Cloud Integration", description: "Protects from online security threats All communication is protected with 256 bit secure encryption and 24 hours data backup" },
        { id: 2, title: "Permitted Browser Feature", description: "With this feature you can limit software usage for your employees to your user computers" },
        { id: 3, title: "Manage User Permission", description: "All users such as Doctor, Nurse and Allied Health professional are assigned permissions to limit their access to data" },
        { id: 4, title: "Continuous Data Protection", description: "All data is being backed up in real-time in a follower database server. All documents are stored with safety and security of google server (Primary site in Singapore and Recovery site in Ireland) or customized according to owners requirements. Database server and webserver are fully compliant with Global certification " },
    ]);
   
    return (
        <>
            <div className="bg__body">
                <div className="container">
                    <div className="row g-3  pt-5 mb-2">
                        <div className="col-md-6">
                            <div className="data__safety__security">
                                <h3 className="text-center mb-4">
                                    <span className="header__unique__features">Data Safety and Security</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 ps-md-5 pe-md-5">
                            <div className="data__safety__security__img">
                                <img src={data_safety_security_img} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row g-3  pt-5 mb-2">
                        {
                            dataSafetyAndSecurity.length > 0 && dataSafetyAndSecurity.map((item, id) => {
                                return (
                                    <div key={id} className="col-md-6">
                                        <div className="data__safety__card">
                                            <div className="card">
                                                <div className="card-body p-4">
                                                    <h6 className="data__safety__card__title">{item.title}</h6>
                                                    <p className="data__safety__card__description">{item.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <NewsLetter />
            </div>
        </>
    );
};

export default DataSafetySecurity;