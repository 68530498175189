import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PharmacyPOSAccrodian from './PharmacyPOSAccrodian';
import HowItWorkingProcess from './HowItWorkingProcess';
import NewsLetter from '../home/NewsLetter';

import PosSystem__img from '../../images/PosSystem__img.png';
import mpm_img from '../../images/mpm_img.png';
import youtube_arrow_icons from '../../images/youtube_arrow_icons.png';
import Pharma from '../../images/Pharma.jpg';
import GainClearView from '../../images/final_icons/GainClearView.png';
import ManualEPrescription from '../../images/final_icons/ManualEPrescription.png';
import Campaign from '../../images/final_icons/Campaign.png';
import KeepTrack_stock from '../../images/final_icons/KeepTrack_stock.png';
import CustomerService from '../../images/final_icons/CustomerService.png';
import ModernConsumer from '../../images/final_icons/ModernConsumer.png';
import medicine__img4 from '../../images/medicine__img4.png';
import search_medicine__img from '../../images/search_medicine__img.png';
import sales_point__img from '../../images/sales_point__img.png';
import delivery__img4 from '../../images/delivery__img4.png';



const GreatPharma = () => {
    const howToProcessGreatPharma = [
        { id: 1, images: medicine__img4, title: "Medicine" },
        { id: 2, images: search_medicine__img, title: "Stock Check" },
        { id: 3, images: sales_point__img, title: "Payment" },
        { id: 4, images: delivery__img4, title: "Delivery" },
    ];
    // console.log("arrData", howToProcessGreatPharma);
    const [greatPharmacySoftware, setGreatPharmacySoftware] = useState([
        {
            title: "Gain a clear overview of your business", description: "Manage all of your locations from headquarters. Take decisions at head office and distribute them immediately across the organisation.",
            images: GainClearView, id: 1
        },
        {
            title: "Handle manual and e-prescriptions", description: "Accept both paper and electronic prescriptions. The system takes care of storing an electronic copy of a paper prescription.",
            images: ManualEPrescription, id: 2
        },
        {
            title: "Manage campaigns and offers", description: "Set up prices, offers and campaigns in advance. Distribute them across your operation and keep control over them from head office",
            images: Campaign, id: 3
        },
        {
            title: "Keep track of your stock", description: "The MHP pharmacy POS terminal is not simply a sales device. Use it to check up-to-date inventory, store and location data.",
            images: KeepTrack_stock, id: 4
        },
        {
            title: "Give better customer service", description: "Suggest a generic medicine instead of the branded one that was prescribed. The system will find appropriate substitutes with the same ATC code and strength.",
            images: CustomerService, id: 5
        },
        {
            title: "Reach out to modern consumers", description: "Notify your customers when their prescriptions are close to their last withdrawal via email, text message and mobile app notification.",
            images: ModernConsumer, id: 6
        },

    ]);

    const [specialtySpecific, setSpecialtySpecific] = useState([
        {
            id: 1,
            title: "Time Saved",
            no_of_percentance: "90%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 2,
            title: "Increase in the Productivity",
            no_of_percentance: "25%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 3,
            title: "Smart Workflow",
            no_of_percentance: "93%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
    ])

    return (
        <>
            <div className="bg__body">
                <div className="specialities__banner">
                    <div className="specialities__banner__img">
                        <img src={Pharma} alt="image" />
                    </div>
                    <div className="specialities__banner__text__h">
                        <h3 className="specialities__banner__title">GreatPharma</h3>
                        <div className="specialities__banner__text">
                            <p className="solution__banner__description">
                                Digitise Your Pharmacy with GreatPharma
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container ">
                    <div className="row g-3  pt-5 mb-2">
                        <div className="col-md-6">
                            <div className="">
                                <h3 className="mb-2">
                                    <span className="header__text">GreatPharma</span>
                                </h3>
                                <p className="header__description pe-md-5">
                                    <strong className="inspiration__span">GreatPharma</strong> software empowers you with a complete business solution, full drug life cycle covered. It's the best pharmacy software with, purchase order, requisition, manager approval, list for items with low stock, expiry date check, rack management, sales automation functions, seamless integrations, and mobile accessibility all in one.
                                </p>
                                <p className="header__description pe-md-5">
                                    <strong className="inspiration__span">GreatPharma</strong> software facilitates increased productivity, better collaborations, and accelerated sales performance. The pharmacy module is equipped with a bar coding facility, which makes the delivery of medical items to the patient more efficient.
                                </p>
                                <div className="pt-3">
                                    <Link to="/request-demo" type="text" className="btn btn__ehr">Free Demo</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ehr__img">
                                <img src={PosSystem__img} className="img-fluid" alt="image" border="0" />
                            </div>
                        </div>
                    </div>
                    <div className="row g-3  mpm__margin">
                        <div className="col-md-6 order-sm-first order-last">
                            <div className="mpm__img">
                                <img src={mpm_img} className="img-fluid" alt="image" border="0" />
                                <div className="mpm__overlay__text">
                                    <div className="mpm__youtube__icons">
                                        <img src={youtube_arrow_icons} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mpm">
                                <div className="mpm">
                                    <h3 className="text-center mb-2">
                                        <span className="header__text">How <strong className="inspiration__span">GreatPharma</strong> System Works?</span>
                                    </h3>
                                    <p className="header__description text-center">
                                        Our system is Secured, User friendly and Ease to use.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <HowItWorkingProcess arrData={howToProcessGreatPharma} />
                        </div>
                    </div>

                    
                    <div className="row margin__top">
                        <div className="col-md-12">
                            <h3 className="text-center mb-2">
                                <span className="header__text">Digitise Your Pharmacy with <strong className="inspiration__span">GreatPharma</strong></span>
                            </h3>
                        </div>
                    </div>
                    <div className="row g-3 gx-sm-5 pt-4 d-flex justify-content-center">
                        {
                            greatPharmacySoftware.length > 0 && greatPharmacySoftware.map((item, id) => {
                                return (
                                    <div key={id} className="col-md-3">
                                        <div className="general__practice__card">
                                            <div className="card text-center">
                                                <div className="d-flex justify-content-center pt-4">
                                                    <div className="bg__card__img">
                                                        <img src={item.images} className="card-img-top" alt="images" />
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <h6 className="header__gp__card__subtitle">{item.title}</h6>
                                                    {/* <p className="header__card__discription">{item.description}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="book__app__bg">
                <div className="book__app">
                    <h5 className="book__app__text">Great Pharmacy module deals with the automation of the general workflow and administration management process of a pharmacy. </h5>
                </div>
            </div>

            <div className="bg__body">
                <div className="container">

                    <div className="row pt-5">
                        <div className="col-sm-12">
                            <h3 className="text-center mb-2">
                                <span className="header__text">Generic Features for <strong className="inspiration__span">GreatPharma</strong></span>
                            </h3>
                            <div className="macro">
                                <p className="header__description solution__macro">
                                    Streamline your workflows and reach new levels of efficiency and professionalism.
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className="row g-3">
                        <PharmacyPOSAccrodian />
                    </div>
                </div>
            </div>


            <div className="speciality__bg">
                <div className="speciality">
                    <h3 className="header__speciality__text">Specialty-Specific All-in-One Solution</h3>
                    <div className="container speciality__specific">
                        <div className="row g-3">
                            {
                                specialtySpecific.length > 0 && specialtySpecific.map((item, id) => {
                                    return (
                                        <div key={id} className="col-md-4">
                                            <div className="specialty__specific__card">
                                                <div className="card text-center">
                                                    <div className="card-body">
                                                        <h5 className="specialty__specific__card__title">{item.title}</h5>
                                                        <h3 className="specialty__specific__card__title__percent">{item.no_of_percentance}</h3>
                                                        {/* <p className="specialty__specific__card__discription">{item.description}</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <NewsLetter />
            </div>
        </>
    );
};

export default GreatPharma;