import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo__img1 from '../../images/Logo__img1.png';



const NewsLetter = () => {

    // subscribe form 
    const [subscribe, setSubscribe] = useState({
        subscribe_name: "",
    });
    const inputFieldHandler = (e) => {
        setSubscribe({ ...subscribe, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log("ss", subscribe);
        setSubscribe({ subscribe_name: "" });
    };


    return (
        <div className="container margin__top">
            <div className="row g-3">
                <div className="col-md-4">
                    <div className="call__us__img">
                        <img src={Logo__img1} className="img-fluid" alt="Logo__img1" border="0" />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="">
                        <p className="call__us__text" onClick={() => { window.location.href = 'mailto:info@macrohealthplus.org'; }}>info@macrohealthplus.org</p>
                    </div>
                    <div className="">
                        <p className="call__us__text__no">+880-1855-777766</p>
                    </div>
                    <div className="email__demo__today">
                        <Link to="/request-demo" className="btn  bt__demo__today">Get your Free DEMO Today!</Link>
                    </div>
                </div>
                <div className="col-md-4">
                    <h4 className="news__letter__text text-center mb-2 ">News letter</h4>
                    <div className="news__letter">
                        <form action="" onSubmit={handleSubmit}>
                            <div className="row g-3">
                                <div className="col-sm-12">
                                    <input type="text" className="form-control" placeholder="Enter your E-mail" aria-label="Enter your E-mail"
                                        name='subscribe_name'
                                        value={subscribe.subscribe_name}
                                        onChange={inputFieldHandler}
                                    />
                                </div>
                                <div className="col-sm-12">
                                    <button type="submit" className="btn news__subscribe">Subscribe</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsLetter;