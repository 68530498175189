
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HorizontalStepperSpecialist from './HorizontalStepperSpecialist';
import StepperSpecialistMobile from './StepperSpecialistMobile';
import NewsLetter from '../home/NewsLetter';

import CranialNerveNeuroSurgen from '../../images/CranialNerveNeuroSurgen.png';
import WorkFlow1 from '../../images/option_2/WorkFlow1.png';
import AcceptableTechnology3 from '../../images/option_2/AcceptableTechnology3.png';
import EasyAccess1 from '../../images/option_2/EasyAccess1.png';
import UserFriendly3 from '../../images/option_2/UserFriendly3.png';
import Cloud4 from '../../images/option_2/Cloud4.png';
import DataSecurity4 from '../../images/option_2/DataSecurity4.png';
import EasyConfiguration9 from '../../images/option_2/EasyConfiguration9.png';
import Paperless2 from '../../images/option_2/Paperless2.png';
import Electronic_health_record9 from '../../images/option_2/Electronic_health_record9.png';
import neuro_surgeon_img_banner from '../../images/neuro_surgeon_img_banner.jpg';

import Appointment_im from '../../images/Appointment_im.jpg';
import Step2HistoryCardio from '../../images/SpecialistStep/NeuroSurgeon/Step2HistoryCardio.png';
import Step3HistoryCardioOutput from '../../images/SpecialistStep/NeuroSurgeon/Step3HistoryCardioOutput.png';
import Step4InvestigationOrder from '../../images/SpecialistStep/CARDIO/Step4InvestigationOrder.png';
import Step5ResultChkupFlow from '../../images/SpecialistStep/CARDIO/Step5ResultChkupFlow.png';
import Step6WriteInPrescription from '../../images/SpecialistStep/CARDIO/Step6WriteInPrescription.png';
import Step7DrugInterctionAlert from '../../images/SpecialistStep/CARDIO/Step7DrugInterctionAlert.png';
import Step8MimsDrugInformation from '../../images/SpecialistStep/CARDIO/Step8MimsDrugInformation.png';
import Step9PrescriptionOutput from '../../images/SpecialistStep/CARDIO/Step9PrescriptionOutput.png';

const NeuroSurgeon = () => {
    const neuroSurgeon = [
        { id: 1, label_name: 'Appointment', images: Appointment_im, },
        { id: 2, label_name: 'History & Examination', images: Step2HistoryCardio, },
        { id: 3, label_name: 'History & Examination Output', images: Step3HistoryCardioOutput, },
        { id: 4, label_name: 'Investigation Order', images: Step4InvestigationOrder, },
        { id: 5, label_name: 'Result Check & Follow-up', images: Step5ResultChkupFlow, },
        { id: 6, label_name: 'Write in Prescription', images: Step6WriteInPrescription, },
        { id: 7, label_name: 'Drug Interaction Alert', images: Step7DrugInterctionAlert, },
        { id: 8, label_name: 'MIMS Drug Information', images: Step8MimsDrugInformation, },
        { id: 9, label_name: 'Prescription Output', images: Step9PrescriptionOutput, },
    ];
    const [appointmentManagement, setAppointmentManagement] = useState([
        {
            title: "Neurology specific workflow", images: WorkFlow1, id: 1
        },
        {
            title: "Complete EHR for Neurology", images: Electronic_health_record9, id: 2
        },
        {
            title: "Paperless", images: Paperless2, id: 3
        },
        {
            title: "Easy configuration ", images: EasyConfiguration9, id: 4
        },
        {
            title: "Data security ", images: DataSecurity4, id: 5
        },
        {
            title: "Client-server or cloud-based ", images: Cloud4, id: 6
        },
        {
            title: "User friendly", images: UserFriendly3, id: 6
        },
        {
            title: "Quickly access ", images: EasyAccess1, id: 6
        },
        {
            title: "Adaptable technology", images: AcceptableTechnology3, id: 6
        },

    ]);
    const [specialtySpecific, setSpecialtySpecific] = useState([
        {
            id: 1,
            title: "Time Saved",
            no_of_percentance: "90%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 2,
            title: "Increase in the Productivity",
            no_of_percentance: "25%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 3,
            title: "Smart Workflow",
            no_of_percentance: "93%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
    ]);

    return (
        <>
            <div className="bg__body">
                <div className="specialities__banner">
                    <div className="specialities__banner__img">
                        <img src={neuro_surgeon_img_banner} alt="image" />
                    </div>
                    <div className="specialities__banner__text__h">
                        <h3 className="specialities__banner__title">Neuro Surgeon</h3>
                        <div className="specialities__banner__text">
                            <p className="specialities__banner__description">
                                Innovative Practice Management Software for Neuro Surgeon
                            </p>
                            <div className="text-center">
                                <Link to="/request-demo" type="text" className="btn__ehr">Request For a Demo</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container ">
                    <div className="row pt-5">
                        <div className="col-sm-12">
                            <div className="macro">
                                <p className="header__description__gp solution__macro">
                                    <strong className="inspiration__span">Neurology</strong>-specific EHR allows the Clinician to understand patient history quickly, communicate better with patients and help staff get more done in less time.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row g-3  margin__top">
                        <div className="col-md-5">
                            <div className="gp">
                                <p className="h__description pe-md-5">
                                    Extensive and intuitive Neuro Surgeon specific EHR and practice management solutions are tailored to your practice workflow to meet your multidisciplinary needs.
                                </p>
                                <p className="h__description pe-md-5">
                                    Streamlining communication and access to information, they are built from the ground up for mobile medicine and optimised for any platform
                                </p>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="ehr__img1">
                                <img src={CranialNerveNeuroSurgen} className="img-fluid" alt="image" border="0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="margin__top">
                <div className="book__app__bg">
                    <div className="book__app">
                        <h5 className="book__app__text">MacroHealthPlus offers a fully integrated  solutions designed for Neuro Surgeon  practices to streamline administration functions- built with custom features, reports and templates, clinical care, and revenue cycle management in one single place.</h5>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <div className="container ">

                    <div className="row g-3">
                        <div className="col-md-12">
                            <div className="margin__top">
                                <h3 className="text-center mb-2">
                                    <span className="header__text">How <strong className="inspiration__span">Neuro Surgeon</strong> System Works?</span>
                                </h3>
                                <p className="header__description text-center">
                                    Our system is Secured, User friendly and Ease to use.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="show__big__device">
                                <HorizontalStepperSpecialist arr={neuroSurgeon} />
                            </div>
                            <div className="show__small__device">
                                <StepperSpecialistMobile arr={neuroSurgeon} />
                            </div>
                        </div>
                    </div>
                    <div className="row margin__top">
                        <div className="col-md-12">
                            <div className="key__feature">
                                <h3 className="text-center mb-2">
                                    <span className="header__text">Key Features for Neurology </span>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3 gy-4 gx-sm-5 pt-4 d-flex justify-content-center">
                        {
                            appointmentManagement.length > 0 && appointmentManagement.map((item, id) => {
                                return (
                                    <div key={id} className="col-md-3">
                                        <div className="general__practice__card">
                                            <div className="card text-center">
                                                <div className="d-flex justify-content-center pt-4">
                                                    <div className="bg__card__img">
                                                        <img src={item.images} className="card-img-top" alt="images" />
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <h6 className="header__gp__card__subtitle">{item.title}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="speciality__bg">
                <div className="speciality">
                    <h3 className="header__speciality__text">Specialty-Specific All-in-One Solution</h3>
                    <div className="container speciality__specific">
                        <div className="row g-3">
                            {
                                specialtySpecific.length > 0 && specialtySpecific.map((item, id) => {
                                    return (
                                        <div key={id} className="col-md-4">
                                            <div className="specialty__specific__card">
                                                <div className="card text-center">
                                                    <div className="card-body">
                                                        <h5 className="specialty__specific__card__title">{item.title}</h5>
                                                        <h3 className="specialty__specific__card__title__percent">{item.no_of_percentance}</h3>
                                                        {/* <p className="specialty__specific__card__discription">{item.description}</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <NewsLetter />
            </div>
        </>
    );
};

export default NeuroSurgeon;