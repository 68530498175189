import { PanoramaSharp } from '@mui/icons-material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import under_construction from "../../images/under_construction.jpg";
import DrAminurRahman from '../../images/DrAminurRahman.jpg';
import AminurRahman__img from '../../images/AminurRahman__img.jpg';
import NAEheadShot__img from '../../images/NAEheadShot__img.jpg';



const OurAdvisoryMembersDetails = () => {

    const [ourAdvisoryMembers, setOurAdvisoryMembers] = useState([
        { id: 1, image: NAEheadShot__img, name: "Nick Ebbeck", title: "Managing Director & CEO", description: "I am a Doctor Surgeon", card_link: "our-advisory-members-details" },
        { id: 2, image: NAEheadShot__img, name: "Dr. Aminur Rahman", title: "Managing Director & CEO", description: "I am a Doctor Surgeon", card_link: "our-advisory-members-details" },
        { id: 3, image: DrAminurRahman, name: "Dr. Aminur Rahman", title: "Managing Director & CEO", description: "I am a Doctor Surgeon", card_link: "our-advisory-members-details" },
        { id: 4, image: DrAminurRahman, name: "Dr. Aminur Rahman", title: "Managing Director & CEO", description: "I am a Doctor Surgeon", card_link: "our-advisory-members-details" },
    ]);
    
    const param =useParams();
    const doctor = ourAdvisoryMembers.find(item=>item.id===Number(param.id));
    console.log(doctor , "doc");
    
    return (
        <div className="container pt-2 mb-5">
            <h3 className="text-center mb-2">
                <span className="header__text">Our Advisory Members Details</span>
            </h3>
            <div className="row g-3 pt-3">
                <div className="col-md-4">
                    <div className="pres__message__img">
                        <img src={doctor?.image} className="card-img-top" alt="images" />
                    </div>
                    {/* <div className="btn__accom__more text-center pt-3">
                            <button type="button" className="btn btn__more">Load More</button>
                        </div> */}
                </div>
                <div className="col-md-8">
                    <div className="ms-3 ">
                        <h5 className="board__title">{doctor?.title}</h5>
                        <h5 className="board__desc">{doctor?.designation}</h5>
                        <p className="board__desc"><i className="fa-solid fa-envelope"></i> {doctor?.email}</p>
                        <p className="board__desc">{doctor?.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurAdvisoryMembersDetails;