import React, { useState } from 'react';
import Arrow__img from '../../images/Arrow__img.png';

const HowItWorkingProcess = (props) => {
    // console.log(props.arrData ,"arrData222");
    // console.log(props.arrData, "arrData222");

    return (
        props.arrData.length > 0 &&
        <div key={props.i} className="margin__top">
            <div className="how__it__process__cont">
                <div className="how__it__process__div">
                    <div className="how__it__process  pt-4">
                        <img src={props.arrData[0].images} className="card-img-top" alt="images" />
                    </div>
                    <div className="card-body">
                        <h6 className="it__process__title">{props.arrData[0].title}</h6>
                    </div>
                </div>
                <div className="how__it__process__arrow">
                    <div className="it__process__img  pt-4">
                        <img src={Arrow__img} className="card-img-top arrow__img" alt="images" />
                    </div>
                </div>
                <div className="how__it__process__div">
                    <div className="how__it__process  pt-4">
                        <img src={props.arrData[1].images} className="card-img-top" alt="images" />
                    </div>
                    <div className="card-body">
                        <h6 className="it__process__title">{props.arrData[1].title}</h6>
                    </div>
                </div>
                <div className="how__it__process__arrow">
                    <div className="it__process__img  pt-4">
                        <img src={Arrow__img} className="card-img-top arrow__img" alt="images" />
                    </div>
                </div>
                <div className="how__it__process__div">
                    <div className="how__it__process  pt-4">
                        <img src={props.arrData[2].images} className="card-img-top" alt="images" />
                    </div>
                    <div className="card-body">
                        <h6 className="it__process__title">{props.arrData[2].title}</h6>
                    </div>
                </div>
                <div className="how__it__process__arrow">
                    <div className="it__process__img  pt-4">
                        <img src={Arrow__img} className="card-img-top arrow__img" alt="images" />
                    </div>
                </div>
                <div className="how__it__process__div">
                    <div className="how__it__process  pt-4">
                        <img src={props.arrData[3].images} className="card-img-top" alt="images" />
                    </div>
                    <div className="card-body">
                        <h6 className="it__process__title">{props.arrData[3].title}</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorkingProcess;