import React, { useState } from 'react';
import NewsLetter from '../home/NewsLetter';

const UniqueFeatures = () => {
    const [improveWorkflow, setImproveWorkflow] = useState([
        {
            id: 1, description: " International standard integrated medical software to direct communication between patient-doctor in a secure platform",
        },
        {
            id: 2, description: "Electronic Health Record system with minimal data entry.",
        },
        {
            id: 3, description: "Incorporating World Class MIMS Drug database to protect doctors from error in a very busy practice.",
        },
        {
            id: 4, description: "Automatically alert doctors/health professionals about life saving Drug interactions, Drug-Disease interactions, Drug-Allergy Interactions, Drug-Lactation interactions, Drug-Pregnancy alert, Drug- Duplicate alert during preparing prescriptions.",
        },
        {
            id: 5, description: "Advanced and effective patient screening facilities from booking to billing",
        },
        {
            id: 6, description: "Including immunisation records from birth to end of life. Various Clinical resources for the clinicians.",
        },
        {
            id: 7, description: "Any doctor can advise any patient remotely from anywhere in the world without the need for any paper or pencil just using a mobile phone.",
        },
        {
            id: 8, description: "Various Patient education material first time in Bengali language",
        },
        {
            id: 9, description: "Doctor’s Education from the Australian Clinician to enrich personalised education about Current international practice.",
        },
    ])
    return (
        <>
            <div className="bg__body">
                <div className="container">
                    <div className="row g-3  pt-5 d-flex align-items-center">
                        <div className="col-md-12">
                            <h3 className="mb-3">
                                <span className="header__unique__features">Improve Your Workflow with MacroHealthPlus Practice Managment Software</span>
                            </h3>
                            <div className="unique__features__description">
                                <ul className="list-styled">
                                    {
                                        improveWorkflow.length > 0 && improveWorkflow.map((item, id) => {
                                            return (
                                                <li key={id}>{item.description}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <NewsLetter />
            </div>
        </>
    );
};

export default UniqueFeatures;