
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Digipatient__img from '../../images/Digipatient__img.png';
import HowItWorkingProcess from './HowItWorkingProcess';
import AccrodianPRM from './accrodianForDigi/AccrodianPRM';
import DigiPatientDetailsProcess from './DigiPatientDetailsProcess';
import NewsLetter from '../home/NewsLetter';
import mpm_img from '../../images/mpm_img.png';
import youtube_arrow_icons from '../../images/youtube_arrow_icons.png';

import upload_medical_doc__img from '../../images/upload_medical_doc__img.png';
import medical_history__img from '../../images/medical_history__img.png';
import vital_records__img from '../../images/vital_records__img.png';
import register__img from '../../images/register__img.png';
import WorkfromAnywhere from '../../images/final_icons/WorkfromAnywhere.png';
import TakeCompareImages from '../../images/final_icons/TakeCompareImages.png';
import SearchPatientHealthRecords from '../../images/final_icons/SearchPatientHealthRecords.png';
import StructualNotes from '../../images/final_icons/StructualNotes.png';
import ConfigurableNotes from '../../images/final_icons/ConfigurableNotes.png';
import Storages from '../../images/final_icons/Storages.png';
import Digipatient_banner__img from '../../images/Digipatient_banner__img.jpg';


const DigiPatient = () => {
    const howToProcessDigiPatient = [
        { id: 1, images: register__img, title: "Registration" },
        { id: 2, images: vital_records__img, title: "Vital Records" },
        { id: 3, images: medical_history__img, title: "Medical History" },
        { id: 4, images: upload_medical_doc__img, title: "Upload Medical Documents" },
    ];
    // console.log("arrData", howToProcessDigiPatient);

    const [appointmentManagement, setAppointmentManagement] = useState([
        {
            title: "Work from anywhere", description: "Take your medical records with you - whether you want to give permission for view at multiple clinic locations or review your EHR at home, or access patient records in the event of Emergency by the clinician.",
            images: WorkfromAnywhere, id: 1
        },
        {
            title: "Take and compare images", description: "Show your doctor various stages of the disease in photos to understand their pathogenesis. Control your camera from your EHR",
            images: TakeCompareImages, id: 2
        },
        {
            title: "Search Patient Health Records", description: "MacroHealthPlus EHR provides quick search to help find your records in every stage.",
            images: SearchPatientHealthRecords, id: 3
        },
        {
            title: "Structural notes", description: "Various parts dedicated for your use to keep your record, for comparing and improving the outcome.",
            images: StructualNotes, id: 4
        },
        {
            title: "Configurable Notes", description: "Create case notes, your way. Write, draw, type, and even use speech to text. Go paperless, way you want and best work for you.",
            images: ConfigurableNotes, id: 5
        },
        {
            title: "Storage", description: "Keep your records safe - store as much as you need. MacroHealthPlus storage is expandable according to your need.",
            images: Storages, id: 6
        },

    ]);

    const [specialtySpecific, setSpecialtySpecific] = useState([
        {
            id: 1,
            title: "Time Saved",
            no_of_percentance: "90%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 2,
            title: "Increase in the Productivity",
            no_of_percentance: "25%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 3,
            title: "Smart Workflow",
            no_of_percentance: "93%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
    ]);

    // const [arrData, setArrData] = useState(
    //     {});

    return (
        <>
            <div className="bg__body">
                <div className="specialities__banner">
                    <div className="specialities__banner__img">
                        <img src={Digipatient_banner__img} alt="image" />
                    </div>
                    <div className="specialities__banner__text__h">
                        <h3 className="specialities__banner__title">DigiPatient</h3>
                        <div className="specialities__banner__text">
                            <p className="solution__banner__description">
                                Driving a single digital view at the point of care
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container ">
                    <div className="row g-3  margin__top mb-2">
                        <div className="col-md-5">
                            <div className="">
                                <h3 className="mb-2">
                                    <span className="header__text">Manage your electronic health records, on your way.</span>
                                </h3>
                                <p className="header__description pe-md-5 pt-2">
                                    The Patient Portal has been designed to navigate all the necessary usage in a simple way. Greater visibility
                                    over their health summaries. Easy to upload all your documents. Don’t have to carry and bundle anymore.
                                    No worry to lose or fade away the record. You have your control who will access it and how much it will access.
                                    Patient education material for your convenience in one place. You can answer the clinical questionnaire before
                                    visiting your doctor. Convenient way to communicate to your doctor.
                                </p>
                                <div className="pt-3">
                                    <Link to="/request-demo" type="text" className="btn__ehr">Free Demo</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="ehr__img1">
                                <img src={Digipatient__img} className="img-fluid" alt="image" border="0" />
                            </div>
                        </div>
                    </div>
                    <div className="row g-3  mpm__margin">
                        <div className="col-md-6 order-sm-first order-last">
                            <div className="mpm__img">
                                <img src={mpm_img} className="img-fluid" alt="image" border="0" />
                                <div className="mpm__overlay__text">
                                    <div className="mpm__youtube__icons">
                                        <img src={youtube_arrow_icons} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mpm">
                                <h3 className="text-center mb-2">
                                    <span className="header__text">How <strong className="inspiration__span">DigiPatient</strong> System Works?</span>
                                </h3>
                                <p className="header__description text-center">
                                    Our system is Secured, User friendly and Ease to use.
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-12">
                            <HowItWorkingProcess arrData={howToProcessDigiPatient} />
                        </div>
                    </div>

                    <div className="row pt-5 ">
                        <div className="col-md-12">
                            <DigiPatientDetailsProcess />
                        </div>
                    </div>

                    <div className="row margin__top">
                        <div className="col-md-12">
                            <h3 className="text-center mb-2">
                                <span className="header__text inspiration__span">Key features for Electronic Health Records</span>
                            </h3>
                            <div className="macro">
                                <p className="header__description2 solution__macro">
                                    Keep track of all your health related vital stats and medical documents on the cloud
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row g-3 gx-sm-5 pt-4 d-flex justify-content-center">
                        {
                            appointmentManagement.length > 0 && appointmentManagement.map((item, id) => {
                                return (
                                    <div key={id} className="col-md-4">
                                        <div className="keyhr__card">
                                            <div className="card text-center">
                                                <div className="d-flex justify-content-center pt-4">
                                                    <div className="keyhr__img">
                                                        <img src={item.images} className="card-img-top" alt="images" />
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <h6 className="header__card__subtitle2">{item.title}</h6>
                                                    <p className="header__card__discription">{item.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="book__app__bg margin__top">
                <div className="book__app">
                    <h5 className="book__app__text">Book appointments, confirm it and get alert before the scheduled date.</h5>
                    <h5 className="book__app__text">Save time with automated SMS appointment reminders.</h5>
                </div>
            </div>

            <div className="bg__body">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-12">
                            <h3 className="text-center mb-2">
                                <span className="header__text">Patient Records Management</span>
                            </h3>
                            <div className="macro">
                                <p className="header__description solution__macro">
                                    Streamline your record and reach new levels of efficiency and professionalism.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3">
                        <div className="col-md-12">
                            <AccrodianPRM />
                        </div>
                    </div>
                </div>
            </div>

            <div className="speciality__bg margin__top pt-2">
                <div className="speciality">
                    <h3 className="header__speciality__text">Specialty-Specific All-in-One Solution</h3>
                    <div className="container speciality__specific">
                        <div className="row g-2">
                            {
                                specialtySpecific.length > 0 && specialtySpecific.map((item, id) => {
                                    return (
                                        <div key={id} className="col-md-4">
                                            <div className="specialty__specific__card">
                                                <div className="card text-center">
                                                    <div className="card-body">
                                                        <h5 className="specialty__specific__card__title">{item.title}</h5>
                                                        <h3 className="specialty__specific__card__title__percent">{item.no_of_percentance}</h3>
                                                        {/* <p className="specialty__specific__card__discription">{item.description}</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <NewsLetter />
            </div>
        </>
    );
};

export default DigiPatient;