import React from 'react';
import under_construction from '../../../images/under_construction.jpg';

const DisclaimerCookie = () => {
  return (
    <div>
      <div className='container mt-3'>
        <h3 className='text-start mb-2 pt-2'>
          <span className='header__text'>Disclaimer</span>
        </h3>

        <h3 className='text-start mb-2 pt-2'>
          <span className='header__text'>Disclaimer and Other Terms</span>
        </h3>
        <p style={{ color: '#0D2C49', textAlign: 'justify' }}>
          This website is owned and operated by MacroHealthPlus Software Pty Ltd
          (we or us), and the information and other material on this website
          (Material) has been prepared by us or on our behalf. This website and
          the Material are intended to provide general information only and,
          while it has been prepared with care, it may not be accurate or
          current. Your access and use this website and the Material at your own
          discretion and risk, and you are solely responsible for any losses and
          costs associated with such access or use.
        </p>
        <p style={{ color: '#0D2C49', textAlign: 'justify' }}>
          You acknowledge and agree that:
        </p>
        <ul className='pt-0' style={{ listStyleType: 'circle' }}>
          <li>
            this website and the Material do not constitute professional medical
            or healthcare advice, diagnosis or recommendation of treatment and
            are not intended, nor should be used, to replace such advice;
          </li>
          <li>
            this website and the Material are provided on an ‘as is’ basis and
            any information provided (or not provided) is not intended to be,
            and should not be construed as, a recommendation or advice by us to
            take or refrain from taking any course of action (including in
            relation to your or any other person’s health care needs);
          </li>
          <li>
            in no circumstances should this website or the Material be relied
            upon without consideration and confirmation by an appropriately
            qualified healthcare practitioner exercising his or her independent
            professional judgement and with the benefit of knowledge of your
            condition and medical history;
          </li>
          <li>
            while we endeavour where practical to ensure that this website and
            the Material is up-to-date, accurate and complete, to the maximum
            extent permitted by law, we make no representations or warranties
            that this website or the Material will be up-to-date, accurate,
            complete or useful for the purpose for which it is accessed, and we
            accept no responsibility in that regard; and
          </li>
          <li>
            to the maximum extent permitted by law, we disclaim any liability to
            you or any other person in any way connected with your or their
            accessing, downloading, using or relying on this website or any
            Material, whether caused by our negligence or otherwise. In
            addition, under no circumstances will we be liable for any indirect,
            incidental, special or consequential damages (including damages for
            loss of business or other profits), in any way connected with the
            use of this website or any Material, whether caused by our
            negligence or otherwise.
          </li>
        </ul>
        <p style={{ color: '#0D2C49', textAlign: 'justify' }}>
          To the extent that you purchase goods or services from us through this
          website, separate terms and conditions will apply to that supply.
        </p>

        <h3 className='text-start mb-2 pt-2'>
          <span className='header__text'>Viruses</span>
        </h3>
        <p style={{ color: '#0D2C49', textAlign: 'justify' }}>
          Without limiting the foregoing, we do not represent or warrant that
          any files obtained from or through this website will be free from
          computer viruses or other defects. Any such files are provided, and
          may only be used, on the basis that you assume all responsibility for
          any loss, damage or other consequence resulting directly or indirectly
          from use of those files.
        </p>

        <h3 className='text-start mb-2 pt-2'>
          <span className='header__text'>Third party websites</span>
        </h3>
        <p style={{ color: '#0D2C49', textAlign: 'justify' }}>
          This website may contain hypertext links, frames or other references
          to parties that are unrelated to us and their websites. Such links are
          provided for your convenience only and we accept no responsibility
          for, and make no representation or warranty about, the accuracy or
          completeness of, or any other matter regarding, those websites. We do
          not necessarily approve of, or endorse, any material on such websites,
          the parties that operate those websites, or any goods or services that
          they supply (whether or not through those websites). You acknowledge
          and agree that you access and use those websites, goods and/or
          services solely at your own risk.
        </p>

        <h3 className='text-start mb-2 pt-2'>
          <span className='header__text'>Cookies</span>
        </h3>
        <p style={{ color: '#0D2C49', textAlign: 'justify' }}>
          Small data files (“cookies”) may be stored on your computer or mobile
          device by our server. We may use cookies to maintain user sessions and
          to generate statistics about the number of people that visit the
          website. Generally, this information will not identify you and we do
          not link it back to your identity or other information that you have
          provided to us.
        </p>
      </div>
    </div>
  );
};

export default DisclaimerCookie;
