import React from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
const EducationalGuidesModal = ({
  modalQRIsOpen,
  setModalQRIsOpen,
  data,
  filterArr,
}) => {
  // console.log("women health", data.topic_name);
  // // Consultation popup
  // const customStyles1 = {
  //     content: {
  //         marginTop: '70px',
  //         top: '35%',
  //         left: '25%',
  //         right: 'auto',
  //         bottom: 'auto',
  //         padding: '5px',
  //         marginRight: '-50%',
  //         transform: 'translate(-7%, -45%)',
  //         width: "55%",
  //         height: "530px",
  //         // background: "#ffffff",
  //     },
  //     overlay: { zIndex: 1000 }
  // };
  const [IsOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  // const handleClick = (moreId) => {
  //     console.log('The link was clicked.', moreId);
  //     setIsOpen(true);
  // };
  console.log(selectedItem);
  function closeModal() {
    setIsOpen(false);
  }
  // const customStyles1 = {
  //     content: {
  //         marginTop: '40px',
  //         top: '50%',
  //         left: '50%',
  //         right: 'auto',
  //         bottom: 'auto',
  //         marginRight: '-50%',
  //         transform: 'translate(-50%, -50%)',
  //         width: "55%",
  //         height: "470px",
  //     },
  // };
  const [filterKey, setFilterKey] = useState('');
  console.log('setFilterKey', filterKey);
  // const filter = () => {
  //     data.
  // }
  return (
    <div>
      <Modal
        isOpen={modalQRIsOpen}
        onRequestClose={() => setModalQRIsOpen(false)}
        // style={customStyles1}
        className='mymodal'
        overlayClassName='myoverlay'
        contentLabel='Example Modal'
      >
        <div className='modal__body'>
          <div className='card-body'>
            <div className='row g-3 mb-3'>
              <div className='col-12'>
                <div className='d-flex justify-content-between'>
                  <h6 className='h__modal__text'>{data?.title}</h6>
                  <p
                    className='modal__close__btn'
                    style={{ fontSize: '16px', cursor: 'pointer' }}
                    onClick={() => {
                      setModalQRIsOpen(false);
                      setFilterKey('');
                    }}
                  >
                    <i className='fa-solid fa-xmark close_btn'></i>
                  </p>
                </div>
                <hr />
                <div className='pt-3'>
                  <h3 className='text-center'>{data?.title}</h3>
                </div>
                {/* <div className="psycho__quetion">
                                    <img src={data?.images} alt="" className="img-fluid" />
                                </div> */}
                <div className='modal__edu__guideline d-flex justify-content-center  pt-3'>
                  {filterArr?.length > 0 &&
                    filterArr?.map((item) => (
                      <button
                        onClick={() =>
                          item.word_name === 'All'
                            ? setFilterKey('')
                            : setFilterKey(item?.word_name)
                        }
                        key={item.id}
                        className='btn modal__guideline__btn'
                      >
                        {item?.word_name}
                      </button>
                    ))}
                </div>
                <hr />
                <div className='modal__edu__guideline pt-3'>
                  <h4 className='h__modal__text text-center mb-2'>
                    <span>{data?.subtitle}</span>
                  </h4>
                  <div className='row g-2 pt-4'>
                    {data?.category?.length > 0 &&
                      data?.category
                        ?.filter((item) =>
                          item.title_name.startsWith(filterKey),
                        )
                        .map((item) => (
                          <div className='col-6 text-start'>
                            <button
                              key={item.id}
                              onClick={() => {
                                setSelectedItem(item);
                                setIsOpen(true);
                              }}
                              className='btn modal__det__btn'
                            >
                              {item.title_name}
                            </button>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div>
        {/* <mhModal closeMhModal={closeModal} mhIsOpen={IsOpen} /> */}
        {/* <mmModal /> */}
        <Modal
          isOpen={IsOpen}
          onRequestClose={closeModal}
          className='mymodal'
          overlayClassName='myoverlay'
          contentLabel='Example Modal'
        >
          <div className='d-flex justify-content-between'>
            <h6 className='h__modal__text'>{data?.title}</h6>
            <div className='psycho__quetion'>
              <img src={data?.images} alt='' className='img-fluid' />
            </div>
            <p
              className='modal__close__btn'
              style={{ fontSize: '16px', cursor: 'pointer' }}
              onClick={closeModal}
            >
              <i className='fa-solid fa-xmark close_btn'></i>
            </p>
          </div>
          <h1>{selectedItem?.title_name}</h1>
        </Modal>
      </div>
    </div>
  );
};
export default EducationalGuidesModal;
