
import React, { useState } from 'react';
import "./HorizontalStepperSolution.css";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Steps1 from '../../images/Steps1.png';
import greatDocStepTwo from '../../images/greatDocStepTwo.png';
import greatDocStepThree from '../../images/greatDocStepThree.png';
import greatDocStepFour from '../../images/greatDocStepFour.png';

import Step3HistoryCardioOutput from '../../images/SpecialistStep/CARDIO/Step3HistoryCardioOutput.png';
import Step4InvestigationOrder from '../../images/SpecialistStep/CARDIO/Step4InvestigationOrder.png';
import Step5ResultChkupFlow from '../../images/SpecialistStep/CARDIO/Step5ResultChkupFlow.png';
import Step6WriteInPrescription from '../../images/SpecialistStep/CARDIO/Step6WriteInPrescription.png';

// const steps = ['Medical Selection', 'MIMS Integration alert medicine', 'Medicine Quantity', 'Room for Visits'];
const steps = ['History & Examination Output', 'Investigation Order', 'Result Check & Follow-up', 'Write in Prescription'];

const HorizontalStepperSolution = () => {

    const arr = [
        { id: 1, images: Step3HistoryCardioOutput, title: "Test Entry" },
        { id: 2, images: Step4InvestigationOrder, title: "Pathology" },
        { id: 3, images: Step5ResultChkupFlow, title: "Radiology" },
        { id: 4, images: Step6WriteInPrescription, title: "Report" },
    ]


    const [activeStep, setActiveStep] = React.useState(1);
    const [skipped, setSkipped] = React.useState(new Set());


    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            //   newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleReset = () => {
        setActiveStep(1);
    };

    //
    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(-57% + 43px)',
            right: 'calc(43% + 43px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#A2D17F',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#A2D17F',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderTopWidth: 4,
            borderRadius: 1,
        },
    }));

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#A8B2BB',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#FFFFFF',
            zIndex: 1,
            fontSize: 18,
            width: 45,
            height: 45,
            padding: 10,
            borderRadius: '50%',
            backgroundColor: '#A2D17F',
        },
        '& .QontoStepIcon-circle': {
            //   color: '#FFFFFF',
            width: 45,
            height: 45,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
            //   backgroundColor: '#A8B2BB',
        },

    }));

    function QontoStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle">
                    </div>
                )}
            </QontoStepIconRoot>
        );
    }

    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
    };

 
    const [element, setElement] = useState(arr[0]);
    const stepsController = (i) => {
        if (activeStep === steps.length) {
            handleReset()
            setElement(arr[0])
        } else if (activeStep === i) {
            handleNext()
            setElement(arr[activeStep])
        }
    }
    return (
        <>
            {/* <div className="dd">
                {
                    element?.images &&
                    <div className="stepper__img text-center mb-5">
                        <img src={element?.images} alt=""/>
                    </div>
                }
            </div> */}
            <div className="sss">
                {
                    element?.images &&
                    <div className="g_doc g-doc-scroll">
                        <div className="stepper__img text-center mb-5">
                            <img src={element?.images} alt="" lazy="lodding" />
                        </div>
                    </div>
                }
            </div>

            <div className="pt-4">
                <Box sx={{ width: '100%' }}>
                    <Stack sx={{ width: '100%' }} spacing={4}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                            {steps.map((label, i) => (
                                <Step key={label} >
                                    <StepLabel onClick={() => stepsController(i)} StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Stack>
                </Box>
            </div>
        </>

    );
}
export default HorizontalStepperSolution;
