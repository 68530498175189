import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import NewsLetter from '../home/NewsLetter';
import http from '../../http';
import placeholder from '../../images/Document-placeholder.png';
const ResearchReportsDynamic = () => {
  const { subCategoryId } = useParams();

  //   const [webinars, setWebinars] = useState([
  //     {
  //       id: 1,
  //       title: 'Metabolic syndrome in children:common question',
  //       description:
  //         'Pediatric endocrinologist A/Prof Gary Leong answers some common question about metabolic syndrome in primary care.',
  //       images: thumb,
  //       video:
  //         'https://player.vimeo.com/video/870542272?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //     {
  //       id: 2,
  //       title:
  //         '.Product Explainer – Managing Atherogenic Dyslipidaemia and Diabetic Retinopathy in type 2 diabetes',
  //       description:
  //         'In this Product Explainer, Endocrinologist Prof Richard O’Brien explains the role of Fenofibrate in reducing the progression',
  //       images: thumb1,
  //       video:
  //         'https://player.vimeo.com/video/870566000?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //     {
  //       id: 3,
  //       title:
  //         'Product Explainer – Ferrous sulfate + ascorbic acid for the treatment and prevention of iron deficiency',
  //       images: thumb2,
  //       description:
  //         'In this Product Explainer, Haematologist Dr Renee Eslick explains the role of a modified-release therapeutic oral iron',
  //       video:
  //         'https://player.vimeo.com/video/870565903?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //     {
  //       id: 4,
  //       title: 'Prostatectomy: Common GP questions',
  //       description:
  //         'Urological surgeon Professor Henry Woo answers the most common questions that GPs have in relation to post-prostatectomy patients',
  //       images: thumb3,
  //       video:
  //         'https://player.vimeo.com/video/870544698?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //     {
  //       id: 5,
  //       title:
  //         'Allergic and non-allergic rhinitis: Practical approach for GPs – Dr Jessica Tattersall',
  //       description:
  //         'Diagnosing and optimally treating allergic rhinitis can make a significant difference to a sufferer’s life. Chronic allergic rhinitis',
  //       images: thumb4,
  //       video:
  //         'https://player.vimeo.com/video/870546406?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //     {
  //       id: 6,
  //       title:
  //         'Expert Panel: Menopause Management Past, Present and Future In this Healthed lecture, Dr Terri Foran',
  //       description: '',
  //       images: thumb5,
  //       video:
  //         'https://player.vimeo.com/video/870547579?h=b53c143534&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //     {
  //       id: 7,
  //       title:
  //         'The Breast Cancer and HRT Controversy – An Expert Panel Separates Fact From Fiction',
  //       description:
  //         'The publication of the Women’s Health Initiative study in 2002 changed forever the previous concepts of menopause management. Dr Terri Foran',
  //       images: thumb6,
  //       video:
  //         'https://player.vimeo.com/video/870550620?h=f45a3e4545&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //     {
  //       id: 8,
  //       title:
  //         '.A world-first way for family and friends to help a suicidal person',
  //       description: '',
  //       images: thumb7,
  //       video:
  //         'https://player.vimeo.com/video/870552872?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //     {
  //       id: 9,
  //       title: 'Understanding Breast Pathology Reports',
  //       description:
  //         'Dr Mak will describe the differences between fine needle aspiration and core biopsy. Dr Mak will',
  //       images: thumb8,
  //       video:
  //         'https://player.vimeo.com/video/870556039?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //     {
  //       id: 10,
  //       title:
  //         'Reducing the Risk of Food Allergy in Children – Latest Evidence and Guidelines',
  //       description:
  //         'Learn about the risk factors for food allergy and prevailing theories for the cause, as well as  Dr Marnie Robinson ',
  //       images: thumb9,
  //       video:
  //         'https://player.vimeo.com/video/870556370?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //     {
  //       id: 11,
  //       title: 'Cosmetic Dermatology',
  //       description:
  //         'Take the lead for your patients. Educate yourself about the latest, safe and effective treatments performed by ...Dr Ritu Gupta ',
  //       images: thumb10,
  //       video:
  //         'https://player.vimeo.com/video/870556665?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //     {
  //       id: 12,
  //       title: 'Management of Snake Bite',
  //       description:
  //         'A/Prof Nimo will provide an overview of snake bite in Australia and current first aid and hospital',
  //       images: thumb11,
  //       video:
  //         'https://player.vimeo.com/video/870564174?h=2d4fa57926&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //     {
  //       id: 13,
  //       title:
  //         'Managing Schizophrenia in Primary Care – Practical Tips/ Antipsychotic Prescribing',
  //       description:
  //         'This talk will cover the management of people living with schizophrenia, using a broad bio-psychosocial framework but Prof David Castle ',
  //       images: thumb12,
  //       video:
  //         'https://player.vimeo.com/video/870558849?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //     {
  //       id: 14,
  //       title: 'Vitamin D and Calcium in Pregnancy',
  //       description:
  //         'Pregnancy and breast feeding place extra demands on maternal mineral homeostasis. To ensure adequate maternal well-being and .',
  //       images: thumb13,
  //       video:
  //         'https://player.vimeo.com/video/870563760?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //     {
  //       id: 15,
  //       title: 'Nutrition, Children and Rickets',
  //       description:
  //         'Nutritional rickets has re-emerged a significant paediatric health problem that can result in limb deformity, poor growth,  Prof Craig Munns',
  //       images: thumb14,
  //       video:
  //         'https://player.vimeo.com/video/870568115?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //     {
  //       id: 16,
  //       title: 'Developmental Dysplasia of the Hip',
  //       description:
  //         'A/Prof Nicole Williams, an Orthopaedic Surgeon specialising in bone and joint conditions in infants, children and adolescents',
  //       images: thumb13,
  //       video:
  //         'https://player.vimeo.com/video/870577399?h=aa9933c1b3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //     {
  //       id: 17,
  //       title: 'Management of Vaginismus',
  //       description:
  //         'Interview with Dr Rosie King, 2019 1. How should the GP approach this problem clinically?  Dr Rosie King',
  //       images: thumb13,
  //       video:
  //         'https://player.vimeo.com/video/870577163?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  //     },
  //   ]);
  const [spinner, setSpinner] = useState(true);
  const [subCategory, setSubCategory] = useState({});
  const [resources, setResources] = useState([]);

  useEffect(() => {
    if (subCategoryId) {
      // Array of promises for both HTTP requests
      const requests = [
        http.get('subCategory/' + subCategoryId),
        http.get('resource/get/' + subCategoryId),
      ];

      setSpinner(true); // Show spinner while requests are being made

      // Use Promise.all to wait for all requests to complete
      Promise.all(requests)
        .then((responses) => {
          const subCategoryResponse = responses[0];
          const resourceResponse = responses[1];

          setSubCategory(subCategoryResponse.data.data);
          setResources(resourceResponse.data.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setSpinner(false); // Hide spinner regardless of success or failure
        });
    }
  }, [subCategoryId]);
  if (spinner) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className='loader'></div>
      </div>
    );
  }

  return (
    <>
      <div className='container'>
        <div className='row g-3 pt-4'>
          <div className='col-md-12'>
            <h3 className='text-center mb-2'>
              <span className='header__text'>
                {subCategory?.subCategoryName}
              </span>
            </h3>
          </div>
        </div>
        {resources.length === 0 && (
          <div
            style={{
              margin: '20px 0px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h2>No Resources</h2>
          </div>
        )}
        <div className='row g-3 gy-sm-5 gx-sm-4 pt-4 d-flex justify-content-center'>
          {resources.length > 0 &&
            resources.map((item, id) => {
              const fileExtension = item?.file?.slice(
                ((item?.file?.lastIndexOf('.') - 1) >>> 0) + 2,
              );
              let IsImage = false;
              if (
                fileExtension === 'jpg' ||
                fileExtension === 'jpeg' ||
                fileExtension === 'png' ||
                fileExtension === 'webp'
              ) {
                IsImage = true;
              } else {
                IsImage = false;
              }
              return (
                <div key={id} className='col-md-3'>
                  <Link
                    to={`/webinars/${item.id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <div className='webinars__card'>
                      <div className='card'>
                        {IsImage && (
                          <img
                            src={`${global.img_Url}/${item?.file}`}
                            className='card-img-top'
                            alt='images'
                          />
                        )}
                        {!IsImage && <img src={placeholder} alt='plcea' />}
                        <div className='card-body'>
                          <h6 className='webinars__card__title'>
                            {item.title}
                          </h6>
                          <p className='webinars__card__description'>
                            {item.fileContent}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>

      <div className='bg__body'>
        <NewsLetter />
      </div>
    </>
  );
};

export default ResearchReportsDynamic;
