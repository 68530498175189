import React, { useState } from 'react';
import K10 from '../../../images/K10.PNG';
import phq9__img from '../../../images/phq9__img.PNG';
import hdrs__img from '../../../images/hdrs__img.PNG';
import GPCog__img from '../../../images/GPCog__img.PNG';
import MADRS__img from '../../../images/MADRS__img.PNG';
import PsychoQuestionModal from './PsychoQuestionModal';


const PsychoQuestion = () => {
    const builtPsychologicalQuestionnaires = [
        { id: 1, images: phq9__img, title: "PHQ-9" },
        { id: 2, images: K10, title: "PNDS" },
        { id: 3, images: GPCog__img, title: "GPCOG" },
        { id: 4, images: K10, title: "K10" },
        { id: 5, images: hdrs__img, title: "HDRS" },
        { id: 6, images: MADRS__img, title: "MADRS" },

    ];
    const [modalQRIsOpen, setModalQRIsOpen] = useState(false);
    const [data, setData] = useState(
       { })
    return (
        <div className="row g-3">
        {
            builtPsychologicalQuestionnaires.map((data, id) => {
                return(
                    <div key={id} className="col-md-2">
                        <div className="psycho__ques__card">
                            <h6 className="psycho__ques__card__title "
                                onClick={() => { setData(data); setModalQRIsOpen(true) }}
                                >{data.title}</h6>

                        </div>
                    </div>
                    
                )
            })
        }
            <PsychoQuestionModal modalQRIsOpen={modalQRIsOpen}  setModalQRIsOpen={setModalQRIsOpen} data={data}/>
        </div>
    );
};

export default PsychoQuestion;