
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EnrichMacrohPlausSoft from '../home/EnrichMacrohPlausSoft';
import HorizontalStepperSolution from './HorizontalStepperSolution';
import NewsLetter from '../home/NewsLetter';
import GreatDoc__img from '../../images/GreatDoc__img.png';
import mpm_img from '../../images/mpm_img.png';
import youtube_arrow_icons from '../../images/youtube_arrow_icons.png';
import check_sign from '../../images/check_sign.png';
import patient from '../../images/patient.png';
import patient_care from '../../images/patient_care.png';
import patient_money from '../../images/patient_money.png';
import patient_guide from '../../images/patient_guide.png';
import world_fav from '../../images/world_fav.png';
import world_quantic from '../../images/world_quantic.png';
import world_navorium from '../../images/world_navorium.png';
import world_edb from '../../images/world_edb.png';
import PrescriptionCentrally from '../../images/final_icons/PrescriptionCentrally.png';
import ClinicalNote from '../../images/final_icons/ClinicalNote.png';
import Prescriptions from '../../images/final_icons/Prescriptions.png';
import AdvancedCalender from '../../images/final_icons/AdvancedCalender.png';
import DragDrop from '../../images/final_icons/DragDrop.png';
import ColourCodedDoctorAppontment from '../../images/final_icons/ColourCodedDoctorAppontment.png';
import RealtimeSMSDelivery from '../../images/final_icons/RealtimeSMSDelivery.png';
import AppointmentSMS from '../../images/final_icons/AppointmentSMS.png';
import procedure__img from '../../images/final_icons/procedure__img.png';
import ICD10__img from '../../images/final_icons/ICD10__img.png';
import mims_lo_img from '../../images/final_icons/mims_lo_img.png';
import FestivalSMS from '../../images/final_icons/FestivalSMS.png';
import ehr_records_img from '../../images/ehr_records_img.png';
import mental_img from '../../images/mental_img.png';
import cardiologists_img from '../../images/cardiologists_img.png';
import general_surgeon_banner from '../../images/general_surgeon_banner.jpg';
import allied_img from '../../images/allied_img.png';
import ophthalmologists_img from '../../images/ophthalmologists_img.png';
import alternative_img from '../../images/alternative_img.png';
import medicine_selection_img from '../../images/medicine_selection_img.png';
import mims_inte__img from '../../images/mims_inte__img.png';
import medicine_quantity__img from '../../images/medicine_quantity__img.png';
import room_visits__img from '../../images/room_visits__img.png';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
};

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
};

function ourWorldwidePartnersNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
};

function ourWorldwidePartnersPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
};

const GreatDoc = () => {
    const howToProcessGreatDoc = [
        { id: 1, images: medicine_selection_img, title: "Test Entry" },
        { id: 2, images: mims_inte__img, title: "Pathology" },
        { id: 3, images: medicine_quantity__img, title: "Radiology" },
        { id: 4, images: room_visits__img, title: "Report" },
    ];
    const [keyFeaturesMacroHealth, setKeyFeaturesMacroHealth] = useState([
        {
            features_name: "Digi Patient", features_description: "Allow patients to manage appointments, upload referrals and documents, fill in forms, and access health records dashboard",
            technologies_img: patient, id: 1
        },
        {
            features_name: "Doctor MacroHealth Plus", features_description: "Access your schedule, dictate letters, communicate with your practice securely, and access clinical files - anytime,anywhere.",
            technologies_img: patient_care, id: 2
        },
        {
            features_name: "Smart Lab", features_description: "MHP lets you easily review and share lab reports with patients and doctors, can be delivered over printing, email, SMS and WhatsApp.",
            technologies_img: patient_money, id: 3
        },
        {
            features_name: "Macro Pharma", features_description: "Empower your Pharmacy with an intuitive Inventory Management & Billing system",
            technologies_img: patient_guide, id: 4
        },

    ]);
    const [practiceManagementList, setPracticeManagementList] = useState([
        { id: 1, images: check_sign, description: "Drug information." },
        { id: 2, images: check_sign, description: "Drug-drug interaction " },
        { id: 3, images: check_sign, description: "Drug-disease interaction" },
        { id: 4, images: check_sign, description: "Drug-lactation alert " },
        { id: 5, images: check_sign, description: "Drug-pregnancy alert" },
        { id: 6, images: check_sign, description: "Drug-duplicate alert" },

    ]);
    const [featuresEHR, setFeaturesEHR] = useState([
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: ehr_records_img, id: 1
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: mental_img, id: 2
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: cardiologists_img, id: 3
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: cardiologists_img, id: 4
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: cardiologists_img, id: 5
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: allied_img, id: 6
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: ophthalmologists_img, id: 7
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: alternative_img, id: 8
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: ophthalmologists_img, id: 8
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: alternative_img, id: 10
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: alternative_img, id: 11
        },
        {
            features_name: "Faster Patient Check-ins",
            technologies_img: alternative_img, id: 12
        },

    ]);

    const [ourWorldwidePartners, setOurWorldwidePartners] = useState([
        { technologies_img: world_fav, id: 1 },
        { technologies_img: world_quantic, id: 2 },
        { technologies_img: world_navorium, id: 3 },
        { technologies_img: world_edb, id: 4 },
        { technologies_img: world_fav, id: 5 },
        { technologies_img: world_quantic, id: 6 },
        { technologies_img: world_navorium, id: 7 },
        { technologies_img: world_edb, id: 8 },

    ]);
    const ourWorldwidePartnersSlider = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        nextArrow: <ourWorldwidePartnersNextArrow />,
        prevArrow: <ourWorldwidePartnersPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    const [appointmentManagement, setAppointmentManagement] = useState([
        {
            title: "Track all prescription centrally", description: "MHP's practice management software provides central tracking of all the prescriptions given at the clinic. This helps clinic owners keep track of all the prescriptions written to various patients. Software also provides analytics of major drugs prescribed by doctors at the clinic.",
            images: PrescriptionCentrally, id: 1
        },
        {
            title: "Clinical Notes", description: "Doctors can write clinical notes for personal reference and tag them to patients to easily review all clinical notes for a patient whenever required. Clinical notes can also be printed on paper or directly emailed to patients if required. Doctors can capture chief complaints, symptoms, issues, medical diagnosis, investigation, precautions etc. through clinical notes. Add our point",
            images: ClinicalNote, id: 2
        },
        {
            title: "Print/SMS/Email Prescription On The Fly", description: "Biggest advantage of ePrescriptions is that it can be instantly shared to patients over email, SMS or doctors can choose to print and handover copies. Instant sharing means that even if the patient is not present at the clinic and any investigation result suggests that medication needs to be changed, the patient does not need to physically visit the clinic to collect the updated prescription. Instead, doctors can make changes to the prescription digitally and deliver it over email or sms",
            images: Prescriptions, id: 3
        },
        {
            title: "Advanced Calendar",
            images: AdvancedCalender, id: 4
        },
        {
            title: "Drag, Drop & Re-Schedule",
            images: DragDrop, id: 5
        },
        {
            title: "Colour Coded Multiple Doctor Appointment Scheduling",
            images: ColourCodedDoctorAppontment, id: 6
        },
        {
            title: "Real-time SMS Delivery",
            images: RealtimeSMSDelivery, id: 7
        },
        {
            title: "Appointment SMS Reminders",
            images: AppointmentSMS, id: 8
        },
        {
            title: "Festival & Birthday SMS",
            images: FestivalSMS, id: 9
        },

    ]);
    const [keyFeaturesForGreatDoc, setKeyFeaturesForGreatDoc] = useState([
        {
            title: "Track all prescription centrally", description: "MHP's practice management software provides central tracking of all the prescriptions given at the clinic. This helps clinic owners keep track of all the prescriptions written to various patients. Software also provides analytics of major drugs prescribed by doctors at the clinic.",
            images: PrescriptionCentrally, id: 1
        },
        {
            title: "MIMS Integrated",
            images: mims_lo_img, id: 2
        },
        {
            title: "ICD-10 integrated for diagnosis",
            images: ICD10__img, id: 3
        },
        {
            title: "Procedure list 10,000 integrated",
            images: procedure__img, id: 4
        },
        {
            title: "Reason for visit 30,000 integrated",
            images: DragDrop, id: 5
        },
        {
            title: "Clinical Notes", description: "Doctors can write clinical notes for personal reference and tag them to patients to easily review all clinical notes for a patient whenever required. Clinical notes can also be printed on paper or directly emailed to patients if required. Doctors can capture chief complaints, symptoms, issues, medical diagnosis, investigation, precautions etc. through clinical notes. Add our point",
            images: ClinicalNote, id: 6
        },
        {
            title: "Print/SMS/Email Prescription", description: "Biggest advantage of ePrescriptions is that it can be instantly shared to patients over email, SMS or doctors can choose to print and handover copies. Instant sharing means that even if the patient is not present at the clinic and any investigation result suggests that medication needs to be changed, the patient does not need to physically visit the clinic to collect the updated prescription. Instead, doctors can make changes to the prescription digitally and deliver it over email or sms",
            images: Prescriptions, id: 7
        },
        {
            title: "Advanced Calendar",
            images: AdvancedCalender, id: 8
        },

        {
            title: "Colour Coded Multiple Doctor Appointment Scheduling",
            images: ColourCodedDoctorAppontment, id: 9
        },
        {
            title: "Real-time SMS Delivery",
            images: RealtimeSMSDelivery, id: 10
        },
        {
            title: "Appointment SMS Reminders",
            images: AppointmentSMS, id: 11
        },

        {
            title: "Festival & Birthday SMS",
            images: FestivalSMS, id: 12
        },

    ]);
    const [specialtySpecific, setSpecialtySpecific] = useState([
        {
            id: 1,
            title: "Time Saved",
            no_of_percentance: "90%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 2,
            title: "Increase in the Productivity",
            no_of_percentance: "25%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 3,
            title: "Smart Workflow",
            no_of_percentance: "93%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
    ]);

    return (
        <>
            <div className="bg__body mb-5">
                <div className="specialities__banner">
                    <div className="specialities__banner__img">
                        <img src={general_surgeon_banner} alt="image" />
                    </div>
                    <div className="specialities__banner__text__h">
                        <h3 className="specialities__banner__title">GreatDoc</h3>
                        <div className="specialities__banner__text">
                            <p className="solution__banner__description">
                                Make better decisions at the point of care
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container ">
                    <div className="row g-3  pt-5">
                        <div className="col-md-5">
                            <div className="pt-0">
                                <h3 className="mb-2">
                                    <span className="header__text">GreatDoc</span>
                                </h3>
                                <p className="header__description">
                                    Very easily with clicks a doctor can   record chief complaints, history, symptoms, examination findings, diagnosis,
                                    investigation. With compatible software you can record voice to speech in notes. Easy to order investigations from
                                    list or from your favourites, write scripts with MIMS guidance, check the interaction in pop-ups, Prevent mistakes
                                    in script writing in a busy practice. Protect yourself and your patient from mistakes, make informed decision with
                                    your patient and give them confidence in you. Become a great carer for your patient. Write a paper script or
                                    <strong className="inspiration__span"> ePrescriptions</strong> and send it to your patient and make it paperless.
                                </p>
                                <div className="great__doc">
                                    <p className="header_description">Get the MIMS integrated:</p>
                                    <ul className="list-unstyled">
                                        {
                                            practiceManagementList.length > 0 && practiceManagementList.map((item, id) => {
                                                return (
                                                    <li key={id} className="d-flex dermotologist">
                                                        <p><img src={item.images} className=" img-fluid " style={{ width: '20', height: '20px' }} alt="image" border="0" /></p>
                                                        <p className="ms-2">{item.description}</p>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>

                                <div className="pt-2">
                                    <Link to="/request-demo" type="text" className="btn__ehr">Free Demo</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 ps-md-5">
                            <div className="pr__img margin__top pt-3">
                                <img src={GreatDoc__img} className="img-fluid" alt="image" border="0" />
                            </div>
                        </div>
                    </div>

                    <div className="row g-3  mpm__margin">
                        <div className="col-md-6 order-sm-first order-last">
                            <div className="mpm__img">
                                <img src={mpm_img} className="img-fluid" alt="image" border="0" />
                                <div className="mpm__overlay__text">
                                    <div className="mpm__youtube__icons">
                                        <img src={youtube_arrow_icons} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mpm">
                                <h3 className="text-center mb-2">
                                    <span className="header__text">How <strong className="inspiration__span">GreatDoc</strong> Works?</span>
                                </h3>
                                <p className="header__description text-center">
                                    Our system is Secured, User friendly and Ease to use.
                                </p>
                            </div>
                        </div>
                    </div>



                    <div className="row margin__top">
                        <div className="col-md-12">
                            <div className="key__feature">
                                <h3 className="text-center mb-2">
                                    <span className="header__text">Improve your workflow with <strong className="inspiration__span">GreatDoc</strong></span>
                                </h3>
                                <p className="header__description text-center">
                                    State of the art prescription writing system
                                </p>
                            </div>
                            <div className="pt-3">
                                <HorizontalStepperSolution />
                                {/* <HorizontalStepperSolution arrData={howToProcessGreatDoc}/> */}
                            </div>
                        </div>

                    </div>


                    <div className="row margin__top">
                        <div className="col-md-12">
                            <EnrichMacrohPlausSoft />
                        </div>
                    </div>

                    <div className="row margin__top pt-5">
                        <div className="col-md-12">
                            <div className="key__feature">
                                <h3 className="text-center mb-2">
                                    <span className="header__text">Key Features for <strong className="inspiration__span">GreatDoc</strong></span>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="row g-3 gy-4 gx-sm-5 pt-4 d-flex justify-content-center">
                        {
                            keyFeaturesForGreatDoc.length > 0 && keyFeaturesForGreatDoc.map((item, id) => {
                                return (
                                    <div key={id} className="col-md-3">
                                        <div className="general__practice__card">
                                            <div className="card text-center">
                                                <div className="d-flex justify-content-center pt-4">
                                                    <div className="bg__card__img">
                                                        <img src={item.images} className="card-img-top" alt="images" />
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <h6 className="header__gp__card__subtitle">{item.title}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="margin__top">
                <div className="speciality__bg">
                    <div className="speciality">
                        <h3 className="header__speciality__text">Specialty-Specific All-in-One Solution</h3>
                        <div className="container speciality__specific">
                            <div className="row g-3">
                                {
                                    specialtySpecific.length > 0 && specialtySpecific.map((item, id) => {
                                        return (
                                            <div key={id} className="col-md-4">
                                                <div className="specialty__specific__card">
                                                    <div className="card text-center">
                                                        <div className="card-body">
                                                            <h5 className="specialty__specific__card__title">{item.title}</h5>
                                                            <h3 className="specialty__specific__card__title__percent">{item.no_of_percentance}</h3>
                                                            {/* <p className="specialty__specific__card__discription">{item.description}</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <NewsLetter />
            </div>
        </>
    );
};

export default GreatDoc;