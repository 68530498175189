
import React, { useState } from 'react';
import "./HorizontalStepperSpecialist.css";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';


const HorizontalStepperSpecialist = ({ arr }) => {
    const [activeStep, setActiveStep] = React.useState(1);
    const [skipped, setSkipped] = React.useState(new Set());


    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            //   newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    //
    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(-54% + 28px)',
            right: 'calc(45% + 28px)',
            // left: calc(-54% + 7px);
            // right: calc(45% + 25px);
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#A2D17F',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#A2D17F',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderTopWidth: 4,
            borderRadius: 1,
        },
    }));

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#A8B2BB',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#FFFFFF',
            zIndex: 1,
            fontSize: 18,
            width: 45,
            height: 45,
            padding: 10,
            borderRadius: '50%',
            backgroundColor: '#A2D17F',
        },
        '& .QontoStepIcon-circle': {
            //   color: '#FFFFFF',
            width: 45,
            height: 45,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
            //   backgroundColor: '#A8B2BB',
        },

    }));

    function QontoStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle">
                        {/* <AddIcon className="QontoStepIcon-circlePlus"/> */}
                    </div>
                )}
            </QontoStepIconRoot>
        );
    }

    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
    };


    const [element, setElement] = useState(arr[0]);
    const stepsController = (i) => {
        if (activeStep === arr.length) {
            handleReset()
            setElement(arr[0])
        } else if (activeStep === i) {
            handleNext()
            setElement(arr[activeStep])
        }
    };

    // console.log("arrr", element);

    return (
        <>
            <div className="sss">
                {
                    element?.images &&
                    <div className="g_doc g-doc-scroll">
                        <div className="stepper__img text-center mb-5">
                            <img src={element?.images} alt="" lazy="lodding" />
                        </div>
                    </div>
                }
            </div>

            <div className="margin__top">
                <Box sx={{ width: '100%' }}>
                    <Stack sx={{ width: '100%' }} spacing={4}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                            {arr.map((label, i) => (
                                <Step key={i} >
                                    <StepLabel onClick={() => stepsController(i)} StepIconComponent={QontoStepIcon}>{label.label_name}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Stack>
                </Box>
            </div>
        </>

    );
}
export default HorizontalStepperSpecialist;
