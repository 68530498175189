
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HowItWorkingProcess from './HowItWorkingProcess';
import mpm_img from '../../images/mpm_img.png';

import MarketPlace__img from '../../images/MarketPlace__img.png';
import NewsLetter from '../home/NewsLetter';
import youtube_arrow_icons from '../../images/youtube_arrow_icons.png';
import pharmacy_marketplace_img from '../../images/pharmacy_marketplace_img.png';
import marketplace_img from '../../images/marketplace_img.png';
import HighQuality from '../../images/final_icons/HighQuality.png';
import BestPrice from '../../images/final_icons/BestPrice.png';
import AfterSalesServices from '../../images/final_icons/AfterSalesServices.png';
import search_medicine__img from '../../images/search_medicine__img.png';
import add_item__img from '../../images/add_item__img.png';
import upload_precribe__img from '../../images/upload_precribe__img.png';
import pay_track__img from '../../images/pay_track__img.png';


const Marketplace = () => {
    const howToProcessMarketPlace = [
        { id: 1, images: search_medicine__img, title: "Search Medicine" },
        { id: 2, images: add_item__img, title: "Add Item To Cart" },
        { id: 3, images: upload_precribe__img, title: "Upload Prescription" },
        { id: 4, images: pay_track__img, title: "Pay & Track Your Order" },
    ];
    // console.log("arrData", howToProcessMarketPlace);

    const [moreoverUniqueBenefits, setMoreoverUniqueBenefits] = useState([
        {
            title: "Best Quality Apps", description: "Since we are the pioneers in the industry for making Online Medicine Apps, You can be assured to get the best quality when you work with us. Our team of professionals is adept at developing an Pharmacy App that adheres to all government compliances and regulations. We have experience in developing apps for more than 510+ Online Pharmacies and Online Marketplaces in almost 34+ different countries. We can guarantee that we have a wide arena of experience in this field. Moreover, we have won international awards for Pharmacy Mobile App Development Company and also winner of the highest Pharmacy Mobile apps and Web development Company.",
            images: HighQuality, id: 1
        },
        {
            title: "Best Price", description: "We know the market well and therefore, we are committed to providing you with the best medical app solutions and we offer highly competitive prices. Moreover, our services don't end after delivering you the app! We also make sure that you are completely satisfied with the services and offer immediate action in case you face any problems or errors.",
            images: BestPrice, id: 2
        },
        {
            title: "After Sales Service", description: "We know the market well and therefore, we are committed to providing you with the best medical app solutions and we offer highly competitive prices. Moreover, our services don't end after delivering you the app! We also make sure that you are completely satisfied with the services and offer immediate action in case you face any problems or errors.",
            images: AfterSalesServices, id: 3
        },

    ]);

    const [specialtySpecific, setSpecialtySpecific] = useState([
        {
            id: 1,
            title: "Time Saved",
            no_of_percentance: "90%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 2,
            title: "Increase in the Productivity",
            no_of_percentance: "25%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 3,
            title: "Smart Workflow",
            no_of_percentance: "93%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
    ]);

    return (
        <>
            <div className="bg__body">
                <div className="specialities__banner">
                    <div className="specialities__banner__img">
                        <img src={marketplace_img} alt="image" />
                    </div>
                    <div className="specialities__banner__text__h">
                        <h3 className="specialities__banner__title">Marketplace</h3>
                        <div className="specialities__banner__text">
                            <p className="solution__banner__description">
                                Online Marketplace development for Pharmacy Shop chain, Doctors and Allied healthcare services
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container ">
                    <div className="row g-3 margin__top mb-2">
                        <div className="col-md-6">
                            <div className="">
                                <h3 className="mb-2">
                                    <span className="header__text">Marketplace</span>
                                </h3>
                                <p className="header__description pe-md-5">
                                    <strong className="inspiration__span">MacroHealthPlus</strong> helps you build an online marketplace for your online pharmacy and align the Doctor and Allied health care services with your facility. Imagine your pharmacy store will get visibility across the country and sell the product.
                                </p>
                                <p className="header__description pe-md-5">
                                    Now make a rough estimate of how much investments you would need! Not to forget, you would require a place, workforce, supplies and much more.
                                </p>
                                <div className="pt-3">
                                    <Link to="/request-demo" type="text" className="btn btn__ehr">Free Demo</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ehr__img">
                                <img src={MarketPlace__img} className="img-fluid" alt="image" border="0" />
                            </div>
                        </div>
                    </div>
                    <div className="row g-3  mpm__margin">
                        <div className="col-md-6 order-sm-first order-last">
                            <div className="mpm__img">
                                <img src={mpm_img} className="img-fluid" alt="image" border="0" />
                                <div className="mpm__overlay__text">
                                    <div className="mpm__youtube__icons">
                                        <img src={youtube_arrow_icons} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mpm">
                                <div className="mpm">
                                    <h3 className="text-center mb-2">
                                        <span className="header__text">How <strong className="inspiration__span">Marketplace</strong> System Works?</span>
                                    </h3>
                                    <p className="header__description text-center">
                                        Our system is Secured, User friendly and Ease to use.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <HowItWorkingProcess arrData={howToProcessMarketPlace} />
                        </div>
                    </div>

                    <div className="row  margin__top">
                        <div className="col-md-12">
                            <h5 className="text-center mb-2">
                                <span className="header__text__martplace">Let’s look at some of the evident advantages for online pharmacy marketplace:</span>
                            </h5>
                            <div className="pharmacy__marketplace">
                                <img src={pharmacy_marketplace_img} className="img-fluid" alt="pharmacy_marketplace img" border="0" />
                            </div>
                        </div>
                    </div>

                    <div className="row margin__top">
                        <div className="col-md-12">
                            <div className="macro">
                                <h5 className="text-center mb-2">
                                    <span className="header__text__martplace">Moreover, you get some unique benefits when you collaborate with MacroHealthPlus for your online pharmacy marketplace app. Have a look at some of the following advantages</span>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3 gx-sm-5 pt-4 d-flex justify-content-center">
                        {
                            moreoverUniqueBenefits.length > 0 && moreoverUniqueBenefits.map((item, id) => {
                                return (
                                    <div key={id} className="col-md-3">
                                        <div className="moreover__unique__benefits__card">
                                            <div className="card text-center">
                                                <div className="d-flex justify-content-center pt-4">
                                                    <div className="app__mangement__img">
                                                        <img src={item.images} className="card-img-top" alt="images" />
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <h6 className="header__gp__card__subtitle">{item.title}</h6>
                                                    {/* <p className="header__card__discription">{item.description}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="speciality__bg">
                <div className="speciality">
                    <h3 className="header__speciality__text">Specialty-Specific All-in-One Solution</h3>
                    <div className="container speciality__specific">
                        <div className="row g-3">
                            {
                                specialtySpecific.length > 0 && specialtySpecific.map((item, id) => {
                                    return (
                                        <div key={id} className="col-md-4">
                                            <div className="specialty__specific__card">
                                                <div className="card text-center">
                                                    <div className="card-body">
                                                        <h5 className="specialty__specific__card__title">{item.title}</h5>
                                                        <h3 className="specialty__specific__card__title__percent">{item.no_of_percentance}</h3>
                                                        {/* <p className="specialty__specific__card__discription">{item.description}</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <NewsLetter />
            </div>
        </>
    );
};

export default Marketplace;