import React, { useState } from "react";
import { Link } from "react-router-dom";
import Digi_Patient2 from "../../images/Digi_Patient2.png";
import Great_Doc1 from "../../images/Great_Doc1.png";
import Great_Lab1 from "../../images/Great_Lab1.png";
import Great_Pharma1 from "../../images/Great_Pharma1.png";
import telehealth1 from "../../images/telehealth1.png";
import Ecommerce_img from "../../images/Ecommerce_img.png";
import Lab_Agent1 from "../../images/Lab_Agent1.png";
import Marketplace1 from "../../images/Marketplace1.png";
import MHPSaasImg from "../../images/MHPsaas2.png";
import ExternalLab_img from "../../images/external_lab.png";

const ClientLogin = () => {
  const [pricingAllCost, setPricingAllCost] = useState([
    // {
    //     title: "DigiPatient",
    //     card_link: "digi-patient",
    //     link:'',
    //     images: Digi_Patient2, id: 1
    // },
    {
      title: "GreatDoc",
      card_link: "great-doc",
      images: Great_Doc1,
      id: 2,
      link: "https://greatdoc.macrohealthplus.org/",
    },
    {
      title: "GreatClinic",
      card_link: "great-lab",
      images: Great_Lab1,
      id: 3,
      link: "https://greatdoc.macrohealthplus.org/",
    },
    {
      title: "GreatPharma",
      card_link: "great-pharma",
      images: Great_Pharma1,
      id: 4,
      link: "https://pos.macrohealthplus.org/",
    },
    // {
    //     title: "Telehealth",
    //     card_link: "telehealth",
    //     images: telehealth1, id: 5
    // },

    {
      title: "External Lab",
      card_link: "e-commerce-medicine",
      images: ExternalLab_img,
      id: 6,
      link: "https://externallab.macrohealthplus.org",
    },

    {
      title: "MHP Saas",
      card_link: "lab-agent",
      images: MHPSaasImg,
      id: 8,
      link: "https://gd-app.macrohealthplus.org/",
    },
    {
      title: "e-Pharmacy",
      card_link: "e-commerce-medicine",
      images: Ecommerce_img,
      id: 6,
      link: "https://epharma.macrohealthplus.org/",
    },
    {
      title: "Marketplace",
      card_link: "marketplace",
      images: Marketplace1,
      id: 7,
      link: "https://marketplace.macrohealthplus.org/",
    },
  ]);

  const [systemThinking, setsystemThinking] = useState([
    {
      id: 1,
      moduleOne: [
        { id: 1, title: "Patient" },
        { id: 2, title: "Patient" },
        { id: 3, title: "Patient" },
      ],
    },
    {
      id: 2,
      moduleone: [
        { id: 1, title: "Patient" },
        { id: 2, title: "Patient" },
        { id: 3, title: "Patient" },
      ],
    },
  ]);

  return (
    <>
      <div className="container mb-4 margin__top">
        <h1 className="text-center mb-3">Our Products</h1>
        <div className="macro">
          <p className="header__description solution__macro">
            Simplify the clinical operations in the most effective way with the{" "}
            <span className="inspiration__span">
              Health Management Software.
            </span>
          </p>
        </div>
        <div className="row g-5 gx-md-5 pt-3 px-4 justify-content-start">
          {pricingAllCost.length > 0 &&
            pricingAllCost.map((item, id) => {
              return (
                <div key={id} className="col-md-3">
                  <a
                    href={`${item?.link ? item?.link : "#"}`}
                    target={item?.link ? "_blank" : "_self"}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="client__login__card">
                      <div className="card pt-4">
                        <div className="d-flex justify-content-center macrohealth__mt">
                          <img
                            src={item.images}
                            className="card-img-top"
                            alt="images"
                          />
                        </div>
                        <div className="card-body pt-4">
                          <h6>{item.title}</h6>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ClientLogin;
