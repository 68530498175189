import React from 'react';
import '../footer/Footer.css';
import { Link } from 'react-router-dom';
import moment from 'moment';
import social_app_store from '../../images/social_app_store.png';
import play__store__img from '../../images/play__store__img.png';
import GoogleIcon from '@mui/icons-material/Google';
import SSLCMZ from '../../images/SSL-cmz.png';

const Footer = () => {
  return (
    <div className='footer__bg'>
      <div className='pt-0'>
        <div className='container mb-5'>
          <div className='row g-3 pt-4'>
            <div className='col-md-6 pe-md-5'>
              <div className='social__media'>
                <h5 className='header_link_footer'>
                  <span>Social Media</span>
                </h5>
                <div className='ht-social'>
                  <a
                    href='https://www.facebook.com/macrohealthplusofficial'
                    target='_blank'
                    className='facebook me-3'
                  >
                    <i className='fab fa-facebook-f'></i>
                  </a>
                  <a
                    href='https://twitter.com/macrohealthplus'
                    className='twitter me-3 '
                    target='_blank'
                  >
                    <i className='fab fa-twitter'></i>
                  </a>
                  <a
                    href='https://www.linkedin.com/company/macrohealthplus/'
                    className='linkedin me-3 '
                    target='_blank'
                  >
                    <i className='fab fa-linkedin-in'></i>
                  </a>
                  <a href='#' className='youtube me-3 ' target='_blank'>
                    <i className='fab fa-youtube'></i>
                  </a>
                  {/* <a href="#" className="whatsapp me-3 "><i className="fab fa-whatsapp"></i></a> */}
                  <a
                    href='https://www.instagram.com/macrohealthplus/?igshid=Zjc2ZTc4Nzk%3D'
                    className='instagram me-3'
                    target='_blank'
                  >
                    <i className='fab fa-instagram'></i>
                  </a>
                  <a href='#' className='google me-3' target='_blank'>
                    <i className='fab fa-google'></i>
                  </a>
                  {/* <a href="#" className="google me-3 "><GoogleIcon/></a> */}
                  {/* <a href="#" className="pinterest"><i className="fab fa-pinterest-p"></i></a> */}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: '15px',
                }}
              >
                <div className='d-flex align-items-center gap-2'>
                  <div className='footer__social__img pt-4'>
                    {/* <Link to="" className=""> */}
                    <img
                      src={social_app_store}
                      alt=''
                      style={
                        {
                          // height: '20px',
                          // width: '120px',
                        }
                      }
                      className='disabled'
                    />
                    {/* </Link> */}
                  </div>
                  <div className='footer__social__img pt-4'>
                    {/* <Link to="" className=""> */}
                    <img
                      src={play__store__img}
                      style={
                        {
                          // height: '20px',
                          // width: '120px',
                        }
                      }
                      alt='image'
                      className='disabled'
                    />
                    {/* </Link> */}
                  </div>
                </div>
                <img
                  src={SSLCMZ}
                  alt='ssl-commerz'
                  style={{
                    height: '130px',
                    width: '350px',
                  }}
                />
              </div>

              <div></div>
            </div>

            <div className='col-md-6'>
              <h5 className='header_link_footer mb-2'>
                <span>Contact Us</span>
              </h5>
              <div className='row g-2'>
                <div className='col-md-6'>
                  <h5 className='header_link_footer'>Head Office</h5>
                  <div className='row g-1 office__address'>
                    <div className='col-12'>
                      <span className='contact__footer'>
                        <i className='fs-5 p-1 text__color  fa-solid fa-phone'></i>
                        <p className='px-3 text__color contact_us_description'>
                          +61-299-431072
                        </p>
                      </span>
                    </div>
                    <div className='col-12'>
                      <span
                        className='d-flex align-items-start'
                        onClick={() => {
                          window.location.href =
                            'mailto:info@macrohealthplus.org';
                        }}
                      >
                        <i className='fs-5 p-1 text__color fas fa-envelope'></i>
                        <p className='px-3 text__color contact_us_description'>
                          info@macrohealthplus.org
                        </p>
                      </span>
                    </div>
                    <div className='col-12'>
                      <span className='d-flex align-items-start '>
                        <i className='fs-5 p-1 text__color fas fa-map-marker-alt'></i>
                        <p className='px-3 text__color contact__us__address '>
                          9 Westbrook Avenue{' '}
                          <span className='text-nowrap'>
                            {' '}
                            Wahroonga, NSW 2076,{' '}
                          </span>{' '}
                          Australia
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <h5 className='header_link_footer'>Bangladesh Office</h5>
                  <div className='row g-1 office__address'>
                    <div className='col-12'>
                      <span className='contact__footer'>
                        <i className='fs-5 p-1 text__color  fa-solid fa-phone'></i>
                        <p className='px-3 text__color contact_us_description'>
                          +880-1855-777766
                        </p>
                      </span>
                    </div>
                    <div className='col-12'>
                      <span
                        className='d-flex align-items-start '
                        onClick={() => {
                          window.location.href =
                            'mailto:info@macrohealthplus.org';
                        }}
                      >
                        <i className='fs-5 p-1 text__color fas fa-envelope'></i>
                        <p className='px-3 text__color contact_us_description'>
                          info@macrohealthplus.org
                        </p>
                      </span>
                    </div>
                    <div className='col-12'>
                      <span className='d-flex align-items-start '>
                        <i className='fs-5 p-1 text__color fas fa-map-marker-alt'></i>
                        <p className='px-3 text__color contact__us__address '>
                          House # 35, Road #{' '}
                          <span className='text-nowrap'> East Rampura,</span>
                          <br /> Dhaka, Bangladesh
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mb-3 container'>
          <h6 style={{ color: 'white' }}>TIN Number - 540449482882</h6>
        </div>
        {/* <div
          style={{
            background: '#4D9621',
            padding: '6px 0px',
            color: 'white',
          }}
        >
          <div className='container'>
            <h3
              style={{
                fontSize: '16px',
                fontWeight: '500',
                cursor: 'pointer',
                lineHeight: '14px',
                padding: '4px 0px',
                margin: '0px',
              }}
            >
              Refund & Return Policy
            </h3>
            <p
              style={{
                fontSize: '12px',
                fontWeight: 'normal',
                padding: '0px',
                margin: '0px',
              }}
            >
             
            </p>
          </div>
        </div> */}
        <div className='footer__copyright'>
          <div className='container d-flex bd-highlight footer__copyright'>
            <div className='p-2 flex-grow-1 bd-highlight'>
              {/* <span >©  {moment().format('DD-MM-YYYY')}</span> */}
              <span>© {moment().format('YYYY')}</span>
              <a
                href='https://macrohealthplus.org/'
                target='_blank'
                style={{
                  color: '#4D9621',
                  background: '#EBEBEB',
                  textDecoration: 'none',
                }}
              >
                {' '}
                MacroHealthPlus
              </a>
              .<span> All Rights Reserved.</span>
            </div>
            <div className='p-2 '>
              <Link
                to='/terms'
                className='text-decoration-none footer-list foot__text__color'
              >
                Terms & Conditions
              </Link>
            </div>
            <div className='p-2 bd-highlight'>
              <Link
                to='/privacy'
                className='text-decoration-none footer-list foot__text__color'
              >
                Privacy Policy
              </Link>
            </div>
            <div className='p-2 bd-highlight'>
              <Link
                to='/security'
                className='text-decoration-none  footer-list foot__text__color'
              >
                Security
              </Link>
            </div>
            <div className='p-2 bd-highlight'>
              <Link
                to='/refund-return-policy'
                className='text-decoration-none  footer-list foot__text__color'
              >
                Refund & Return Policy
              </Link>
            </div>
            <div className='p-2 bd-highlight'>
              <Link
                to='/cookie-disclaimer'
                className='text-decoration-none  footer-list foot__text__color'
              >
                Cookie Disclaimer
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
