import "./AccrodianPRM.css";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import vital_sign_img from '../../../images/vital_sign_img.png';
import vital_clinical_record_img from '../../../images/vital_clinical_record_img.png';
import upload_img from '../../../images/upload_img.png';
import maintain_vital_clinical_records from '../../../images/maintain_vital_clinical_records.png';

const AccrodianPRMTelehealth = () => {
    const [subMenuOpen, setSubMenuOpen] = useState(0);
    const toggleMenu = (x) => setSubMenuOpen(subMenuOpen === x ? -1 : x);

    return (
            <div className="row g-3 pt-1">
                {/* handle only body  */}
                <div className="col-md-6">
                    <div className="d-1">
                        <div className={`sub-menu  ${subMenuOpen === 0 ? "is-open" : ""}`}>
                            <div className="prm__img">
                                {/* <p>item 1</p> */}
                                <img src={vital_sign_img} alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                    <div className="d-2">
                        <div className={`sub-menu  ${subMenuOpen === 1 ? "is-open" : ""}`}>
                            <div className="prm__img">
                                {/* <p>item 2</p> */}
                                <img src={vital_clinical_record_img} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="d-3">
                        <div className={`sub-menu  ${subMenuOpen === 2 ? "is-open" : ""}`}>
                            <div className="prm__img">
                                {/* <p>item 3</p> */}
                                <img src={upload_img} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="d-4">
                        <div className={`sub-menu  ${subMenuOpen === 3 ? "is-open" : ""}`}>
                            <div className="prm__img">
                                {/* <p>item 4</p> */}
                                <img src={maintain_vital_clinical_records} alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                    <div className="d-5">
                        <div className={`sub-menu  ${subMenuOpen === 4 ? "is-open" : ""}`}>
                            <div className="prm__img">
                                {/* <p>item 4</p> */}
                                <img src={maintain_vital_clinical_records} alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>

                {/* handle button & body  */}
                <div className="col-md-6">
                    <div className="submenu-list">
                        <div className="d-1">
                            <div onClick={() => toggleMenu(0)} className="d-flex justify-content-between prm__btn" >
                                <p className="prm__drop__title">Secure & Compliant</p>
                                <p><FontAwesomeIcon icon={subMenuOpen === 0 ? faMinus : faPlus} /></p>
                            </div>

                            <div className={`sub-menu  ${subMenuOpen === 0 ? "is-open" : ""}`}>
                                <div className="prm__drop__body">
                                    <p className="header__description">
                                        Our <strong className="inspiration__span">Telehealth</strong> technology uses end-to-end encryption with peer-to-peer connections.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="d-2">
                            <div onClick={() => toggleMenu(1)} className="d-flex justify-content-between prm__btn">
                                <p className="prm__drop__title">EHR Integration</p>
                                <p><FontAwesomeIcon icon={subMenuOpen === 1 ? faMinus : faPlus} /></p>
                            </div>

                            <div className={`sub-menu ${subMenuOpen === 1 ? "is-open" : ""}`}>
                                <div className="prm__drop__body">
                                    <p className="header__description">During the e-consult/video consultation, past medical information is available for you to diagnose and plan treatment.</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-3">
                            <div onClick={() => toggleMenu(2)} className="d-flex justify-content-between prm__btn">
                                <p className="prm__drop__title">Request E-Consultation</p>
                                <p><FontAwesomeIcon icon={subMenuOpen === 2 ? faMinus : faPlus} /></p>
                            </div>

                            <div className={`sub-menu ${subMenuOpen === 2 ? "is-open" : ""}`}>
                                <div className="prm__drop__body">
                                    <p className="header__description">Your patients can request e-consultations online. Especially useful for follow ups, post-surgery consultations, regular diabetic dosage adjustments etc.</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-4">
                            <div onClick={() => toggleMenu(3)} className="d-flex justify-content-between prm__btn">
                                <p className="prm__drop__title">Video Consultation</p>
                                <p><FontAwesomeIcon icon={subMenuOpen === 3 ? faMinus : faPlus} /></p>
                            </div>

                            <div className={`sub-menu ${subMenuOpen === 3 ? "is-open" : ""}`}>
                                <div className="prm__drop__body">
                                    <p className="header__description">Let your patients book a video appointment, and then consult on video. Now you do not have to be in your clinic to practice</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-5">
                            <div onClick={() => toggleMenu(4)} className="d-flex justify-content-between prm__btn">
                                <p className="prm__drop__title">Collect Payments</p>
                                <p><FontAwesomeIcon icon={subMenuOpen === 4 ? faMinus : faPlus} /></p>
                            </div>

                            <div className={`sub-menu ${subMenuOpen === 4 ? "is-open" : ""}`}>
                                <div className="prm__drop__body">
                                    <p className="header__description">Supports industry standard payment gateway integration for Credit card/Mobile Banking.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};
export default AccrodianPRMTelehealth;