import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const TopHeader = () => {
    const styles = {
        slide: {
            padding: 8,
            minHeight: 0,
            color: '#fff',
            textAlign: 'center',
        },
        slide1: {
            background: '#4D9621',
        },
    };

    const [textData, setTextData] = useState([
        {
            id: 1,
            title: "MacroHealthPlus is a secure platform for simple and effective private practice",
        },
        {
            id: 2,
            title: "The new way to manage your healthcare process through MacroHealthPlus",
        },
        {
            id: 3,
            title: "Optimise capacity across the health care system with MacroHealthPlus a single platform",
        },
        {
            id: 3,
            title: "Digitise your clinic with a powerful MacroHealthPlus Practice Management Software",
        },
        {
            id: 3,
            title: "Privacy protected, safe and secure Platform through MacroHealthPlus",
        },
    ]);
    

    return (
        < div className="">
            <AutoPlaySwipeableViews interval={6000}>
                {
                    textData.length > 0 && textData.map((item, id) => {
                        return (
                            <div key={id} style={Object.assign({}, styles.slide, styles.slide1)}>
                                <p className="ht__text">{item.title}</p>
                            </div>
                        )
                    })
                }
            </AutoPlaySwipeableViews>
        </div>
    );
};

export default TopHeader;