import React, { useState } from 'react';
import EducationalGuidesModal from './EducationalGuidesModal';
import NewsLetter from '../home/NewsLetter';
import under_construction from '../../images/under_construction.jpg';
const EducationalGuides = () => {
  const [modalQRIsOpen, setModalQRIsOpen] = useState(false);
  const [data, setData] = useState({});
  const builtPsychologicalQuestionnaires = [
    {
      id: 1,
      images: under_construction,
      title: 'Women’s Health',
      subtitle: 'Category',
      category: [
        { id: 1, title_name: 'After miscarriage' },
        { id: 2, title_name: 'After your operation' },
        { id: 3, title_name: 'Avoiding constipation vaginal repair surgery' },
        { id: 4, title_name: 'Bladder training' },
        { id: 5, title_name: 'Breast care when baby has died' },
        { id: 6, title_name: 'Breast cysts' },
        { id: 7, title_name: 'Breast feeding -low -milk -supply' },
        { id: 8, title_name: 'Breastfeeding your baby' },
        { id: 9, title_name: 'Breast imaging test' },
        { id: 10, title_name: 'Breast nipple thrush' },
        { id: 11, title_name: 'Breast reconstruction total mastectomy' },
        { id: 12, title_name: 'Breast biopsy test' },
        { id: 13, title_name: 'Breast soreness' },
        { id: 14, title_name: 'Cmv and pregnancy' },
        { id: 15, title_name: 'Colposcopy ' },
        { id: 16, title_name: 'Common discomforts pregnancy' },
        { id: 17, title_name: 'Cone biopsy' },
        { id: 18, title_name: 'Contraception-your choices' },
        { id: 19, title_name: 'Contraceptive pill ' },
        { id: 20, title_name: 'Curette for miscarriage ' },
        {
          id: 21,
          title_name: 'Caring for yourself following a total mastectomy',
        },
        { id: 22, title_name: 'Emergency contraception' },
        { id: 23, title_name: 'Emptying your bladder after birth' },
        { id: 24, title_name: 'Endometrial atypical hyperplasia' },
        { id: 25, title_name: 'Endometrial cancer' },
        { id: 26, title_name: 'Endometriosis information women' },
        { id: 27, title_name: 'Endometriosis treating with laparoscopy' },
        { id: 28, title_name: 'Expressing -breast-milk ' },
        { id: 29, title_name: 'Fibroadenomas' },
        { id: 30, title_name: 'Follow-up -cervical -cancer' },
        { id: 31, title_name: 'Food -safety -during pregnancy' },
        { id: 32, title_name: 'Headache -After -Epidural spinal anesthetic ' },
        { id: 33, title_name: ' Healthy eating pregnant' },
        { id: 34, title_name: 'Healthy eating pregnant' },
        { id: 35, title_name: 'Hysterectomy total laparoscopic' },
        { id: 36, title_name: 'Improving your recovery after birth ' },
        { id: 37, title_name: 'Induction of labor' },
        { id: 38, title_name: 'Inhalants' },
        { id: 39, title_name: 'Interstitial cystitis ' },
        { id: 40, title_name: 'Iodine supplements facts ' },
        { id: 41, title_name: 'Lymphoedema Managing your diabetes' },
        { id: 42, title_name: 'Mastitis' },
        { id: 43, title_name: 'Matrrnal sepsis info ' },
        { id: 44, title_name: 'Matrrnal sepsis' },
        { id: 45, title_name: 'Medicines in breast feeding ' },
        { id: 46, title_name: 'Menopausal hormone therapy ' },
        { id: 47, title_name: 'Metformin' },
        { id: 48, title_name: 'Methotrexate for ectopic pregnancy' },
        { id: 49, title_name: 'Minor operation advice for going home ' },
        { id: 50, title_name: 'My last birth was a caessarean' },
        { id: 51, title_name: 'Myomectomy' },
        { id: 52, title_name: 'Nausea vomiting in pregnancy' },
        { id: 53, title_name: 'Nipple shields' },
        { id: 54, title_name: 'Nipple vasospasm' },
        { id: 55, title_name: 'Ovarian cancer' },
        { id: 56, title_name: 'Parent tears third and fourth degree ' },
        { id: 57, title_name: 'Positional talipes' },
        { id: 58, title_name: 'Pregnancy related carpal tunnel' },
        { id: 59, title_name: 'Preventing constipation ' },
        { id: 60, title_name: 'Tips avoid lifting straining ' },
        { id: 61, title_name: 'Treating endometriosis ' },
        { id: 62, title_name: 'Trial of void' },
        { id: 63, title_name: 'Using a breast pump' },
        { id: 64, title_name: 'Vaginal pessary prolapses' },
        { id: 65, title_name: 'Vaginal surgery advice for going home' },
        { id: 66, title_name: 'Vitamin D pregnancy' },
        { id: 67, title_name: 'Vulvar laser surgery- advic for going home' },
        { id: 68, title_name: 'Vulvar surgery- and going home' },
        { id: 69, title_name: 'Weight gain during pregnancy ' },
        { id: 70, title_name: 'Weight gain graps ' },
        { id: 71, title_name: 'Weight gain graps ' },
        { id: 72, title_name: 'Weight gain graps ' },
        { id: 73, title_name: 'Weight gain graps ' },
      ],
    },
    {
      id: 2,
      images: under_construction,
      title: 'Child Health',
      subtitle: 'Category',
      category: [
        { id: 1, title_name: 'Abdominal pain ' },
        { id: 2, title_name: 'Cow’s milk allergy and milk free diet' },
        { id: 3, title_name: 'Egg allergy and egg free diet ' },
        { id: 4, title_name: 'Food allergy and eczema' },
        { id: 5, title_name: 'Anterior knee pain in children and adolescents' },
        { id: 6, title_name: 'Anxiety' },
        { id: 7, title_name: 'Apnoea - Bradycardia -in – premature - baby' },
        { id: 8, title_name: 'Appendicitis' },
        { id: 9, title_name: 'Asthma ' },
        { id: 10, title_name: 'Autism' },
        { id: 11, title_name: 'Baby massage' },
        { id: 12, title_name: 'Bed wetting ' },
        { id: 13, title_name: 'Bites and stings' },
        { id: 14, title_name: 'Bowlegs and knock knees' },
        { id: 15, title_name: 'Breath holding spell' },
        { id: 16, title_name: 'Bronchiolitis' },
        { id: 17, title_name: 'Cerebral palsy ' },
        { id: 18, title_name: 'Chickenpox ' },
        { id: 19, title_name: 'concussion and mild head injury ' },
        { id: 20, title_name: 'congenital talipes ' },
        { id: 21, title_name: 'constipation ' },
        { id: 22, title_name: 'covid -19 children ' },
        { id: 23, title_name: 'crying baby ' },
        { id: 24, title_name: 'curly toes ' },
        { id: 25, title_name: 'Daytime wetting' },
        { id: 26, title_name: 'Depression' },
        { id: 27, title_name: 'Development delay' },
        { id: 28, title_name: 'Development dysplasia' },
        { id: 29, title_name: 'Disruptive behaviours' },
        { id: 30, title_name: 'Ears glue and grommets' },
        { id: 31, title_name: 'Ears hearing loss' },
        { id: 32, title_name: 'Eczema wet dressing' },
        { id: 33, title_name: 'Eczema ' },
        { id: 34, title_name: 'Eyes congenital cataracts' },
        { id: 35, title_name: 'Fever febrile convulsions' },
        { id: 36, title_name: ' Flat feet' },
        { id: 37, title_name: 'Food baby’s first food ' },
        { id: 38, title_name: 'Food fussy eating in toddlers' },
        { id: 39, title_name: 'Food vegetarian eating in children' },
        { id: 40, title_name: 'Gastroenteritis' },
        { id: 41, title_name: 'Hemangiomas' },
        { id: 42, title_name: 'Hives urticaria' },
        { id: 43, title_name: 'Hydroceles' },
        { id: 44, title_name: 'Hypospadias' },
        { id: 45, title_name: 'Inguinal hernias premature babies' },
        { id: 46, title_name: 'In toeing in children pigeon toes' },
        { id: 47, title_name: 'Joint hypermobility' },
        { id: 48, title_name: 'Kawasaki diseases' },
        { id: 49, title_name: 'Language development the early year' },
        { id: 50, title_name: 'Microtia' },
        { id: 51, title_name: 'Nappy rash' },
        { id: 52, title_name: 'Nightmares and night terrors' },
        { id: 53, title_name: 'Obesity a healthy lifestyle' },
        { id: 54, title_name: 'Obesity losing weight ' },
        { id: 55, title_name: 'Obesity weight management tips' },
        { id: 56, title_name: 'Otitis media with effusion middle ear' },
        { id: 57, title_name: 'Perthes disease' },
        { id: 58, title_name: 'Plagiocephaly' },
        { id: 59, title_name: 'Pneumonia ' },
        { id: 60, title_name: 'Positional plagiocephaly' },
        { id: 61, title_name: 'Retinopathy of prematurity' },
        { id: 62, title_name: 'Scoliosis' },
        { id: 63, title_name: 'Screening for DDH' },
        { id: 64, title_name: 'Seizures and epilepsy' },
        { id: 65, title_name: 'Slipped capital femoral epiphysis' },
        { id: 66, title_name: 'Spina bifida' },
        { id: 67, title_name: 'Stuttering ' },
        { id: 68, title_name: 'Sucrose pain relief' },
        { id: 69, title_name: 'Teeth caring' },
        { id: 70, title_name: 'Tetralogy of fallot spells' },
        { id: 72, title_name: 'Tongue tie' },
        { id: 73, title_name: 'Tonsillectomy' },
        { id: 74, title_name: 'Trigger thumb' },
        { id: 75, title_name: 'Undescended testes' },
        { id: 76, title_name: 'Urinary tract infection' },
      ],
    },
    {
      id: 3,
      images: under_construction,
      subtitle: 'Category',
      title: 'Infectious diseases',
      category: [
        { id: 1, title_name: 'Avain influenza' },
        { id: 2, title_name: 'Barmah forest virus' },
        { id: 3, title_name: 'Blastocystis hominis' },
        { id: 4, title_name: 'Boil skin infections' },
        { id: 5, title_name: 'Campylobacter' },
        { id: 6, title_name: 'Chickenpox' },
        { id: 7, title_name: 'Chickenpox and shingles' },
        { id: 8, title_name: 'Chikungunya' },
        { id: 9, title_name: 'Chlamydia' },
        { id: 10, title_name: 'Cholera' },
        { id: 11, title_name: 'Dengue' },
        { id: 12, title_name: 'Dientamoeba fragilis' },
        { id: 13, title_name: 'Diphtheria' },
        { id: 14, title_name: 'Enteroviruses' },
        { id: 15, title_name: 'Gastro infection control' },
        { id: 16, title_name: 'Gastroenteritis-outbreaks' },
        { id: 16, title_name: 'Giardiasis' },
        { id: 17, title_name: 'Gonorrhoea' },
        { id: 18, title_name: 'Haemophilus influenzae (hib)' },
        { id: 19, title_name: 'Hajj travel advice' },
        { id: 20, title_name: 'Hand, footand mouth disease' },
        { id: 21, title_name: 'Hepatitis B' },
        { id: 22, title_name: 'Hepatitis A' },
        { id: 23, title_name: 'Hepatitis C' },
        { id: 24, title_name: 'Hepatitis E' },
        { id: 25, title_name: 'Herpes' },
        { id: 26, title_name: 'HIV infection' },
        { id: 27, title_name: 'Human parechovirus' },
        { id: 28, title_name: 'Impetigo' },
        { id: 29, title_name: 'Infectious mononucleosis ' },
        { id: 30, title_name: 'Influenza' },
        { id: 31, title_name: 'Kunjin virus' },
        { id: 32, title_name: 'Legionnaires' },
        { id: 33, title_name: 'Leprosy' },
        { id: 34, title_name: 'Listeriosis' },
        { id: 35, title_name: 'Malaria' },
        { id: 36, title_name: 'Measles' },
        { id: 37, title_name: 'Meningococcal' },
        { id: 38, title_name: 'Methicillin-resistant' },
        { id: 39, title_name: 'Mosquitoes’ health hazard' },
        { id: 40, title_name: 'Mumps' },
        { id: 41, title_name: 'Naegleria fowleri' },
        { id: 42, title_name: 'Norovirus' },
        { id: 43, title_name: 'Parvovirus B19' },
        { id: 44, title_name: 'Plague' },
        { id: 45, title_name: 'Pneumococcal diaease' },
        { id: 46, title_name: 'Polio' },
        { id: 47, title_name: 'Psittacosis' },
        { id: 48, title_name: 'Qfever' },
        { id: 49, title_name: 'Qfever -vaccine' },
        { id: 50, title_name: 'Rabies' },
        { id: 51, title_name: 'Rat lung worm disease' },
        { id: 52, title_name: 'Rheumatic heart disease' },
        { id: 53, title_name: 'Rubella' },
        { id: 54, title_name: 'Salmnellosis' },
        { id: 55, title_name: 'Sea food poisoning' },
        { id: 56, title_name: 'Shiga-toxigenic' },
        { id: 57, title_name: 'Shigellosis' },
        { id: 58, title_name: 'Syphilis' },
        { id: 59, title_name: 'Tb' },
        { id: 60, title_name: 'Tetanus' },
        { id: 61, title_name: 'Typhoid' },
        { id: 62, title_name: 'Typhus' },
        { id: 63, title_name: 'Whooping cough' },
        { id: 64, title_name: 'Yellow fever' },
      ],
    },
  ];
  const filterArr = [
    { id: 0, word_name: 'All' },
    { id: 1, word_name: 'A' },
    { id: 2, word_name: 'B' },
    { id: 3, word_name: 'C' },
    { id: 4, word_name: 'D' },
    { id: 5, word_name: 'E' },
    { id: 6, word_name: 'F' },
    { id: 7, word_name: 'G' },
    { id: 8, word_name: 'H' },
    { id: 9, word_name: 'I' },
    { id: 10, word_name: 'J' },
    { id: 11, word_name: 'K' },
    { id: 12, word_name: 'L' },
    { id: 13, word_name: 'M' },
    { id: 14, word_name: 'N' },
    { id: 15, word_name: 'O' },
    { id: 16, word_name: 'P' },
    { id: 17, word_name: 'Q' },
    { id: 18, word_name: 'R' },
    { id: 19, word_name: 'S' },
    { id: 20, word_name: 'T' },
    { id: 21, word_name: 'U' },
    { id: 22, word_name: 'V' },
    { id: 23, word_name: 'W' },
    { id: 24, word_name: 'X' },
    { id: 25, word_name: 'Y' },
    { id: 26, word_name: 'Z' },
  ];
  return (
    <>
      <div className='bg__body'>
        <div className='container '>
          <div className='row g-3 pt-4'>
            <div className='col-md-12'>
              <h3 className='text-center'>
                <span className='header__text'>Patient Education</span>
              </h3>
            </div>
          </div>
          <div className='row g-3 pt-4'>
            {builtPsychologicalQuestionnaires.map((data, id) => {
              return (
                <div key={id} className='col-md-4'>
                  <div className='psycho__ques__card'>
                    <h6
                      className='psycho__ques__card__title '
                      onClick={() => {
                        setData(data);
                        setModalQRIsOpen(true);
                      }}
                    >
                      {data.title}
                    </h6>
                  </div>
                </div>
              );
            })}
            <EducationalGuidesModal
              modalQRIsOpen={modalQRIsOpen}
              setModalQRIsOpen={setModalQRIsOpen}
              data={data}
              filterArr={filterArr}
            />
          </div>
        </div>
      </div>
      <div className='bg__body'>
        <NewsLetter />
      </div>
    </>
  );
};
export default EducationalGuides;
