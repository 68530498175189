import React from 'react';
import './PharmacyPOSAccrodian.css';

const PharmacyPOSAccrodian = () => {
    return (
        <>
            <section className="faq-section__lab">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="accordion accordion-flush" id="faqlist1">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                                            Simplify Sales and Service
                                        </button>
                                    </h2>
                                    <div id="faq-content-1" className="accordion-collapse collapse show" data-bs-parent="#faqlist1">
                                        <div className="accordion-body">
                                            <p className="header__description">
                                                Speed customer service, transactions and help your staff to provide superior service. Deliver a personalised
                                                customer experience to grow wallet share, find product items and answer the questions instantly—even offline,
                                                make everyone's job simpler with easy-to-use software, Bring the checkout to your customers with mobile POS.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                                            Stock Management
                                        </button>
                                    </h2>
                                    <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                        <div className="accordion-body">
                                            <p className="header__description">Track your stock, set min/max stock level, make inventory up to date. Great Pharmacy Software with advanced inventory tracking helps to streamline your entire pharmacy shop</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                            Expiry Management
                                        </button>
                                    </h2>
                                    <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                        <div className="accordion-body">
                                            <p className="header__description">Keep a track of expired & near expiry products sitting at your shelves. Return the products to the supplier before expiry & save 100% losses due to expiry</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                                            Prescriptions
                                        </button>
                                    </h2>
                                    <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                        <div className="accordion-body">
                                            <p className="header__description">Reach out to more customers by maintaining records of medical evaluation of the patient’s health by accepting paper & e-prescriptions</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-5">
                                            Rack Management
                                        </button>
                                    </h2>
                                    <div id="faq-content-5" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                        <div className="accordion-body">
                                            <p className="header__description">Easily maintain stock of multiple items Rack-wise which helps to identify which item is kept in which rack at the time of billing & generate Rack-wise stock reports</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-6">
                                            Re Order Management
                                        </button>
                                    </h2>
                                    <div id="faq-content-6" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                        <div className="accordion-body">
                                            <p className="header__description">Know the Fast, Slow and Non- Moving products in your store. Set Reorder points. Generate Reorder on the basis of Product Sale/Profit/Stock level & Calculations</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-7">
                                            Substitute Availability
                                        </button>
                                    </h2>
                                    <div id="faq-content-7" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                        <div className="accordion-body">
                                            <p className="header__description">Boost your sales by suggesting Substitute medicines instead of the prescribed ones. The software will find appropriate substitutes with same Salt Composition & Strength</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-8">
                                            Free Drug Helpline
                                        </button>
                                    </h2>
                                    <div id="faq-content-8" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                        <div className="accordion-body">
                                            <p className="header__description">Get complete information of 33K Medicines inside <strong className="inspiration__span">GreatPharma</strong>. Increase 40-50% sales by searching substitutes & placing orders to nearby suppliers</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-9">
                                            24x 7 Ordering
                                        </button>
                                    </h2>
                                    <div id="faq-content-9" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                        <div className="accordion-body">
                                            <p className="header__description">View stock updates, schemes & rates in real-time and place online orders to nearby suppliers anytime anywhere with the <strong className="inspiration__span">GreatPharma</strong> App.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-10">
                                            Digital Payment and Collection
                                        </button>
                                    </h2>
                                    <div id="faq-content-10" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                        <div className="accordion-body">
                                            <p className="header__description">Simplify your digital collections & payments using Great Pharma with secure Payment Modes</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-11">
                                            Delivery
                                        </button>
                                    </h2>
                                    <div id="faq-content-11" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                        <div className="accordion-body">
                                            <p className="header__description">Deliver products to your customer on time with Great Pharma Delivery. We assure 100% Safety & Security with our trusted Delivery Partners.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PharmacyPOSAccrodian;