import React, { useState } from 'react';
import Modal from 'react-modal';
import NewsLetter from '../home/NewsLetter';
import who_are_img from '../../images/who_are_img.png';
import OurbusinessGoal from '../../images/OurbusinessGoal.png';
import DrAminurRahman from '../../images/DrAminurRahman.jpg';
import AminurRahman__img from '../../images/AminurRahman__img.jpg';
import NAEheadShot__img from '../../images/NAEheadShot__img.jpg';
import mohasina_img from '../../images/mohsina.jpeg';
import dummy__img from '../../images/dummy__img.jpg';
import sajed_img from '../../images/sajed_img.jpg';

const WhoWeAre = () => {
  const [modalTeamMembersIsOpen, setModalTeamMembersIsOpen] = useState(false);
  function openModalTeamMembers() {
    // alert("mmm")
    setModalTeamMembersIsOpen(true);
  }
  function closeModalTeamMembers(e) {
    setModalTeamMembersIsOpen(false);
  }
  const [arrTeam, setArrTeam] = useState([]);
  // console.log("arr:", arr);

  const [modalAdvisoryMembersIsOpen, setModalAdvisoryMembersIsOpen] =
    useState(false);
  function openModalAdvisoryMembers() {
    // alert("mmm")
    setModalAdvisoryMembersIsOpen(true);
  }
  function closeModalAdvisoryMembers(e) {
    setModalAdvisoryMembersIsOpen(false);
  }
  const [arr, setArr] = useState([]);
  // console.log("arr:", arr);

  const [ourTargetMarket, setOurTargetMarket] = useState([
    {
      id: 1,
      description:
        'Doctor, Nurses, Allied health professionals, Health administrator, Clinics, Private and Public Hospital in Bangladesh.',
    },
    { id: 2, description: 'General People of Bangladesh in large as users.' },
    {
      id: 3,
      description: 'Later stage similar groups in other parts of the world.',
    },
  ]);
  const [ourBusinessPlan, setOurBusinessPlan] = useState([
    {
      id: 1,
      description:
        'We produce International Standard Medical Software for Clinical use by the Doctors, Nurses, and Allied Health professionals.',
    },
    {
      id: 2,
      description:
        'We integrate the public health care system to personal care and develop software for general people.',
    },
    {
      id: 3,
      description:
        'Connecting people and health care professionals through electronic platforms.',
    },
    {
      id: 4,
      description:
        'Educate Medical professionals and the general public through electronic media.',
    },
  ]);
  const [ourTeamMembers, setOurTeamMembers] = useState([
    {
      id: 1,
      image: AminurRahman__img,
      name: 'Dr. Aminur Rahman',
      title: 'Managing Director & CEO',
      description:
        "<p>Dr. Md Aminur Rahman is the CEO of Macrohealth Plus Software, a cutting-edge technology company in the healthcare sector. He is a highly experienced and accomplished professional, with a background in Medicine and a passion for using technology to improve healthcare outcomes.</p><p>With over a decade of experience in the healthcare industry, Dr. Rahman has a deep understanding of the challenges faced by healthcare providers and patients alike. He has leveraged this knowledge to develop innovative software solutions that streamline processes and improve patient outcomes.</p><p>Under Dr. Rahman's leadership, Macrohealth Plus Software has become a leading provider of healthcare technology, with a strong focus on developing solutions that integrate seamlessly with existing systems and processes. The company has earned a reputation for delivering high-quality, reliable software that makes a real difference in the lives of healthcare providers and patients.</p><p>Dr. Rahman is a visionary leader who is always looking for new and innovative ways to improve healthcare outcomes through technology. He is a strong advocate for the power of technology to make a positive impact in the world, and is dedicated to using his expertise to help make this a reality.</p><p>With his passion, drive, and commitment to excellence, Dr. Md Aminur Rahman is a leader who is poised to make a lasting impact on the healthcare industry.</p>",
    },
    {
      id: 2,
      image: mohasina_img,
      name: 'Dr. Mohsina Nazneen Bhuiyan',
      title: 'Director',
      description:
        "<p>Dr. Mohsina Bhuiyan is the Director of Macrohealth plus Software, a healthcare technology company that develops innovative software solutions to improve patient care and streamline healthcare services. Dr. Bhuiyan is a highly skilled and experienced healthcare professional who has dedicated her career to improving healthcare access and quality for patients. She has extensive experience in healthcare management and software development, and she is known for her leadership and vision in the industry.</p><p>Dr. Bhuiyan's background includes a medical degree and postgraduate training in healthcare. She got Information Technology from her family background. Her late father Mr Muzzaffar Ali Bhuiyan was the Pioneer IT, in Bangladesh. He established the first commercialized IT: Adamjee Jute mill,Central bank: Bank of East Pakistan (Bangladesh Bank) and established IBM in Bangladesh. She has worked in a variety of healthcare settings, including hospitals and  clinics in Australia and is the current CEO of Complete Vision optometrist. Her expertise in healthcare management has helped develop the software. This also includes the usage of other medical professionals. Her knowledge has benefited the company greatly. As the Director of Macrohealth plus Software, Dr. Bhuiyan is responsible for overseeing the development and implementation of software solutions that enhance patient care, increase efficiency, and reduce healthcare costs. She works closely with healthcare providers and industry experts to identify areas where technology can improve healthcare services and collaborates with the team to create innovative solutions that meet the needs of others. Dr. Bhuiyan's commitment to improving healthcare access and quality has earned her a reputation as a visionary in the industry. She is highly respected by her colleagues and is known for her dedication, expertise, and innovative approach to healthcare technology.</p>",
    },
  ]);
  const [ourAdvisoryMembers, setOurAdvisoryMembers] = useState([
    {
      id: 1,
      image: NAEheadShot__img,
      name: 'Nick Ebbeck',
      title: 'Advisor',
      card_link: 'our-advisory-members-details',
      description:
        'Nick is an experienced CEO with over 30 years of corporate experience. Nick has global experience in, particular in the Australian & New Zealand markets driving growth in the technology industry. He previously held senior roles at Harvey Norman, JB HiFi, and Vodafone, where he was responsible for all global enterprise sales across Australia and New Zealand. Nick also served as Mayor of his local community, where his collegiate approach provided substantial benefits to his community. Nick firmly believes in placing the customer first and at the centre of every decision he makes and will embed this methodology into the DNA of SureVision to ensure our customers receive what they expect.',
    },
    {
      id: 2,
      image: sajed_img,
      name: 'Dr Muhammad Sajedur Rahman',
      title: 'Advisor',
      card_link: 'our-advisory-members-details',
      description:
        'Sajed possess over four decades of experiences and versatile knowledge in diverse areas -ranging from academia, applied research in scientific and engineering fields and international business development and marketing management consultancy. Born in Bangladesh, Sajed has an outstanding academic career with meritorious results from secondary-higher secondary final examinations, a master’s degree in Nuclear Physics from Government College (now University) Lahore Pakistan, and a PhD (1976) in Solid State Physics from Imperial College of Science, Technology, Management and Medicine, London University, UK- where he studied under a British Commonwealth Fellowship and Scholarship Programme. Sajed held teaching and research positions at universities and colleges in Bangladesh, United Kingdom, Malaysia and Australia. He was a Senior Research Scientist at the Textile Technology and Applied Physics Divisions of CSIRO Australia. He held senior positions at the Planning Division of Sydney water, prior to moving onto joining as Executive Director of an international business development and marketing management consultancy. Sajed has over two decades of hands-on practical experience in providing strategic business development advisory services to numerous Australian and overseas business organisations. As an Associate of Asia Pacific Research Institute at Macquarie (APRIM), Macquarie University, he provided education and training services in strategic business planning and business re-engineering to several large State-Owned Enterprises from People’s Republic of China and Vietnam. He has presented numerous scholarly papers at business seminars, as well as, chaired strategic business seminars at the Australian Institute of Management. Sajed firmly believes in placing the customers first, and providing them with fitness-for-purpose quality services to satisfy their strategic sustainable development and growth needs.',
    },
  ]);

  return (
    <>
      <div className='bg__body'>
        <div className='container'>
          <div className='row g-3  pt-5 mb-2'>
            <div className='col-md-6'>
              <div className='ehr'>
                <h2 className='mb-2'>
                  <span className='header__text h__text__color'>Mission</span>
                </h2>
                <p className='header__description pe-md-4'>
                  We are the “clinical standard” partner of your low-cost
                  medical care in Bangladesh.
                </p>
              </div>
              <div className='ehr'>
                <h2 className='mb-2'>
                  <span className='header__text h__text__color'>Vision</span>
                </h2>
                <p className='header__description pe-md-4'>
                  Error free highest quality of medical care through world class
                  computer programs, guide the clinician now and in future with
                  Artificial Intelligence (AI) and deliver it with an affordable
                  low cost.
                </p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='ehr__img'>
                <img
                  src={who_are_img}
                  className='img-fluid'
                  alt='image'
                  border='0'
                />
              </div>
            </div>
          </div>

          <div className='row g-3  margin__top'>
            <div className='col-md-12'>
              <h3 className='text-center mb-4'>
                <span className='header__text'>Our Team Member</span>
              </h3>
            </div>
          </div>
          <div className='row g-3 pt-2 mb-3 d-flex justify-content-center'>
            {ourTeamMembers.length > 0 &&
              ourTeamMembers.map((item, id) => {
                return (
                  <div key={id} className='col-md-3'>
                    <div
                      className='our__team__card'
                      onClick={() => {
                        openModalTeamMembers();
                        setArrTeam(item);
                      }}
                    >
                      <div className='card text-center'>
                        <div className='our__team__card__img  pt-4'>
                          <img
                            src={item.image}
                            className='card-img-top'
                            alt='images'
                          />
                        </div>
                        <div className='card-body'>
                          <h6 className='our__team__card__title'>
                            {item.name}
                          </h6>
                          <h6 className='our__team__card__subtitle'>
                            {item.title}
                          </h6>
                          {/* <p className="our__team__card__discription">{item.description}</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            <Modal
              isOpen={modalTeamMembersIsOpen}
              onRequestClose={closeModalTeamMembers}
              className='mymodal'
              overlayClassName='myoverlay'
              contentLabel='Example Modal'
            >
              <div className='modal__body'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-12'>
                      <span
                        className='float-end'
                        style={{ fontSize: '20px', cursor: 'pointer' }}
                        onClick={closeModalTeamMembers}
                      >
                        <i className='fa-solid fa-xmark close_btn'></i>
                      </span>
                      <h6 className='header__consultation__text'>
                        Team Member
                      </h6>
                    </div>
                  </div>
                  <div className='row g-2 pt-3'>
                    <div className='col-12'>
                      <div className='card text-center'>
                        <div className='our__team__card__img  pt-4'>
                          <img
                            src={arrTeam.image}
                            className='card-img-top'
                            alt='images'
                          />
                        </div>
                        <div className='card-body'>
                          <h6 className='advisor__card__title'>
                            {arrTeam.name}
                          </h6>
                          <h6 className='advisor__card__subtitle'>
                            {arrTeam.title}
                          </h6>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: arrTeam.description,
                            }}
                            className='advisor__card__discription g-doc-scroll'
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>

          <div className='row g-3  pt-4'>
            <div className='col-md-12'>
              <h3 className='text-center mb-4'>
                <span className='header__text'>Our Advisory Member</span>
              </h3>
            </div>
          </div>

          <div className='row g-3 pt-2 d-flex justify-content-center'>
            {ourAdvisoryMembers.length > 0 &&
              ourAdvisoryMembers.map((item, id) => {
                return (
                  <div key={id} className='col-md-3'>
                    {/* <Link to={`/our-advisory-members-details/${item.id}`} style={{ textDecoration: "none" }} > */}
                    <div
                      className='our__team__card'
                      onClick={() => {
                        openModalAdvisoryMembers();
                        setArr(item);
                      }}
                    >
                      <div className='card text-center'>
                        <div className='our__team__card__img  pt-4'>
                          <img
                            src={item.image}
                            className='card-img-top'
                            alt='images'
                          />
                        </div>
                        <div className='card-body'>
                          <h6 className='our__team__card__title'>
                            {item.name}
                          </h6>
                          <h6 className='our__team__card__subtitle'>
                            {item.title}
                          </h6>
                          {/* <p className="our__team__card__discription">{item.description}</p> */}
                        </div>
                      </div>
                    </div>
                    {/* </Link> */}
                  </div>
                );
              })}
            <Modal
              isOpen={modalAdvisoryMembersIsOpen}
              onRequestClose={closeModalAdvisoryMembers}
              className='mymodal'
              overlayClassName='myoverlay'
              contentLabel='Example Modal'
            >
              <div className='modal__body'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-12'>
                      <span
                        className='float-end'
                        style={{ fontSize: '20px', cursor: 'pointer' }}
                        onClick={closeModalAdvisoryMembers}
                      >
                        <i className='fa-solid fa-xmark close_btn'></i>
                      </span>
                      <h6 className='header__consultation__text'>
                        Advisory Member
                      </h6>
                    </div>
                  </div>
                  <div className='row g-2 pt-3'>
                    <div className='col-12'>
                      <div className='card text-center'>
                        <div className='our__team__card__img  pt-4'>
                          <img
                            src={arr.image}
                            className='card-img-top'
                            alt='images'
                          />
                        </div>
                        <div className='card-body'>
                          <h6 className='advisor__card__title'>{arr.name}</h6>
                          <h6 className='advisor__card__subtitle'>
                            {arr.title}
                          </h6>
                          <p className='advisor__card__discription g-doc-scroll'>
                            {arr.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>

          <div className='row margin__top'>
            <h3 className='text-center mb-4'>
              <span className='header__text'>Our Business Plan</span>
            </h3>
            <div className='col-md-12'>
              <div className='row g-3'>
                {ourBusinessPlan.length > 0 &&
                  ourBusinessPlan.map((item, id) => {
                    return (
                      <div key={id} className='col-md-3'>
                        <div className='business__plan__card'>
                          <div className='card'>
                            <div className='card-body'>
                              <p className='business__plan__description'>
                                {item.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className='row margin__top'>
            <h3 className='text-center mb-4'>
              <span className='header__text'>Our Target Market</span>
            </h3>
            <div className='col-md-12'>
              <div className='row g-3'>
                {ourTargetMarket.length > 0 &&
                  ourTargetMarket.map((item, id) => {
                    return (
                      <div key={id} className='col-md-4'>
                        <div className='target__market__card'>
                          <div className='card'>
                            <div className='card-body'>
                              <p className='target__market__description'>
                                {item.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className='row margin__top'>
            <div className='col-md-12'>
              <h3 className='text-center mb-1'>
                <span className='header__text'>Our Business Goals</span>
              </h3>
            </div>
          </div>
          <div className='row pt-0'>
            <div className='col-md-12'>
              <div className='our__business__goal'>
                <img src={OurbusinessGoal} className='img-fluid' alt='image' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bg__body'>
        <NewsLetter />
      </div>
    </>
  );
};

export default WhoWeAre;
