import React, { useState } from 'react';
import Modal from 'react-modal';
import CustomPDF from './CustomPDF';
Modal.setAppElement('#root');

const ResourceModal = ({
  modalQRIsOpen,
  setModalQRIsOpen,
  data,
  filterArr,
}) => {
  // const [selectedItem, setSelectedItem] = useState({});
  const [selectedItemForResource, setSelectedItemForResource] = useState({});
  const [filterKey, setFilterKey] = useState('');
  // const [isOpenSubC, setIsOpenSubC] = useState(false);
  const [isOpenForResource, setIsOpenForResource] = useState(false);

  //   function closeModal() {
  //     setIsOpen(false);
  //   }

  const customStyles1 = {
    content: {
      //   marginTop: '40px',
      top: '57%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '70%',
      height: '470px',
    },
  };
  // const customStylesForSubC = {
  //   content: {
  //     top: '50%',
  //     left: '50%',
  //     overflowX: 'hidden',
  //     overflowY: 'auto',
  //     right: 'auto',
  //     bottom: 'auto',
  //     marginRight: '-50%',
  //     transform: 'translate(-50%, -50%)',
  //     width: '80%',
  //     height: '560px',
  //   },
  // };

  const handleSelectSubCategory = (data) => {
    setSelectedItemForResource(data?.resources[0] || {});
    setIsOpenForResource(true);
    // setSelectedItem(data);

    // setIsOpenSubC(true);
    // setIsOpen(true);
  };
  // const filter = () => {
  //     data.
  // }

  //   const handleSelect = (item) => {
  //     setSelectedItem(item);
  //     setIsOpen(true);
  //     if (selectedItem.id) {
  //       http
  //         .get(`resource/get/${selectedItem.id}`)
  //         .then((res) => {
  //           setResources(res.data.data);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         })
  //         .finally(() => {
  //           setSpinner(false);
  //         });
  //     }
  //   };
  //   const handleSelect = (item) => {
  //     setModalQRIsOpen(false);
  //     navigate(`research-reports/${item.id}`);
  //   };

  // const handleResource = (item) => {
  //   setSelectedItemForResource(item);
  //   const fileExtension = item?.file?.slice(
  //     ((item?.file?.lastIndexOf('.') - 1) >>> 0) + 2,
  //   );
  //   setIsOpenForResource(true);
  // };

  return (
    <div>
      <Modal
        isOpen={modalQRIsOpen}
        onRequestClose={() => setModalQRIsOpen(false)}
        style={customStyles1}
        className='mymodal'
        overlayClassName='myoverlay'
        contentLabel='Example Modal'
      >
        <div className='modal__body'>
          <div className='card-body'>
            <div className='row g-3 mb-3'>
              <div className='col-12'>
                <div className='pt-3'>
                  <h3 className='text-center'>{data?.categoryName}</h3>
                </div>
                <div className='modal__edu__guideline d-flex justify-content-center  pt-3'>
                  {filterArr?.length > 0 &&
                    filterArr?.map((item) => (
                      <button
                        onClick={() =>
                          item.word_name === 'All'
                            ? setFilterKey('')
                            : setFilterKey(item?.word_name)
                        }
                        key={item.id}
                        className='btn modal__guideline__btn'
                        style={{
                          fontSize: '14px',
                        }}
                      >
                        {item?.word_name}
                      </button>
                    ))}
                </div>

                <hr />

                <div className='modal__edu__guideline pt-3'>
                  <div className='row g-2 pt-4'>
                    {data?.subCategories?.length > 0 &&
                      data?.subCategories
                        ?.filter((item) =>
                          item.subCategoryName.startsWith(filterKey),
                        )
                        .map((item) => (
                          <div key={item.id} className='col-6 text-start'>
                            <button
                              onClick={() => handleSelectSubCategory(item)}
                              className='btn modal__det__btn'
                              style={{
                                fontSize: '13px',
                              }}
                            >
                              {item.subCategoryName}
                            </button>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div>
        <Modal
          isOpen={isOpenForResource}
          style={{
            content: {
              top: '80px',
              left: '10px',
              right: '10px',
              bottom: '10px',
              overflow: 'hidden',
            },
            overlay: {
              backgroundColor: 'transparent',
            },
          }}
          onRequestClose={() => setIsOpenForResource(false)}
          overlayClassName='myoverlay'
          contentLabel='Example Modal'
        >
          <div className='row  p-0 m-0 '>
            <div className='col-12 p-0 m-0'>
              <div className='col-12'>
                <button
                  style={{
                    background: 'lightgray',
                    color: 'black',
                    border: 'none',
                    float: 'right',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    padding: '2px 8px',
                  }}
                  onClick={() => setIsOpenForResource(false)}
                >
                  <i className='fa fa-times'></i>
                </button>
                <div
                  style={{
                    overflow: 'hidden',
                  }}
                >
                  {selectedItemForResource?.embedUrl &&
                  selectedItemForResource?.embedUrl !== 'null' &&
                  selectedItemForResource?.embedUrl !== null ? (
                    <>
                      <iframe
                        src={`${selectedItemForResource?.embedUrl}`}
                        frameborder='0'
                        allow='autoplay; fullscreen; picture-in-picture'
                        style={{ width: '100%', height: '400px' }}
                        title='Q&amp;A - Gary Leong - Metabolic Syndrome.mp4'
                      ></iframe>
                    </>
                  ) : (
                    <>
                      {selectedItemForResource?.file?.slice(
                        ((selectedItemForResource?.file?.lastIndexOf('.') -
                          1) >>>
                          0) +
                          2,
                      ) === 'pdf' ? (
                        <div
                          style={{
                            width: '100%',
                            height: '80vh',
                          }}
                        >
                          <CustomPDF
                            url={`${global.img_Url}${selectedItemForResource?.file}`}
                          />
                        </div>
                      ) : (
                        <a
                          href={`${global.img_Url}${selectedItemForResource?.file}`}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='btn btn-primary'
                        >
                          Show File
                        </a>
                      )}
                    </>
                  )}

                  {/* <blockquote>
                    {selectedItemForResource?.fileContent}
                  </blockquote> */}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      {/* <div>
        <Modal
          isOpen={isOpenSubC}
          style={customStylesForSubC}
          onRequestClose={() => setIsOpenSubC(false)}
          className='mymodal'
          overlayClassName='myoverlay'
          contentLabel='Example Modal'
        >
          <div className='row gap-3'>
            <div className='col-12'>
              <h1 className='text-center'>{selectedItem?.subCategoryName}</h1>
              <hr />
            </div>
            <div className='col-12'>
              {selectedItem.resources?.length === 0 && (
                <div
                  style={{
                    margin: '20px 0px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <h2>No Resources</h2>
                </div>
              )}
            </div>
            <div className='col-12'>
              <div className='row g-3 gy-sm-5 gx-sm-4 pt-1 d-flex  px-2'>
                {selectedItem?.resources?.length > 0 &&
                  selectedItem?.resources?.map((item, id) => {
                    const fileExtension = item?.file?.slice(
                      ((item?.file?.lastIndexOf('.') - 1) >>> 0) + 2,
                    );
                    let IsImage = false;
                    if (
                      fileExtension === 'jpg' ||
                      fileExtension === 'jpeg' ||
                      fileExtension === 'png' ||
                      fileExtension === 'webp'
                    ) {
                      IsImage = true;
                    } else {
                      IsImage = false;
                    }
                    return (
                      <div
                        onClick={() => handleResource(item)}
                        key={id}
                        className='col-md-3 '
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <div className='webinars__card'>
                          <div className='card'>
                            {IsImage && (
                              <img
                                src={`${global.img_Url}/${item?.file}`}
                                className='card-img-top'
                                alt='images'
                              />
                            )}
                            {!IsImage && <img src={placeholder} alt='plcea' />}
                            <div className='card-body'>
                              <h6 className='webinars__card__title'>
                                {item.title}
                              </h6>
                              <p className='webinars__card__description'>
                                {item.fileContent}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </Modal>
       
      </div> */}
    </div>
  );
};

export default ResourceModal;
