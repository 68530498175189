import React, { useState } from 'react';
import AccrodianItem from './accrodianDigiPatient/AccrodianItem';
import Vital_img from '../../images/Vital_img.png';
import Appoinment__img from '../../images/Appoinment__img.png';

const DigiPatientDetailsProcess = () => {
    const [accrodianHealthRecordData, setElectronicHealthRecords] = useState([
        { id: 1, title: "Manage personal health records", desc: "Patients can upload vital records and other health- related documents to the app." },
        { id: 2, title: "Make payments", desc: "Patients can pay doctor’s fees via the in-app payment feature at the time of booking itself." },
        { id: 3, title: "Upload Health Documents", desc: "Patients can upload documents such as prescriptions and other health-related documents" },
        { id: 4, title: "Access learning materials", desc: "Patients can access different learning materials such as women health, child health and infectious disease -related documents to the app" },
    ]);
    const [accrodianAppointmentData, setAccrodianAppointmentData] = useState([
        { id: 1, title: "Search Doctors", desc: "Users can search doctors for online consultation by doctor name, city, specialties, symptoms, and view details: specialty, subspecialty, degree, address, for consultation charges, availability, ratings." },
        { id: 2, title: "Book Video Consultation", desc: "Users can book the video consultation appointment at a convenient time slot based on symptoms or doctor specialty and the doctors’ availability." },
        { id: 3, title: "View Appointments", desc: "Users can see their upcoming doctor appointment details such as doctor’s name, date, time, total charges, payment status, and symptoms." },
        { id: 4, title: "Notifications", desc: "Users receive notifications for upcoming appointments, rescheduled appointments, shared medicine orders, and follow-up appointments." },
    ]);
    const [accrodianLaboratoryData, setAccrodianLaboratoryData] = useState([
        { id: 1, title: "Select Packages", desc: "Users can book packages offered by labs and read about included tests, eligibility, test preparations and requirements, and detailed description before booking." },
        { id: 2, title: "View complete lab details", desc: "View all the necessary lab details like contact details, offered packages, awards, lab images, offered tests, working hours, certificates, etc., on the booking page." },
        { id: 3, title: "Search and Book Lab Tests", desc: "Users can easily search lab tests by selecting lab category and test name, and they can book either sample home collection or lab appointment." },
        { id: 4, title: "Push Notifications", desc: "In this feature, users get push notifications related to order status updates and new offers." },
    ])
    const [accrodianPharmacyData, setAccrodianPharmacyData] = useState([
        { id: 1, title: "Super-fast search and buy", desc: "Super-fast search allows medicine ordering without scrolling through the whole catalog. One can search for medicines through voice also." },
        { id: 2, title: "Dose reminder", desc: "Set dose reminders with descriptions so that you always take your medicines on time. Later on, you can modify your reminders also." },
        { id: 3, title: "Upload Medicine Order", desc: "Take a photo of the medicine order and upload it directly. As simple as that. One can even choose the picture from the gallery also." },
        { id: 4, title: "Find Substitutes", desc: "Get the list of all the alternatives and cheaper options of the products based on the composition of the searched item." },
    ])
    const [openAccrodian, setOpenAccrodian] = useState(0);
    const handleAccrodian = accrodianNumber => {
        setOpenAccrodian(openAccrodian === accrodianNumber ? null : accrodianNumber)
    }


    return (
        <div className="conatiner mb-4">
            <div className="row ">
                <div className="col-sm-12">
                    <h3 className="text-center mb-2">
                        <span className="header__text">DigiPateint</span>
                    </h3>
                    <div className="macro">
                        <p className="header__description2 solution__macro">
                            Digital Electronic Health Records apps organize doctor appointments, set reminders for taking
                            medication. They offer 360° patient care and allow integration as practice management,
                            revenue cycle management, laboratory management, and medical billing software.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row pt-3">
                <div className="col-md-12">
                    <div className="pricing__nav">
                        <ul className="nav nav-pills mb-3 nav-fill nav__bg" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link pricing__link active" id="pills-by-HealthRecord-tab" data-bs-toggle="pill" data-bs-target="#pills-by-HealthRecord" type="button" role="tab" aria-controls="pills-by-HealthRecord" aria-selected="true">Health Record</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link pricing__link text-nowrap" id="pills-Appointment-tab" data-bs-toggle="pill" data-bs-target="#pills-Appointment" type="button" role="tab" aria-controls="pills-Appointment" aria-selected="false">Appointment</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link pricing__link" id="pills-Laboratory-tab" data-bs-toggle="pill" data-bs-target="#pills-Laboratory" type="button" role="tab" aria-controls="pills-Laboratory" aria-selected="false">Laboratory</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link pricing__link" id="pills-Pharmacy-tab" data-bs-toggle="pill" data-bs-target="#pills-Pharmacy" type="button" role="tab" aria-controls="pills-Pharmacy" aria-selected="false">Pharmacy</button>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-by-HealthRecord" role="tabpanel" aria-labelledby="pills-by-HealthRecord-tab">
                            <div className="row g-3 pt-3">
                                <div className="col-md-6">
                                    <div className="digipatient__img">
                                        <img src={Vital_img} alt="" className="img-fluid"  loading="lazy" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {
                                        accrodianHealthRecordData.map((data, index, id) => {
                                            return (
                                                <div key={index}>
                                                    <AccrodianItem 
                                                        dataProps={data}
                                                        indexProps={index}
                                                        openAccrodianProps={openAccrodian}
                                                        setOpenAccrodianProps={setOpenAccrodian}
                                                        handleAccrodianProps={handleAccrodian}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-Appointment" role="tabpanel" aria-labelledby="pills-Appointment-tab">
                            <div className="row g-3 pt-3">
                                <div className="col-md-6">
                                    <div className="digipatient__img">
                                        <img src={Appoinment__img} alt="" className="img-fluid"  loading="lazy" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {
                                        accrodianAppointmentData.map((data, index, id) => {
                                            return (
                                                <div key={index}>
                                                    <AccrodianItem 
                                                        dataProps={data}
                                                        indexProps={index}
                                                        openAccrodianProps={openAccrodian}
                                                        setOpenAccrodianProps={setOpenAccrodian}
                                                        handleAccrodianProps={handleAccrodian}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-Laboratory" role="tabpanel" aria-labelledby="pills-Laboratory-tab">
                            <div className="row g-3 pt-3">
                                <div className="col-md-6">
                                    <div className="digipatient__img">
                                        <img src={Appoinment__img} alt="" className="img-fluid"  loading="lazy" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {
                                        accrodianLaboratoryData.map((data, index, id) => {
                                            return (
                                                <div key={index}>
                                                    <AccrodianItem 
                                                        dataProps={data}
                                                        indexProps={index}
                                                        openAccrodianProps={openAccrodian}
                                                        setOpenAccrodianProps={setOpenAccrodian}
                                                        handleAccrodianProps={handleAccrodian}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-Pharmacy" role="tabpanel" aria-labelledby="pills-Pharmacy-tab">
                            <div className="row g-3 pt-3">
                                <div className="col-md-6">
                                    <div className="digipatient__img">
                                        <img src={Appoinment__img} alt="" className="img-fluid"  loading="lazy" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {
                                        accrodianPharmacyData.map((data, index, id) => {
                                            return (
                                                <div key={index}>
                                                    <AccrodianItem 
                                                        dataProps={data}
                                                        indexProps={index}
                                                        openAccrodianProps={openAccrodian}
                                                        setOpenAccrodianProps={setOpenAccrodian}
                                                        handleAccrodianProps={handleAccrodian}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default DigiPatientDetailsProcess;