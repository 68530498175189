import "./AccrodianPRM.css";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import DigonosisHistory__img from '../../../images/DigonosisHistory__img.png';
import vital__sign__img from '../../../images/vital__sign__img.png';
import UploadMedicalDocuments__img from '../../../images/UploadMedicalDocuments__img.png';
import maintain_vital_clinical_records from '../../../images/maintain_vital_clinical_records.png';

const AccrodianPRM = () => {
  const [subMenuOpen, setSubMenuOpen] = useState(0);
  const toggleMenu = (x) => setSubMenuOpen(subMenuOpen === x ? -1 : x);

  return (
    <>
      <div className="row g-3 pt-1">
        {/* handle only body  */}
        <div className="col-md-6">
          <div className="d-1">
            <div className={`sub-menu  ${subMenuOpen === 0 ? "is-open" : ""}`}>
              <div className="prm__img">
                {/* <p>item 1</p> */}
                <img src={vital__sign__img} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="d-2">
            <div className={`sub-menu  ${subMenuOpen === 1 ? "is-open" : ""}`}>
              <div className="prm__img">
                {/* <p>item 2</p> */}
                <img src={DigonosisHistory__img} alt="" className="img-fluid"/>
              </div>
            </div>
          </div>
          <div className="d-3">
            <div className={`sub-menu  ${subMenuOpen === 2 ? "is-open" : ""}`}>
              <div className="prm__img">
                {/* <p>item 3</p> */}
                <img src={UploadMedicalDocuments__img} alt="" />
              </div>
            </div>
          </div>
          <div className="d-4">
            <div className={`sub-menu  ${subMenuOpen === 3 ? "is-open" : ""}`}>
              <div className="prm__img">
                {/* <p>item 4</p> */}
                <img src={UploadMedicalDocuments__img} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        {/* handle button & body  */}
        <div className="col-md-6">
          <div className="submenu-list">
            <div className="d-1">
              <div onClick={() => toggleMenu(0)} className="d-flex justify-content-between prm__btn" >
                <p className="prm__drop__title">Maintain Vital clinical records</p>
                <p><FontAwesomeIcon icon={subMenuOpen === 0 ? faMinus : faPlus} /></p>
              </div>

              <div className={`sub-menu  ${subMenuOpen === 0 ? "is-open" : ""}`}>
                <div className="prm__drop__body">
                  <p className="header__description">
                    With <strong className="inspiration__span">MacroHealthPlus</strong> electronic health records you can easily record all
                    your vitals like height, weight, BMI, Blood Pressure, Pulse rate, Temperature, Respiration, Oxygen saturations, Allergy,
                    Diet, Blood Group, Medical Questionnaire and Clinical outcome data from your end to get a partnership with your doctor to manage your health.
                  </p>
                  <p className="header__description">You can enter the findings with a single click.</p>
                  <p className="header__description">
                    You can also keep a record of active medication and help your doctor to find drug to drug or drug to disease interaction and help
                    you prescribe new medications safely.
                  </p>
                </div>
              </div>
            </div>

            <div className="d-2">
              <div onClick={() => toggleMenu(1)} className="d-flex justify-content-between prm__btn">
                <p className="prm__drop__title">Medical History</p>
                <p><FontAwesomeIcon icon={subMenuOpen === 1 ? faMinus : faPlus} /></p>
              </div>

              <div className={`sub-menu ${subMenuOpen === 1 ? "is-open" : ""}`}>
                <div className="prm__drop__body">
                  <p className="header__description">Medical history of a patient is very important detail which forms basis for future treatment in lot of cases. Doctors can capture medical history of patient using  <strong className="inspiration__span">MacroHealthPlus</strong> EHR and refer same in future.</p>
                  <p className="header__description"> <strong className="inspiration__span">MacroHealthPlus</strong> EHR also provides medical history tags which can be added to patients in order to serve as quick reference point in future. For lot of doctors simply scanning medical tags gives them quick sense of patient's medical history.</p>
                </div>
              </div>
            </div>
            <div className="d-3">
              <div onClick={() => toggleMenu(2)} className="d-flex justify-content-between prm__btn">
                <p className="prm__drop__title">Upload Medical Documents</p>
                <p><FontAwesomeIcon icon={subMenuOpen === 2 ? faMinus : faPlus} /></p>
              </div>

              <div className={`sub-menu ${subMenuOpen === 2 ? "is-open" : ""}`}>
                <div className="prm__drop__body">
                  <p className="header__description">You can add medical documents related to tests/investigation reports of the patients in formats like files, pdf, excel, medical images etc. for a particular patient. Same can be viewed anytime in future.</p>
                  <p className="header__description">Software also allows doctors to tag files with customized name and also add description to the medical document which can act as reference in future.</p>
                  <p className="header__description">Doctors can either choose to simply view the medical documents inside the software or even download files. Medical documents can also be sent to patients whenever patients requests for any particular medical document.</p>
                </div>
              </div>
            </div>
            <div className="d-4">
              <div onClick={() => toggleMenu(3)} className="d-flex justify-content-between prm__btn">
                <p className="prm__drop__title">Search Patient Health Records</p>
                <p><FontAwesomeIcon icon={subMenuOpen === 3 ? faMinus : faPlus} /></p>
              </div>

              <div className={`sub-menu ${subMenuOpen === 3 ? "is-open" : ""}`}>
                <div className="prm__drop__body">
                  <p className="header__description"> <strong className="inspiration__span">MacroHealthPlus</strong> EHR provides quick search to help find patient records by patient-name, patient-id etc. Doctors can quickly search for a particular patient's health record by using  <strong className="inspiration__span">MacroHealthPlus</strong>'s instant patient search functionality which shows patient matches as you type.</p>
                  <p className="header__description">Patients can be filtered by patient groups and also by treatment, appointment and payment status.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AccrodianPRM;