import React, { useEffect, useState } from "react";
import "../header/Header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

import img_logo1 from "../../images/img_logo1.png";
import LOGO_2 from "../../images/LOGO_2.png";
import Digi_Patient2 from "../../images/Digi_Patient2.png";
import Great_Doc1 from "../../images/Great_Doc1.png";
import Great_Lab1 from "../../images/Great_Lab1.png";
import Great_Pharma1 from "../../images/Great_Pharma1.png";
import telehealth1 from "../../images/telehealth1.png";
import Ecommerce_img from "../../images/Ecommerce_img.png";
import Marketplace1 from "../../images/Marketplace1.png";
import Lab_Agent1 from "../../images/Lab_Agent1.png";

import educational_guides_img from "../../images/educational_guides_img.png";
import ehr_records_img from "../../images/ehr_records_img.png";
import news_img from "../../images/news_img.png";
import help_img from "../../images/help_img.png";
import webinars_img from "../../images/webinars_img.png";

import general_practice_img from "../../images/general_practice_img.png";
import cardiologist_img from "../../images/cardiologist_img.png";
import chest_physician_img from "../../images/chest_physician_img.png";
import neurologist_img from "../../images/neurologist_img.png";
import urologist_img from "../../images/urologist_img.png";
import ent_specialist_img from "../../images/ent_specialist_img.png";
import Dermatologist_img from "../../images/Dermatologist_img.png";
import rheumatologist_img from "../../images/rheumatologist_img.png";
import psychiatrist_psychologist_img from "../../images/psychiatrist_psychologist_img.png";
import obstetrics_gynaecologist_img from "../../images/obstetrics_gynaecologist_img.png";
import paediatricians_img1 from "../../images/paediatricians_img1.png";
import orthopaedic_surgeon_img from "../../images/orthopaedic_surgeon_img.png";
import gastroenterologist_img from "../../images/gastroenterologist_img.png";
import ophthalmologist_img from "../../images/ophthalmologist_img.png";
import neuro_surgeon_img from "../../images/neuro_surgeon_img.png";
import general_surgeon_img from "../../images/general_surgeon_img.png";
import http from "../../http";

const Header = () => {
  const navigate = useNavigate();
  const [group, setGroup] = useState([]);
  const headerClickHandler = (e) => {
    e.preventDefault();
    // navigate(-1);
    navigate("/");
  };

  useEffect(() => {
    http
      .get("/group/get")
      .then((res) => {
        setGroup(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const location = useLocation();
  return (
    <div className="ht__navbar  sticky-top ">
      {/* <TopHeader /> */}
      <nav className="navbar navbar-expand-lg bg__body navbar-dark">
        <div className="container container-fluid ht__navbar__logo">
          <Link to="/" className={`navbar-brand`}>
            {/* <img src={img_logo1} className="img-fluid" style={{ width: '95px', height: '65px' }} alt="img_logo1" border="0" /> */}
            <img
              src={img_logo1}
              className="img-fluid"
              style={{ height: "50px", objectFit: "contain" }}
              alt="img_logo1"
              border="0"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end navbar__collapse"
            id="navbarNav"
          >
            <span onClick={headerClickHandler}></span>

            <ul className="navbar-nav menu3">
              <li className="nav-item mx-1">
                <Link
                  to="/"
                  className={`navbar-brand nav-link navLink  active ${
                    location.pathname === "/" && "active-nav"
                  }`}
                  aria-current="page"
                >
                  <span>Home</span>
                </Link>
              </li>
              <li className="nav-item dropdown mx-1">
                <Link
                  to="#"
                  className={`navbar-brand nav-link navLink  ${
                    location.pathname === "" && "active-nav"
                  }`}
                  id="navbarDropdown13"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="big__submenu">
                    Solutions <i className="fa-solid fa-angle-down pl-2"></i>
                  </span>
                </Link>
                <div
                  className="dropdown-menu drop__solutions"
                  aria-labelledby="navbarDropdown13"
                >
                  <div className="row g-2">
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="digi-patient" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={Digi_Patient2}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>DigiPatient</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="great-doc" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={Great_Doc1}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>GreatDoc</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="great-lab" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={Great_Lab1}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>GreatClinic</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="great-pharma" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={Great_Pharma1}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>GreatPharma</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="telehealth" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={telehealth1}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Telehealth</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link
                          to="e-commerce-medicine"
                          className="dropdown-item"
                        >
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={Ecommerce_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>e-Pharmacy</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="marketplace" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={Marketplace1}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Marketplace</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="lab-agent" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={Lab_Agent1}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>LabAgent</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown13">
                                    <li className="nav-item">
                                        <Link to="electronic-health-records" className="dropdown-item drop__nav__link">
                                         <span>Electronic Health Records</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Practice Management</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="laboratory" className="dropdown-item drop__nav__link">
                                         <span>Great Lab</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pharmacy-pos" className="dropdown-item drop__nav__link">
                                         <span>Great Pharma</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="telehealth" className="dropdown-item drop__nav__link">
                                         <span>Telehealth</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="e-commerce-medicine" className="dropdown-item drop__nav__link">
                                         <span>E-commerce Ecommerce_img</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="marketplace" className="dropdown-item drop__nav__link">
                                         <span>Marketplace</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="lab-agent" className="dropdown-item drop__nav__link">
                                         <span>Lab Agent</span> 
                                        </Link>
                                    </li>
                                </ul> */}
              </li>
              <li className="nav-item dropdown mx-1">
                <Link
                  to="#"
                  className={`navbar-brand nav-link navLink  ${
                    location.pathname === "" && "active-nav"
                  }`}
                  id="navbarDropdown13"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="big__submenu">
                    Specialities <i className="fa-solid fa-angle-down pl-2"></i>
                  </span>
                </Link>
                <div
                  className="dropdown-menu drop__specialities"
                  aria-labelledby="navbarDropdown13"
                >
                  <div className="row g-2">
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="general-practice" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={general_practice_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>General Practice</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="cardiologist" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={cardiologist_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Cardiologist</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="chest-physician" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={chest_physician_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Chest Physician</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="gastroenterologist" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={gastroenterologist_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Gastroenterologist</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="neurologist" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={neurologist_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Neurologist</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="urologist" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={urologist_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Urologist</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="ophthalmologist" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={ophthalmologist_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Ophthalmologist</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="ent-specialist" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={ent_specialist_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>ENT Specialist</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="dermatologist" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={Dermatologist_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Dermatologist</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="rheumatologist" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={rheumatologist_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Rheumatologist</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link
                          to="psychiatrist-and-psychologist"
                          className="dropdown-item"
                        >
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={psychiatrist_psychologist_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Psychiatrist and Psychologist</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="neuro-surgeon" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={neuro_surgeon_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Neuro-surgeon</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link
                          to="obstetrics-and-gynaecologist"
                          className="dropdown-item"
                        >
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={obstetrics_gynaecologist_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Obstetrics and Gynaecologist</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="paediatricians" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={paediatricians_img1}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Paediatricians</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link
                          to="orthopaedic-surgeon"
                          className="dropdown-item"
                        >
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={orthopaedic_surgeon_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Orthopaedic Surgeon</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="general-surgeon" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={general_surgeon_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>General Surgeon</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown13">
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>General Practice</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Cardiologist</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Chest Physician</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Gastroenterologist</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Neurologist</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Urologist</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>ENT Specialist</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Ophthalmologist</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Dermatologist </span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Rheumatologist </span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Psychiatrist and Psychologist</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Neuro-surgeon</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Obstetrics and Gynaecologist</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Paediatricians </span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Orthopaedic Surgeon</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>General Surgeon</span> 
                                        </Link>
                                    </li>
                                </ul> */}
              </li>

              <li className="nav-item dropdown mx-1">
                <Link
                  to="#"
                  className={`navbar-brand nav-link navLink  ${
                    location.pathname === "" && "active-nav"
                  }`}
                  id="navbarDropdown13"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="big__submenu">
                    Resources <i className="fa-solid fa-angle-down pl-2"></i>
                  </span>
                </Link>
                <div
                  className="dropdown-menu drop__resources"
                  aria-labelledby="navbarDropdown13"
                >
                  <div className="row g-2">
                    {group.length &&
                      group.slice(0, 1).map((item, index) => (
                        <div key={index} className="col-sm-4">
                          <div className="nav-item">
                            <Link
                              to={`resource/${item.id}`}
                              className="dropdown-item"
                            >
                              <span>
                                <div className="hsubmenu__img">
                                  {item.icon ? (
                                    <img
                                      src={`${global.img_Url}${item.icon}`}
                                      alt="icon-img"
                                    />
                                  ) : (
                                    <i
                                      style={{
                                        fontSize: "20px",
                                        color: "#4D9621",
                                      }}
                                      class="fa fa-list"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </div>
                                <div className="hsubmenu__text ps-2">
                                  <p>{item.groupName}</p>
                                </div>
                              </span>
                            </Link>
                          </div>
                        </div>
                      ))}

                    {/* <div className='col-sm-4'>
                      <div className='nav-item'>
                        <Link to='educational-guides' className='dropdown-item'>
                          <span>
                            <div className='hsubmenu__img'>
                              <img
                                src={educational_guides_img}
                                className='card-img-top'
                                alt='images'
                              />
                            </div>
                            <div className='hsubmenu__text ps-2'>
                              <p>Patient Education</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div> */}
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="research-reports" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={ehr_records_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Medical Education</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="news-and-views" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={news_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>News and Views</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="help-centre" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={help_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Help Centre</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="nav-item">
                        <Link to="webinars" className="dropdown-item">
                          <span>
                            <div className="hsubmenu__img">
                              <img
                                src={webinars_img}
                                className="card-img-top"
                                alt="images"
                              />
                            </div>
                            <div className="hsubmenu__text ps-2">
                              <p>Webinars</p>
                            </div>
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown13">
                                    <li className="nav-item">
                                        <Link to="electronic-health-records" className="dropdown-item drop__nav__link">
                                         <span>Educational Guides</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Research & Reports</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>News and Views</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Help Centre</span> 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Webinars</span> 
                                        </Link>
                                    </li>
                                </ul> */}
              </li>

              {/* <li className="nav-item dropdown mx-1">
                                <Link to='#' className={`navbar-brand nav-link navLink  ${location.pathname === "" && "active-nav"}`} id="navbarDropdown13" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span>Resources <i className="fa-solid fa-angle-down pt-1 pl-2"></i></span></Link>

                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown13">
                                    <li className="nav-item">
                                        <Link to="practice-management" className="dropdown-item drop__nav__link">
                                         <span>Webinars</span> 
                                        </Link>
                                    </li>
                                </ul>
                            </li> */}
              <li className="nav-item mx-1">
                <Link
                  to="/pricing"
                  className={`navbar-brand nav-link navLink ${
                    location.pathname === "/pricing" && "active-nav"
                  }`}
                >
                  <span>Pricing</span>
                </Link>
              </li>
              <li className="nav-item dropdown mx-1">
                <Link
                  to="#"
                  className={`navbar-brand nav-link navLink  ${
                    location.pathname === "" && "active-nav"
                  }`}
                  id="navbarDropdown13"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    About Us <i className="fa-solid fa-angle-down pl-2"></i>
                  </span>
                </Link>

                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown13"
                >
                  <li className="nav-item">
                    <Link
                      to="who-we-are"
                      className="dropdown-item drop__nav__link"
                    >
                      <span>Who We Are</span>
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                                        <Link to="unique-features" className="dropdown-item drop__nav__link">
                                         <span>Unique Features</span> 
                                        </Link>
                                    </li> */}
                  <li className="nav-item">
                    <Link
                      to="why-choose-us"
                      className="dropdown-item drop__nav__link"
                    >
                      <span>Why Choose Us</span>
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                                        <Link to="data-safety-and-security" className="dropdown-item drop__nav__link">
                                         <span>Data Safety and Security</span> 
                                        </Link>
                                    </li> */}
                </ul>
              </li>
              {/* <li className="nav-item">
                                <Link to='/about' className={`navbar-brand nav-link navLink ${location.pathname === "/about" && "active-nav"}`} ><span>About Us</span></Link>
                            </li> */}
              <li className="nav-item mx-1">
                <Link
                  to="/contact"
                  className={`navbar-brand nav-link navLink ${
                    location.pathname === "/contact" && "active-nav"
                  }`}
                >
                  <span>Contact Us</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/request-demo"
                  id="h_btn"
                  className="navbar-brand nav-link navLink btn_learn_more "
                >
                  Request a Demo
                </Link>
              </li>
              <li className="nav-item client_login">
                <Link
                  to="/client-login"
                  id="h_btn"
                  className="navbar-brand nav-link navLink btn_learn_more"
                >
                  Client Login
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;

// import React from 'react';
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import img_logo1 from '../../images/img_logo1.png';
// import img_logo from '../../images/img_logo.png';
// import '../header/Header.css';
// import TopHeader from './TopHeader';

// const Header = () => {
//     const navigate = useNavigate();
//     const headerClickHandler = e => {
//         e.preventDefault()
//         // navigate(-1);
//         navigate("/")
//     };
//     const location = useLocation();

//     return (
//         <div className="sticky-top">
//             <TopHeader />
//             <nav className="navbar navbar-expand-lg bg__body navbar-dark" >
//                 <div className="container container-fluid" >
//                     <Link to="/" className={`navbar-brand`} >
//                         <img src={img_logo1} className="img-fluid" style={{ width: '80px', height: '55px' }} alt="img_logo1" border="0" />
//                     </Link>
//                     <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
//                         <span className="navbar-toggler-icon"></span>
//                     </button>
//                     <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
//                         <span onClick={headerClickHandler}></span>

//                         <ul className="navbar-nav menu3">
//                             <li className="nav-item">
//                                 <Link to='/' className={`navbar-brand nav-link navLink active ${location.pathname === "/" && "active-nav"}`} aria-current="page"><span>Home</span></Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link to='/solutions' className={`navbar-brand nav-link navLink ${location.pathname === "/solutions" && "active-nav"}`} ><span>Solutions</span></Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link to='/resources' className={`navbar-brand nav-link navLink ${location.pathname === "/resources" && "active-nav"}`} ><span>Resources</span></Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link to='/specialities' className={`navbar-brand nav-link navLink ${location.pathname === "/specialities" && "active-nav"}`} ><span>Specialities</span></Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link to='/pricing' className={`navbar-brand nav-link navLink ${location.pathname === "/pricing" && "active-nav"}`} ><span>Pricing</span></Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link to='/about' className={`navbar-brand nav-link navLink ${location.pathname === "/about" && "active-nav"}`} ><span>About Us</span></Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link to='/contact' className={`navbar-brand nav-link navLink ${location.pathname === "/contact" && "active-nav"}`} ><span>Contact Us</span></Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link to='/request-demo' id="h_btn" className="navbar-brand nav-link navLink btn_learn_more ">Request a Demo</Link>
//                             </li>
//                             <li className="nav-item client_login">
//                                 <Link to='/client-login' id="h_btn" className="navbar-brand nav-link navLink btn_learn_more">Client Login</Link>
//                             </li>
//                         </ul>

//                     </div>
//                 </div>
//             </nav>
//         </div>
//     );
// };

// export default Header;
