import React from 'react'
import under_construction from "../../images/under_construction.jpg";


const Login = () => {
  return (
    <div className="container mb-4 mt-5">
      <h1 className="text-center mb-3">This features are under construction</h1>
      <div className="text-center">
        <img src={under_construction} alt="image" style={{ height: "360px", width: "60%" }} />
      </div>
    </div>
  )
}

export default Login;