import React from 'react';

const refundReturnPolicy = () => {
  return (
    <div className='container mt-3'>
      <h2
        style={{
          fontSize: '18px',
          fontWeight: '600',
          marginBottom: '1rem',
        }}
      >
        Refund & Return Policy
      </h2>

      <ol
        style={{
          fontSize: '16px',
        }}
      >
        <li>
          {' '}
          Return or refund request must be raised within 7 to 10 days for any
          product and services, or from the date of delivery of services.
        </li>
        <li>
          If you are not satisfied on your purchase subscription package, please
          raise a return request on Macrohealthplus website.
        </li>
        <li>
          For selected products, we accept refund on the change of your mind.
          Please refer to your subscription id and send to email at info
          <a
            href='https://macrohealthplus.org/'
            style={{
              textDecoration: 'none',
              color: '#69B128',
            }}
          >
            {' '}
            marchohealthplus.org
          </a>
          .
        </li>
      </ol>

      <h2
        style={{
          fontSize: '18px',
          fontWeight: '600',
          marginBottom: '1rem',
        }}
      >
        Issuance of Refunds
      </h2>
      <ol>
        <li>
          The refund period / process starts when marchohealthplus has processed
          your refund according to your refund type.
        </li>
        <li>
          The processing time of your refund depends on the type of refund and
          the payment method you used.
        </li>
        <li>The refund amount covers the item price show the purchase.</li>
      </ol>
    </div>
  );
};

export default refundReturnPolicy;
