import React from "react";
import Header from "../header/Header";
import { Outlet } from "react-router-dom";
import Footer from "../footer/Footer";
import TopHeader from "../header/TopHeader";

const Layouts = ({ childdren }) => {
  // window.addEventListener('load', (event) => {
  //     console.log('page is fully loaded');
  //   });
  return (
    <div style={{ overflow: "hidden" }}>
      <TopHeader />
      <Header />
      <div>{childdren}</div>

      <div className="content">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layouts;
