import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NewsLetter from '../home/NewsLetter';

import { useEffect } from 'react';
import http from '../../http';

const ResearchReports = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await http.get('medicalEducation/get');

        if (response.status === 200) {
          setData(response.data?.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getData();
    return () => {
      //// cleanup
    };
  }, []);

  return (
    <>
      <div className='container'>
        <div className='row g-3 pt-4'>
          <div className='col-md-12'>
            <h3 className='text-center mb-2'>
              <span className='header__text'>Medical Education</span>
            </h3>
          </div>
        </div>
        <div className='row g-3 gy-sm-5 gx-sm-4 pt-4 d-flex justify-content-center'>
          {loading && <Loading />}
          {data.length > 0 &&
            data.map((item, id) => {
              return (
                <div key={id} className='col-md-3'>
                  <Link
                    to={`/research-reports/${item.id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <div className='webinars__card'>
                      <div className='card'>
                        <img
                          src={`${global.img_Url}${item.thumbnail}`}
                          className='card-img-top'
                          alt='images'
                        />
                        <div className='card-body'>
                          <h6 className='webinars__card__title'>
                            {item.title}
                          </h6>
                          <p className='webinars__card__description'>
                            {item.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
        {/* <div className='text-center pt-4 mb-5'>
          <a src='#' type='text' className='btn webinars__btn__load__more'>
            Load More
          </a>
        </div> */}
      </div>
      <div className='bg__body'>
        <NewsLetter />
      </div>
    </>
  );
};

const Loading = () => (
  <div
    style={{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div class='loader'></div>
  </div>
);
export default ResearchReports;
