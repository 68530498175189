import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HorizontalStepperSpecialist from './HorizontalStepperSpecialist';
import StepperSpecialistMobile from './StepperSpecialistMobile';
import NewsLetter from '../home/NewsLetter';

import MusculoSkeletalExamination from '../../images/MusculoSkeletalExamination.png';
import HealthWorkflow8 from '../../images/option_1/HealthWorkflow8.png';
import Paperless3 from '../../images/option_1/Paperless3.png';
import EasyConfiguration from '../../images/option_1/EasyConfiguration.png';
import DataSecurity1 from '../../images/option_1/DataSecurity1.png';
import ClientServer1 from '../../images/option_1/ClientServer1.png';
import UserFriendly from '../../images/option_1/UserFriendly.png';
import AcceptableTechnology4 from '../../images/option_1/AcceptableTechnology4.png';
import Electronic_health_record4 from '../../images/option_1/Electronic_health_record4.png';
import EasyAccess2 from '../../images/option_1/EasyAccess2.png';
import rheumatologist_img_banner from '../../images/rheumatologist_img_banner.jpg';
import Appointment_im from '../../images/Appointment_im.jpg';
import Step2HistoryCardio from '../../images/SpecialistStep/Rheumatologist/Step2HistoryCardio.png';
import Step3HistoryCardioOutput from '../../images/SpecialistStep/Rheumatologist/Step3HistoryCardioOutput.png';
import Step4InvestigationOrder from '../../images/SpecialistStep/CARDIO/Step4InvestigationOrder.png';
import Step5ResultChkupFlow from '../../images/SpecialistStep/CARDIO/Step5ResultChkupFlow.png';
import Step6WriteInPrescription from '../../images/SpecialistStep/CARDIO/Step6WriteInPrescription.png';
import Step7DrugInterctionAlert from '../../images/SpecialistStep/CARDIO/Step7DrugInterctionAlert.png';
import Step8MimsDrugInformation from '../../images/SpecialistStep/CARDIO/Step8MimsDrugInformation.png';
import Step9PrescriptionOutput from '../../images/SpecialistStep/CARDIO/Step9PrescriptionOutput.png';

const Rheumatologist = () => {
    const rheumatolo = [
        { id: 1, label_name: 'Appointment', images: Appointment_im, },
        { id: 2, label_name: 'History & Examination', images: Step2HistoryCardio, },
        { id: 3, label_name: 'History & Examination Output', images: Step3HistoryCardioOutput, },
        { id: 4, label_name: 'Investigation Order', images: Step4InvestigationOrder, },
        { id: 5, label_name: 'Result Check & Follow-up', images: Step5ResultChkupFlow, },
        { id: 6, label_name: 'Write in Prescription', images: Step6WriteInPrescription, },
        { id: 7, label_name: 'Drug Interaction Alert', images: Step7DrugInterctionAlert, },
        { id: 8, label_name: 'MIMS Drug Information', images: Step8MimsDrugInformation, },
        { id: 9, label_name: 'Prescription Output', images: Step9PrescriptionOutput, },
    ];
    const [appointmentManagement, setAppointmentManagement] = useState([
        {
            title: "Rheumatology specific workflow", images: HealthWorkflow8, id: 1
        },
        {
            title: "Complete EHR for Rheumatology", images: Electronic_health_record4, id: 2
        },
        {
            title: "Paperless", images: Paperless3, id: 3
        },
        {
            title: "Easy configuration ", images: EasyConfiguration, id: 4
        },
        {
            title: "Data security ", images: DataSecurity1, id: 5
        },
        {
            title: "Client-server or cloud-based ", images: ClientServer1, id: 6
        },
        {
            title: "User friendly", images: UserFriendly, id: 7
        },
        {
            title: "Quickly access ", images: EasyAccess2, id: 8
        },
        {
            title: "Adaptable technology", images: AcceptableTechnology4, id: 9
        },

    ]);
    const [specialtySpecific, setSpecialtySpecific] = useState([
        {
            id: 1,
            title: "Time Saved",
            no_of_percentance: "90%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 2,
            title: "Increase in the Productivity",
            no_of_percentance: "25%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 3,
            title: "Smart Workflow",
            no_of_percentance: "93%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
    ])

    return (
        <>
            <div className="bg__body">
                <div className="specialities__banner">
                    <div className="specialities__banner__img">
                        <img src={rheumatologist_img_banner} alt="image" />
                    </div>
                    <div className="specialities__banner__text__h">
                        <h3 className="specialities__banner__title">Rheumatologist</h3>
                        <div className="specialities__banner__text">
                            <p className="specialities__banner__description">
                                Innovative Practice Management Software for Rheumatologist
                            </p>
                            <div className="text-center">
                                <Link to="/request-demo" type="text" className="btn__ehr">Request For a Demo</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container ">
                    <div className="row pt-5">
                        <div className="col-sm-12">
                            <div className="macro">
                                <p className="header__description__gp solution__macro">
                                    <strong className="inspiration__span">Rheumatology</strong>-specific EHR, Extensive musculoskeletal examinations and their findings in various plates to record. Allow you quickly understand your patient history, communicate better with patients and help staff get more done in less time.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3  margin__top">
                        <div className="col-md-5">
                            <div className="gp">
                                <p className="h__description pe-md-5">
                                    Extensive and intuitive <strong className="inspiration__span">Rheumatologist</strong> specific EHR and practice management solutions are tailored to your practice workflow to meet your multidisciplinary needs.
                                </p>
                                <p className="h__description pe-md-5">
                                    Streamlining communication and access to information, they are built to be used in mobile phones or via desktop or any platform. Design for black outs and electricity failure or natural calamities in any time and from anywhere.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="ehr__img1">
                                <img src={MusculoSkeletalExamination} className="img-fluid" alt="image" border="0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="margin__top">
                <div className="book__app__bg">
                    <div className="book__app">
                        <h5 className="book__app__text">MacroHealthPlus offers a fully integrated  solutions designed for Rheumatologist practices to streamline administration functions- built with custom features, reports and templates, clinical care, and revenue cycle management in one single place.</h5>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <div className="container">

                    <div className="row g-3">
                        <div className="col-md-12">
                            <div className="margin__top">
                                <h3 className="text-center mb-2">
                                    <span className="header__text">How <strong className="inspiration__span">Rheumatologist</strong> System Works?</span>
                                </h3>
                                <p className="header__description text-center">
                                    Our system is Secured, User friendly and Ease to use.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="show__big__device">
                                <HorizontalStepperSpecialist arr={rheumatolo} />
                            </div>
                            <div className="show__small__device">
                                <StepperSpecialistMobile arr={rheumatolo} />
                            </div>
                        </div>
                    </div>
                    <div className="row margin__top">
                        <div className="col-md-12">
                            <div className="key__feature">
                                <h3 className="text-center mb-2">
                                    <span className="header__text">Key Features for Rheumatology</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3 gy-4 gx-sm-5 pt-4 d-flex justify-content-center">
                        {
                            appointmentManagement.length > 0 && appointmentManagement.map((item, id) => {
                                return (
                                    <div key={id} className="col-md-3">
                                        <div className="general__practice__card">
                                            <div className="card text-center">
                                                <div className="d-flex justify-content-center pt-4">
                                                    <div className="bg__card__img">
                                                        <img src={item.images} className="card-img-top" alt="images" />
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <h6 className="header__gp__card__subtitle">{item.title}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="speciality__bg">
                <div className="speciality">
                    <h3 className="header__speciality__text">Specialty-Specific All-in-One Solution</h3>
                    <div className="container speciality__specific">
                        <div className="row g-3">
                            {
                                specialtySpecific.length > 0 && specialtySpecific.map((item, id) => {
                                    return (
                                        <div key={id} className="col-md-4">
                                            <div className="specialty__specific__card">
                                                <div className="card text-center">
                                                    <div className="card-body">
                                                        <h5 className="specialty__specific__card__title">{item.title}</h5>
                                                        <h3 className="specialty__specific__card__title__percent">{item.no_of_percentance}</h3>
                                                        {/* <p className="specialty__specific__card__discription">{item.description}</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <NewsLetter />
            </div>
        </>
    );
};

export default Rheumatologist;