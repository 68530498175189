import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import GP_img from '../../images//GP_img.png';
import cardiologist_img2 from '../../images/cardiologist_img2.png';
import gastroenterologist_img2 from '../../images/gastroenterologist_img2.png';
import chest_physician_img2 from '../../images/chest_physician_img2.png';
import Urologist__Img2 from '../../images/Urologist__Img2.png';
import ent_specialist_img2 from '../../images/ent_specialist_img2.png';
import ophthalmologist_img2 from '../../images/ophthalmologist_img2.png';
import Dermatologist_img2 from '../../images/Dermatologist_img2.png';
import psychiatrist_psychologist_img2 from '../../images/psychiatrist_psychologist_img2.png';
import rheumatologist_img2 from '../../images/rheumatologist_img2.png';
import neurologist_img2 from '../../images/neurologist_img2.png';
import orthopaedic_surgeon_img2 from '../../images/orthopaedic_surgeon_img2.png';
import paediatricians_img2 from '../../images/paediatricians_img2.png';
import neuro_surgeon_img2 from '../../images/neuro_surgeon_img2.png';
import obstetrics_gynaecologist_img2 from '../../images/obstetrics_gynaecologist_img2.png';
import general_surgeon_img2 from '../../images/general_surgeon_img2.png';
import coming__img from '../../images/coming__img.png';

import GeneralPractice from '../../images/specialist/GeneralPractice.png';
import Cardilogist from '../../images/specialist/Cardilogist.png';
import Gastroenterologist from '../../images/specialist/Gastroenterologist.png';
import ChestPhysician from '../../images/specialist/ChestPhysician.png';
import ENT from '../../images/specialist/ENT.png';
import Opthalmologist from '../../images/specialist/Opthalmologist.png';
import Dermatologist from '../../images/specialist/Dermatologist.png';
import PhyciatistOrPhycologist from '../../images/specialist/PhyciatistOrPhycologist.png';
import Rhumologist from '../../images/specialist/Rhumologist.png';
import Nuoroligst from '../../images/specialist/Nuoroligst.png';
import Authopedic from '../../images/specialist/Authopedic.png';
import Urologist from '../../images/specialist/Urologist.png';
import Dental from '../../images/specialist/Dental.png';
import GenaralSurgon from '../../images/specialist/GenaralSurgon.png';
import Gynolcologist from '../../images/specialist/Gynolcologist.png';
import NueroSurgon from '../../images/specialist/NueroSurgon.png';
import Peditrcian from '../../images/specialist/Peditrcian.png';



const EnrichMacrohPlausSoft = () => {
    const [enrichClinicalExperience, setEnrichClinicalExperience] = useState([
        {
            features_name: "General Practice",
            card_link: "general-practice",
            hover_tech_img: GeneralPractice,
            technologies_img: GP_img, id: 1
        },
        {
            features_name: "Cardiologist",
            card_link: "cardiologist",
            hover_tech_img: Cardilogist,
            technologies_img: cardiologist_img2, id: 2
        },
        {
            features_name: "Gastroenterologist",
            card_link: "gastroenterologist",
            hover_tech_img: ChestPhysician,
            technologies_img: chest_physician_img2, id: 3
        },
        {
            features_name: "Chest Physician",
            card_link: "chest-physician",
            hover_tech_img: Gastroenterologist,
            technologies_img: gastroenterologist_img2, id: 4
        },
        {
            features_name: "Urologist",
            card_link: "urologist",
            hover_tech_img: Urologist,
            technologies_img: Urologist__Img2, id: 5
        },
        {
            features_name: "ENT Specialist",
            card_link: "ent-specialist",
            hover_tech_img: ENT,
            technologies_img: ent_specialist_img2, id: 6
        },
        {
            features_name: "Ophthalmologist ",
            card_link: "ophthalmologist",
            hover_tech_img: Opthalmologist,
            technologies_img: ophthalmologist_img2, id: 7
        },
        {
            features_name: "Dermatologist",
            card_link: "dermatologist",
            hover_tech_img: Dermatologist,
            technologies_img: Dermatologist_img2, id: 8
        },
        {
            features_name: "Psychiatrist and Psychologist",
            card_link: "psychiatrist-and-psychologist",
            hover_tech_img: PhyciatistOrPhycologist,
            technologies_img: psychiatrist_psychologist_img2, id: 9
        },
        {
            features_name: "Rheumatologist",
            card_link: "rheumatologist",
            hover_tech_img: Rhumologist,
            technologies_img: rheumatologist_img2, id: 10
        },
        {
            features_name: "Neurologist",
            card_link: "neurologist",
            hover_tech_img: Nuoroligst,
            technologies_img: neurologist_img2, id: 11
        },
        {
            features_name: "Orthopaedic Surgeon",
            card_link: "orthopaedic-surgeon",
            hover_tech_img: Authopedic,
            technologies_img: orthopaedic_surgeon_img2, id: 12
        },
        {
            features_name: "Neuro-surgeon",
            card_link: "neuro-surgeon",
            hover_tech_img: NueroSurgon,
            technologies_img: neuro_surgeon_img2, id: 13
        },
        {
            features_name: "Obstetrics and Gynaecologist",
            card_link: "obstetrics-and-gynaecologist",
            hover_tech_img: Gynolcologist,
            technologies_img: obstetrics_gynaecologist_img2, id: 14
        },
        {
            features_name: "Paediatricians",
            card_link: "paediatricians",
            hover_tech_img: Peditrcian,
            technologies_img: paediatricians_img2, id: 15
        },
        {
            features_name: "General Surgeon",
            card_link: "general-surgeon",
            hover_tech_img: GenaralSurgon,
            technologies_img: general_surgeon_img2, id: 16
        },
    ]);


    return (
        <>
            <div className="row pt-4">
                <div className="col-md-12">
                    <h3 className="text-center mb-3">
                        <span className="header__text">Enrich the Clinical Experience with  <strong className="inspiration__span">MacroHealthPlus</strong> Software</span>
                    </h3>
                </div>
            </div>
            <div className="row g-3 pt-2 d-flex justify-content-center">
                {
                    enrichClinicalExperience.length > 0 && enrichClinicalExperience.map((item, id) => {
                        return (
                            <div key={id} className="col-md-2">
                                <Link to={`/${item.card_link}`} style={{ textDecoration: "none" }}>
                                    <div className="enrich__card">
                                        <div className="card text-center">
                                            {/* <div className="enrich__card__img  pt-4">
                                                <img src={item.technologies_img} className="card-img-top" alt="images" />
                                            </div> */}
                                            <div className="card__img">
                                                <img src={item.technologies_img} alt="Avatar" className="image" />
                                                <div className="overlay__img">
                                                    <img src={item.hover_tech_img} alt="Avatar" className="image" />
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <h6 className="features__ehr__card__title">{item.features_name}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                    })
                }

            </div>
            {/* <div className="row g-3 pt-2 d-flex justify-content-center">
                {
                    enrichClinicalExperience.length > 0 && enrichClinicalExperience.map((item, id) => {
                        return (
                            <div key={id} className="col-md-2">
                                <Link to={`/${item.card_link}`} style={{ textDecoration: "none" }}>
                                    <div className="enrich__card">
                                        <div className="card text-center">
                                            <div className="enrich__card__img  pt-4">
                                                <img src={item.technologies_img} className="card-img-top" alt="images" />
                                            </div>
                                            <div class="card__img">
                                                <img src={item.technologies_img} alt="Avatar" class="image" />
                                                <div class="overlay__img">
                                                    <img src={item.hover_tech_img} alt="Avatar" class="image" />
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <h6 className="features__ehr__card__title">{item.features_name}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                    })
                }

            </div> */}

        </>
    );
};

export default EnrichMacrohPlausSoft;