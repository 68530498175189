import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import price_cost_ques_icon from "../../images/price_cost_ques_icon.png";
import check_sign from "../../images/check_sign.png";
import { Button, Tooltip } from "@mui/material";
import axios from "axios";
import useResizeObserver from "../../hooks/useResizeObserver";
const pricingData = [
  {
    id: 1,
    tabTitle: "GreatPharma",
    tabLink: "great-pharma",
    items: [
      {
        id: 1,
        planTitle: "General",
        cost: "BDT 10,000",
        isEveryThing: false,
        pharmacyType: "Single Pharmacy",
        userType: "1 Admin, 2 Sales person",
        monthlyServiceCharge: "BDT 1,000/Monthly",
        billingYear: "Billing on Quarterly/ Yearly",
        serviceTitle: "Get Started Now",
        buttonText: "Get Started",
        redirectLink: "https://app.macrohealthplus.org/register",
        services: [
          "Fast Invoicing",
          "Stock Management",
          "Smooth Returns",
          "Purchase Management",
          "Vendor Management",
          `4 Ways real time Communication
          (SMS*, Email, WhatsApp & Notification)
          `,
          `Patient Feedback Collection`,
          `Bar Code Scan`,
          `Rack Management`,
          `Advanced integrated report- MIS`,
          `Web and Mobile App Access`,
        ],
      },
      {
        id: 2,
        planTitle: "Standard",
        cost: "BDT 20,000",
        isEveryThing: false,
        pharmacyType: "Single Pharmacy",
        userType: "1 Admin, Multiple Sales person",
        monthlyServiceCharge: "BDT 1,500/Monthly",
        billingYear: "Billing on Quarterly/ Yearly",
        serviceTitle: "Grow your Practice",
        buttonText: "Get Started",
        redirectLink: "https://app.macrohealthplus.org/register",
        services: [
          "Fast Invoicing",
          "Stock Management",
          "Smooth Returns",
          "Purchase Management",
          "Vendor Management",
          `4 Ways real time Communication
          (SMS*, Email, WhatsApp & Notification)
          `,
          `Patient Feedback Collection`,
          `Bar Code Scan`,
          `Rack Management`,
          `Advanced integrated report- MIS`,
          `Web and Mobile App Access`,
          `SMS Notification*`,
        ],
      },
      {
        id: 3,
        planTitle: "Premium",
        isEveryThing: true,
        everyThing: `Let’s talk
        Tailored to fit your business
        `,
        // cost: "BDT 20,000",
        // pharmacyType: "Single Pharmacy",
        // userType: "1 Admin, Multiple Sales person",
        // monthlyServiceCharge: "BDT 1,500/Monthly",
        // billingYear: "Billing on Quarterly/ Yearly",
        serviceTitle: "Deliver at Scale",
        buttonText: "Get Started",
        redirectLink: "https://app.macrohealthplus.org/register",
        services: ["Everything in Specialist, please"],
      },
    ],
  },
  {
    id: 2,
    tabTitle: "GreatDoc",
    tabLink: "great-doc",
    items: [
      // {
      //   id: 1,
      //   planTitle: "General",
      //   cost: "BDT 1,000/Monthly",
      //   isEveryThing: false,
      //   // pharmacyType: "Single Pharmacy",
      //   userType: "Single User, 200 Appointments",
      //   // monthlyServiceCharge: "BDT 1,000/Monthly",
      //   billingYear: "Billing on  Yearly",
      //   serviceTitle: "Get Started Now",
      //   buttonText: "Get Started",
      //   redirectLink: "https://gd-app.macrohealthplus.org/register",
      //   services: [
      //     "Online Booking",
      //     "Patient Records",
      //     "Consultation from templates",
      //     "Referral letters & Prescriptions",
      //     `4 Ways real time Communication
      //     (SMS, Email, WhatsApp & Notification)
      //     `,
      //     "Reminders, Greetings, Offers",
      //     "Web and Mobile App Access",
      //     "Tele-consulting",
      //   ],
      // },
      {
        id: 2,
        planTitle: "Standard",
        cost: "BDT 1,000/Monthly",
        isEveryThing: false,
        // pharmacyType: "Single Pharmacy",
        userType: "Single User, Unlimited Appointments",
        // monthlyServiceCharge: "BDT 1,500/Monthly",
        billingYear: "Billing on Yearly",
        serviceTitle: "Grow your Practice",
        buttonText: "Get Started",
        redirectLink: "https://gd-app.macrohealthplus.org/register",
        services: [
          "Online Booking",
          "Patient Records",
          "Consultation from templates",
          "Referral letters & Prescriptions",
          `4 Ways real time Communication
          (SMS, Email, WhatsApp & Notification)
          `,
          "Reminders, Greetings, Offers",
          "Web and Mobile App Access",
          "Tele-consulting",
          "Criteria based Bulk SMS sending*",
          "Appointment notification by SMS*",
        ],
      },
      {
        id: 3,
        planTitle: "Premium",
        isEveryThing: true,
        everyThing: `Let’s talk
        Tailored to fit your business
        `,
        // cost: "BDT 20,000",
        // pharmacyType: "Single Pharmacy",
        // userType: "1 Admin, Multiple Sales person",
        // monthlyServiceCharge: "BDT 1,500/Monthly",
        // billingYear: "Billing on Quarterly/ Yearly",
        serviceTitle: "Deliver at Scale",
        buttonText: "Get Started",
        redirectLink: "https://gd-app.macrohealthplus.org/register",
        services: ["Everything in Specialist, please"],
      },
    ],
  },
  {
    id: 3,
    tabTitle: "GreatClinic",
    tabLink: "great-lab",
    items: [
      {
        id: 2,
        planTitle: "Standard",
        cost: "BDT 5,000/Monthly",
        isEveryThing: false,
        // pharmacyType: "Single Pharmacy",
        // userType: "Single User, Unlimited Appointments",
        // monthlyServiceCharge: "BDT 1,500/Monthly",
        billingYear: "Billing on Yearly",
        serviceTitle: "For newly open diagnostic Lab with all facilities",
        buttonText: "Get Started",
        redirectLink: "https://gd-app.macrohealthplus.org/register",
        services: [
          `Patient Registration`,
          `Billing Management`,
          `Unlimited Users`,
          `Limited Invoice`,
          `QR Code System`,
          `5 User/Collectors`,
          `5 Franchise`,
          `Advanced Integrated Report- MIS`,
          `Web and Mobile App Access`,
          `4 Ways real time Communication
          (SMS, Email, WhatsApp & Notification)
          `,
        ],
      },
      {
        id: 3,
        planTitle: "Premium",
        isEveryThing: true,
        everyThing: `Let’s talk
        Tailored to fit your business
        `,
        // cost: "BDT 20,000",
        // pharmacyType: "Single Pharmacy",
        // userType: "1 Admin, Multiple Sales person",
        // monthlyServiceCharge: "BDT 1,500/Monthly",
        // billingYear: "Billing on Quarterly/ Yearly",
        serviceTitle:
          "For growing labs that need more services and flexibility",
        buttonText: "Get Started",
        redirectLink: "https://macrohealthplus.org/request-demo",
        services: [
          `Patient Registration`,
          `Billing Management`,
          `Unlimited Users`,
          `Unlimited Invoice`,
          `QR Code System`,
          `Unlimited User/Collectors`,
          `Unlimited Franchise`,
          `QR Coded Lab Report`,
          `Advanced Integrated Report- MIS`,
          `Web and Mobile App Access`,
          `4 Ways real time Communication
          (SMS, Email, WhatsApp & Notification)
          `,
          `SMS Notification *`,
          `Machine Integrations`,
          `Data Exports`,
        ],
      },
    ],
  },
];

const Pricing = () => {
  // const [pricingAllCostGreatDoc, setPricingAllCostGreatDoc] = useState([]);

  // const [pricingAllCostDigiPatient, setPricingAllCostDigiPatient] = useState([
  //   {
  //     id: 1,
  //     title: "Free",
  //     currency: "BDT",
  //     price: "0 / 1 Days",
  //     subtitle: "Per user, per month",
  //     card_link: "digi-patient",
  //     tooltips_title: "Starting at Free which includes your first 1 assets.",
  //     details: [
  //       {
  //         id: 1,
  //         desc_list: " Access anytime and anywhere",
  //       },
  //       {
  //         id: 2,
  //         desc_list: "Manage personal health records",
  //       },
  //       {
  //         id: 3,
  //         desc_list: "Upload documents and images",
  //       },
  //       {
  //         id: 4,
  //         desc_list: "Search Doctors",
  //       },
  //       {
  //         id: 5,
  //         desc_list: "Easy appointment booking",
  //       },
  //       {
  //         id: 6,
  //         desc_list: "Chamber consultation",
  //       },
  //       {
  //         id: 7,
  //         desc_list: "Access telemedicine services",
  //       },
  //       {
  //         id: 8,
  //         desc_list: "Receive e-prescriptions",
  //       },
  //       {
  //         id: 9,
  //         desc_list: "Make payments",
  //       },
  //       {
  //         id: 10,
  //         desc_list: "Receive reminders and Notification",
  //       },
  //       {
  //         id: 11,
  //         desc_list: "Different learning materials",
  //       },
  //     ],
  //   },
  // ]);

  // const [pricingAllCostGreatLab, setPricingAllCostGreatLab] = useState([
  //   {
  //     id: 1,
  //     title: "Standard",
  //     currency: "BDT",
  //     price: "50,000 / One item",
  //     subtitle: "Standard Lab module",
  //     card_link: "great-lab",
  //     tooltips_title:
  //       "Starting at BDT 6,000 / Year which includes your first 1 assets.",
  //     btn_name: "Buy Now",
  //     details: [
  //       {
  //         id: 1,
  //         desc_list: "Front Office",
  //         images: check_sign,
  //       },
  //       {
  //         id: 2,
  //         desc_list: "Billing System",
  //         images: check_sign,
  //       },
  //       {
  //         id: 3,
  //         desc_list: "Messaging -SMS and Email",
  //         images: check_sign,
  //       },
  //       {
  //         id: 4,
  //         desc_list: "Link with Doctor and Hospital",
  //         images: check_sign,
  //       },
  //       {
  //         id: 5,
  //         desc_list: "Diagnostic Reporting with online facilities",
  //         images: check_sign,
  //       },
  //       {
  //         id: 6,
  //         desc_list: "Barcoded sample collects",
  //         images: check_sign,
  //       },
  //       {
  //         id: 7,
  //         desc_list: "QR Coded Lab Reports",
  //         images: check_sign,
  //       },
  //       {
  //         id: 8,
  //         desc_list: "Reagent & X-ray Film Management",
  //         images: check_sign,
  //       },
  //       {
  //         id: 9,
  //         desc_list: "Multi Language Facility",
  //         images: check_sign,
  //       },
  //       {
  //         id: 10,
  //         desc_list: "Management Dashboard",
  //         images: check_sign,
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     title: "Business",
  //     currency: "",
  //     price: "",
  //     subtitle:
  //       "Business Advance Lab Module (Integration with different Lab equipment)",
  //     card_link: "great-lab",
  //     message_title: "Let's talk!",
  //     message_des: "Tailored to fit your business",
  //     tooltips_title: "Contact us for more details",
  //     btn_name: "Contact Us",
  //     details: [
  //       {
  //         id: 1,
  //       },
  //     ],
  //   },
  // ]);

  // const [pricingAllCostGreatPharma, setPricingAllCostGreatPharma] = useState([
  //   {
  //     id: 1,
  //     title: "Single Standard",
  //     currency: "BDT",
  //     price: "10,000",
  //     subtitle: "Single Standard 1-2 User",
  //     card_link: "great-pharma",
  //     tooltips_title:
  //       "Starting at BDT 10,000 which includes your first 1 assets.",
  //     btn_name: "Buy Now",
  //     details: [
  //       {
  //         id: 1,
  //         desc_list: "Search and Buy",
  //         images: check_sign,
  //       },
  //       {
  //         id: 2,
  //         desc_list: "Upload Prescriptions",
  //         images: check_sign,
  //       },
  //       {
  //         id: 3,
  //         desc_list: "Online Payment",
  //         images: check_sign,
  //       },
  //       {
  //         id: 4,
  //         desc_list: "Medicine Reminder",
  //         images: check_sign,
  //       },
  //       {
  //         id: 5,
  //         desc_list: "Different Offers",
  //         images: check_sign,
  //       },
  //       {
  //         id: 6,
  //         desc_list: "Stock Management",
  //         images: check_sign,
  //       },
  //       {
  //         id: 7,
  //         desc_list: "Expiry Management",
  //         images: check_sign,
  //       },
  //       {
  //         id: 8,
  //         desc_list: "Rack Management",
  //         images: check_sign,
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     title: "Standard",
  //     currency: "BDT",
  //     price: "15,000",
  //     subtitle: "Standard 3 User",
  //     card_link: "great-pharma",
  //     tooltips_title:
  //       "Starting at BDT 15,000 which includes your first 3 assets.",
  //     btn_name: "Buy Now",
  //     details: [
  //       {
  //         id: 1,
  //         desc_list: "Search and Buy",
  //         images: check_sign,
  //       },
  //       {
  //         id: 2,
  //         desc_list: "Upload Prescriptions",
  //         images: check_sign,
  //       },
  //       {
  //         id: 3,
  //         desc_list: "Online Payment",
  //         images: check_sign,
  //       },
  //       {
  //         id: 4,
  //         desc_list: "Medicine Reminder",
  //         images: check_sign,
  //       },
  //       {
  //         id: 5,
  //         desc_list: "Different Offers",
  //         images: check_sign,
  //       },
  //       {
  //         id: 6,
  //         desc_list: "Stock Management",
  //         images: check_sign,
  //       },
  //       {
  //         id: 7,
  //         desc_list: "Expiry Management",
  //         images: check_sign,
  //       },
  //       {
  //         id: 8,
  //         desc_list: "Rack Management",
  //         images: check_sign,
  //       },
  //     ],
  //   },
  //   {
  //     id: 3,
  //     title: "Premium",
  //     currency: "BDT",
  //     price: "30,000",
  //     subtitle: "Premium Max 5 User",
  //     card_link: "great-pharma",
  //     tooltips_title:
  //       "Starting at BDT 30,000 which includes your first 5 assets.",
  //     btn_name: "Buy Now",
  //     details: [
  //       {
  //         id: 1,
  //         desc_list: "Search and Buy",
  //         images: check_sign,
  //       },
  //       {
  //         id: 2,
  //         desc_list: "Upload Prescriptions",
  //         images: check_sign,
  //       },
  //       {
  //         id: 3,
  //         desc_list: "Online Payment",
  //         images: check_sign,
  //       },
  //       {
  //         id: 4,
  //         desc_list: "Medicine Reminder",
  //         images: check_sign,
  //       },
  //       {
  //         id: 5,
  //         desc_list: "Different Offers",
  //         images: check_sign,
  //       },
  //       {
  //         id: 6,
  //         desc_list: "Stock Management",
  //         images: check_sign,
  //       },
  //       {
  //         id: 7,
  //         desc_list: "Expiry Management",
  //         images: check_sign,
  //       },
  //       {
  //         id: 8,
  //         desc_list: "Rack Management",
  //         images: check_sign,
  //       },
  //     ],
  //   },
  //   {
  //     id: 4,
  //     title: "Business",
  //     currency: "",
  //     price: "",
  //     subtitle: "",
  //     card_link: "great-pharma",
  //     message_title: "Let's talk!",
  //     message_des: "Tailored to fit your business",
  //     tooltips_title: "Contact us for more details",
  //     btn_name: "Contact Us",
  //     details: [
  //       {
  //         id: 1,
  //       },
  //     ],
  //   },
  // ]);

  // const [pricingAllCostEPharmacy, setPricingAllCostEPharmacy] = useState([
  //   {
  //     id: 1,
  //     title: "Standard ",
  //     currency: "BDT",
  //     price: "30,000 / One time cost",
  //     subtitle:
  //       "One time cost maintenance and service charge 20% of total cost Domain charge will be paid separately",
  //     card_link: "e-commerce-medicine",
  //     tooltips_title:
  //       "One time cost maintenance and service charge 20% of total cost Domain charge will be paid separately",
  //     details: [
  //       {
  //         id: 1,
  //         desc_list: "You will get from MPH ",
  //         images: check_sign,
  //       },
  //       {
  //         id: 2,
  //         desc_list: "30,000 medicines with images",
  //         images: check_sign,
  //       },
  //       {
  //         id: 3,
  //         desc_list: "Authorized doctor checking",
  //         images: check_sign,
  //       },
  //     ],
  //   },
  // ]);

  // const [pricingAllCostMarketPlace, setPricingAllCostMarketPlace] = useState([
  //   {
  //     id: 1,
  //     title: "Pharmacy ",
  //     currency: "BDT",
  //     price: "3,000 / One time cost",
  //     subtitle: "One time cost and 1% of total sale ",
  //     card_link: "marketplace",
  //     tooltips_title: "One time cost and 1% of total sale ",
  //     btn_name: "Registration Now",
  //     details: [
  //       {
  //         id: 1,
  //         desc_list: "You will get from MPH ",
  //         images: check_sign,
  //       },
  //       {
  //         id: 2,
  //         desc_list: "30,000 medicines with images",
  //         images: check_sign,
  //       },
  //       {
  //         id: 3,
  //         desc_list: "Authorized doctor checking",
  //         images: check_sign,
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     title: "Allied Health Staff ",
  //     currency: "BDT",
  //     price: "100 /user registration",
  //     subtitle: "No charge for any job processing ",
  //     tooltips_title: "No charge for any job processing ",
  //     card_link: "marketplace",
  //     btn_name: "Registration Now",
  //     details: [
  //       {
  //         id: 1,
  //         desc_list: "You will get from MPH ",
  //         images: check_sign,
  //       },
  //       {
  //         id: 2,
  //         desc_list: "30,000 medicines with images",
  //         images: check_sign,
  //       },
  //       {
  //         id: 3,
  //         desc_list: "Authorized doctor checking",
  //         images: check_sign,
  //       },
  //     ],
  //   },
  // ]);

  // const [pricingAllCostLabAgent, setPricingAllCostLabAgent] = useState([
  //   {
  //     id: 1,
  //     title: "Freelancer ",
  //     currency: "BDT",
  //     price: "1,000/ user registration",
  //     subtitle: "No charge for any job processing",
  //     card_link: "lab-agent",
  //     tooltips_title: "No charge for any job processing",
  //     btn_name: "Registration Now",
  //     details: [
  //       {
  //         id: 1,
  //         desc_list: "You will get from MPH ",
  //         images: check_sign,
  //       },
  //       {
  //         id: 2,
  //         desc_list: "30,000 medicines with images",
  //         images: check_sign,
  //       },
  //       {
  //         id: 3,
  //         desc_list: "Authorized doctor checking",
  //         images: check_sign,
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     title: "Laboratory",
  //     currency: "",
  //     price: "",
  //     subtitle: "",
  //     card_link: "lab-agent",
  //     message_title: "Let's talk!",
  //     message_des: "Tailored to fit your business",
  //     tooltips_title: "Contact us for more details",
  //     btn_name: "Registration Now",
  //     details: [
  //       {
  //         id: 1,
  //       },
  //     ],
  //   },
  // ]);

  // const [pricingAllCosTelehealth, setPricingAllCosTelehealth] = useState([
  //   {
  //     id: 1,
  //     title: "Free",
  //     currency: "",
  //     price: "",
  //     subtitle: "",
  //     card_link: "telehealth",
  //     tooltips_title: "Starting at Free which includes your first 1 assets.",
  //     details: [
  //       {
  //         id: 1,
  //         desc_list: "Online e-consultation",
  //       },
  //       {
  //         id: 2,
  //         desc_list: "2 Way video consultation",
  //       },
  //       {
  //         id: 3,
  //         desc_list: "Integrated with Digital Health Record",
  //       },
  //       {
  //         id: 4,
  //         desc_list: "Integrated with Doctor’s prescription",
  //       },
  //       {
  //         id: 5,
  //         desc_list: "Payment Gateway integration",
  //       },
  //       {
  //         id: 6,
  //         desc_list: "No charge for any video consulting",
  //       },
  //     ],
  //   },
  // ]);

  // const [pricingAllCostAll, setPricingAllCostAll] = useState([
  //   {
  //     id: 1,
  //     title: "Standard ",
  //     currency: "BDT",
  //     price: "300,000 / Per Year",
  //     subtitle: "Standard (25 User for all products)",
  //     card_link: "/",
  //     tooltips_title: "Standard (25 User for all products)",
  //     btn_name: "Buy Now",
  //     details: [
  //       {
  //         id: 1,
  //         desc_list: "You will get from MPH ",
  //         images: check_sign,
  //       },
  //       {
  //         id: 2,
  //         desc_list: "30,000 medicines with images",
  //         images: check_sign,
  //       },
  //       {
  //         id: 3,
  //         desc_list: "Authorized doctor checking",
  //         images: check_sign,
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     title: "Business",
  //     currency: "",
  //     price: "",
  //     subtitle: "(Based on the customer requirement)",
  //     card_link: "/",
  //     message_title: "Let's talk!",
  //     message_des: "Tailored to fit your business",
  //     tooltips_title: "Contact us for more details",
  //     btn_name: "Contact Us",
  //     details: [
  //       {
  //         id: 1,
  //       },
  //     ],
  //   },
  // ]);

  // const [pricingAllCostGreatLabNote, setPricingAllCostGreatLabNote] = useState([
  //   {
  //     id: 1,
  //     meassage:
  //       "Support and maintenance charged will be paid separately 0% of cost price yearly basis.",
  //   },
  //   {
  //     id: 2,
  //     meassage:
  //       "Customer can be stored data in their own database or can be stored in google cloud.",
  //   },
  // ]);
  // const [pricingAllCostGreatPharmaNote, setPricingAllCostGreatPharmaNote] =
  //   useState([
  //     {
  //       id: 1,
  //       meassage: "Initial stock will be prepared by MHP free of cost.",
  //     },
  //     {
  //       id: 2,
  //       meassage:
  //         "Other than stock data migration will be charged upon discussion with the customer.",
  //     },
  //     {
  //       id: 3,
  //       meassage:
  //         "Support and maintenance charged will be paid separately 10% of cost price monthly basis.",
  //     },
  //     {
  //       id: 4,
  //       meassage:
  //         "Customer can be stored data in their own database or can be stored in google cloud based on the separate domain change.",
  //     },
  //   ]);

  // const [pricingAllCostAllNote, setPricingAllCostAllNote] = useState([
  //   {
  //     id: 1,
  //     meassage:
  //       "Support and maintenance charged will be paid separately 0% of cost price yearly basis.",
  //   },
  //   {
  //     id: 2,
  //     meassage:
  //       "Customer can be stored data in their own database or can be stored in google cloud.",
  //   },
  // ]);

  // const [pricingAllGreatPharma, setPricingAllGreatPharma] = useState([]);
  // const [pricingAllMarketPlace, setPricingAllMarketPlace] = useState([]);
  // const [pricingAllLabAgent, setPricingAllLabAgent] = useState([]);
  // //  for GreatDoc
  // useEffect(() => {
  //   axios
  //     .get("https://gdsaasbackend.macrohealthplus.org/api/v1/subscription-plan")
  //     .then(function (response) {
  //       const data = response?.data?.data?.subscription_plans;
  //       const firstData = data[0];
  //       firstData.name = "General";
  //       const secondData = data[1];
  //       secondData.name = "Specialist";
  //       const thirdData = data[3];
  //       thirdData.name = "Premium";
  //       const modifiedData = [firstData, secondData, thirdData];
  //       setPricingAllCostGreatDoc(modifiedData);
  //       // setPricingAllCostGreatDocNote(firstData?.plan_details);
  //       console.log("zabir", response?.data?.data?.subscription_plans);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, []);
  // //   for GreatPharma
  // useEffect(() => {
  //   axios
  //     .get("https://gdsaasbackend.macrohealthplus.org/api/v1/subscription-plan")
  //     .then(function (response) {
  //       const data = response?.data?.data?.subscription_plans;
  //       const firstData = data[0];
  //       firstData.name = "Single Standard";
  //       const secondData = data[1];
  //       secondData.name = "Standard";
  //       const thirdData = data[3];
  //       thirdData.name = "Premium";
  //       const modifiedData = [firstData, secondData, thirdData];
  //       setPricingAllGreatPharma(modifiedData);
  //       console.log("zabir", response?.data?.data?.subscription_plans);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, []);

  // //   for Marketplace
  // useEffect(() => {
  //   axios
  //     .get("https://gdsaasbackend.macrohealthplus.org/api/v1/subscription-plan")
  //     .then(function (response) {
  //       const data = response?.data?.data?.subscription_plans;
  //       const firstData = data[0];
  //       firstData.name = "Pharmacy";
  //       const secondData = data[1];
  //       secondData.name = "Allied Health";
  //       const modifiedData = [firstData, secondData];
  //       setPricingAllMarketPlace(modifiedData);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, []);

  // //   for LabAgent
  // useEffect(() => {
  //   axios
  //     .get("https://gdsaasbackend.macrohealthplus.org/api/v1/subscription-plan")
  //     .then(function (response) {
  //       const data = response?.data?.data?.subscription_plans;
  //       const firstData = data[0];
  //       firstData.name = "Freelancer";
  //       const secondData = data[1];
  //       secondData.name = "Laboratory";
  //       const modifiedData = [firstData, secondData];
  //       setPricingAllLabAgent(modifiedData);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, []);

  const [pricingAllCost, setPricingAllCost] = useState([...pricingData]);

  const { width } = useResizeObserver();

  // const [col, setCol] = useState("col-6");

  // useEffect(() => {
  //   switch (width) {
  //     case width < 678:
  //       setCol("col-12");
  //       break;
  //     case width < 900 && width > 678:
  //       setCol("col-6");
  //       break;
  //     default:
  //       setCol("col-md-4");
  //   }
  // }, [width]);
  // const col = width < 576 ? 'col-12' : 'col-md-2';

  return (
    <>
      <div className="container mb-4 pt-4">
        <h3 className="text-center mb-3">
          <strong className="inspiration__span">
            Choose the perfect plan for you
          </strong>
        </h3>
        <h5 className="pricing__all__txt">Pricing (All cost is in BDT) :</h5>

        <div className="row">
          <div className="col-12">
            <div className="pricing__nav">
              <ul
                className="nav nav-pills mb-3 nav-fill nav__bg"
                id="pills-tab"
                role="tablist"
              >
                {pricingAllCost.map((item, i) => {
                  return (
                    <li
                      className={item.id === 1 ? "nav-item active" : "nav-item"}
                      key={i}
                      style={{
                        marginBottom: "0 !important",
                      }}
                      role="presentation"
                    >
                      <button
                        className={`nav-link pricing__link text-nowrap ${
                          item.id === 1 ? "active" : ""
                        }`}
                        id={`pills-${item?.tabTitle}-tab`}
                        data-bs-toggle="pill"
                        data-bs-target={`#pills-${item?.tabTitle}`}
                        type="button"
                        role="tab"
                        aria-controls={`pills-${item?.tabTitle}`}
                        aria-selected={`${item?.id === 1}` ? "true" : "false"}
                      >
                        {item?.tabTitle}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade active show"
                id={`pills-${pricingAllCost[0]?.tabTitle}`}
                role="tabpanel"
                aria-labelledby={`pills-${pricingAllCost[0]?.tabTitle}-tab`}
              >
                <div className="row g-3 pt-3 d-flex justify-content-center">
                  {pricingAllCost[0].items.length > 0 &&
                    pricingAllCost[0].items.map((item, id) => {
                      return (
                        <div key={id} className={`col-md-3`}>
                          <a
                            href={`${item?.redirectLink}`}
                            target="_link"
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              style={{
                                border: "1px solid yellowGreen",
                                borderRadius: "10px",
                                padding: "10px",
                                minHeight: "570px",
                                position: "relative",
                              }}
                            >
                              <div className="pt-4">
                                <h4 className="hpricing__card__title mb-1">
                                  {item?.planTitle}
                                </h4>
                                <div className="gdpricing d-flex justify-content-center pt-3">
                                  {item?.cost ? (
                                    <h4 className="hpricing__card__title mb-2">
                                      {item?.cost}
                                    </h4>
                                  ) : null}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "3px",
                                  }}
                                >
                                  {!item?.isEveryThing ? (
                                    <>
                                      <h4
                                        style={{
                                          color: "#565050",
                                          fontSize: "14px",
                                          padding: "0px",
                                          margin: "1px 0",
                                        }}
                                      >
                                        Pharmacy: {item?.pharmacyType}
                                      </h4>
                                      <h4
                                        style={{
                                          color: "#565050",
                                          fontSize: "14px",
                                          padding: "0px",
                                          margin: "1px 0",
                                        }}
                                      >
                                        User: {item?.userType}
                                      </h4>
                                      <h4
                                        style={{
                                          color: "#565050",
                                          fontSize: "14px",
                                          padding: "0px",
                                          margin: "1px 0",
                                        }}
                                      >
                                        Service Charge:
                                        {item?.monthlyServiceCharge}
                                      </h4>
                                      <h4
                                        style={{
                                          textAlign: "center",
                                          color: "green",
                                          fontSize: "14px",
                                          fontWeight: "500",
                                          padding: "0px",
                                          margin: "3px 0",
                                        }}
                                      >
                                        {item?.billingYear}
                                      </h4>
                                    </>
                                  ) : null}

                                  {item?.isEveryThing && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "30px 0",
                                      }}
                                    >
                                      <h4
                                        style={{
                                          color: "#565050",
                                          textAlign: "center",
                                          fontSize: "14px",
                                          padding: "0px",
                                          margin: "0",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {item?.everyThing}
                                      </h4>
                                    </div>
                                  )}

                                  <a
                                    href={`${item?.redirectLink}`}
                                    target="_blank"
                                    style={{
                                      color: "yellowGreen",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {item?.serviceTitle}
                                  </a>
                                </div>
                                {/* <h6 className="gdpricing__card__subtitle">
                              </h6> */}

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  className="card-body mp__card__body"
                                >
                                  <div className="gdpricing__body">
                                    <ul className="list-unstyled pt-3">
                                      {item?.isEveryThing &&
                                        item?.services?.map(
                                          (serv, serIndex) => {
                                            return (
                                              <li
                                                key={serIndex}
                                                className="d-flex align-items-start m-0 p-0 "
                                              >
                                                <img
                                                  src={check_sign}
                                                  className=" img-fluid pt-1"
                                                  style={{
                                                    // width: "18",
                                                    height: "15px",
                                                  }}
                                                  alt=""
                                                  border="0"
                                                />
                                                <p
                                                  className="ms-2"
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {serv}
                                                </p>
                                              </li>
                                            );
                                          }
                                        )}
                                      {!item?.isEveryThing &&
                                        item?.services?.map(
                                          (serv, serIndex) => {
                                            return (
                                              <li
                                                key={serIndex}
                                                className="d-flex align-items-start m-0 p-0 "
                                              >
                                                <img
                                                  src={check_sign}
                                                  className=" img-fluid pt-1"
                                                  style={{
                                                    // width: "18",
                                                    height: "15px",
                                                  }}
                                                  alt=""
                                                  border="0"
                                                />
                                                <p
                                                  className="ms-2"
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {serv}
                                                </p>
                                              </li>
                                            );
                                          }
                                        )}
                                    </ul>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      position: "absolute",
                                      bottom: "7px",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                    }}
                                    className="text-center pt-2"
                                  >
                                    {id ===
                                    pricingAllCost[0].items.length - 1 ? (
                                      <Link
                                        to={"/request-demo"}
                                        className="btn__pricing"
                                      >
                                        Contact Us
                                      </Link>
                                    ) : (
                                      <a
                                        href={`${item.redirectLink}`}
                                        target="_blank"
                                        className="btn__pricing"
                                      >
                                        Buy Now
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id={`pills-${pricingAllCost[1].tabTitle}`}
                role="tabpanel"
                aria-labelledby={`pills-${pricingAllCost[1].tabTitle}-tab`}
              >
                <div className="row g-3 pt-3 d-flex justify-content-center">
                  {pricingAllCost[1].items.length > 0 &&
                    pricingAllCost[1].items.map((item, id) => {
                      return (
                        <div key={id} className="col-md-3">
                          <a
                            href={`${item?.redirectLink}`}
                            target="_link"
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              style={{
                                border: "1px solid yellowGreen",
                                borderRadius: "10px",
                                padding: "10px",
                                minHeight: "500px",
                                position: "relative",
                              }}
                            >
                              <div className="pt-4">
                                <h4 className="hpricing__card__title mb-1">
                                  {item?.planTitle}
                                </h4>
                                <div className="gdpricing d-flex justify-content-center pt-3">
                                  {item?.cost ? (
                                    <h4 className="hpricing__card__title mb-3">
                                      {item?.cost}
                                    </h4>
                                  ) : null}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "3px",
                                  }}
                                >
                                  {!item?.isEveryThing ? (
                                    <>
                                      <h4
                                        style={{
                                          color: "#565050",
                                          fontSize: "14px",
                                          padding: "0px",
                                          margin: "1px 0",
                                        }}
                                      >
                                        User: {item?.userType}
                                      </h4>
                                      <h4
                                        style={{
                                          textAlign: "center",
                                          color: "green",
                                          fontSize: "14px",
                                          fontWeight: "500",
                                          padding: "0px",
                                          margin: "3px 0",
                                        }}
                                      >
                                        {item?.billingYear}
                                      </h4>
                                    </>
                                  ) : null}

                                  {item?.isEveryThing && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "30px 0",
                                      }}
                                    >
                                      <h4
                                        style={{
                                          color: "#565050",
                                          textAlign: "center",
                                          fontSize: "14px",
                                          padding: "0px",
                                          margin: "0",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {item?.everyThing}
                                      </h4>
                                    </div>
                                  )}
                                  <a
                                    href={`${item?.redirectLink}`}
                                    target="_blank"
                                    style={{
                                      color: "yellowGreen",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {item?.serviceTitle}
                                  </a>
                                </div>
                                <div className="card-body mp__card__body">
                                  <div className="gdpricing__body">
                                    <ul className="list-unstyled pt-3">
                                      {item?.isEveryThing &&
                                        item?.services?.map(
                                          (serv, serIndex) => {
                                            return (
                                              <li
                                                key={serIndex}
                                                className="d-flex align-items-start m-0 p-0 "
                                              >
                                                <img
                                                  src={check_sign}
                                                  className=" img-fluid pt-1"
                                                  style={{
                                                    // width: "18",
                                                    height: "15px",
                                                  }}
                                                  alt=""
                                                  border="0"
                                                />
                                                <p
                                                  className="ms-2"
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {serv}
                                                </p>
                                              </li>
                                            );
                                          }
                                        )}
                                      {!item?.isEveryThing &&
                                        item?.services?.map(
                                          (serv, serIndex) => {
                                            return (
                                              <li
                                                key={serIndex}
                                                className="d-flex align-items-start m-0 p-0 "
                                              >
                                                <img
                                                  src={check_sign}
                                                  className=" img-fluid pt-1"
                                                  style={{
                                                    // width: "18",
                                                    height: "15px",
                                                  }}
                                                  alt=""
                                                  border="0"
                                                />
                                                <p
                                                  className="ms-2"
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {serv}
                                                </p>
                                              </li>
                                            );
                                          }
                                        )}
                                    </ul>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      position: "absolute",
                                      bottom: "7px",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                    }}
                                    className="text-center pt-2"
                                  >
                                    {id ===
                                    pricingAllCost[1].items.length - 1 ? (
                                      <Link
                                        to={`/request-demo`}
                                        className="btn__pricing"
                                      >
                                        Contact Us
                                      </Link>
                                    ) : (
                                      <a
                                        href={`${item.redirectLink}`}
                                        target="_blank"
                                        className="btn__pricing"
                                      >
                                        {item.id === 1 || item.id === 2
                                          ? "Buy Now"
                                          : "Contact Us"}
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id={`pills-${pricingAllCost[2].tabTitle}`}
                role="tabpanel"
                aria-labelledby={`pills-${pricingAllCost[2].tabTitle}-tab`}
              >
                <div className="row g-3 pt-3 d-flex justify-content-center">
                  {pricingAllCost[2].items.length > 0 &&
                    pricingAllCost[2].items.map((item, id) => {
                      return (
                        <div key={id} className="col-md-3">
                          <a
                            href={`${item?.redirectLink}`}
                            target="_link"
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              style={{
                                border: "1px solid yellowGreen",
                                borderRadius: "10px",
                                padding: "10px",
                                minHeight: "540px",
                                position: "relative",
                              }}
                            >
                              <div className="pt-4">
                                <h4 className="hpricing__card__title mb-1">
                                  {item?.planTitle}
                                </h4>
                                {item?.cost ? (
                                  <div className="gdpricing d-flex justify-content-center pt-3">
                                    <h4 className="hpricing__card__title mb-1">
                                      {item?.cost}
                                    </h4>
                                  </div>
                                ) : null}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "3px",
                                  }}
                                >
                                  {!item?.isEveryThing ? (
                                    <>
                                      <h4
                                        style={{
                                          textAlign: "center",
                                          color: "green",
                                          fontSize: "14px",
                                          fontWeight: "500",
                                          padding: "0px",
                                          margin: "3px 0",
                                        }}
                                      >
                                        {item?.billingYear}
                                      </h4>
                                    </>
                                  ) : null}

                                  {item?.isEveryThing && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "10px 0",
                                      }}
                                    >
                                      <h4
                                        style={{
                                          color: "#565050",
                                          textAlign: "center",
                                          fontSize: "14px",
                                          padding: "0px",
                                          margin: "0",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {item?.everyThing}
                                      </h4>
                                    </div>
                                  )}
                                  <a
                                    href={`${item?.redirectLink}`}
                                    target="_blank"
                                    style={{
                                      color: "yellowGreen",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {item?.serviceTitle}
                                  </a>
                                </div>
                                {/* <h6 className="gdpricing__card__subtitle">
                              </h6> */}

                                <div className="card-body mp__card__body">
                                  <div className="gdpricing__body">
                                    <ul className="list-unstyled pt-3">
                                      {item?.isEveryThing &&
                                        item?.services?.map(
                                          (serv, serIndex) => {
                                            return (
                                              <li
                                                key={serIndex}
                                                className="d-flex align-items-start m-0 p-0 "
                                              >
                                                <img
                                                  src={check_sign}
                                                  className=" img-fluid pt-1"
                                                  style={{
                                                    // width: "18",
                                                    height: "15px",
                                                  }}
                                                  alt=""
                                                  border="0"
                                                />
                                                <p
                                                  className="ms-2"
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {serv}
                                                </p>
                                              </li>
                                            );
                                          }
                                        )}
                                      {!item?.isEveryThing &&
                                        item?.services?.map(
                                          (serv, serIndex) => {
                                            return (
                                              <li
                                                key={serIndex}
                                                className="d-flex align-items-start m-0 p-0 "
                                              >
                                                <img
                                                  src={check_sign}
                                                  className="img-fluid pt-1"
                                                  style={{
                                                    // width: "18",
                                                    height: "15px",
                                                  }}
                                                  alt=""
                                                  border="0"
                                                />
                                                <p
                                                  className="ms-2"
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {serv}
                                                </p>
                                              </li>
                                            );
                                          }
                                        )}
                                    </ul>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      position: "absolute",
                                      bottom: "7px",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                    }}
                                    className="text-center pt-2"
                                  >
                                    {id ===
                                    pricingAllCost[2].items.length - 1 ? (
                                      <Link
                                        to={"/request-demo"}
                                        className="btn__pricing"
                                      >
                                        Contact Us
                                      </Link>
                                    ) : (
                                      <a
                                        href={`${item.redirectLink}`}
                                        target="_blank"
                                        className="btn__pricing"
                                      >
                                        Buy Now
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
