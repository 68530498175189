import React from 'react';
import { Link } from 'react-router-dom';

const Privacy = () => {
  return (
    <div className='container mt-3'>
      <h1>Privacy Policy</h1>
      <div className=' mt-3'>
        <h5>Introduction</h5>
        <p>
          <Link
            to='/'
            style={{
              textDecoration: 'none',
              color: '#69B128',
            }}
          >
            Macrohealthplus
          </Link>{' '}
          (“<b>Macrohealthplus</b>”, “<b>our</b>”, “<b>us</b>”, or “<b>we</b>”),
          is the "data controller" of the personal information we collect about
          you when you visit or use any of our websites, or use products,
          services and applications that are linked to this Privacy Statement
          (collectively, our “<b>Sites</b>”). This Privacy Statement explains
          how we collect, share and use your personal information collected in
          this way and how you can exercise your privacy rights.
        </p>
        <p>
          When we interact with you, we may also provide you with additional
          privacy notices about specific processing activities or offer you
          additional choices about how Macrohealthplus processes your personal
          information at that particular point in time.
        </p>
        <p>
          Click on one of the links below to jump to the section that interests
          you:
        </p>
      </div>

      <div className=' row'>
        <div className='col-12 px-3 condition-privacy'>
          <h5>Our collection of information</h5>
          <ul className='ms-2'>
            <li>
              <b>Information collected directly from you.</b> We may ask you for
              personal information to provide a service or carry out a
              transaction that you have requested. You may also provide personal
              information to us when you contact or engage with us (e.g., with a
              comment, inquiry or customer support request, site visitors) or
              register for an account to use our Sites. This personal
              information may include:
              <ul className='ms-3 my-3'>
                <li>
                  <b>Identity data,</b> such as your name, title,
                  company/organization name, e-mail address, telephone numbers
                  and physical address (including street, city, state, postal or
                  zip code, and/or country);
                </li>
                <li>
                  <b>Registration data,</b> provided by you when you register
                  for an account to use our Sites, including usernames and
                  passwords;
                </li>
                <li>
                  <b>Recruitment data,</b> including information submitted in
                  connection with a job application or inquiry (as described
                  below);
                </li>
                <li>
                  <b>Business contact data,</b> such as information related to
                  other employees, owners, directors, officers, or contractors
                  of a third-party organization (e.g., business, company,
                  partnership, sole proprietorship, non-profit, or government
                  agency);
                </li>
                <li>
                  <b>Biometric data,</b> such as facial recognition scanning,
                  for health, safety, and security screening and other
                  administrative functions;
                </li>
                <li>
                  <b>Marketing and communications data,</b> including your
                  marketing preferences and your subscriptions to our
                  publications;
                </li>
                <li>
                  <b>Financial information,</b> including credit card or other
                  financial account information for the purpose of enabling us
                  to facilitate your purchase of Company products and services
                  that may be available on our Sites;
                </li>
                <li>
                  <b>Export control information,</b> such as your nationality,
                  citizenship and country of residence, that allows us to
                  determine your eligibility under export control regulations to
                  receive information about certain technologies;
                </li>
                <li>
                  <b>Transaction data,</b> including inquiries about and orders
                  for our products and services and details of payments to and
                  from you, including purchase order history and information
                  needed to facilitate payment transactions;
                </li>
                <li>
                  <b>Event registration information,</b> including information
                  you provide when filling in e-registration forms; and
                </li>
                <li>
                  <b>Your feedback,</b> including surveys, recommendations, or
                  other feedback from you about our Sites as well as our
                  products and services generally.
                </li>
              </ul>
              When you do not provide requested information, we may not be able
              to provide you the requested service or complete your transaction.
            </li>
            <li>
              <b>Information we collect automatically about you.</b> We collect
              certain information about your visit to our Sites using cookies
              and similar tracking technologies. We will always respect any
              legal rights you may have to prevent us doing this. Data collected
              in this way includes:
              <ul className='ms-3 my-3'>
                <li>
                  <b>Usage data,</b> including information about how you use our
                  Sites, what pages you view, the number of bytes transferred,
                  the links you click, the materials you access, the date and
                  time you access the Site, the website from which you linked to
                  one of our Sites and other actions taken within the Sites;
                </li>
                <li>
                  <b>Technical data,</b> such as your Internet Protocol (IP)
                  address, your browser type and capabilities and language and
                  your operating system.
                </li>
              </ul>
              For information about the cookies and other similar tracking
              technologies we use on our Sites, please refer to our Cookie
              Notice.
            </li>
            <li>
              <b>Geo-location information.</b> For certain Sites, with your
              consent, we may collect the precise, real-time location (i.e.,
              geo-location) of your mobile device. This information will be used
              only for purposes of facilitating your use of the Sites including
              by our service providers who may process this information in
              connection with providing services on our behalf. If given, you
              may adjust this consent by managing your Location Services
              preferences through the settings of your mobile device.
            </li>
            <li>
              <b>Information collected from third parties.</b> In accordance
              with applicable law, we may collect personal information about you
              from third parties, such as social media websites and applications
              and combine it with information we already hold, to help us
              improve and customize our Sites to your preferences and for other
              purposes set forth in this Privacy Statement. We may also collect
              your business contact information from your employer or other
              third parties to facilitate or otherwise engage in traditional
              business activities and similar administrative matters.
            </li>
          </ul>

          <h5>Our use of information</h5>
          <p>
            We may use the personal information collected via our Sites or when
            you contact us for purposes that include:
          </p>
          <ul className='ms-3 my-2'>
            <li>
              <b>Business Contact Data.</b> Macrohealthplus collects and uses
              business contact data to engage in communications or transactions,
              including for conducting due diligence regarding, or providing or
              receiving, a product or service. For example, Macrohealthplus may
              collect and use this business contact data in order to facilitate
              and manage orders, contracts, warranties, maintenance, and similar
              business functions, for credit analysis and collection purposes,
              to assist with our own internal compliance and legal requirements,
              and (if necessary) to defend our interests and claims.
            </li>
            <li>
              <b>Services and transactions.</b> We may use your personal
              information to deliver services to you or carry out transactions
              you have requested, including, but not limited to, providing
              information on Company products or services you have purchased or
              otherwise use, registering purchased products, processing product
              orders, handling warranty claims, replacing product manuals,
              answering customer service requests and facilitating the use of
              our Sites.
            </li>
            <li>
              <b>Administering and protecting our business and Sites.</b> We may
              use your personal information to administer and protect our
              business and our Sites, including troubleshooting, system
              maintenance, support, reporting and hosting of data.
            </li>
            <li>
              <b>Improving our business, Sites, products and services.</b> We
              may use your personal information to perform business analyses or
              for other purposes that help us to develop and improve the quality
              of our business, Sites, products and services (including new
              products and services), for example, by customizing our Sites to
              your particular preferences or interests.
            </li>
            <li>
              <b>Marketing.</b> In accordance with applicable laws and
              regulations, we may use your personal information to inform you of
              products or services which may be of interest to you, and to
              otherwise communicate with you about offerings, events and news,
              surveys, special offers, and related topics. You are able to
              opt-out from marketing communications sent via e-mail at any time,
              free of charge by using the “unsubscribe” link in any e-mail
              marketing materials you receive from us, or by contacting us using
              the contact information listed in this Privacy Statement.
              Depending on your country of residence, you may also be able to
              opt out of other types of marketing communications; please contact
              us using the contact information listed in the Privacy Statement
              for more information. Some jurisdictions provide individuals
              (e.g., California residents) with the right to request certain
              information regarding our disclosure of personal information to
              third parties for the third party’s direct marketing purposes. For
              more information on our data disclosure practices for marketing
              purposes, please contact us in accordance with the instructions
              listed below.
            </li>
            <li>
              <b>Interest-Based Advertising.</b> We collect data about your
              online activities and identify your interests so that we can
              provide advertising that is most relevant to you. You can opt out
              of receiving interest-based advertising from Macrohealthplus as
              described in our Cookie Notice.
            </li>
            <li>
              <b>Financial Incentive and Loyalty Programs.</b> We collect and
              use personal information to maintain and administer our financial
              incentive and loyalty programs. We use the personal information
              you provide in these programs to offer unique rewards, track your
              program status, and to facilitate the exchange of program points
              for products, promotional materials, training workshops, and other
              items. To the extent permitted by law, we may also use any
              personal information that you provide in the context of a
              financial incentive program for any other purpose set forth in
              this Privacy Statement. Please review the terms and conditions of
              each financial incentive program in which you would like to
              register or participate as such terms and conditions may be
              narrowly tailored to the applicable program. If you consent to
              participate in these programs, you may withdraw that consent at
              any time by contacting us using the contact details at the end of
              this Privacy Statement or in accordance with the instructions set
              forth in the applicable program’s terms and conditions.
            </li>
            <li>
              <b>Employment applications.</b> In connection with a job
              application or inquiry, you may provide us with information about
              yourself, such as a resume/curriculum vitae, professional
              references, information about education and job background, and
              information about professional training and certifications. We and
              members of our group may use this information for the purpose of
              employment consideration, background checks and employment
              eligibility, and as otherwise set forth in any separate privacy
              statement or other notice made available to in connection with
              your application. We may use a third party (e.g., job recruiter)
              or social media platform to solicit, collect, and retain
              employment applications.
            </li>
            <li>
              <b>Research and analysis.</b> We may use your personal information
              to conduct research and analysis to help us analyze your
              purchasing preferences, identify the products and services that
              best meet your requirements and measure the effectiveness of the
              advertising we serve you.
            </li>
            <li>
              <b>Enforcement:</b> We may use the personal information we collect
              in order to detect, prevent and respond to fraud, intellectual
              property infringement, violations of our terms and conditions,
              violations of law or other misuse of our Sites.
            </li>
          </ul>
          <p>
            Where permitted by law, we may combine the information that we
            collect via our Sites with other information we hold about you (such
            as information about your use of our products and services) in order
            to offer you an improved and consistent customer experience when
            interacting with us or for other purposes set forth in this Privacy
            Statement.
          </p>
          <h5>The legal basis on which we process information</h5>
          <p>
            Where required by law, we will ensure there is a legal basis for the
            processing of your personal information. In most cases our legal
            basis will depend on the personal information concerned and the
            specific context in which we collect it.
          </p>
          <ul>
            <li>
              <b>Contract:</b> the processing is necessary for the performance
              of the services we provide to you, or in order to take steps (at
              your request) prior to, and anticipation of, performing, such
              services;
            </li>
            <li>
              <b>Legal obligation:</b> the processing is necessary to comply
              with our legal obligations, including compliance with applicable
              laws, regulations, governmental and quasi-governmental requests,
              court orders or subpoenas;
            </li>
            <li>
              <b>Consent:</b> the processing is based on your consent to the
              processing of your personal information for one or more specified
              purposes (e.g. marketing);
            </li>
            <li>
              <b>Legitimate interests:</b> the processing is necessary to meet
              our legitimate interests, for example to develop and improve our
              Sites, products and/or services for the benefit of our customers;
              or
            </li>
            <li>
              <b>Vital interests:</b> the processing is necessary to protect
              your vital interests (for example, health and safety reasons if
              you attend a meeting at a Macrohealthplus location).
            </li>
          </ul>
          <p>
            If you have any questions about or need further information
            concerning the legal basis on which we collect and use your personal
            information, please send an email to{' '}
            <a target='_blank' href='mailto:info@macrohealthplus.org'>
              info@macrohealthplus.org
            </a>
            .
          </p>

          <h5>Anonymizing information</h5>
          <p>
            In accordance with applicable law, we may anonymize your personal
            information so that it can no longer be used to identify you.
            Anonymized information is not considered personal information and is
            therefore not subject to this Privacy Statement. We may collect,
            use, aggregate and share anonymized information for any purpose.
          </p>
          <h5>How long we keep information</h5>
          <p>
            The period during which we store your personal information varies
            depending on the purpose for the processing. For example, we store
            personal information needed to provide you with products and
            services, or to facilitate transactions you have requested, for so
            long as you are a customer of Macrohealthplus or if you submit an
            employment application via our Sites, we will retain details of your
            application as set forth in the privacy notice made available to you
            in connection with your application or as otherwise required by law.
            In all other cases, we store your personal information for as long
            as is needed to fulfil the purposes outlined in this Privacy
            Statement, following which time it is either anonymized (where
            permitted by applicable law), deleted or destroyed.
          </p>
          <h5>Disclosure of information</h5>
          <p>
            We may share your personal information with selected third parties
            in accordance with applicable law, including as set out below:
          </p>
          <ul>
            <li>
              <b>Our group companies.</b> We may share your personal information
              with our affiliates and group companies as reasonably necessary
              for the purposes set out in this Privacy Statement.
            </li>
            <li>
              <b>Service providers.</b> We may share your personal information
              with companies with which we have contracted to provide services
              on our behalf, such as hosting websites, conducting surveys,
              processing transactions, analyzing our Sites and performing
              analyses to improve the quality of our business, Sites, products
              and services. We require these service providers to protect the
              confidentiality of your personal information.
            </li>
            <li>
              <b>Distributors and other trusted business partners.</b> We may
              share your personal information with third parties that distribute
              our products and other trusted business partners for purposes that
              include allowing those third parties to send marketing
              communications to you. Such sharing of personal information for
              marketing purposes will be performed in accordance with applicable
              laws and regulations.
            </li>
            <li>
              <b>Disclosure in connection with transactions.</b> In connection
              with certain transactions, we may disclose some or all of your
              personal information to financial institutions, government
              entities and shipping companies or postal services involved in
              fulfillment of the transaction.
            </li>
            <li>
              <b>
                Disclosures in connection with acquisitions or divestitures.
              </b>{' '}
              Circumstances may arise where for strategic or other business
              reasons Macrohealthplus decides to sell, buy, divest, merge or
              otherwise reorganize businesses in some countries. We may disclose
              information we maintain about you to the extent reasonably
              necessary to proceed with the negotiation or completion of a
              merger, acquisition, divestiture or sale of all or a portion of
              Macrohealthplus’s assets.
            </li>
            <li>
              <b>Disclosure for other reasons.</b> We may disclose personal
              information if required or authorized to do so by law or in the
              good-faith belief that such action is necessary to comply with
              legal requirements or with legal process served on us, to protect
              and defend our rights or property or, in urgent circumstances, to
              protect the personal safety of any individual.
            </li>
          </ul>
          <h5>International transfers</h5>
          <p>
            Macrohealthplus is a global organization and your personal
            information may be transferred to, held, stored or used across
            various locations worldwide such as the US, Mexico and India in
            accordance with applicable law. This means that when we collect your
            personal information it may be processed in countries that may have
            data protection laws that are different to the laws of your country.
            However, Macrohealthplus will always take reasonable steps to
            protect your privacy and to provide a level of protection of
            personal information that is comparable to that of your country of
            residence which includes implementing the European Commission's
            Standard Contractual Clauses. For more information on the
            appropriate safeguards in place to protect your personal
            information, please contact us at info@macrohealthplus.org.
          </p>
          <h5>Security</h5>
          <p>
            We are committed to protecting the security of your personal
            information. We use technical and organisational measures to provide
            a level of security appropriate to the risk of processing your
            personal information. For more information on how Macrohealthplus
            protects your personal information please contact us at
            info@macrohealthplus.org.
          </p>
          <h5>Your rights</h5>
          <p>
            Depending on applicable law, you may have certain rights with
            respect to your personal information.
          </p>
          <ul>
            <li>
              You may have the right to <b>access,</b> <b>correct,</b>{' '}
              <b>update</b> or <b>request deletion</b> of your personal
              information.
            </li>
            <li>
              You can <b>object</b> to the processing of your personal
              information, ask us to <b>restrict</b> processing of your personal
              information or request <b>portability</b> of your personal
              information.
            </li>
            <li>
              You may have the right{' '}
              <b>
                not to have a decision made about you that is based solely on
                automated processing, including profiling
              </b>{' '}
              if that decision produces legal effects about you or significantly
              affects you.
            </li>
            <li>
              Similarly, if we have collected and process your personal
              information with your consent, then{' '}
              <b>you can withdraw your consent at any time.</b> Withdrawing your
              consent will not affect the lawfulness of any processing we
              conducted prior to your withdrawal, nor will it affect processing
              of your personal information conducted in reliance on lawful
              processing grounds other than consent.
            </li>
            <li>
              You may have the right to{' '}
              <b>opt-out of the sale of your personal information.</b>{' '}
              Macrohealthplus does not sell your personal information to third
              parties for profit. However, we do share or otherwise disclose
              your personal information to third parties in accordance with this
              Privacy Statement and our Cookie Notice. For example, we engage
              with third parties to distribute our products, provide you with
              technical assistance, operate website analytical tools and
              features, and engage in marketing and sales of our products and
              services. Such activities may involve the disclosure of your
              personal information to third parties and may be considered a
              “sale” of data under certain (but not all) data protection laws.
            </li>
            <li>
              You have the right to <b>complain</b> to a data protection
              authority responsible for overseeing compliance with data
              protection law in your jurisdiction about our collection and use
              of your personal information if you believe our processing of your
              personal information does not comply with data protection law. We
              would however appreciate the chance to address your concerns, so
              please feel free to contact us regarding any complaint you may
              have. We will not discriminate against individuals for exercising
              their data privacy rights.
            </li>
          </ul>
          <p>
            We will facilitate your exercise of the rights that apply to you in
            accordance with applicable law. However, even if your personal
            information is not afforded protection under, or otherwise subject
            to, a data protection law, we may seek to accommodate any request or
            inquiry you may have with regard to our data processing activities.
            If you have questions about your rights or wish to exercise your
            rights, you can contact us at{' '}
            <a target='_blank' href='mailto:info@macrohealthplus.org'>
              info@macrohealthplus.org
            </a>
          </p>
          <h5>Children's information</h5>
          <p>
            Our Sites are not directed to, and should not be used by, children
            under the age of sixteen (16) and we do not knowingly collect, use
            or sell information from such individuals. If you are a minor as
            understood by laws of your country, please do not submit any
            personal information through our Sites.
          </p>
          <h5>Links to other websites</h5>
          <p>
            Our Sites may contain links to third-party websites, products and
            services. We have no liability or responsibility for those websites,
            products and services, their policies, or their collection or other
            processing of your personal information. The practices of those
            third parties are governed by their own privacy policies. We
            encourage you to learn about the privacy policies of those third
            parties.
          </p>
          <h5>Contact us</h5>
          <p>
            If you have questions regarding this Privacy Statement or our
            handling of your personal information, would like to lodge a
            complaint, or wish to contact our Data Protection Officer, please
            contact us using the contact information listed below. We will
            promptly address your concern and strive to reach a satisfactory
            resolution.
          </p>
          <br />
          <div className='d-flex justify-content-start align-items-center my-2 gap-2'>
            <p className='fw-semibold my-0 py-0'>Email:</p>
            <a target='_blank' href='mailto:info@macrohealthplus.org'>
              info@macrohealthplus.org
            </a>
          </div>
          <div className='d-flex justify-content-start align-items-center my-2 gap-2'>
            <p className='fw-semibold my-0 py-0'>Mailing Address:</p>
            <p className='my-0 py-0'>
              House # 35, Road # East Rampura, Dhaka, Bangladesh
            </p>
          </div>
          <div className='d-flex justify-content-start align-items-center my-2 gap-2'>
            <p className='fw-semibold my-0 py-0'>Phone Number:</p>
            <p className='my-0 py-0'>
              {' '}
              <a href='tel:+880-1855-777766'>+880-1855-777766</a>
            </p>
          </div>
          <br />
          <h5>Changes to this Privacy Statement</h5>
          <p>
            We may occasionally update this Privacy Statement. When we do, we
            will revise the "last updated" date at the top of the Privacy
            Statement and take such additional steps as may be required by law
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
