
import React from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';


const HealthEcosystemMobileDevice = ({ arr }) => {

  return (
    <div className="container">
      <h2 className="eco__txt">Health <span className="inspiration__span">Eco-System</span></h2>
      <Timeline position="alternate">
        {
          arr.length > 0 && arr.map((item, id) => {
            return (
              <TimelineItem key={id}>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>{item.title}</TimelineContent>
              </TimelineItem>
            )
          })
        }
      </Timeline>
    </div>
  );
};

export default HealthEcosystemMobileDevice;