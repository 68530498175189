// CustomPDF.jsx
import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const CustomPDF = ({ url }) => {
  return (
    <div
      className='pdf-viewer'
      style={{ height: '75vh', width: '90vw', margin: 'auto' }}
    >
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js'>
        <Viewer fileUrl={url} enableSmoothScroll={true} />
      </Worker>
    </div>
  );
};

export default CustomPDF;
