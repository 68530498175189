import React from "react";
import under_construction from "../../../images/under_construction.jpg";

const Terms = () => {
  const [language, setLanguage] = React.useState("bn");

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-12 d-flex my-3 justify-content-end">
          <select
            className="form-select"
            style={{ width: "auto" }}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <option value="bn">বাংলা</option>
            <option value="en">English</option>
          </select>
        </div>
      </div>

      {language === "bn" && (
        <div className="row bn-terms">
          <h3>শর্তাবলী:</h3>

          <p>
            <a href="https://macrohealthplus.org/">MacroHealthPlus</a>{" "}
            সফ্টওয়্যার Pty Limited, একটি নিবন্ধিত কোম্পানী এবং সীমিত দায়বদ্ধতা
            সংস্থা, যা আমাদের ওয়েবসাইট পরিচালনা করে । এই সফ্টওয়্যারটি
            স্বাস্থ্য বিষয়ক একটি আন্তর্জাতিক সফ্টওয়্যার কোম্পানি । যা
            বিশ্বজুড়ে গ্রাহকদের স্বাস্থ্যসেবা সফ্টওয়্যার নিয়ে কাজ করে ৷ এই
            নথিতে সফ্টওয়্যারটি ব্যবহারের যে সকল শর্তাবলী উল্লেখ রয়েছে, তা পরে
            "চুক্তি" হিসাবে উল্লেখ করা হবে । এখানে সংজ্ঞায়িত হিসাবে আপনার এবং
            গ্রাহকের (স্বাস্থ্যসেবা প্রদানকারী , ডাক্তার ও রোগী) এবং
            Macrohealthplus-এর মধ্যে একটি আইনগতভাবে বাধ্যতামূলক চুক্তি গঠন করে ৷
          </p>

          <ol>
            <li>
              <p>
                (ক) ওয়েবসাইটটিকে গ্রাহক হিসাবে ব্যবহার করে বা ওয়েবসাইটে গ্রাহক
                হিসাবে নিবন্ধন করার মাধ্যমে বা
              </p>
            </li>
            <li>
              <p>
                (খ) একটি বোতাম (বাটন) বা লিঙ্কে ক্লিক করে, যা আপনার গ্রহণযোগ্যতা
                নির্দেশ করে, আপনি চুক্তির শর্তাবলী বুঝতে এবং এর দ্বারা সম্মত হোন
                ৷ এছাড়াও আপনি নিশ্চিত করেন যে, আপনার কাছে কোম্পানিগুলির পক্ষে
                কাজ করার জন্য মনোনীত কর্তৃপক্ষ রয়েছে ।
              </p>
            </li>
          </ol>

          <p>
            Macrohealthplus যে কোনো সময়ে এবং আমাদের নিজস্ব বিবেচনার ভিত্তিতে এই
            শর্তাবলী পরিবর্তন বা পরিবর্তন করার অধিকার সংরক্ষণ করে। যদি কোম্পানি
            এই শর্তাবলীতে পরিবর্তন করে, আমরা আপনাকে এই ধরনের পরিবর্তনের নোটিশ
            প্রদান করবো ।
          </p>

          <p>সংজ্ঞা এবং ব্যাখ্যা:</p>

          <ul>
            <li>
              "গ্রাহক" মানে হাসপাতাল, ক্লিনিক, ডাক্তার, পরিচর্যাকারী, পুনর্বাসন
              / ফিজিওথেরাপি কেন্দ্র, ডায়ালাইসিস ক্লিনিক, সুস্থতা কেন্দ্র, স্পা
              সেন্টার, ফার্মেসি বা ই-ফার্মেসি, ল্যাব পরিষেবা, মেডিকেল ট্যুরিজম
              এজেন্ট/সুবিধা সহ, কিন্তু সীমাবদ্ধ নয়, এমন একজন সফ্টওয়্যার
              ব্যবহারকারী বা ডায়াগনস্টিক সেন্টার।
            </li>
            <li>
              "গ্রাহক" মানে হলো, সেই সমস্ত দর্শক বা ওয়েবসাইটের ব্যবহারকারী অথবা
              ব্যক্তি, যারা আমাদের সফ্টওয়্যার পরিষেবাগুলির তথ্য জানতে
              Macrohealthplus-এর সাথে যোগাযোগ করে ।
            </li>
            <li>
              "গ্রাহক" বলতে স্বাস্থ্যসেবা, ঔষধ, শল্যচিকিৎসা, থেরাপিউটিক, বা
              স্বাস্থ্য গ্রাহকের দ্বারা সরবরাহ করা অন্যান্য পরিষেবাগুলিকে
              বোঝায়।
            </li>
            <li>
              "বৌদ্ধিক সম্পত্তির অধিকার" মানে কপিরাইট, ট্রেডমার্ক, সার্ভিস
              মার্ক, ট্রেড ড্রেস, ট্রেড সিক্রেটস, প্রচারের অধিকার, ডাটাবেস
              অধিকার, পেটেন্ট এবং অন্যান্য বৌদ্ধিক সম্পত্তির অধিকার বা আইন
              দ্বারা স্বীকৃত মালিকানা অধিকার বোঝায় ।
            </li>
            <li>
              "দর" মানে সফ্টওয়্যার পরিষেবাগুলির জন্য গ্রাহকের কাছে
              MacroHealthplus দ্বারা চার্জ করা ফি ৷
            </li>
          </ul>
          <p>এই চুক্তিতে (যদি না প্রসঙ্গ অন্যথায় প্রয়োজন হয়):</p>

          <ol class="custom-style">
            <li>
              <p class="custom-style">
                ক. একবচনের রেফারেন্সে বহুবচনের একটি রেফারেন্স । এর বিপরীত এবং
                যেকোন লিঙ্গের রেফারেন্স অন্যান্য সমস্ত লিঙ্গের উল্লেখ
                অন্তর্ভুক্ত করে;
              </p>
            </li>
            <li>
              <p>
                খ. প্রকৃত ব্যক্তিদের রেফারেন্স আইনি ব্যক্তিদের উল্লেখ
                অন্তর্ভুক্ত ।
              </p>
            </li>
            <li>
              <p>
                গ. সংবিধিবদ্ধ বিধানের রেফারেন্সকে অর্থ হিসাবে বোঝানো হবে এবং
                আপাতত বলবৎ কোনো সংশোধন বা পুনঃপ্রণয়ন (এই চুক্তির তারিখের আগে বা
                পরে হোক) এবং বিধিবদ্ধ বিধান অনুসারে প্রণীত সমস্ত বিধিবদ্ধ উপকরণ
                বা আদেশের উল্লেখ সহ বোঝানো হবে;
              </p>
            </li>
            <li>
              <p>
                ঘ. যে কোন ধারা, তফসিল বা পরিশিষ্টের রেফারেন্স এই চুক্তির
                অনুচ্ছেদ বা উপধারা, তফসিল বা সংযোজন বা এই চুক্তির একটি রেফারেন্স
                হিসাবে বিবেচিত হবে ।
              </p>
            </li>
          </ol>

          <p>
            <b>১. ওয়েবসাইট শর্তাবলী:</b> এই চুক্তির সাথে সম্মতি সাপেক্ষে,
            Macrohealthplus এর গ্রাহককে বাণিজ্যিক ব্যবহারের জন্য ওয়েবসাইট এবং
            পরিষেবাগুলি ব্যবহার করার জন্য একটি সীমিত, অ-এক্সক্লুসিভ লাইসেন্স
            প্রদান করে । প্রযোজ্য আইন দ্বারা অনুমোদিত সম্পূর্ণ পরিমাণে,
            ব্যবহারের জন্য দেওয়া এই লাইসেন্স হস্তান্তর যোগ্য নয় । গ্রাহক কোন
            ডাটার অনুলিপি করতে পারবেন না (এই লাইসেন্স এবং অন্য কোন প্রযোজ্য
            শর্তাবলী, বা ব্যবহারের নিয়ম দ্বারা স্পষ্টভাবে অনুমতি দেওয়া ছাড়া),
            ডিকম্পাইল, রিভার্স ইঞ্জিনিয়ার, ডিসঅ্যাসেম্বল, ওয়েবসাইটের সোর্স কোড
            বের করার চেষ্টা, পরিবর্তন করা বা ডেরিভেটিভ কাজ তৈরি করা বা এর
            বিষয়বস্তু তৈরী করা । গ্রাহক সর্বদা তাদের পাসওয়ার্ড, লগইন
            নাম/ব্যবহারকারী-আইডি এবং অ্যাকাউন্টের তথ্যের গোপনীয়তা নিজ দ্বায়ীত্ব
            বজায় রাখবেন । অনুমোদিত যে কেউ তাদের লগইন তথ্য এবং পাসওয়ার্ড
            ব্যবহার করে ওয়েবসাইট ব্যবহার করবেন । যেকোনো ভূলত্রুটি এবং ব্যবহারের
            জন্য সরাসরি দায়বদ্ধ হবেন । যদিও আমরা সঠিক বিষয়বস্তু প্রদানের জন্য
            যুক্তিসঙ্গত প্রচেষ্টা করি, আমরা কোন গ্যারান্টি, উপস্থাপনা বা
            ওয়্যারেন্টি দিই না, তা প্রকাশ করা হোক বা উহ্য করা হোক না কেন,
            গ্রাহকদের দ্বারা প্রদত্ত ডেটার নির্ভুলতা বা পরিষেবাগুলির প্রতি
            সম্মান রেখেই উপলব্ধ অন্য কোনও সামগ্রী ব্যবহার করা । এই ধরণের কোনো
            বিষয়বস্তুর উপর নির্ভর করে আপনার দ্বারা গৃহীত কোনো সিদ্ধান্ত বা
            পদক্ষেপের জন্য আমরা কোনো অবস্থায় জোগাড় প্রদান করতে পারি না ।
          </p>
          <br />
          <p>
            <b>২. অধিকতম দায়িত্ব সীমাবদ্ধতা:</b> কোন কারও অধিকতম দায়িত্ব
            সীমাবদ্ধতা হিসেবে তাদের প্রতি মোট দায়িত্ব সীমার মধ্যে হলেও, কোন
            উল্লেখযোগ্য, বা ক্ষতিকর প্রতি মৌল্যের জন্য এবং যেসব প্রতি মৌল্য বা
            আইনগত বা অনুবাদের পরিসীমা বাহিত হতে পারে তা অনুযায়ী সম্মতি করেন ।
          </p>
          <br />
          <p>
            <b>৩. পরিসীমার পরিসীমা:</b> এই চুক্তি এবং আমাদের পরিসীমাগুলি এবং
            তাদের কোনো অংশের যেকোনো অংশ আপনির প্রতি চুক্তির শর্ত হিসেবে গড়িয়ে
            দেওয়া হতে পারে এবং এই শর্তগুলির কোন মেয়াদসীমা সাপেক্ষে আপনির প্রতি
            লাগু হতে পারে ।
          </p>
          <br />
          <p>
            <b>৪. অধিকার প্রদান:</b> &nbsp; চুক্তির শর্তাবলী সাপেক্ষে,
            Macrohealthplus গ্রাহককে অনুদান দেয় এবং তারা চুক্তির মেয়াদের জন্য
            ওয়েবসাইট এবং পরিষেবাগুলিতে অ্যাক্সেস এবং ব্যবহার করার জন্য একটি
            অ-এক্সক্লুসিভ, অ-হস্তান্তরযোগ্য, সীমিত অধিকার স্বীকার করে। উপরে
            উল্লিখিত অধিকার প্রসারিত নয়:
          </p>
          <p>
            (ক) পরিষেবাটিকে, সম্পূর্ণ বা আংশিকভাবে, অন্য কোনো ব্যক্তি, সত্ত্বা
            বা ব্যবসার জন্য উপলব্ধ করা;
          </p>
          <p>
            (খ) পরিষেবা এবং ওয়েবসাইটের বিষয়বস্তু পরিবর্তন করা বা চুক্তিতে
            পরিকল্পিত ব্যতীত অন্য কোনও বাণিজ্যিক উদ্দেশ্যে, বা কোনও সর্বজনীন
            প্রদর্শন, কার্য সম্পাদন, বিক্রয় বা ভাড়ার জন্য এই জাতীয় সামগ্রী
            ব্যবহার করা;
          </p>
          <p>
            (গ) সম্পূর্ণ বা আংশিকভাবে পরিষেবা বা ওয়েবসাইটটিকে অনুলিপি, বিপরীত
            প্রকৌশলী, ডিকম্পাইল বা বিচ্ছিন্ন করা, বা অন্যথায় ব্যবহৃত
            সফ্টওয়্যারটির উৎস্য কোড আবিষ্কার করার চেষ্টা করা;
          </p>
          <p>
            (ঘ) Macrohealthplus দ্বারা প্রদত্ত বা অনুমোদিত নয়, এমন অন্য কোনও
            পরিষেবার সাথে সিস্টেমটিকে সংশোধন বা একত্রিত করুন ৷
          </p>
          <br />
          <p>
            <b>৫. চুক্তির মেয়াদ:</b> &nbsp; এই চুক্তির প্রাথমিক মেয়াদ দুই (২)
            বছরের জন্য হবে, কার্যকরী তারিখ থেকে শুরু হবে যদি না এই চুক্তির অধীনে
            প্রদত্ত আগে শেষ করা হয়। তারপরে, চুক্তিটি পরপর এক (১) বছরের
            মেয়াদ(গুলি) জন্য স্বয়ংক্রিয়ভাবে পুনর্নবীকরণ করা হবে । যদি না উভয়
            পক্ষ, তার পুনর্নবীকরণের মেয়াদ শেষ হওয়ার আগে অন্য পক্ষকে ত্রিশ (৩০)
            দিনের নোটিশ প্রদান করে নবায়ন না করার জন্য তার অভিপ্রায়কে জানায়।
          </p>
          <p>
            <b>৬. অ্যাকাউন্ট নিবন্ধন:</b> &nbsp; পরিষেবাগুলি ব্যবহার করার জন্য
            গ্রাহককে অবশ্যই Macrohealthplus-এর সাথে একটি অ্যাকাউন্ট নিবন্ধন করতে
            হবে ৷ Macrohealthplus গ্রাহককে নিবন্ধন পদ্ধতিতে সহায়তা করতে পারে বা
            অনুরোধ করা হলে, গ্রাহকের পক্ষে অ্যাকাউন্ট এবং প্রোফাইল তৈরি করতে
            পারেন এবং ইমেলের মাধ্যমে অ্যাকাউন্ট যাচাই করার জন্য লিঙ্কটি পাঠাতে
            পারেন । যে পরিস্থিতিতে গ্রাহক কোনো তথ্য প্রদান করেন না এবং
            প্রোফাইলের বিশদ বিবরণ সম্পূর্ণ করার জন্য প্রয়োজনীয় বা প্রাসঙ্গিক
            ধরনের তথ্য সনাক্ত করার জন্য Macrohealthplus-কে অনুরোধ করেন ।
            সেক্ষেত্রে অ্যাকাউন্টে Macrohealthplus দ্বারা পূরণ করা কোনো ভুল বা
            ভুল তথ্যের জন্য গ্রাহক সম্পূর্ণ দায়ভার গ্রহণ করবেন। গ্রাহকের কোন
            ভুল তথ্য চেক করার এবং শেষ পর্যন্ত সংশোধন করার বাধ্যবাধকতা রয়েছে ।
            গ্রাহক স্বীকার করেন যে, অ্যাকাউন্টটি গ্রাহকের বা তাদের অনুশীলনের
            ব্যক্তিগত বা মালিকানাধীন এবং তারা অ্যাকাউন্টটিকে গোপনীয় হিসাবে
            বিবেচনা করবে বলে আশা করা যাচ্ছে । গ্রাহক রেজিস্ট্রেশন এবং প্রোফাইল
            ফর্ম দ্বারা অনুরোধ করা অনুশীলন এবং গ্রাহকের শংসাপত্র সম্পর্কে সঠিক,
            বর্তমান এবং সম্পূর্ণ তথ্য প্রদান করতে সম্মত হন । প্ল্যাটফর্মে
            নিবন্ধন করার মাধ্যমে আপনি সর্বদা T&C মেনে চলতে, বাংলাদেশের আইন
            অনুযায়ী প্ল্যাটফর্ম ব্যবহার করতে এবং প্ল্যাটফর্মের অপব্যবহার থেকে
            বিরত থাকতে সম্মত হচ্ছেন । আপনি আরো স্বীকার করছেন এবং সম্মত হচ্ছেন
            যে, আপনি কোনো স্বয়ংক্রিয় সফটওয়্যার ব্যবহার করে প্ল্যাটফর্মে
            প্রবেশ করবেন না বা প্ল্যাটফর্মটি ব্যবহার করবেন না।
            Macrohealthplus-এর সাথে একটি অ্যাকাউন্ট স্থাপন করে, গ্রাহক
            প্রতিনিধিত্ব করে যে তাদের বয়স কমপক্ষে ১৮ বছর (একজন ডাক্তারের
            ক্ষেত্রে), অথবা গ্রাহক যেখানে বসবাস করেন সেখানে প্রাপ্তবয়স্কদের
            আইনগত বয়স যদি সেই এখতিয়ারের বেশি বয়স হয়।
          </p>
          <p>
            <b>৭. সিস্টেমে অ্যাক্সেস এবং পরিষেবাগুলির ব্যবহার:</b>
            <blockquote>
              <b>i) ভেরিফিকেশন:</b>
              গ্রাহকরা সম্মত হন যে, তাদের পরিষেবার প্রাপ্তি Macrohealthplus
              দ্বারা তাদের পরিচয় এবং প্রমাণপত্রাদি একজন স্বাস্থ্যসেবা
              অনুশীলনকারী হিসাবে এবং তাদের চলমান যোগ্যতার যাচাই সাপেক্ষে।
              নিবন্ধন প্রক্রিয়ার অংশ হিসেবে এবং তার পরে যে কোনো সময়ে, গ্রাহককে
              Macrohealthplus-কে বিভিন্ন তথ্য যেমন লাইসেন্স, যোগ্যতা, দক্ষতার
              ক্ষেত্র, স্বীকৃতি এবং অন্যান্য তথ্য প্রদান করতে হতে পারে, যাতে
              তারা সে ক্ষেত্রের একজন বৈধ স্বাস্থ্যসেবা অনুশীলনকারী হিসেবে তাদের
              প্রমাণপত্রাদি প্রমাণ করতে পারে । তারা দাবি করে ("প্রত্যয়নপত্রের
              তথ্য")। Macrohealthplus এই ধরনের শংসাপত্রের তথ্য যাচাই করতে পারে
              বা গ্রাহকের কাছে অতিরিক্ত তথ্যের জন্য জিজ্ঞাসা করতে পারে।
              Macrohealthplus তাদের শংসাপত্রের তথ্যের সত্যতা যাচাই করার জন্য
              তৃতীয় পক্ষের কাছ থেকে অনুসন্ধানও করতে পারেন । গ্রাহক
              Macrohealthplus থেকে এই ধরনের অনুসন্ধান করার অনুমতি দেয় । এই
              ধরণের তৃতীয় পক্ষ, এবং গ্রাহক তাদের এবং Macrohealthplus-কে এই
              ধরনের তথ্যের জন্য অনুরোধ বা প্রকাশের ফলে উদ্ভূত কোনো দাবি বা দায়
              থেকে ক্ষতিমুক্ত রাখতে সম্মত হন। গ্রাহকরা সম্মত হন যে
              Macrohealthplus যে কোনো সময়ে তাদের পরিষেবাগুলিতে অ্যাক্সেস বা
              ব্যবহার স্থগিত বা বন্ধ করতে পারে যদি তারা তাদের শংসাপত্রের তথ্য
              নির্ধারণ বা যাচাই করতে কোনো সময় অক্ষম । Macrohealthplus
              শংসাপত্রের তথ্যের পুনঃযাচাই করার অধিকার সংরক্ষণ করেন এবং যখন
              প্রয়োজন হয়, এবং উপরের অধিকার এবং প্রতিশ্রুতি পুনঃযাচাইতেও
              প্রসারিত হবে। গ্রাহকরা তাদের শংসাপত্রের তথ্যও আপডেট রাখবেন এবং
              তাদের শংসাপত্রের তথ্যের কোনো অংশ প্রত্যাহার, বাতিল বা মেয়াদ শেষ
              হলে অবিলম্বে Macrohealthplus-কে জানাবেন ।
            </blockquote>
            <blockquote>
              <b>ii) সেফগার্ড:</b>
              গ্রাহক তাদের অ্যাকাউন্ট অ্যাক্সেস, ব্যবহার বা পরিবর্তন থেকে রক্ষা
              করার জন্য যথাযথ প্রশাসনিক, ভৌত এবং প্রযুক্তিগত সুরক্ষা প্রয়োগ এবং
              বজায় রাখবে ।
            </blockquote>
            <blockquote>
              <b>iii) কোনো তৃতীয় পক্ষের প্রবেশাধিকার নেই:</b>
              গ্রাহকরা Macrohealthplus এর পূর্ব লিখিত সম্মতি ছাড়া কোনো তৃতীয়
              পক্ষকে অ্যাকাউন্ট বা পরিষেবাগুলিতে অ্যাক্সেস বা ব্যবহার করার
              অনুমতি দেবেন না । গ্রাহক কোনো তৃতীয় পক্ষকে অ্যাকাউন্ট অ্যাক্সেস
              করতে বা ওয়েবসাইটে গ্রাহকদের তথ্য প্রদানের অনুমতি দেবে না ।
              গ্রাহকরা Macrohealthplus কে অবিলম্বে অবহিত করবে, যে কোনো আদেশ বা
              ব্যহত তথ্যের বাধ্যতামূলক প্রকাশের দাবি, যদি প্রকাশের জন্য
              অ্যাকাউন্ট বা পরিষেবাগুলিতে অ্যাক্সেস বা ব্যবহারের প্রয়োজন হয় ।
            </blockquote>
            <blockquote>
              <b>iv) জমা দেওয়া বিষয়বস্তু:</b>
              Macrohealthplus কোন সামগ্রীর মালিকানা দাবি করে না, যা গ্রাহক
              ওয়েবসাইটের মাধ্যমে উপলব্ধ করে, সামগ্রী সহ । Macrohealthplus-এর
              নিজস্ব বিবেচনার ভিত্তিতে, এই ধরনের উপকরণগুলি সম্পূর্ণ বা আংশিকভাবে
              বা পরিবর্তিত আকারে পরিষেবাতে অন্তর্ভুক্ত করা যেতে পারে । এই ধরনের
              সামগ্রীর ক্ষেত্রে গ্রাহক জমা দেন বা ওয়েবসাইটে অন্তর্ভুক্তির জন্য
              উপলব্ধ করেন, তারা Macrohealthplus-কে একটি অ-চিরস্থায়ী, চুক্তিগত
              সম্পর্ক শেষ হওয়ার পরে বাতিলযোগ্য, বিশ্বব্যাপী, রয়্যালটি-মুক্ত
              এবং সর্বজনীনভাবে ব্যবহার, অনুলিপি, বিতরণ করার জন্য অ-এক্সক্লুসিভ
              লাইসেন্স প্রদান করে। প্রদর্শন, পরিবর্তন, ডেরিভেটিভ কাজ তৈরি করা
              এবং এই জাতীয় উপকরণ বা এই জাতীয় উপকরণের যে কোনও অংশকে
              সাব-লাইসেন্স দেওয়া (পাশাপাশি এই ধরনের জমা দেওয়া সামগ্রীর সাথে
              আপনার জমা দেওয়া নামটি ব্যবহার করুন)। গ্রাহক এতদ্বারা প্রতিনিধিত্ব
              করে, ওয়ারেন্ট এবং চুক্তি যে প্রদত্ত যেকোন সামগ্রীতে এমন কিছু
              অন্তর্ভুক্ত করে না (পাঠ্য, চিত্র, সঙ্গীত বা ভিডিও সহ, তবে সীমাবদ্ধ
              নয়) যার লাইসেন্স দেওয়ার সম্পূর্ণ অধিকার তাদের নেই৷
              Macrohealthplus কোনো দায়িত্ব নেয় না এবং গ্রাহকের দ্বারা পোস্ট
              করা বা জমা দেওয়া কোনো জমা দেওয়া সামগ্রীর জন্য কোনো দায়বদ্ধতা
              গ্রহণ করে না ।
            </blockquote>
          </p>
          <br />
          <p>
            <b>৮. ব্যপ্তি:</b> <br /> (ক) প্রতিটি পক্ষের পরিষেবার সুযোগ নীচে
            সংজ্ঞায়িত করা হবে, Macrohealthplus এর ভূমিকার সাথে প্রাথমিকভাবে
            গ্রাহকদের কাছে গ্রাহকদের তালিকা এবং বিশদ বিবরণ উপস্থাপন করা এবং
            প্রদত্ত পরিষেবাগুলির জন্য তথ্য এবং অথবা অনুমান গ্রহণের জন্য
            গ্রাহকদের সাথে যোগাযোগ করতে সক্ষম করা । <br /> (খ) প্রতিটি পক্ষের
            নির্দিষ্ট বাধ্যবাধকতা নিম্নরূপ হবে: <br />{" "}
            <p style={{ marginLeft: "15px" }}>
              i. গ্রাহকের কর্তব্য এবং বাধ্যবাধকতা: <br />
            </p>
            &nbsp;(ক) <b>জেনারেল:</b> গ্রাহকের হবে: <br />{" "}
            <p style={{ marginLeft: "15px" }}>
              i. স্বাস্থ্যসেবা পরিষেবা এবং কোনও পরিচর্যার ঝুঁকি সম্পর্কিত
              গ্রাহকদের সময়মত এবং সঠিক তথ্য সরবরাহ করুন । <br /> ii. গোপনীয়তা
              হিসাবে বজায় রাখুন এবং একটি গ্রাহক সম্পর্কিত সমস্ত তথ্য এবং
              Macrohealthplus থেকে প্রাপ্ত অন্য যেকোন তথ্য সুরক্ষিত রাখুন এবং এই
              চুক্তির উদ্দেশ্য বা প্রাসঙ্গিক বিধানের উদ্দেশ্য ব্যতীত অন্য কোনও
              গ্রাহক বা Macrohealthplus থেকে প্রাপ্ত অন্য কোনও ডেটা সম্পর্কিত
              কোনও তথ্য প্রকাশ বা ব্যবহার করবেন না । স্বাস্থ্য সেবা পরিষদ ।{" "}
              <br />
              iii. সমস্ত প্রযোজ্য গোপনীয়তা এবং তথ্য আইন এবং প্রবিধানগুলি মেনে
              চলুন, যতক্ষণ না তারা গ্রাহকের ব্যক্তিগত এবং স্বাস্থ্য তথ্যের
              গ্রাহকের সংগ্রহের সাথে সম্পর্কিত । <br /> iv. কোনো বিজ্ঞাপন বা
              প্রচার সামগ্রীতে এর নাম প্রদর্শনে কোনো আপত্তি নেই । <br />
              v. Macrohealthplus-কে সর্বদা গ্রাহক, বিশেষত্ব, ডাক্তার এবং
              ডাক্তারের প্রোফাইলের আপ টু ডেট তথ্য সরবরাহ করুন । আপডেটগুলি সরাসরি
              গ্রাহকের দ্বারা করা যেতে পারে, অথবা Macrohealthplus-কে ইমেলের
              মাধ্যমে পাঠানো যেতে পারে । এই ক্ষেত্রে গ্রাহক তাদের পক্ষ থেকে
              প্রোফাইলে তথ্য আপডেট অথবা সম্পাদনা, মুছে ফেলার জন্য
              Macrohealthplus সম্পূর্ণরূপে অনুমোদিত করে । গ্রাহক
              Macrohealthplus-কে তাদের প্রোফাইলে আপডেট অথবা কোনো সম্পাদনা বা
              অপসারণ করার অনুমতি দেয় । কারণ Macrohealthplus সরাসরি আপডেট করা
              তথ্য খুঁজে পায়, এছাড়াও Macrohealthplus-এর পরিপূরক সহযোগীদের
              সম্পর্কে অন্যান্য তথ্য যোগ করার জন্য । <br /> vi. Macrohealthplus
              বা এর ব্যবসায়িক মডেল সম্পর্কে অবমাননাকর মন্তব্য করবেন না এবং এমন
              কিছু করবেন না যাতে সাধারণভাবে জনসাধারণের চোখে Macrohealthplus এর
              ভাবমূর্তি ক্ষুন্ন না হয় । <br /> vii. এই চুক্তির উদ্দেশ্য ও
              উদ্দেশ্য পূরণের জন্য Macrohealthplus-কে সর্বাধিক সহযোগিতা প্রসারিত
              করা হয় । <br />
              vii. প্রতি মাসের শেষে যারা Macrohealthplus-এর রেফারেলের মাধ্যমে
              গ্রাহকের সাথে দেখা করেছেন । তাদের গ্রাহকদের (বিশেষত্ব অনুসারে
              গ্রাহকের সংখ্যা অন্ততপক্ষে) তালিকা সহ Macrohealthplus প্রদান করুন
              । এই উদ্দেশ্যে, Macrohealthplus ইতিমধ্যেই তার নিবন্ধন পদ্ধতি এবং
              নীতির মাধ্যমে গ্রাহকের স্পষ্ট সম্মতি নিশ্চিত করেছেন । যদি গ্রাহক
              এখনও কোনো কারণে অনুরোধ অনুযায়ী ডেটা সরবরাহ করতে না পারেন, তাহলে
              এটি Macrohealthplus-কে প্রাসঙ্গিক পরিসংখ্যানগত ডেটা প্রদান করবে ।{" "}
              <br />
              ix. এর কর্মচারী বা পরামর্শদাতাদের জানানোর জন্য দায়ী থাকবেন । যেন
              তাদের প্রোফাইলগুলি ওয়েবসাইটে যোগ করা হবে ।
            </p>{" "}
            <br /> খ. <b>নিষেধাজ্ঞা:</b> গ্রাহক এই পরিষেবাগুলি ব্যবহার করবেন না:{" "}
            <br />
            <p style={{ marginLeft: "20px" }}>
              i. অন্যের অধিকার লঙ্ঘন বা লঙ্ঘন করে এমন কোনো বিষয়বস্তু পোস্ট,
              প্রকাশ বা প্রেরণ করবেন না । যা বেআইনি, হুমকি, অপমানজনক, মানহানিকর,
              গোপনীয়তা বা প্রচারের অধিকারের আক্রমণকারী, হিংসাত্মক, অশ্লীল,
              অপবিত্র, ঘৃণ্য বা জাতিগতভাবে বা অন্যথায় আপত্তিকর, যেমন: ছবি বা
              বিষয়বস্তু যা অশ্লীল, যৌনতাপূর্ণ বা নগ্নতা, অশালীন, মানহানিকর,
              ঘৃণাপূর্ণ বা অসহিষ্ণু প্রকৃতির বা কোনো এখতিয়ারের আইন লঙ্ঘন করে ।{" "}
              <br />
              ii. অপরাধমূলক কার্যক্রম উৎসাহিত না করা, অপরাধমূলক কার্যকলাপ
              পরিচালনা না করা, বা নাগরিক দায়বদ্ধতার জন্ম দিবে বা অন্যথায় কোনো
              আইন লঙ্ঘন করবে, এমন কার্যক্রম থেকে বিরত থাকা । <br /> iii. একজন
              ব্যক্তি বা সত্ত্বার ছদ্মবেশ ধারণ করে বা মিথ্যার আশ্রয়ে কোন ব্যক্তি
              বা সত্তার সাথে তাদের সম্পর্ক স্থাপন করে, এমন কার্যক্রম থেকে বিরত
              থাকবো । <br /> iv. ট্রেডমার্ক, কপিরাইট এবং অন্যান্য মেধা সম্পত্তি
              অধিকার সহ অন্য পক্ষের অধিকার লঙ্ঘন করে এমন কোনও সামগ্রী আপলোড,
              পোস্ট, প্রেরণ বা অন্যথায় দেখানো থেকে বিরত থাকা । <br /> v.
              সফ্টওয়্যার ভাইরাস বা অন্য কোনো কম্পিউটার কোড, কোনো কম্পিউটার
              সফ্টওয়্যার বা হার্ডওয়্যার বা টেলিকমিউনিকেশন সরঞ্জামের
              কার্যকারিতা বাধাগ্রস্ত, ধ্বংস বা সীমিত করার জন্য ডিজাইন করা
              ফাইলগুলি ধারণ করে, এমন কোনো সামগ্রী ওয়েবসাইটে বা এর মাধ্যমে
              প্রেরণ বা অন্যথায় প্রেরণ না করা । <br />
              vi. এই ওয়েবসাইটে প্রদত্ত যেকোন বিষয়বস্তু অ্যাক্সেস, অনুলিপি বা
              ম্যানিপুলেট করার জন্য যে কোনো স্বয়ংক্রিয় স্ক্রিপ্ট বা "রোবট"
              ব্যবহার না করা । <br /> vii. এই ওয়েবসাইটটি প্রকাশ করে, এমন
              সার্ভারগুলিতে পরিষেবা আক্রমণ অস্বীকারে নিযুক্ত হন, এমন কার্যক্রম
              থেকে বিরত থাকা । <br />
              vii. তৃতীয় পক্ষের কাছে প্রদেয় কোনো আইনি দায়িত্বের লঙ্ঘন, যেমন
              চুক্তিভিত্তিক দায়িত্ব বা আস্থার দায়িত্ব প্রদান না করা । <br />{" "}
              viii. Macrohealthplus এর দায়িত্ব ও বাধ্যবাধকতা: Macrohealthplus
              হতে পারে:
            </p>
            <br />
            ক. ব্যবসার প্রচার করুন, Macrohealthplus সম্ভাব্য গ্রাহকদের কাছে তথ্য
            ছড়িয়ে দেওয়ার জন্য পদক্ষেপ নিতে পারে, উদাহরণগুলির মধ্যে রয়েছে:{" "}
            <br />
            <p style={{ marginLeft: "20px" }}>
              i. স্থানীয় প্রেসের মাধ্যমে সচেতনতা (সাক্ষাৎকার / সুবিধার লিখন /
              প্রথম পক্ষের ডাক্তার) । <br /> ii. ব্লগ এবং সামাজিক মিডিয়া ।{" "}
              <br />
              iii. এর সমস্ত অফিস/আউটলেট এবং বিদেশী ভ্রমণ অংশীদারদের নেটওয়ার্কের
              মাধ্যমে । <br /> iv. রোডশো, সেমিনার, সম্মেলন, চিকিৎসা স্বাস্থ্য
              মেলা, ট্রাভেল মার্ট এবং প্রেস মিট সমন্বয় করা । <br />{" "}
            </p>
            খ. পরিষেবার জন্য গ্রাহকের কাছে রেফার করুন, কিন্তু কোনো সময়েই
            Macrohealthplus একজন গ্রাহককে অন্য গ্রাহকের চেয়ে সুপারিশ করবে না ।{" "}
            <br /> গ. এই চুক্তির উদ্দেশ্য এবং উদ্দেশ্য পূরণের জন্য গ্রাহককে
            সর্বাধিক সহযোগিতা প্রসারিত করুন । <br /> ঘ. গ্রাহক সম্পর্কে
            অবমাননাকর মন্তব্য করবেন না । <br />
            ঙ. এমন কিছু করবেন না, যাতে সাধারণভাবে জনসাধারণের চোখে গ্রাহকের
            ভাবমূর্তি ক্ষুন্ন হয় । Macrohealthplus কোন প্রতিনিধিত্ব করে না যে,
            এই ওয়েবসাইটের ব্যবহার বা এখানে প্রদত্ত বিষয়বস্তু আপনার স্থানীয়
            এখতিয়ারের আইন লঙ্ঘন করে । আপনি আপনার এখতিয়ারের আইনের জন্য দায়ী
            থাকবেন । এই ওয়েবসাইটটি ব্যবহার করবেন না, যদি এই ধরনের ব্যবহার আপনার
            বসবাসের অধিক্ষেত্রের আইন লঙ্ঘন করে । আমরা অধিকার সংরক্ষণ করি, কিন্তু
            কোনো ভৌগলিক অঞ্চল বা এখতিয়ারে কোনো সত্তার কাছে আমাদের পরিষেবাগুলিতে
            অ্যাক্সেস সীমিত করতে বাধ্য নই । আমরা কেস-বাই-কেস ভিত্তিতে এই অধিকার
            প্রয়োগ করতে পারি । পরিষেবা এবং বিনিয়োগের সমস্ত বিবরণ আমাদের নিজস্ব
            বিবেচনার ভিত্তিতে, বিজ্ঞপ্তি ছাড়াই যে কোনও সময় পরিবর্তন সাপেক্ষে ।
            আমরা যেকোনো সময় যেকোনো পরিষেবা বন্ধ করার অধিকার সংরক্ষণ করি ।
          </p>
          <br />
          <p>
            <b>৯. রেটিং, প্রতিক্রিয়া এবং পর্যালোচনা:</b> <br />
            <p style={{ marginLeft: "20px" }}>
              ক. রেটিং: গ্রাহকদের কাছ থেকে ১ থেকে ৫ তারা রেটিং পেতে পারেন ।
              গ্রাহকের দেওয়া রেটিং স্বয়ংক্রিয়ভাবে ওয়েবসাইটে পোস্ট করা হবে ।{" "}
              <br />
              খ. প্রতিক্রিয়া: রেটিং সহ, Macrohealthplus গ্রাহক সম্পর্কে মতামত
              জমা দেওয়ার অনুমতি দিতে পারে । গ্রাহক এতদ্বারা বোঝেন এবং সম্মত হন
              যে, Macrohealthplus-এর দ্বারা উপযুক্ত হিসাবে বিবেচিত, যে কোন
              উপায়ে বা ফর্মে প্রতিক্রিয়া পোস্ট এবং প্রদর্শন করা হবে কিনা তা
              সিদ্ধান্ত নেওয়ার সীমাহীন অধিকার রয়েছে । ওয়েবসাইট থেকে
              প্রতিক্রিয়া প্রকাশ বা অপসারণ করার সিদ্ধান্ত নেওয়ার সময়
              Macrohealthplus গ্রাহকের ইনপুটও বিবেচনা করতে পারে । এছাড়াও,
              গ্রাহক এতদ্বারা সম্মত হন এবং বোঝেন যে Macrohealthplus সরাসরি
              গ্রাহকদের কাছ থেকে বা তৃতীয় পক্ষের কাছ থেকে অতিরিক্ত প্রতিক্রিয়া
              সংগ্রহ করতে পারে । <br />
              গ্রাহকদের রেটিং বা প্রতিক্রিয়া প্রদানের জন্য সহযোগী বা কর্মীদের
              অনুমতি দেওয়ার অনুমতি নেই ৷ গ. পর্যালোচনা: Macrohealthplus
              গ্রাহকদের সম্পর্কে তৃতীয় পক্ষের পর্যালোচনা পোস্ট করার অধিকার
              সংরক্ষণ করে, যেমন: গুগল পর্যালোচনা, ফেইসবুক পর্যালোচনা এবং তৃতীয়
              পক্ষের অংশীদারদের দ্বারা জমা দেওয়ার পর্যালোচনা করা ৷ গ্রাহক
              ক্ষতিহীন ম্যাক্রোহেলথপ্লাস এবং এর শেয়ারহোল্ডার, কর্মকর্তা,
              পরিচালক, কর্মচারী এবং সকলের বিরুদ্ধে এবং যেকোনো দাবি, ক্ষয়ক্ষতি,
              খরচ, মামলা, রায়, ডিক্রি, লাভের ক্ষতি, ব্যবসা এবং/অথবা সদিচ্ছা ও
              ব্যয়ের বিরুদ্ধে ক্ষতিপূরণ ও সম্মতি দেন। (যুক্তিসঙ্গত আইনি ফি সহ),
              এই ধারার স্পেসিফিকেশন অনুযায়ী সংগৃহীত এই ধরনের রেটিং,
              প্রতিক্রিয়া বা পর্যালোচনার পোস্টিং এবং প্রদর্শনের উপর ভিত্তি করে,
              সম্পর্কিত, বা উদ্ভূত।
            </p>
          </p>
          <p>
            <b>১0. পেমেন্ট:</b> <br />
            Macrohealthplus, কোনভাবেই, গ্রাহকদের দ্বারা গ্রহণ করা স্বাস্থ্যসেবা
            পরিষেবার ক্ষেত্রে গ্রাহককে কোন অর্থপ্রদানের জন্য দায়ী বা দায়বদ্ধ
            হবে না ।
          </p>
          <p>
            <b>১১. স্বাধীন ঠিকাদার:</b> <br />
            এই চুক্তির কোন কিছুই নিয়োগকর্তা এবং কর্মচারী, প্রধান এবং এজেন্ট,
            অংশীদারিত্ব, যৌথ উদ্যোগ, বা এই চুক্তির বিধানগুলি সম্পাদন করার জন্য
            শুধুমাত্র একে অপরের সাথে চুক্তিবদ্ধ স্বাধীন পক্ষগুলির সম্পর্ক ব্যতীত
            অন্য কোনও সম্পর্ক তৈরি করার জন্য বোঝানো হবে না ৷
          </p>
          <p>
            <b>১২. দলগুলোর প্রতিনিধিত্ব এবং ওয়ারেন্টি:</b> <br />
            i) প্রতিটি পক্ষ প্রতিনিধিত্ব করে এবং অন্য পক্ষের প্রতি ওয়ারেন্ট
            করে: <br />
            ক. এই চুক্তিতে প্রবেশ করার এবং এখানে প্রয়োজনীয় কাজগুলি সম্পাদন
            করার অধিকার, ক্ষমতা এবং কর্তৃত্ব রয়েছে । <br />
            খ. এই ধরনের পক্ষের দ্বারা এই চুক্তির সঞ্চালন, এবং এই জাতীয় পক্ষের
            দ্বারা তার বাধ্যবাধকতা এবং কর্তব্যগুলি এখানে সম্পাদন করা, এমন কোনও
            চুক্তি লঙ্ঘন করে না এবং করবে না, যা এই জাতীয় ব্যক্তির একটি পক্ষ বা
            যার দ্বারা এটি অন্যথায় আবদ্ধ । <br />
            গ. এটি একে অপরের কাছ থেকে প্রাপ্ত তথ্যের গোপনীয়তা বজায় রাখার
            ক্ষেত্রে সর্বোচ্চ সতর্কতা অবলম্বন করবে এবং নিশ্চিত করবে যে এটির
            কর্মী বা প্রতিনিধিরা যে কোনও উপায়ে এর অপব্যবহার না করে । অধিনস্ত
            দলগুলি ও তার কর্মী বা প্রতিনিধিদের গোপনীয়তার বাধ্যবাধকতা লঙ্ঘনের
            জন্য দায়ী থাকবে । <br />
            ঘ. এই চুক্তিটি প্রতিটি পক্ষের জন্য একটি আইনী, বৈধ এবং বাধ্যতামূলক
            বাধ্যবাধকতা গঠন করবে, যতক্ষণ না এখানে পক্ষগুলি দ্বারা এর সমাপ্তি ঘটে
            । <br />
            ii) <b>গ্রাহক প্রতিনিধিত্ব করে এবং পরোয়ানা দেয়:</b> <br />
            ক. যে ক্ষেত্রে হাসপাতাল, ক্লিনিক বা স্বাস্থ্যসেবা পরিষেবাগুলি
            চালিয়ে যাওয়ার জন্য এটির প্রয়োজনীয় লাইসেন্স বা অনুমোদন রয়েছে এবং
            বজায় রাখবে এবং এটি বহন করা অধিক্ষেত্রের কোনও আইনের বিধান লঙ্ঘন করে
            না, যেখানে এটি রয়েছে বর্তমান । <br />
            খ. যে সকল ডাক্তার এবং গ্রাহকের অন্যান্য কর্মীরা স্বাস্থ্যসেবা
            পরিসেবা প্রদানের জন্য সু-যোগ্য এবং পর্যাপ্তভাবে প্রশিক্ষিত । <br />
            গ. গ্রাহক তার যত্ন এবং হেফাজতে থাকাকালীন গ্রাহকের মঙ্গল নিশ্চিত করার
            জন্য গ্রাহককে সমস্ত প্রয়োজনীয় সতর্কতা অবলম্বন করতে হবে । <br />
            ঘ. যে সকল গ্রাহকের পর্যাপ্ত পরিকাঠামো, সুযোগ-সুবিধা এবং কর্মী
            রয়েছে, যাতে গ্রাহকের যত্ন নেওয়ার জন্য গ্রাহককে স্বাস্থ্যসেবা বা
            পরিষেবা প্রদান করেন । <br />
            ঙ. যে গ্রাহক প্রশাসকদেরকে পক্ষে ওয়েবসাইটে তথ্য অ্যাক্সেস এবং আপডেট
            করার জন্য এবং ওয়েবসাইটে জমা দেওয়া অনুসন্ধানগুলি গ্রহণ করার জন্য
            অর্পণ করবেন । গ্রাহক তার প্রশাসক দ্বারা প্রদত্ত যেকোন তথ্য বা
            প্রতিনিধি প্রশাসকের দ্বারা ওয়েবসাইটের ব্যবহারের জন্য দায়বদ্ধতা এবং
            দায় দাবি করে ৷ অ্যাডমিনিস্ট্রেটরের যোগাযোগের তথ্য সঠিক কিনা তা
            নিশ্চিত করার জন্য এবং Macrohealthplus দ্বারা ফরোয়ার্ড করা গ্রাহকের
            জিজ্ঞাসার উত্তর দেওয়ার জন্য গ্রাহক সম্পূর্ণভাবে দায়ী থাকবেন ।
          </p>
          <p>
            <b>১৩. ব্যক্তিগত তথ্য:</b> <br />
            ক. গ্রাহক এতদ্বারা স্বীকার করেন যে এটি সীমাবদ্ধতা ছাড়াই
            স্বাস্থ্য-সম্পর্কিত তথ্য সহ গ্রাহকদের ব্যক্তিগত ডেটা পেতে পারে।
            গ্রাহক প্রতিনিধিত্ব করে এবং পরোয়ানা করে যে এটি পরিষেবাগুলি ব্যবহার
            করার সময় এবং তারপরে, সমস্ত প্রযোজ্য স্থানীয় এবং আন্তর্জাতিক
            আইনগুলি মেনে চলবে । যা সংগ্রহ, ব্যবহার, সংক্রমণ, প্রক্রিয়াকরণ,
            প্রাপ্তি, রিপোর্টিং, প্রকাশ, রক্ষণাবেক্ষণ পরিচালনা করতে পারে এবং
            ব্যক্তিগত তথ্য সঞ্চয় করে । <br />
            খ. Macrohealthplus তার গোপনীয়তা নীতি অনুসারে গ্রাহকদের সাথে
            সম্পর্কিত ব্যক্তিগত ডেটা ব্যবহার করবে, যা দ্বারা অন্তর্ভুক্ত হবে ।
          </p>
          <p>
            <b>১৪. দাবিত্যাগ এবং ওয়ারেন্টি:</b> <br />
            গ্রাহকদের দ্বারা প্রদত্ত পরিষেবা, ওয়েবসাইট এবং তথ্য "যেমন আছে" এবং
            "যেমন উপলব্ধ" ভিত্তিতে প্রদান করা হয়, কোনো প্রকারের কোনো
            ওয়্যারেন্টি ছাড়াই, প্রকাশ করা বা উহ্য, যার মধ্যে সীমাবদ্ধ নয়
            ব্যবসায়িকতার অন্তর্নিহিত ওয়ারেন্টি, কোনো বিশেষের জন্য ফিটনেস
            উদ্দেশ্য, এবং অ-লঙ্ঘন । ভুল বা অসম্পূর্ণ তথ্য সহ সিস্টেম বা
            সিস্টেমের তথ্যের উপর নির্ভর করে নেওয়া বা করা, সমস্ত কাজ অথবা বাদ
            দেওয়ার জন্য আপনি সম্পূর্ণরূপে দায়ী । Macrohealthplus ভুল সংক্রমণ
            এবং ক্ষতির জন্য যে কোনও এবং সমস্ত দায় অস্বীকার করে টেলিকমিউনিকেশন
            পরিষেবা প্রদানকারী বা সিস্টেম দ্বারা যোগাযোগের ব্যর্থতার ফলে পরিষেবা
            । Macrohealthplus ওয়েবসাইট এবং পরিষেবাগুলি উপলব্ধ রয়েছে তা নিশ্চিত
            করার জন্য সর্বোত্তম প্রচেষ্টা ব্যবহার করবে । কিন্তু তারা ক্রমাগত
            উপলব্ধ বা বাধামুক্ত থাকবে, এমন গ্যারান্টি বা ওয়ারেন্টি দেয় না ।
            আপনি স্বীকার করেন যে, গ্রাহকরা সিস্টেমে অ্যাক্সেস এবং আমাদের
            পরিষেবাগুলি গ্রহণ করছে ৷ এই ধরনের গ্রাহকরা প্রযোজ্য শর্তাবলী মেনে
            চলতে প্রতিশ্রুতিবদ্ধ ।
          </p>
          <p>
            <b>১৫. দায়বদ্ধতা সীমাবদ্ধতা:</b> <br />
            এই শর্তাবলীর অন্যান্য শর্তাবলী সত্ত্বেও, Macrohealthplus কোনো
            আনুষঙ্গিক, ফলস্বরূপ, পরোক্ষ, বিশেষ, শাস্তিমূলক ক্ষতি বা কোনো
            প্রকারের দায়দ্বায়িত্বের জন্য দ্বায়ী থাকবে না । আর্থিক ক্ষতি বা
            সংযোগ থেকে উদ্ভূত এই চুক্তির সাথে, কর্মের ধরণ নির্বিশেষে, চুক্তিতে,
            টর্ট (অবহেলা সহ) বা অন্যথায়, এমনকি যদি কোনো পক্ষের কোনো প্রতিনিধিকে
            উপদেশ প্রদানের পরামর্শ দেওয়া হয় । এই চুক্তির অধীনে
            Macrohealthplus-এর সামগ্রিক দায়বদ্ধতা কোনো পরিস্থিতিতেই আগের বারো
            মাসে আপনার কাছ থেকে Macrohealthplus দ্বারা প্রাপ্ত কোনো ফি-এর
            মূল্যকে অতিক্রম করবে না । আপনি এবং ম্যাক্রোহেলথপ্লাস সম্মত হন যে
            দায়বদ্ধতার পূর্ববর্তী সীমাবদ্ধতা হল আপনার এবং ম্যাক্রোহেলথপ্লাসের
            মধ্যে ঝুঁকির একটি সম্মত বরাদ্দ ৷
          </p>
          <p>
            <b>১৬. ক্ষতিপূরণ:</b> <br />
            i) পক্ষগুলি ক্ষতিপূরণ করবে, ক্ষতিমুক্ত করবে এবং একে অপরকে এবং এর
            শেয়ারহোল্ডার, কর্মকর্তা, পরিচালক, কর্মচারী এবং সহযোগীদের প্রতিরক্ষা
            করবে এবং সকলের বিরুদ্ধে এবং যেকোনো চার্জ, দাবি, ক্ষতি, খরচ, মামলা,
            রায়, ডিক্রি, ক্ষতি, খরচ (যুক্তিসঙ্গত আইনি ফি সহ) ), জরিমানা, দাবী
            দায় এবং কর্মের কারণ, তৃতীয় পক্ষের দ্বারা জাহির করা যে কোন ধরণের বা
            আকারের ("দাবি") এর উপর ভিত্তি করে, এর সাথে সম্পর্কিত, বা লঙ্ঘন বা
            দাবীকৃত লঙ্ঘন বা চুক্তির যে কোনও ব্যর্থতার কারণে উদ্ভূত, উপস্থাপনা
            অথবা এই চুক্তির অধীনে ওয়ারেন্টি । <br />
            ii) এটি স্পষ্ট করা হয়েছে যে, ম্যাক্রোহেলথপ্লাস ক্ষতিপূরণ দিতে বাধ্য
            নয় । ক্ষতিকারক ধরে রাখতে বা গ্রাহকের দ্বারা প্রদত্ত স্বাস্থ্যসেবা
            পরিষেবা সম্পর্কিত কোনও গ্রাহক অথবা তার আত্মীয় বা অভিভাবকদের দ্বারা
            দাবি করা থেকে গ্রাহককে রক্ষা করতে বাধ্য নয় ৷
          </p>
          <p>
            <b>১৭. গোপন তথ্য:</b> <br />
            i) পক্ষগুলি একে অপরের বিষয়ে কঠোরতম আস্থা, গোপনীয় তথ্য বজায় রাখতে
            এবং ধরে রাখতে সম্মত হয় । "গোপনীয় তথ্য" মানে যে কোনো অ-পাবলিক তথ্য
            যা প্রকৃত বা প্রত্যাশিত ব্যবসা বা গবেষণা ও বিকাশের সাথে সম্পর্কিত,
            এই চুক্তি এবং এর শর্তাদি, পক্ষগুলির মধ্যে ব্যবস্থার স্কিম, ব্যবসা
            সহ, কিন্তু সীমাবদ্ধ নয় Macrohealthplus এর মডেল এবং এর কাজের পদ্ধতি,
            গ্রাহক ডেটা, গবেষণা, পণ্য পরিকল্পনা বা পার্টির পণ্য বা পরিষেবা এবং
            বাজার সম্পর্কিত অন্যান্য তথ্য, তাই গ্রাহক তালিকা এবং গ্রাহক, আর্থিক
            বা অন্যান্য ব্যবসায়িক তথ্য । তবে পক্ষগুলিকে এই ব্যবস্থার উদ্দেশ্যে
            প্রয়োজনীয় পরিমাণে গ্রাহক অথবা গ্রাহকের কাছে অন্য পক্ষের গোপনীয়
            তথ্য প্রকাশ করার অনুমতি দেওয়া হবে । <br />
            ii) গ্রাহকদের সুরক্ষিত স্বাস্থ্য তথ্যের গোপনীয়তা রক্ষা করার জন্য
            পর্যাপ্ত প্রশাসনিক, প্রযুক্তিগত এবং শারীরিক সুরক্ষা বজায় রাখতেও
            গ্রাহক সম্মত হন । নিরাপত্তার কোনো লঙ্ঘন বা গ্রাহকের স্বাস্থ্য তথ্যের
            বে-আইনি প্রকাশ এই ধরনের লঙ্ঘন বা প্রকাশ সম্পর্কে জানার দুই (২) দিনের
            মধ্যে ম্যা Macrohealthplus-কে রিপোর্ট করতে হবে এবং এই চুক্তির
            অবসানের জন্য একটি ভিত্তি হতে পারে । <br />
            iii) এটা স্পষ্ট করা হয়েছে যে, গ্রাহকের গোপনীয়তা লঙ্ঘনের কারণে এই
            ধারার অধীনে উদ্ভূত কোনো দাবির জন্য Macrohealthplus দায়ী থাকবে না
            এবং এর ফলে উদ্ভূত কোনো ক্ষতির জন্য গ্রাহক Macrohealthplus-কে
            ক্ষতিপূরণ দিতে দায়বদ্ধ থাকবে । <br />
            iv) এই ধারার অধীনে গোপনীয়তার বিধান বাধ্যতামূলক এবং এই চুক্তির
            সমাপ্তি থেকে বেঁচে থাকবে ৷
          </p>
          <p>
            <b>১৮. সমাপ্তি:</b> <br />
            i) <b>কারণ সহ সমাপ্তি:</b> <br />
            যেকোনো পক্ষই এই চুক্তি বাতিল করতে পারে যদি: <br /> ক. অন্যপক্ষ এই
            চুক্তির যেকোন বস্তুগত শর্ত বা শর্ত লঙ্ঘন করে এবং এই চুক্তির লিখিত
            বিজ্ঞপ্তি প্রাপ্তির পর পনেরো (১৫) দিনের মধ্যে এই ধরনের লঙ্ঘন
            নিরাময়ে ব্যর্থ হয় । <br />
            খ. অন্য পক্ষ দেউলিয়া হওয়া বা ঋণদাতাদের সুবিধার জন্য দেউলিয়াত্ব,
            রিসিভারশিপ, লিকুইডেশন বা সংমিশ্রণ সম্পর্কিত কোনো স্বেচ্ছায় বা
            অনিচ্ছাকৃত প্রক্রিয়ার পিটিশনের বিষয় হয়ে ওঠে । <br />
            ii) <b>কারণ ছাড়াই সমাপ্তি:</b>
            যেকোনো পক্ষ অন্য পক্ষকে ১৫ (পনেরো) দিনের পূর্বে লিখিত নোটিশ দেওয়ার
            পর, ইচ্ছামত এই চুক্তি বাতিল করতে পারে । Macrohealthplus অবিলম্বে এই
            চুক্তিটি বাতিল করতে পারে, যদি এটি তার নিজস্ব বিবেচনার ভিত্তিতে
            নির্ধারণ করে যে, একজন গ্রাহকের দ্বারা প্রদত্ত চিকিৎসাগুলি গ্রাহকের
            এখতিয়ারে আইনি কিনা তা নির্বিশেষে সাধারণভাবে স্বীকৃত অনুশীলন বা
            মানগুলির সাথে অসঙ্গতিপূর্ণ ।
          </p>
          <p>
            <b>১৯. বিরোধ নিষ্পত্তি এবং পরিচালনা আইন:</b> <br />
            i) যে কোনো দাবি, বিবাদ, পার্থক্য বা পক্ষের মধ্যে বিরোধ এই ধরনের
            বিরোধের উদ্ভবের তারিখ থেকে ৩০ (ত্রিশ) দিনের মধ্যে পারস্পরিক আলোচনার
            মাধ্যমে সমাধান করার চেষ্টা করা হবে । <br />
            ii) যদি উপরোক্ত উল্লেখিত ৩০ (ত্রিশ) দিনের মধ্যে আলোচনার মাধ্যমে
            বিরোধের সমাধান না হয়, তবে পক্ষগুলি এই চুক্তির কারণে বা এর সাথে
            সম্পর্কিত সমস্ত বিরোধ এবং মতভেদকে দলগুলির পারস্পরিক চুক্তি দ্বারা
            নিযুক্ত একমাত্র সালিসকারীর দ্বারা সালিসের কাছে জমা দিতে বাধ্য থাকবে,
            অথবা একটি পক্ষ প্রথম সালিসের প্রস্তাব করার ১৫ দিনের মধ্যে এই ধরনের
            চুক্তির অনুপস্থিতিতে, বিরোধটি তিনটি সালিসকারীর সমন্বয়ে গঠিত একটি
            সালিসী ট্রাইব্যুনাল দ্বারা সমাধান করা হবে, যার মধ্যে ১৫ (পনেরো)
            দিনের মেয়াদ শেষ হওয়ার ১৫ (পনেরো) দিনের মধ্যে প্রতিটি পক্ষ দ্বারা
            একজনকে নিয়োগ করা হবে । সময়কাল প্রথম দুই সালিসকারীর মধ্যে শেষটি
            নিযুক্ত হওয়ার ১৫ (পনেরো) দিনের মধ্যে প্রথম দুই সালিসকারীর পারস্পরিক
            চুক্তির মাধ্যমে তৃতীয় সালিসকারী নির্বাচন করা হবে । সালিশের স্থান
            হবে অস্ট্রেলিয়া এবং বাংলাদেশ । সালিশি কার্যক্রমে যে ভাষা ব্যবহার
            করা হবে, তা ইংরেজি হতে হবে । সালিশি কার্যক্রমের পুরস্কার চূড়ান্ত
            হবে এবং চুক্তির সকল পক্ষের জন্য বাধ্যতামূলক । এই চুক্তিটি ইংল্যান্ড
            এবং ওয়েলসের আইন অনুসারে পরিচালিত হয় এবং এটিকে বোঝানো হয় ।
            ইংল্যান্ড এবং ওয়েলসের আদালতের এই চুক্তি, এর কার্যকারিতা বা এর লঙ্ঘন
            থেকে উদ্ভূত সমস্ত ক্রিয়াকলাপের ক্ষেত্রে একচেটিয়া এখতিয়ার থাকবে ।
          </p>
          <p>
            <b>২0. কপিরাইট নোটিশ:</b> <br />
            Macrohealthplus অন্যদের বৌদ্ধিক সম্পত্তির অধিকারকে সম্মান করে এবং
            আশা করে যে এর পরিষেবার ব্যবহারকারীরাও একই কাজ করবে । আপনি যদি
            বিশ্বাস করেন যে, ওয়েবসাইটে উপস্থিত কোনো বিষয়বস্তু এমনভাবে অনুলিপি
            করা হয়েছে, যা কপিরাইট লঙ্ঘন করে, অনুগ্রহ করে নিম্নলিখিত তথ্য
            ম্যাক্রোহেলথপ্লাসে ফরোয়ার্ড করুন । <br />
            একটি কপিরাইট লঙ্ঘনের নোটিশ ফাইল করতে, আপনাকে
            info@macrohealthplus.org-এ নিম্নলিখিতগুলি অন্তর্ভুক্ত করে একটি
            বিজ্ঞপ্তি পাঠাতে হবে: <br />
            i) আপনার নাম, ঠিকানা, টেলিফোন নম্বর এবং ইমেল ঠিকানা । <br />
            ii) কপিরাইটযুক্ত কাজের একটি বিবরণ যা আপনি দাবি করেন যে লঙ্ঘন করা
            হয়েছে । <br />
            iii) কথিত লঙ্ঘনকারী উপাদান কোথায় অবস্থিত তার সঠিক URL বা একটি বিবরণ
            । <br />
            iv) আপনার দ্বারা একটি বিবৃতি যে আপনি একটি ভাল বিশ্বাস আছে যে,
            বিতর্কিত ব্যবহার কপিরাইট মালিক, এর এজেন্ট, বা আইন দ্বারা অনুমোদিত
            নয় । <br />
            v) কপিরাইট স্বার্থের মালিকের পক্ষে কাজ করার জন্য অনুমোদিত ব্যক্তির
            একটি বৈদ্যুতিক বা শারীরিক স্বাক্ষর । <br />
            মিথ্যা প্রমাণের শাস্তির অধীনে আপনার দ্বারা একটি বিবৃতি যে আপনার
            নোটিশে উপরের তথ্য সঠিক এবং আপনি কপিরাইটের মালিক বা কপিরাইট মালিকের
            পক্ষে কাজ করার জন্য অনুমোদিত ।
          </p>
          <p>
            <b>২১. সাধারণ বিধান:</b> <br />
            i) বেঁচে থাকা: এই চুক্তির সমাপ্তি কোন পক্ষের কোন অধিকার, বাধ্যবাধকতা
            বা দায়বদ্ধতাকে শেষ, প্রভাবিত বা ক্ষতিগ্রস্থ করবে না, যা এই ধরনের
            সমাপ্তির পূর্বে সঞ্চিত হতে পারে বা সেই বিধানগুলি যা তাদের অর্থ ও
            প্রেক্ষাপটে এই চুক্তির যেকোন সমাপ্তি বা মেয়াদ টিকে থাকা উচিত ।{" "}
            <br />
            ii) নন-সলিসিট: এই চুক্তির মেয়াদকালে, উভয় পক্ষ, অন্য পক্ষের পূর্ব
            লিখিত সম্মতি ব্যতিরেকে, প্রত্যক্ষ বা পরোক্ষভাবে, অন্য পক্ষের বা এর
            সহযোগী সংস্থার কোন কর্মচারী বা ঠিকাদারকে চাকুরী বন্ধ করতে বা প্রদান
            বন্ধ করার জন্য অনুরোধ বা উৎসাহিত করবে না । সেবা, অন্য পক্ষ বা এর
            সহযোগীদের । <br />
            iii) অ্যাসাইনমেন্ট: ম্যাক্রোহেলথপ্লাসের পূর্ব লিখিত সম্মতি ব্যতীত এই
            চুক্তিটি গ্রাহকের দ্বারা সম্পূর্ণ বা আংশিকভাবে বরাদ্দ করা হবে না।
            যাই হোক, ম্যাক্রোহেলথপ্লাস এই চুক্তিটি, তার বিবেচনার ভিত্তিতে,
            গ্রাহককে পূর্বে বিজ্ঞপ্তি ছাড়াই উপযুক্ত মনে করতে পারে, এমন
            অ্যাফিলিয়েটগুলিকে অর্পণ করতে পারে ৷ আরও ম্যাক্রোহেলথপ্লাসের
            নিয়ন্ত্রণে পরিবর্তনের কারণে এই চুক্তিটি অব্যাহত থাকবে এবং প্রভাবিত
            হবে না । এই চুক্তিটি বাধ্যতামূলক হবে বা পক্ষগুলি এবং তাদের
            প্রতিনিধিদের এবং সংশ্লিষ্ট উত্তরাধিকারীদের এবং অনুমোদিত বরাদ্দের
            সুবিধার জন্য খরচ বহন করবে। <br />
            এই চুক্তির উদ্দেশ্যে, "নিয়ন্ত্রণে পরিবর্তন" বলতে
            ম্যাক্রোহেলথপ্লাসের মালিকানা, পরিচালনা বা নিয়ন্ত্রণে প্রত্যক্ষ বা
            পরোক্ষ পরিবর্তন বোঝাবে তা একীভূতকরণ, অধিগ্রহণ, শেয়ার
            স্থানান্তর/সাবস্ক্রিপশন, একত্রীকরণ বা অন্য কোন কারণে । <br /> iv)
            মওকুফ: কোন পক্ষই তার কোন অধিকার, ক্ষমতা বা প্রতিকার এই অধীন পরিত্যাগ
            করেছে বলে গণ্য হবে না যদি না সেই পক্ষ লিখিতভাবে এই ধরনের মওকুফ
            অনুমোদন করে। <br />
            v) সম্পূর্ণ চুক্তি: এই চুক্তি, এখানে যেকোন এবং সমস্ত
            সংযুক্তি/প্রদর্শনী সহ, এখানে বিষয়বস্তুর সাথে সম্পর্কিত পক্ষগুলির
            মধ্যে সম্পূর্ণ চুক্তি গঠন করে এবং সমস্ত পূর্বের আলোচনা এবং বোঝাপড়া,
            মৌখিক বা লিখিত হোক না কেন, এতদ্বারা বাতিল করা হয়েছে ৷ <br />
            vi) বিচ্ছেদ যোগ্যতা: এই চুক্তির প্রতিটি বিভাগ বিচ্ছেদ যোগ্য । যদি
            কোনো বিধান উপযুক্ত বিচারব্যবস্থার আদালত দ্বারা অপ্রয়োগ যোগ্য হয়,
            তাহলে এই ধরনের রায় বোধগম্য রয়ে যাওয়া, অন্য কোনো বিধানকে
            ক্ষতিগ্রস্ত করবে না এবং অন্যান্য সমস্ত বিধান কার্যকর থাকবে । <br />
            vii) ফোর্স ম্যাজিওর: ঈশ্বরের কাজ, ভূমিকম্প, শ্রম বিরোধ, আইন, বিধি বা
            সরকারী নীতির পরিবর্তন, দাঙ্গা, যুদ্ধ, অগ্নি, বন্যা, বিদ্রোহ, নাশকতা,
            নিষেধাজ্ঞা, মহামারীর কারণে কার্যক্ষমতায় কোনো বিলম্ব বা ব্যর্থতার
            জন্য ম্যাক্রোহেলথপ্লাস দায়ী থাকবে না । , মহামারী, বিক্রেতা বা
            সরবরাহকারীদের ক্রিয়া বা বাদ দেওয়া, পরিবহন সমস্যা, টেলিযোগাযোগ বা
            তৃতীয় পক্ষের পরিষেবাগুলিতে (ডিএনএস প্রচার সহ) বাধা বা বিলম্বের
            অনুপলব্ধতা, তৃতীয় পক্ষের সফ্টওয়্যার বা হার্ডওয়্যারের ব্যর্থতা বা
            কাঁচামাল, সরবরাহ, বা ব্যবহৃত শক্তি পাওয়ার অক্ষমতা ইন বা সরঞ্জাম
            প্রয়োজন । Macrohealthplus কোনো পরিস্থিতিতে সার্ভার ডাউন টাইমের জন্য
            দায়ী নয় । <br />
            viii) আইনের সাথে সম্মতি: প্রতিটি পক্ষ এই চুক্তির অধীনে তার
            বাধ্যবাধকতাগুলি সম্পাদন করার জন্য সমস্ত প্রযোজ্য আইন এবং
            প্রবিধানগুলি মেনে চলবে । তার পক্ষ থেকে যে কোনও
            প্রবিধান/আইন/নির্দেশিকা/আদেশ ইত্যাদির যে কোনো এবং সমস্ত অ-সম্মতির
            জন্য পৃথকভাবে দায়বদ্ধ হবে ৷ <br />
            ix) নোটিশ: এখানে দেওয়া সমস্ত প্রয়োজনীয় বা কাঙ্ক্ষিত নোটিশ
            লিখিতভাবে দেওয়া হবে । ব্যক্তিগতভাবে বিতরণ করা যেতে পারে বা
            ইলেকট্রনিক যোগাযোগের মাধ্যমে (সাধারণ মেইলের একটি অনুলিপি সহ) বা
            নিবন্ধিত বা প্রত্যয়িত মেইলের মাধ্যমে পাঠানো যেতে পারে । ইলেকট্রনিক
            যোগাযোগের মাধ্যমে পাঠানো হলে, যে দিন নোটিশ পাঠানো হয়েছে, সেই দিনেই
            নোটিশ দেওয়া হয়েছে বলে গণ্য হবে । যদি নোটিশটি নিবন্ধিত বা
            প্রত্যয়িত মেইলের মাধ্যমে পাঠানো হয় বা ব্যক্তিগতভাবে বিতরণ করা হয়,
            যেদিন নোটিশটি গৃহীত হয় বা যখন বিতরণ প্রত্যাখ্যান করা হয় তখন এটি
            দেওয়া হয়েছে বলে মনে করা হবে ।
          </p>
          <ul>
            <li>
              কোনো পণ্য এবং পরিষেবার জন্য বা পরিষেবাগুলি সরবরাহের তারিখ থেকে ৭
              থেকে ১০ দিনের মধ্যে ফেরত বা ফেরতের অনুরোধ উত্থাপন করতে হবে।
            </li>
          </ul>
        </div>
      )}

      {language === "en" && (
        <div className="row">
          <h1>Terms & Conditions</h1>
          <div className="col-12 px-3 condition-privacy">
            <h5>Date of Last Revision: June 21, 2023</h5>
            <p>
              Macrohealthplus software Pty Limited is a Limited Liability
              company with a registered office at 9 Westbrook Avenue Wahroonga,
              NSW 2076, Australia that manages{" "}
              <a target="_blank" href="https://www.macrohealthplus.com">
                www.macrohealthplus.org
              </a>{" "}
              (the “Website”), an international software company dealing with
              healthcare software around the world to customers. This document
              comprises the Terms of Use, hereinafter referred to as the
              “Agreement”, and constitutes a legally binding agreement between
              you, the customer (healthcare provider, doctors, patients) as
              defined herein, and Macrohealthplus. By (a) using the Website as a
              Customer or registering as a Customers on the Website or (b)
              clicking on a button or link indicating your acceptance thereof,
              you understand and hereby agree to the terms of the Agreement. You
              also confirm that you have the designated authority to act on
              behalf of the companies. Macrohealthplus reserves the right to
              change or modify these terms at any time and in our sole
              discretion. If the company makes changes to these terms, we will
              provide you with notice of such changes, such as by sending an
              email, posting a notice on our Website or updating the date at the
              top of the Agreement. Your continued use of the Website or our
              services will confirm your acceptance of the revised terms.
              Macrohealthplus and companies shall jointly be referred to as
              “Parties' ' and separately as “Party' '. WHEREAS,
            </p>
            <ol className="mx-3" type="A">
              <li>
                Macrohealthplus will list the Software details and features of
                its website for Customers to research and find information.
              </li>
              <li>
                The Customer may use the Website to find information and submit
                inquiries to the Macrohealthplus for software services and
                support.
              </li>
            </ol>
            <p className="my-2">
              NOW THEREFORE, in consideration of the mutual promises and
              covenants set forth in this{" "}
            </p>
            <p>Agreement, the Parties hereby agree as follows:</p>
            <p>Definitions and Interpretation </p>
            <p>
              In this Agreement, unless the context requires otherwise, the
              following terms shall have the following meanings:
            </p>
            <p>
              “Customer” means a software user including, but not limited to,
              hospital, clinic, doctor, caregiver, rehabilitation /
              physiotherapy center, dialysis clinic, wellness center, spa
              center, pharmacy or e-pharmacy, lab services, medical tourism
              agent/facilitator or diagnostic center. The term shall also
              include an administrator of a customer’s account on the Website.{" "}
              <br />
              “Customer” means those visitors to or users of the Website and/or
              individuals who contact Macrohealthplus seeking information on our
              software Services. Unless expressly specified otherwise, reference
              to a Customer shall also include the relatives, guardian, or other
              agent of a Customer who uses the Website or communicates with
              Macrohealthplus on behalf of the Company. <br />
              “Customer” shall refer to the healthcare, medicinal, surgical,
              therapeutic, or other services to be provided by a Health
              Customer. <br />
              "Intellectual Property Rights" means copyrights, trademarks,
              service marks, trade dress, trade secrets, publicity rights,
              database rights, patents, and other intellectual property rights
              or proprietary rights recognized by law. <br />
              “Rates” means the fees charged by the MacroHealthplus to the
              Customer for the Software Services. <br />
              In this Agreement (unless the context requires otherwise):
            </p>
            <ol className="ms-3" type="a">
              <li>
                Reference to the singular includes a reference to the plural and
                vice versa, and reference to any gender includes a reference to
                all other genders;
              </li>
              <li>
                Reference to natural persons includes reference to legal
                persons;
              </li>
              <li>
                Reference to statutory provisions shall be construed as meaning
                and including references also to any amendment or re-enactment
                (whether before or after the date of this Agreement) for the
                time being in force and to all statutory instruments or orders
                made pursuant to statutory provisions;
              </li>
              <li>
                Reference to any Article, Clause, Schedule or Annexure shall be
                deemed to be a reference to such Article, Clause, Schedule or
                Annexure of or to this Agreement.
              </li>
            </ol>
            <br />
            <ol className="ms-3">
              <li>
                <b>Websites Terms</b> <br />
                <p>
                  Subject to compliance with this Agreement, Macrohealthplus
                  grants the Customer a limited, non-exclusive license to use
                  the Website and services for commercial use. To the fullest
                  extent permitted by applicable law, this license granted to
                  use is non-transferable. The Customer may not copy (except as
                  expressly permitted by this license and any other applicable
                  terms, conditions, or usage rules), decompile, reverse
                  engineer, disassemble, attempt to derive the source code of,
                  modify, or create derivative works of the Website or the
                  content therein. The Customer is responsible for maintaining
                  the secrecy of their passwords, login name/user-id and account
                  information at all times and shall be directly responsible for
                  any and all use of the Website by anyone using their login
                  information and password whether with or without their
                  permission. <br />
                  WHILE WE MAKE REASONABLE EFFORTS TO PROVIDE ACCURATE CONTENT,
                  WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES, WHETHER
                  EXPRESSED OR IMPLIED, WITH RESPECT TO ACCURACY OF THE DATA
                  PROVIDED BY THE CUSTOMERS, OR ANY OTHER CONTENT AVAILABLE
                  THROUGH THE SERVICES. IN NO EVENT SHALL WE BE LIABLE TO YOU OR
                  ANYONE ELSE FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN
                  RELIANCE ON ANY SUCH CONTENT.
                </p>
              </li>
              <li>
                <b>Intellectual Property</b> <br />
                <p>
                  All Intellectual Property Rights in and to the Website and its
                  entire contents, features and functionality (including but not
                  limited to all information, software, code, algorithms,
                  database, text, displays, images, video and audio, and the
                  design, selection and arrangement thereof) are owned by
                  Macrohealthplus, its suppliers, and licensors. Macrohealthplus
                  reserves all rights not expressly granted to you. <br />
                  All rights in and to the Customer’s intellectual property not
                  expressly granted to Macrohealthplus in this Agreement are
                  reserved by Customer.
                </p>
              </li>
              <li>
                <b>Arrangement</b>
                <br />
                <p>
                  Macrohealthplus shall list the Customers and Healthcare
                  Services on its Website and enable the Customers to find a
                  Customer and details for the Healthcare Services required. The
                  Customer may offer its designated facilities and medical care
                  and treatment including health checks, diagnosis, tests,
                  investigations, surgeries, facilitation of health and travel
                  services, dispensation of medicines in its super and
                  subspecialties to the Customers using the Website. Customer
                  may list special packages on the Website or provide discount
                  codes and the Customer shall ensure these packages are
                  properly fulfilled and discount codes are honored.
                </p>
              </li>
              <li>
                <b>Grant of Rights</b> <br />
                <p>
                  Subject to the terms of the Agreement, Macrohealthplus grants
                  to the Customer and they accept a non-exclusive,
                  non-transferable, limited right to have access to and to use
                  the Website and Services for the term of the Agreement. <br />
                  The aforementioned right does not extend to: (a) make the
                  Service, in whole or in part, available to any other person,
                  entity or business; (b) modify the contents of the Service and
                  the Website or use such content for any commercial purpose, or
                  any public display, performance, sale or rental other than
                  envisaged in the Agreement; (c) copy, reverse engineer,
                  decompile or disassemble the Service or the Website, in whole
                  or in part, or otherwise attempt to discover the source code
                  to the software used; or (d) modify or combine the System with
                  any other services not provided or approved by
                  Macrohealthplus.
                </p>
              </li>
              <li>
                <b>Term of Agreement</b>
                <br />
                <p>
                  <span>
                    The initial term of this Agreement shall be for a period of
                    two (2) years, commencing from the Effective Date unless
                    terminated earlier as provided under this Agreement.
                    Thereafter, the Agreement shall be renewed automatically for
                    successive one (1) year term(s) unless either Party
                    intimates its intention for non-renewal by providing a
                    thirty (30) day notice to the other Party before the expiry
                    of any renewed term thereof.
                  </span>
                </p>
              </li>
              <li>
                <b>Account Registration</b> <br />
                <p>
                  The Customer must register an Account with Macrohealthplus in
                  order to use the Services. Macrohealthplus may assist the
                  Customer in the registration procedures or can even create the
                  account and profiles on behalf of the Customer, if requested,
                  and send the link to verify the account by email. In the
                  situation in which the Customer does not provide any
                  information and requests Macrohealthplus to identify the
                  necessary or relevant types of information in order to
                  complete the profile details, the Customer takes full
                  responsibility for any wrong or inaccurate information filled
                  in by Macrohealthplus in the account. The Customer has the
                  obligation to check and eventually correct any inaccurate
                  information. The Customer acknowledges that the account is
                  personal or proprietary to the Customer or their practice and
                  that they are expected to treat the account as confidential.
                  The Customer agrees to provide accurate, current and complete
                  information about the practice and Customer’s credentials as
                  prompted by the registration and profile forms. <br />
                  By establishing an account with Macrohealthplus, the Customer
                  represents that they are at least 18 years of age (in case of
                  a doctor), or of the legal age of majority where the Customer
                  resides if that jurisdiction has an older age of majority.
                </p>
              </li>
              <li>
                Access to the System and Use of Services
                <ol type="i" className="mx-3">
                  <li>
                    Verification <br />
                    Customers agree that their receipt of Services is subject to
                    verification by Macrohealthplus of their identity and
                    credentials as a healthcare practitioner and to their
                    ongoing qualification as such. <br />
                    As part of the registration process and at any time
                    thereafter, Customer may be required to provide
                    Macrohealthplus with various information such as licenses,
                    qualifications, area of expertise, accreditations and other
                    information in order to prove their credentials as a valid
                    healthcare practitioner in the field they claim ("Credential
                    Information''). Macrohealthplus may verify such Credential
                    Information or may ask the Customer for additional
                    information. Macrohealthplus may also make enquiries from
                    third parties to verify the authenticity of their Credential
                    Information. Customer authorizes Macrohealthplus to make
                    such enquiries from such third parties, and Customer agrees
                    to hold them and Macrohealthplus harmless from any claim or
                    liability arising from the request for or disclosure of such
                    information. Customers agree that Macrohealthplus may
                    suspend or terminate their access to or use of the Services
                    at any time if they are unable at any time to determine or
                    verify their Credential Information. Macrohealthplus
                    reserves the right to carry out re-verification of
                    Credential Information as and when required, and the above
                    rights and commitments will extend to re-verification as
                    well. <br />
                    Customers shall also keep their Credential Information
                    updated and will inform Macrohealthplus immediately should
                    any portion of their Credential Information be revoked,
                    canceled or expire.
                  </li>
                  <li>
                    Safeguards <br />
                    The Customer will implement and maintain appropriate
                    administrative, physical and technical safeguards to protect
                    their account from access, use, or alteration.
                  </li>
                  <li>
                    No Third Party Access <br />
                    Customers will not permit any third party to have access to
                    or to use the account or the Services without
                    Macrohealthplus’s prior written consent. Customer will not
                    allow any third party to access the account or provide
                    information to Customers on the Website. Customers will
                    promptly notify Macrohealthplus of any order or demand for
                    compulsory disclosure of disrupted information if the
                    disclosure requires access to or use of the account or
                    Services.
                  </li>
                  <li>
                    Submitted Content <br />
                    Macrohealthplus does not claim ownership of any materials
                    the Customer makes available through the Website, including
                    contents. At Macrohealthplus's sole discretion, such
                    materials may be included in the Service in whole or in part
                    or in a modified form. With respect to such materials the
                    Customer submits or makes available for inclusion on the
                    Website, they grant Macrohealthplus a non-perpetual,
                    terminable upon end of contractual relationship, worldwide,
                    royalty-free and non-exclusive license to use, copy,
                    distribute, publicly display, modify, create derivative
                    works, and sublicense such materials or any part of such
                    materials (as well as use the name that you submit in
                    connection with such submitted content). The Customer hereby
                    represents, warrants and covenants that any materials
                    provided do not include anything (including, but not limited
                    to, text, images, music or video) to which they do not have
                    the full right to grant the license. Macrohealthplus takes
                    no responsibility and assumes no liability for any submitted
                    content posted or submitted by the Customer.
                  </li>
                </ol>
              </li>
              <li>
                Scope <br />
                <ol type="A" className="mx-3">
                  <li>
                    The scope of service of each Party shall be as defined below
                    with the role of Macrohealthplus being primarily to present
                    the list and details of Customers to the Customers and to
                    enable the Customers to contact the Customers to receive
                    information and/or estimates for the Services offered.
                  </li>
                  <li>
                    The specific obligations of each Party shall be as follows:
                    <ol type="i" className="mx-3 ">
                      <li>
                        Duties and obligations of the Customer:
                        <ol type="A" className="ms-3 ">
                          <li>
                            <span
                              style={{
                                textDecoration: "underline",
                              }}
                            >
                              General
                            </span>{" "}
                            <br /> <span>Customer shall:</span>
                            <ol type="a" className="ms-3">
                              <li>
                                Provide Customers with timely and accurate
                                information regarding the Healthcare Services
                                and any attendant risks;
                              </li>
                              <li>
                                Maintain as confidential and keep secure all
                                information regarding a Customer and any other
                                data received from Macrohealthplus, and not
                                disclose or use any information regarding a
                                Customer or any other data received from
                                Macrohealthplus other than for the purposes of
                                this Agreement or the provision of the relevant
                                Healthcare Services;
                              </li>
                              <li>
                                Comply with all applicable privacy and
                                information laws and regulations so far as they
                                relate to the Customer's collection of
                                Customer’s personal and health information
                              </li>
                              <li>
                                Have no objection to the display of its name on
                                any advertisement or publicity material;
                              </li>
                              <li>
                                Provide up to date information of the Customer,
                                specialties, Doctors and Doctor profiles to
                                Macrohealthplus at all times. Updates can be
                                either made directly by the Customer, or sent
                                over by email to Macrohealthplus, in which case
                                the Customer fully authorized Macrohealthplus to
                                update and/or edit/remove information on the
                                profile on their behalf. The Customer authorizes
                                Macrohealthplus to make updates and/or any
                                edits/removals on their profile as
                                Macrohealthplus directly finds updated
                                information, and also to add other information
                                about Macrohealthplus’s complementary
                                collaborators;
                              </li>
                              <li>
                                Not make derogatory remarks about
                                Macrohealthplus or its business model and not do
                                anything which has the effect of disparaging the
                                image of Macrohealthplus in the eyes of the
                                public in general;
                              </li>
                              <li>
                                Extend maximum co-operation to Macrohealthplus
                                for the fulfillment of the objectives and
                                purpose of this Agreement;
                              </li>
                              <li>
                                Provide Macrohealthplus with a list of Customers
                                (or at least the number of customers by
                                specialty, as the case may be) who have visited
                                the Customer by Macrohealthplus’s referral, at
                                the end of each month. To this purpose,
                                Macrohealthplus has already ensured Customer’s
                                express consent through its registration
                                procedures and policies. In case the Customer is
                                still unable to deliver the data as requested
                                for any reason, it will provide Macrohealthplus
                                with the relevant statistical data;
                              </li>
                              <li>
                                Be responsible for informing its
                                employees/consultants that their profiles will
                                be added to the Website.
                              </li>
                            </ol>
                            <li>
                              <span
                                style={{
                                  textDecoration: "underline",
                                }}
                              >
                                {" "}
                                Restriction{" "}
                              </span>{" "}
                              <br />
                              <span>
                                Customer shall not use the Services to:
                              </span>
                              <ol type="a" className="ms-3">
                                <li>
                                  {" "}
                                  Post, publish or transmit any Content which
                                  violates or infringes in any way upon the
                                  rights of others, which is unlawful,
                                  threatening, abusive, defamatory, libelous,
                                  invasive of privacy or publicity rights,
                                  violent, vulgar, obscene, profane, hateful, or
                                  racially or ethnically or otherwise
                                  objectionable, such as images or content that
                                  is obscene, sexually explicit or contains
                                  nudity, indecent, defamatory, hateful or
                                  intolerant in nature or in violation of the
                                  laws of any jurisdiction;
                                </li>
                                <li>
                                  Encourage, conduct activity that is criminal,
                                  would give rise to civil liability or would
                                  otherwise violate any law;
                                </li>
                                <li>
                                  {" "}
                                  Impersonate a person or entity or falsely
                                  state their affiliation with a person or
                                  entity;
                                </li>
                                <li>
                                  Upload, post, transmit or otherwise make
                                  available any Content that infringes the
                                  rights of another party including but not
                                  limited to trademark, copyright and other
                                  intellectual property rights;
                                </li>
                                <li>
                                  Transmit or otherwise make available any
                                  material on or via the Website that contains
                                  software viruses or any other computer code,
                                  files designed to interrupt, destroy or limit
                                  the functionality of any computer software or
                                  hardware or telecommunications equipment;
                                </li>
                                <li>
                                  Use any automated scripts or “robots” to
                                  access, copy, or manipulate any content
                                  provided on this Website;
                                </li>
                                <li>
                                  Engage in denial of service attacks upon the
                                  servers that publish this Website;
                                </li>
                                <li>
                                  Breach of any legal duty owed to a third
                                  party, such as a contractual duty or a duty of
                                  confidence.
                                </li>
                              </ol>
                            </li>
                          </li>
                        </ol>
                      </li>
                      <li>
                        Duties and obligations of Macrohealthplus: <br />
                        Macrohealthplus may:
                        <ol type="A" className="ms-3">
                          <li>
                            Promote the business, Macrohealthplus may take steps
                            to disseminate information to the prospective
                            Customers, examples include:
                            <ol type="a" className="ms-3">
                              <li>
                                Awareness through local Press (Interviews /
                                Write-ups of Facilities / Doctors of First
                                Party);
                              </li>
                              <li>Blogs and Social Media;</li>
                              <li>
                                Through all its offices / outlets and network of
                                foreign travel partners;
                              </li>
                              <li>
                                Coordinate roadshow, seminars, conferences,
                                medical health fairs, travel marts and press
                                meets;
                              </li>
                            </ol>
                            <li>
                              Refer Customers to the Customer for the Services,
                              but at no time will Macrohealthplus recommend one
                              Customer over another Customer;
                            </li>
                            <li>
                              Extend maximum co-operation to the Customer for
                              the fulfillment of the objectives and purpose of
                              this Agreement;
                            </li>
                            <li>
                              Not make derogatory remarks about the Customer;
                            </li>
                            <li>
                              Not do anything which has the effect of
                              disparaging the image of the Customer in the eyes
                              of the public in general. <br />
                              Macrohealthplus makes no representation that the
                              usage of this Website, or the content provided
                              herein, will not violate the laws of your local
                              jurisdiction. You are responsible for the laws of
                              your jurisdiction. Do not use this Website if such
                              usage violates the law of the jurisdiction in
                              which you reside. We reserve the right but are not
                              obligated to limit access to our services to any
                              entity, in any geographic region or jurisdiction.
                              We may exercise this right on a case-by-case
                              basis. All descriptions of services and
                              investments are subject to change at any time
                              without notice, at our sole discretion. We reserve
                              the right to discontinue any service at any time.
                            </li>
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                Ratings, Feedback and Reviews
                <ol type="A" className="ms-3">
                  <li>
                    <b
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Ratings:
                    </b>{" "}
                    Customer may receive a rating from Customers, from 1 to 5
                    stars. Ratings provided by the Customer will automatically
                    be posted on the Website.
                  </li>
                  <li>
                    <b
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Feedback:
                    </b>{" "}
                    Together with the rating, Macrohealthplus may allow
                    submission of feedback about the Customer. The Customer
                    hereby understands and agrees that Macrohealthplus has the
                    unrestricted right to decide whether to post and display the
                    feedback in any way or form as considered appropriate by
                    Macrohealthplus. Macrohealthplus may also consider
                    Customer’s input when deciding whether to publish or remove
                    feedback from the Website. Also, the Customer hereby agrees
                    and understands that Macrohealthplus may collect additional
                    feedback directly from the Customers or from any third
                    party. <br />
                    Customers are not permitted to allow associates or staff to
                    provide Ratings or Feedback.
                  </li>
                  <li>
                    <b
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Reviews:
                    </b>{" "}
                    Macrohealthplus reserves the right to post third party
                    reviews about Customers, such as Google Reviews, Facebook
                    reviews, and reviews submitted by third party partners.{" "}
                    <br />
                    The Customer also indemnifies and agrees to hold harmless
                    Macrohealthplus and its shareholders, officers, directors,
                    employees and affiliates from and against all and any
                    claims, damages, costs, suits, judgments, decrees, loss of
                    profit, business and/or goodwill and expenses (including
                    reasonable legal fees), based upon, relating to, or arising
                    out of a posting and displaying of such ratings, feedbacks
                    or reviews collected as per the specification of this
                    clause.
                  </li>
                </ol>
              </li>
              <li>
                Payments <br />
                Macrohealthplus shall, in no manner whatsoever, be responsible
                or liable for any payments to the Customer in relation to the
                Healthcare Services availed by the Customers.
              </li>
              <li>
                Independent Contractor <br />
                Nothing in this Agreement shall be construed to create a
                relationship of employer and employee, principal and agent,
                partnership, joint venture, or any relationship other than that
                of independent parties contracting with each other solely to
                carry out the provisions of this Agreement.
              </li>
              <li>
                Representations and Warranties of the Parties
                <ol type="i" className="ms-3">
                  <li>
                    Each Party represents and warrants to the other Party that:
                    <ol type="a" className="ms-3">
                      <li>
                        it has the right, power, and authority to enter into
                        this Agreement and perform the acts required of it
                        herein;
                      </li>
                      <li>
                        the execution of this Agreement by such Party, and the
                        performance by such Party of its obligations and duties
                        hereunder, do not and will not violate any agreement to
                        which such Party is a party or by which it is otherwise
                        bound;
                      </li>
                      <li>
                        it shall take utmost care in maintaining the
                        confidentiality of the information received from each
                        other and shall ensure that the same is not misused by
                        its staff or representatives in any manner whatsoever.
                        Further, the Parties shall be responsible for the breach
                        of confidentiality obligations of its staff or
                        representatives;
                      </li>
                      <li>
                        this Agreement will constitute a legal, valid and
                        binding obligation on each Party till its termination by
                        the Parties herein.
                      </li>
                    </ol>
                  </li>
                  <li>
                    The Customer represents and warrants:
                    <ol type="a" className="mx-3">
                      <li>
                        That it has and will maintain the necessary
                        licenses/approvals to carry on the hospital/clinic or
                        Healthcare Services as the case may be, and the carrying
                        on of the same is not in violation of the provision of
                        any law in the jurisdiction where it is present;
                      </li>
                      <li>
                        That the doctors and other staff of the Customer are
                        well-qualified and adequately trained to provide the
                        Healthcare Services.
                      </li>
                      <li>
                        That the Customer shall take all necessary precautions
                        to ensure the well-being of the Customer while the
                        Customer is in its care and custody.
                      </li>
                      <li>
                        That the Customer has adequate infrastructure,
                        facilities and staff to take care of the Customer while
                        the Customer is rendered the Healthcare Services by the
                        Customer.
                      </li>
                      <li>
                        That the Customer will delegate Administrators to access
                        and update information on the Website on behalf of the
                        Customer and to receive inquiries submitted on the
                        Website. The Customer claims responsibility and
                        liability for any information provided by the
                        Administrator or usage of the Website by the delegated
                        Administrator. Customer is solely responsible for
                        ensuring that the Administrator contact information is
                        accurate and for responding to Customer inquiries
                        forwarded by Macrohealthplus.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                Personal Data
                <ol type="i" className="ms-3">
                  <li>
                    Customer hereby acknowledges that it may receive Customers’
                    personal data including without limitation health-related
                    information. Customer represents and warrants that it will,
                    at all times during the use of the Services and thereafter,
                    comply with all applicable local and international laws that
                    may now or hereafter govern the collection, use,
                    transmission, processing, receipt, reporting, disclosure,
                    maintenance, and storage of personal data.
                  </li>
                  <li>
                    Macrohealthplus will treat personal data relating to
                    Customers in accordance with its Privacy Policy, which is
                    incorporated by reference herein.
                  </li>
                </ol>
              </li>
              <li>
                Disclaimer and Warranties <br />
                The Services, the Website, and information provided by Customers
                is provided "as is'' and "as available" basis without any
                warranty of any kind, expressed or implied, including but not
                limited to the implied warranties of merchantability, fitness
                for a particular purpose, and non-infringement. You are solely
                responsible for any and all acts or omissions taken or made in
                reliance on the system or the information in the system,
                including inaccurate or incomplete information. Macrohealthplus
                disclaims any and all liability for erroneous transmissions and
                loss of service resulting from communication failures by
                telecommunication service providers or the system.
                Macrohealthplus will use best efforts to ensure that the Website
                and Services are available but does not guarantee or warrant
                that they will be continuously available or free from
                interruption. You acknowledge that Customers have access to the
                system and are receiving our services. Such Customers have
                committed to comply with the applicable Terms & Conditions and
                with our policies and procedures concerning use of the system;
                however, the actions of such Customers may be beyond our
                control. Accordingly, we do not assume any liability for or
                relating to any impairment of the privacy, security,
                confidentiality, integrity, availability, or restricted use of
                any information on the system resulting from any Customer's
                actions or failures to act.
              </li>
              <li>
                Limitation of Liability <br />
                NOTWITHSTANDING THE OTHER TERMS OF THESE TERMS, Macrohealthplus
                SHALL NOT BE LIABLE FOR ANY INCIDENTAL, CONSEQUENTIAL, INDIRECT,
                SPECIAL, PUNITIVE DAMAGES OR LIABILITIES OF ANY KIND, OR FOR
                LOSS OF REVENUE, LOSS OF BUSINESS OR OTHER FINANCIAL LOSS
                ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, REGARDLESS
                OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING
                NEGLIGENCE) OR OTHERWISE, EVEN IF ANY REPRESENTATIVE OF A PARTY
                HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                Macrohealthplus’S AGGREGATE LIABILITY UNDER THIS AGREEMENT SHALL
                UNDER NO CIRCUMSTANCES EXCEED THE VALUE OF ANY FEES RECEIVED BY
                Macrohealthplus FROM YOU IN THE PRECEDING TWELVE MONTHS. YOU AND
                Macrohealthplus AGREE THAT THE FOREGOING LIMITATION OF LIABILITY
                IS AN AGREED ALLOCATION OF RISK BETWEEN YOU AND Macrohealthplus.
              </li>
              <li>
                Indemnification
                <ol type="i" className="ms-3">
                  <li>
                    {" "}
                    The Parties shall indemnify, hold harmless and defend each
                    other and its shareholders, officers, directors, employees
                    and affiliates from and against all and any charges, claims,
                    damages, costs, suits, judgments, decrees, losses, expenses
                    (including reasonable legal fees), penalties, demands
                    liabilities and causes of action, of any kind or stature
                    (“Claims”) asserted by third parties, based upon, relating
                    to, or arising out of a breach or claimed breach or failure
                    of any of the covenants, representations or warranties under
                    this Agreement.
                  </li>
                  <li>
                    It is expressly clarified that Macrohealthplus is not
                    obligated to indemnify, hold harmless or defend Customer
                    from Claims asserted by a Customer and/or his relatives or
                    guardians concerning the Healthcare Services provided by the
                    Customer.
                  </li>
                </ol>
              </li>
              <li>
                Confidential Information
                <ol type="i" className="ms-3">
                  <li>
                    The Parties agree to maintain and hold in strictest
                    confidence, confidential information with respect to the
                    affairs of each other. “Confidential Information” means any
                    non-public information that relates to the actual or
                    anticipated business or research and development of the
                    Parties including, but not limited to, this Agreement and
                    its terms thereof, the scheme of the Arrangement between the
                    Parties, the business model of Macrohealthplus and its
                    working methodology, Customer data, research, product plans
                    or other information regarding either Party’s products or
                    services and markets therefore, customer lists and
                    customers, finances or other business information. The
                    Parties shall however be permitted to disclose the
                    Confidential Information of the other Party to the Customer
                    and/or the Customer to the extent required for the purpose
                    of this Arrangement.
                  </li>
                  <li>
                    Customer also agrees to maintain adequate administrative,
                    technical, and physical safeguards to protect the
                    confidentiality of protected health information of the
                    Customers. Any breach of security or unlawful disclosure of
                    health information of a Customer shall be reported to
                    Macrohealthplus within two (2) days of the learning of such
                    breach or disclosure and may be a ground for termination of
                    this Agreement.
                  </li>
                  <li>
                    It is clarified that Macrohealthplus shall not be
                    responsible for any claim arising under this Clause due to
                    breach of Confidentiality by the Customer and Customer shall
                    be liable to indemnify Macrohealthplus for any losses
                    arising as a result of the same.
                  </li>
                  <li>
                    The provision on confidentiality under this Clause is
                    binding and shall survive the termination of this Agreement.
                  </li>
                </ol>
              </li>
              <li>
                Termination
                <ol type="i" className="ms-3">
                  <li>
                    Termination with cause <br />
                    Either Party may terminate this Agreement if:
                    <ol type="a" className="ms-3">
                      <li>
                        the other Party commits a breach of any material term or
                        condition of this Agreement and fails to cure such
                        breach within ten (15) days after receipt of written
                        notice of the same;
                      </li>
                      <li>
                        the other Party becomes the subject of a petition in
                        bankruptcy or any voluntary/involuntary proceeding
                        relating to insolvency, receivership, liquidation, or
                        composition for the benefit of creditors.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Termination without cause <br />
                    Either Party may terminate this Agreement at will, after
                    giving 15 days’ prior written notice of the same to the
                    other Party. Macrohealthplus may terminate this Agreement
                    immediately if it determines in its sole discretion that the
                    treatments provided by a Customer are inconsistent with
                    generally accepted practices or standards, regardless of
                    whether they are legal in the Customer’s jurisdiction.
                  </li>
                </ol>
              </li>
              <li>
                Dispute Resolution and Governing Law <br />
                <ol type="i" className="ms-3">
                  <li>
                    Any claim, controversy, difference or dispute between the
                    Parties shall be attempted to be resolved through mutual
                    negotiations within a period of 30 days from the date of
                    such dispute arising.
                  </li>
                  <li>
                    If the dispute is not resolved by negotiation within the
                    above referred 30 days, the Parties shall be bound to submit
                    all disputes and differences howsoever arising out of or in
                    connection with this Agreement to arbitration by sole
                    arbitrator appointed by mutual agreement of the Parties, or
                    in the absence of such an agreement within 15 days after a
                    Party first proposes an arbitrator, the dispute shall be
                    resolved by an arbitral tribunal composed of three
                    arbitrators, one of whom shall be appointed by each Party
                    within 15 days after expiry of the 15 days period. The third
                    arbitrator shall be selected by the mutual agreement of the
                    first two arbitrators within 15 days after the last of the
                    first two arbitrators has been appointed. The place of
                    arbitration shall be Australia and Bangladesh. <br />
                    The language to be used in the arbitration proceedings shall
                    be English. The award of the arbitration proceedings will be
                    final and binding on all Parties to the Agreement. <br />
                    This Agreement is governed by and is to be construed in
                    accordance with the laws of England and Wales. The courts of
                    England and Wales shall have exclusive jurisdiction with
                    respect to all actions arising out of or in relation to this
                    Agreement, its performance or breach thereof.
                  </li>
                </ol>
              </li>
              <li>
                Copyright Notice <br />
                Macrohealthplus respects the intellectual property rights of
                others and expects the users of its services to do the same. If
                you believe that any content appearing on the Website has been
                copied in a way that constitutes copyright infringement, please
                forward the following information to Macrohealthplus. <br />
                To file a copyright infringement notice, you will need to send a
                notice that includes the following to info@macrohealthplus.org:
                <ul className="ms-3">
                  <li>
                    Your name, address, telephone number, and email address;
                  </li>
                  <li>
                    A description of the copyrighted work that you claim has
                    been infringed;
                  </li>
                  <li>
                    The exact URL or a description of where the alleged
                    infringing material is located;
                  </li>
                  <li>
                    A statement by you that you have a good faith belief that
                    the disputed use is not authorized by the copyright owner,
                    its agent, or the law;
                  </li>
                  <li>
                    An electronic or physical signature of the person authorized
                    to act on behalf of the owner of the copyright interest; and
                  </li>
                  <li>
                    A statement by you, under penalty of perjury, that the above
                    information in your notice is accurate and that you are the
                    copyright owner or authorized to act on the copyright
                    owner's behalf.
                  </li>
                </ul>
              </li>
              <li>
                General Provisions <br />
                <ol type="i" className="ms-3">
                  <li>
                    Survival: Termination of this Agreement will not terminate,
                    affect, or impair any rights, obligations, or liabilities of
                    either Party, which may accrue prior to such termination or
                    those provisions which by their sense and context should
                    survive any termination or expiration of this Agreement.
                  </li>
                  <li>
                    Non-Solicit: During the Term of this Agreement, Either Party
                    will not, without the Other Party’s prior written consent,
                    directly or indirectly, solicit or encourage any employee or
                    contractor of the Other Party or its affiliates to terminate
                    employment with, or cease providing services to, the Other
                    Party or its affiliates.
                  </li>
                  <li>
                    Assignment: This Agreement shall not be assigned, in whole
                    or in part, by the Customer, without the prior written
                    consent of Macrohealthplus. However, Macrohealthplus may
                    assign this Agreement, at its discretion, to such
                    affiliate/s as it may deem fit without prior notification to
                    the Customer. Further, this Agreement shall continue and
                    will remain unaffected by reason of change in control of
                    Macrohealthplus. This Agreement will be binding upon and
                    will inure [7][8] to the benefit of the Parties and their
                    representatives and respective successors and permitted
                    assigns. <br />
                    For the purposes of this Agreement, “change in control”
                    shall mean the direct or indirect change in the ownership,
                    operation or control of Macrohealthplus whether resulting
                    from merger, acquisition, share transfer / subscription,
                    consolidation or otherwise.
                  </li>
                  <li>
                    Waivers: No Party will be deemed to have waived any of its
                    rights, powers or remedies hereunder unless that Party
                    approves such waiver in writing.
                  </li>
                  <li>
                    Entire Agreement: This Agreement, including any and all
                    attachments/Exhibits hereto, constitutes the entire
                    agreement between the Parties relating to the subject matter
                    hereof, and all prior negotiations and understandings,
                    whether oral or written, are superseded hereby.
                  </li>
                  <li>
                    Severability: Each section of this Agreement is severable.
                    If any provision is held unenforceable by a court of
                    competent jurisdiction, such ruling shall not impair any
                    other provision that remains intelligible and all other
                    provisions shall continue in effect.
                  </li>
                  <li>
                    Force Majeure: Macrohealthplus shall not be liable for any
                    delay or failure in performance due to acts of God,
                    earthquake, labor disputes, changes in law, regulation or
                    government policy, riots, war, fire, flood, insurrection,
                    sabotage, embargo, epidemics, pandemics, acts or omissions
                    of vendors or suppliers, transportation difficulties,
                    unavailability of interruption or delay in
                    telecommunications or third party services (including DNS
                    propagation), failure of third party software or hardware or
                    inability to obtain raw materials, supplies, or power used
                    in or equipment needed. Macrohealthplus is not responsible
                    for server downtime under any circumstances.
                  </li>
                  <li>
                    Compliance with Laws: Each Party shall comply with all
                    applicable laws and regulations in performing its
                    obligations under this Agreement and shall be individually
                    liable for any and all non-compliance of any
                    regulation/law/guideline/order etc. on its part.
                  </li>
                  <li>
                    Notices: All notices required or desired to be given
                    hereunder will be in writing and can either be delivered
                    personally or be sent by electronic communication (with a
                    copy by ordinary mail) or by registered or certified mail.
                    If sent by electronic communication, notices will be deemed
                    to have been given on the same day on which the notice is
                    sent. If notice is sent by registered or certified mail or
                    is delivered personally, it shall be deemed to have been
                    given on the day on which the notice is received or when
                    delivery is refused.
                  </li>
                  <li>
                    Return or refund request must be raised within 7 to 10 days
                    for any product and services, or from the date of delivery
                    of services.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      )}
      {/* <div className="container mb-4 mt-5">
                <h1 className="text-center mb-3">This features are under construction</h1>
                <div className="text-center">
                    <img src={under_construction} alt="image" style={{ height: "360px", width: "60%" }} />
                </div>
            </div> */}
    </div>
  );
};

export default Terms;
