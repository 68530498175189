import React, { useState } from 'react';
import Arrow__img from '../../images/Arrow__img.png';
import upload_medical_doc__img from '../../images/upload_medical_doc__img.png';
import medical_history__img from '../../images/medical_history__img.png';
import vital_records__img from '../../images/vital_records__img.png';
import register__img from '../../images/register__img.png';

const HowItWorkingProcessTeleHealth = (props) => {
    // console.log(props.arrData ,"arrData222");
    // console.log(props.arrDataTeleHealth, "arrDataTeleHealth222");

    return (
        props.arrDataTeleHealth.length > 0 &&
        <div key={props.id} className="margin__top">
            <div className="how__it__process__cont">
                <div className="how__it__process__div">
                    <div className="how__it__process  pt-4">
                        <img src={props.arrDataTeleHealth[0].images} className="card-img-top" alt="images" />
                    </div>
                    <div className="card-body">
                        <h6 className="it__process__title">{props.arrDataTeleHealth[0].title}</h6>
                    </div>
                </div>
                <div className="how__it__process__arrow">
                    <div className="it__process__img  pt-4">
                        <img src={Arrow__img} className="card-img-top arrow__img" alt="images" />
                    </div>
                </div>
                <div className="how__it__process__div">
                    <div className="how__it__process  pt-4">
                        <img src={props.arrDataTeleHealth[1].images} className="card-img-top" alt="images" />
                    </div>
                    <div className="card-body">
                        <h6 className="it__process__title">{props.arrDataTeleHealth[1].title}</h6>
                    </div>
                </div>
                <div className="how__it__process__arrow">
                    <div className="it__process__img  pt-4">
                        <img src={Arrow__img} className="card-img-top arrow__img" alt="images" />
                    </div>
                </div>
                <div className="how__it__process__div">
                    <div className="how__it__process  pt-4">
                        <img src={props.arrDataTeleHealth[2].images} className="card-img-top" alt="images" />
                    </div>
                    <div className="card-body">
                        <h6 className="it__process__title">{props.arrDataTeleHealth[2].title}</h6>
                    </div>
                </div>
                <div className="how__it__process__arrow">
                    <div className="it__process__img  pt-4">
                        <img src={Arrow__img} className="card-img-top arrow__img" alt="images" />
                    </div>
                </div>
                <div className="how__it__process__div">
                    <div className="how__it__process  pt-4">
                        <img src={props.arrDataTeleHealth[3].images} className="card-img-top" alt="images" />
                    </div>
                    <div className="card-body">
                        <h6 className="it__process__title">{props.arrDataTeleHealth[3].title}</h6>
                    </div>
                </div>
                <div className="how__it__process__arrow">
                    <div className="it__process__img  pt-4">
                        <img src={Arrow__img} className="card-img-top arrow__img" alt="images" />
                    </div>
                </div>
                <div className="how__it__process__div">
                    <div className="how__it__process  pt-4">
                        <img src={props.arrDataTeleHealth[4].images} className="card-img-top" alt="images" />
                    </div>
                    <div className="card-body">
                        <h6 className="it__process__title">{props.arrDataTeleHealth[4].title}</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorkingProcessTeleHealth;