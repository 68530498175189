import axios from 'axios';

const baseURL = `${
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_MODE_URL
    : process.env.REACT_APP_PRO_MODE_URL
}`;

export default axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

