import { useEffect } from 'react';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import NotFound from './components/notFound/NotFound';
import Contact from './components/contacts/Contact';
import Layouts from './components/layouts/Layouts';
import Home from './components/home/Home';
import About from './components/about/About';
import Login from './components/clientLogin/Login';

// import Home from './Home';
import DigiPatient from './components/solutions/DigiPatient';
import GreatDoc from './components/solutions/GreatDoc';
import GreatLab from './components/solutions/GreatLab';
import GreatPharma from './components/solutions/GreatPharma';
import Telehealth from './components/solutions/Telehealth';
import EPharmacy from './components/solutions/EPharmacy';
import Marketplace from './components/solutions/Marketplace';
import LabAgent from './components/solutions/LabAgent';
import EducationalGuides from './components/resources/EducationalGuides';
import ResearchReports from './components/resources/ResearchReports';
import NewsAndViews from './components/resources/NewsAndViews';
import HelpCentre from './components/resources/HelpCentre';
import Webinars from './components/resources/Webinars';
import GeneralPractice from './components/specialities/GeneralPractice';
import Cardiologist from './components/specialities/Cardiologist';
import ChestPhysician from './components/specialities/ChestPhysician';
import Gastroenterologist from './components/specialities/Gastroenterologist';
import Neurologist from './components/specialities/Neurologist';
import Urologist from './components/specialities/Urologist';
import Ophthalmologist from './components/specialities/Ophthalmologist';
import ENTSpecialist from './components/specialities/ENTSpecialist';
import Dermatologist from './components/specialities/Dermatologist';
import Rheumatologist from './components/specialities/Rheumatologist';
import PsychiatristPsychologist from './components/specialities/PsychiatristPsychologist';
import NeuroSurgeon from './components/specialities/NeuroSurgeon';
import ObstetricsGynaecologist from './components/specialities/ObstetricsGynaecologist';
import Paediatricians from './components/specialities/Paediatricians';
import OrthopaedicSurgeon from './components/specialities/OrthopaedicSurgeon';
import GeneralSurgeon from './components/specialities/GeneralSurgeon';
import WhoWeAre from './components/about/WhoWeAre';
import UniqueFeatures from './components/about/UniqueFeatures';
import Pricing from './components/pricing/Pricing';
import RequestDemo from './components/requestDemo/RequestDemo';
import WebinarsCardDetails from './components/resources/WebinarsCardDetails';
import Solutions from './components/solutions/Solutions';
import WhyChooseUs from './components/about/WhyChooseUs';
import DataSafetySecurity from './components/about/DataSafetySecurity';
import ClientLogin from './components/clientLogin/ClientLogin';
import Terms from './components/footer/footerPages/Terms';
import Privacy from './components/footer/footerPages/Privacy';
import Security from './components/footer/footerPages/Security';
import DisclaimerCookie from './components/footer/footerPages/DisclaimerCookie';
import OurAdvisoryMembersDetails from './components/about/OurAdvisoryMembersDetails';
import EducationalGuidesDynamic from './components/resources/EducationalGuidesDynamic';
import ResearchReportsDynamic from './components/resources/ResearchReportsDynamic';
import Resource from './components/resources/Resource';
import MedicalCardDetails from './components/resources/MedicalCardDetails';
import NewsAndViewsCardDetails from './components/resources/NewsAndViewsCardDetails';
import RefundReturnPolicy from './components/footer/footerPages/RefundReturnPolicy';

function App() {
  //scroll top onClick in the new page
  const location = useLocation();
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }, [location]);

  return (
    <Routes>
      <Route path='/' element={<Layouts />}>
        <Route index element={<Home />}></Route>
        {/* <Route path="solutions/:id" element={<Solutions />}></Route> */}

        <Route path='digi-patient' element={<DigiPatient />}></Route>

        <Route path='great-doc' element={<GreatDoc />}></Route>
        <Route path='great-lab' element={<GreatLab />}></Route>
        <Route path='great-pharma' element={<GreatPharma />}></Route>
        <Route path='telehealth' element={<Telehealth />}></Route>
        <Route path='e-commerce-medicine' element={<EPharmacy />}></Route>
        <Route path='marketplace' element={<Marketplace />}></Route>
        <Route path='lab-agent' element={<LabAgent />}></Route>

        <Route path='general-practice' element={<GeneralPractice />}></Route>
        <Route path='cardiologist' element={<Cardiologist />}></Route>
        <Route path='chest-physician' element={<ChestPhysician />}></Route>
        <Route
          path='gastroenterologist'
          element={<Gastroenterologist />}
        ></Route>
        <Route path='neurologist' element={<Neurologist />}></Route>
        <Route path='urologist' element={<Urologist />}></Route>
        <Route path='ophthalmologist' element={<Ophthalmologist />}></Route>
        <Route path='ent-specialist' element={<ENTSpecialist />}></Route>
        <Route path='dermatologist' element={<Dermatologist />}></Route>
        <Route path='rheumatologist' element={<Rheumatologist />}></Route>
        <Route
          path='psychiatrist-and-psychologist'
          element={<PsychiatristPsychologist />}
        ></Route>
        <Route path='neuro-surgeon' element={<NeuroSurgeon />}></Route>
        <Route
          path='obstetrics-and-gynaecologist'
          element={<ObstetricsGynaecologist />}
        ></Route>
        <Route path='paediatricians' element={<Paediatricians />}></Route>
        <Route
          path='orthopaedic-surgeon'
          element={<OrthopaedicSurgeon />}
        ></Route>
        <Route path='general-surgeon' element={<GeneralSurgeon />}></Route>
        <Route
          path='refund-return-policy'
          element={<RefundReturnPolicy />}
        ></Route>

        <Route
          path='educational-guides'
          element={<EducationalGuides />}
        ></Route>
        {/* <Route
          path='educational-guides/:id'
          element={<EducationalGuidesDynamic />}
        ></Route> */}
        <Route path='/resource/:id' element={<Resource />} />
        <Route path='news-and-views' element={<NewsAndViews />}></Route>
        <Route path='help-centre' element={<HelpCentre />}></Route>
        <Route path='webinars' element={<Webinars />}></Route>
        <Route path='/webinars/:id' element={<WebinarsCardDetails />}></Route>
        <Route
          path='/news-and-views/:id'
          element={<NewsAndViewsCardDetails />}
        ></Route>
        <Route path='research-reports' element={<ResearchReports />}></Route>
        <Route
          path='research-reports/:id'
          element={<MedicalCardDetails />}
        ></Route>
        <Route
          path='research-reports/:subCategoryId'
          element={<ResearchReportsDynamic />}
        ></Route>

        <Route path='pricing' element={<Pricing />}></Route>

        <Route path='who-we-are' element={<WhoWeAre />}></Route>
        <Route path='unique-features' element={<UniqueFeatures />}></Route>
        <Route path='why-choose-us' element={<WhyChooseUs />}></Route>
        <Route
          path='our-advisory-members-details/:id'
          element={<OurAdvisoryMembersDetails />}
        ></Route>

        <Route path='contact' element={<Contact />}></Route>

        <Route path='request-demo' element={<RequestDemo />}></Route>
        <Route path='client-login' element={<ClientLogin />}></Route>

        <Route
          path='data-safety-and-security'
          element={<DataSafetySecurity />}
        ></Route>

        <Route path='terms' element={<Terms />}></Route>
        <Route path='privacy' element={<Privacy />}></Route>
        <Route path='security' element={<Security />}></Route>
        <Route path='cookie-disclaimer' element={<DisclaimerCookie />}></Route>
        <Route path='resources' element={<About />}></Route>

        <Route path='login' element={<Login />}></Route>

        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>

    // <Routes>
    //   <Route path="/" element={<Home />}> </Route>
    // </Routes>
  );
}

export default App;
