import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import http from '../../http';
import NewsLetter from '../home/NewsLetter';
const MedicalCardDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await http.get(`medicalEducation/get/${id}`);

        if (response.status === 200) {
          setData(response.data?.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getData();
    return () => {
      // cleanup
    };
  }, []);
  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <div className='container mt-4'>
          <div className='row col-12'>
            <h3
              style={{
                fontSize: '24px',
                fontWeight: '500',
                textAlign: 'center',
                marginBottom: '20px',
                letterSpacing: '-0.1px',
              }}
            >
              {data?.title}
            </h3>
          </div>
          <div className='row g-3 pt-2'>
            <div className='col-md-12'>
              <div className='webinars__img'>
                <iframe
                  src={data?.videoUrl}
                  frameborder='0'
                  allow='autoplay; fullscreen; picture-in-picture'
                  style={{ width: '100%', height: '400px' }}
                  title='Q&amp;A - Gary Leong - Metabolic Syndrome.mp4'
                ></iframe>
              </div>
              <blockquote
                style={{
                  fontSize: '14px',
                  fontWeight: '400',
                }}
              >
                {data?.description}
              </blockquote>
            </div>
          </div>
        </div>
      )}
      <div className='container'>
        <NewsLetter />
      </div>
    </>
  );
};

const Loading = () => (
  <div
    style={{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div class='loader'></div>
  </div>
);
export default MedicalCardDetails;
