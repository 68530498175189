
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HowItWorkingProcessTeleHealth from './HowItWorkingProcessTeleHealth';
import AccrodianPRMTelehealth from './accrodianForDigi/AccrodianPRMTelehealth';
import NewsLetter from '../home/NewsLetter';

import ehr_img from '../../images/ehr_img.png';
import mpm_img from '../../images/mpm_img.png';
import youtube_arrow_icons from '../../images/youtube_arrow_icons.png';
import TeleHealth from '../../images/TeleHealth.jpg';
import new_consultent_img from '../../images/new_consultent_img.png';
import text_message_img2 from '../../images/text_message_img2.png';
import macrohealthplus_hello_img from '../../images/macrohealthplus_hello_img.png';
import doct_patient_img from '../../images/doct_patient_img.png';
import Setup_Your_Timing__img from '../../images/Setup_Your_Timing__img.png';
import Send_ConsultationSummary__img from '../../images/Send_ConsultationSummary__img.png';
import DefinePayment__img from '../../images/DefinePayment__img.png';
import Manage_VideoAppointment__img from '../../images/Manage_VideoAppointment__img.png';
import Send_Consultation__Summary__img from '../../images/Send_Consultation__Summary__img.png';


const Telehealth = () => {
    const howToProcessTelehealth = [
        { id: 1, images: Setup_Your_Timing__img, title: "Setup Your Timing" },
        { id: 2, images: DefinePayment__img, title: "Define Payment" },
        { id: 3, images: Manage_VideoAppointment__img, title: "Manage Video Appointment" },
        { id: 4, images: Send_ConsultationSummary__img, title: "Take notes in GreatDoc" },
        { id: 5, images: Send_Consultation__Summary__img, title: "Order investigations in GreatDoc" },
    ];
    // console.log("arrData", howToProcessTelehealth);

    const [specialtySpecific, setSpecialtySpecific] = useState([
        {
            id: 1,
            title: "Time Saved",
            no_of_percentance: "90%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 2,
            title: "Increase in the Productivity",
            no_of_percentance: "25%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 3,
            title: "Smart Workflow",
            no_of_percentance: "93%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
    ])
    const [telemedicineVideoConsultations, setTelemedicineVideoConsultations] = useState([
        {
            id: 1,
            title: "Doctors Assistants enters patient's mobile number in a secure web page.",
            images: new_consultent_img,
        },
        {
            id: 2,
            title: "The patient receives and SMS on their mobile phone with a website link.",
            images: text_message_img2,
        },
        {
            id: 3,
            title: "The patient clicks on the link and is taken to a secure website.",
            images: macrohealthplus_hello_img,
        },
        {
            id: 4,
            title: "Doctor can then video call with the patient.",
            images: doct_patient_img,
        },
    ])

    return (
        <>
            <div className="bg__body">
                <div className="specialities__banner">
                    <div className="specialities__banner__img">
                        <img src={TeleHealth} alt="image" />
                    </div>
                    <div className="specialities__banner__text__h">
                        <h3 className="specialities__banner__title">Telehealth</h3>
                        <div className="specialities__banner__text">
                            <p className="solution__banner__description">
                                Secure technology that makes Telehealth easy for you and your patients
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row g-3  pt-5 mb-2">
                        <div className="col-md-6">
                            <div className="ehr">
                                <h3 className="mb-2">
                                    <span className="header__text">Telehealth</span>
                                </h3>
                                <p className="header__description pe-md-5">
                                    <strong className="inspiration__span"> MacroHealthPlus</strong> is a secure, cloud-based telemedicine
                                    software that allows you to connect with patients via live video chat from any computer or mobile device.
                                    It features a simple interface, you can create an experience that is unique to your practice, and it
                                    integrates with most electronic health record systems.
                                </p>
                                <div className="pt-3">
                                    <Link to="/request-demo" type="text" className="btn btn__ehr">Free Demo</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ehr__img">
                                <img src={ehr_img} className="img-fluid" alt="image" border="0" />
                            </div>
                        </div>
                    </div>
                    <div className="row g-3  mpm__margin">
                        <div className="col-md-6 order-sm-first order-last">
                            <div className="mpm__img">
                                <img src={mpm_img} className="img-fluid" alt="image" border="0" />
                                <div className="mpm__overlay__text">
                                    <div className="mpm__youtube__icons">
                                        <img src={youtube_arrow_icons} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mpm">
                                <div className="mpm">
                                    <h3 className="text-center mb-2">
                                        <span className="header__text">How <strong className="inspiration__span">Telehealth</strong> System Works?</span>
                                    </h3>
                                    <p className="header__description text-center">
                                        Our system is Secured, User friendly and Ease to use.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <HowItWorkingProcessTeleHealth arrDataTeleHealth={howToProcessTelehealth} />
                        </div>
                    </div>

                    <div className="row margin__top">
                        <div className="col-sm-12">
                            <h3 className="text-center mb-2">
                                <span className="header__text">Telemedicine with Video Consultations in your Virtual Practice</span>
                            </h3>
                            <div className="macro">
                                <p className="header__description solution__macro">
                                    Easy follow-up care, health monitoring and better health outcomes with greater collaboration
                                </p>
                                <div className="telehealth__div">
                                    <div className="row g-3 pt-4 tele__medi__mb">
                                        <div className="col-md-12">
                                            <h2 className="text-center"><span className="header__text">How it's Work ?</span></h2>
                                        </div>
                                    </div>
                                    {
                                        telemedicineVideoConsultations.length > 0 && telemedicineVideoConsultations.map((item, id) => {
                                            return (
                                                <div key={id} className="row g-3 mb-3">
                                                    <div className="col-md-5">
                                                        <p className="telehealth__description  text-center">{item.title}</p>
                                                    </div>
                                                    <div className="col-md-7">
                                                        <img src={item.images} className="img-fluid" alt="images" border="0" loading='lazy'/>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <div className="container margin__top">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3 className="text-center mb-2">
                                <span className="header__text">Patient Records Management with link <strong className="inspiration__span"> Telehealth</strong></span>
                            </h3>
                            <div className="macro">
                                <p className="header__description solution__macro">
                                    Streamline your workflows and reach new levels of efficiency and professionalism.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-3">
                        <div className="col-md-12">
                            <AccrodianPRMTelehealth />
                        </div>
                    </div>
                </div>
            </div>

            <div className="speciality__bg">
                <div className="speciality">
                    <h3 className="header__speciality__text">Specialty-Specific All-in-One Solution</h3>
                    <div className="container speciality__specific">
                        <div className="row g-3">
                            {
                                specialtySpecific.length > 0 && specialtySpecific.map((item, id) => {
                                    return (
                                        <div key={id} className="col-md-4">
                                            <div className="specialty__specific__card">
                                                <div className="card text-center">
                                                    <div className="card-body">
                                                        <h5 className="specialty__specific__card__title">{item.title}</h5>
                                                        <h3 className="specialty__specific__card__title__percent">{item.no_of_percentance}</h3>
                                                        {/* <p className="specialty__specific__card__discription">{item.description}</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <NewsLetter />
            </div>
        </>
    );
};

export default Telehealth;