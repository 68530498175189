
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NewsLetter from '../home/NewsLetter';

import General_practice from '../../images/General_practice.png';
import HealthWorkflow8 from '../../images/option_1/HealthWorkflow8.png';
import Paperless3 from '../../images/option_1/Paperless3.png';
import EasyConfiguration from '../../images/option_1/EasyConfiguration.png';
import DataSecurity1 from '../../images/option_1/DataSecurity1.png';
import ClientServer1 from '../../images/option_1/ClientServer1.png';
import UserFriendly from '../../images/option_1/UserFriendly.png';
import AcceptableTechnology4 from '../../images/option_1/AcceptableTechnology4.png';
import Electronic_health_record4 from '../../images/option_1/Electronic_health_record4.png';
import EasyAccess2 from '../../images/option_1/EasyAccess2.png';
import General_img_banner from '../../images/General_img_banner.jpg';
import Appointment_im from '../../images/Appointment_im.jpg';
import General__img from '../../images/General__img.PNG';
import GeneralRxFull from '../../images/GeneralRxFull.PNG';
import WritingPrescription from '../../images/WritingPrescription.jpg';
import Mims__img from '../../images/Mims__img.jpg';
import MIMSDrugInformation from '../../images/MIMSDrugInformation.png';
import DrugInteraction from '../../images/DrugInteraction.png';
import Prescription from '../../images/Prescription.jpg';

const GeneralPractice = () => {
    const generalPractice = [
        { id: 1, images: Appointment_im, name: "This is for active one" },
        { id: 2, images: General__img, name: "This is for active two" },
        { id: 3, images: GeneralRxFull, name: "This is for active three" },
        { id: 4, images: WritingPrescription, name: "This is for active four" },
        { id: 5, images: Mims__img, name: "This is for active five" },
        { id: 6, images: DrugInteraction, name: "This is for active six" },
        { id: 7, images: MIMSDrugInformation, name: "This is for active seven" },
        { id: 8, images: Prescription, name: "This is for active eight" },
        { id: 9, images: Prescription, name: "This is for active nine" },
    ];
    const [appointmentManagement, setAppointmentManagement] = useState([
        {
            title: "General Practice Services specific workflow", images: HealthWorkflow8, id: 1
        },
        {
            title: "Complete Electronic Health Reacords for General Practice Services", images: Electronic_health_record4, id: 2
        },
        {
            title: "Paperless", images: Paperless3, id: 3
        },
        {
            title: "Easy configuration ", images: EasyConfiguration, id: 4
        },
        {
            title: "Data security ", images: DataSecurity1, id: 5
        },
        {
            title: "Client-server or cloud-based ", images: ClientServer1, id: 6
        },
        {
            title: "User friendly", images: UserFriendly, id: 7
        },
        {
            title: "Quickly access ", images: EasyAccess2, id: 8
        },
        {
            title: "Adaptable technology", images: AcceptableTechnology4, id: 9
        },

    ]);
    const [specialtySpecific, setSpecialtySpecific] = useState([
        {
            id: 1,
            title: "Time Saved",
            no_of_percentance: "90%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 2,
            title: "Increase in the Productivity",
            no_of_percentance: "25%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 3,
            title: "Smart Workflow",
            no_of_percentance: "93%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
    ]);

    return (
        <>
            <div className="bg__body">
                <div className="specialities__banner">
                    <div className="specialities__banner__img">
                        <img src={General_img_banner} alt="image" />
                    </div>
                    <div className="specialities__banner__text__h">
                        <h3 className="specialities__banner__title">General Practice</h3>
                        <div className="specialities__banner__text">
                            <p className="specialities__banner__description">
                                Innovative Practice Management Software for General Practice Services
                            </p>
                            <div className="text-center">
                                <Link to="/request-demo" type="text" className="btn__ehr">Request For a Demo</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container margin__top">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="macro">
                                <p className="header__description__gp solution__macro">
                                    General Practice-specific <strong className="inspiration__span">Electronic Health Reacords</strong> allows the practitioner to quickly understand patient history, communicate better with patients and help staff get more done in less time.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row g-3  margin__top">
                        <div className="col-md-5">
                            <div className="gp">
                                <p className="h__description pe-md-5">
                                    Extensive, multilayer <strong className="inspiration__span">Electronic Health Reacords</strong>  and practice management solutions tailored to your practice workflow and to meet your multidisciplinary needs.
                                </p>
                                <p className="h__description pe-md-5">
                                    Streamlining communication and access to information, they are built to be used in mobile phones or via desktop. Design for black outs and electricity failure or natural calamities in any time and from anywhere.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="ehr__img1">
                                <img src={General_practice} className="img-fluid" alt="image" border="0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="margin__top">
                <div className="book__app__bg">
                    <div className="book__app">
                        <h5 className="book__app__text">MacroHealthPlus offers a fully integrated  solutions designed for General Practice Services practices to streamline administration functions, pre-loaded forms and templates with clinical care, and revenue cycle management in one single place.</h5>
                    </div>
                </div>
            </div>

            <div className="bg__body pt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="margin__top">
                                <h3 className="text-center mb-2">
                                    <span className="header__text">How <strong className="inspiration__span">General Practice</strong> System Works?</span>
                                </h3>
                                <p className="header__description text-center">
                                    Our system is Secured, User friendly and Ease to use.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            {/* <HorizontalStepperSpecialist arr={generalPractice} /> */}
                        </div>
                    </div>

                    <div className="row margin__top">
                        <div className="col-md-12">
                            <div className="key__feature">
                                <h3 className="text-center mb-2">
                                    <span className="header__text">Key Features for General Practice Services</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3 gy-4 gx-sm-5 pt-4 d-flex justify-content-center">
                        {
                            appointmentManagement.length > 0 && appointmentManagement.map((item, id) => {
                                return (
                                    <div key={id} className="col-md-3">
                                        <div className="general__practice__card">
                                            <div className="card text-center">
                                                <div className="d-flex justify-content-center pt-4">
                                                    <div className="bg__card__img">
                                                        <img src={item.images} className="card-img-top" alt="images" />
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <h6 className="header__gp__card__subtitle">{item.title}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className="row margin__top">
                        <div className="col-sm-12">
                            <h3 className="text-center mb-2">
                                <span className="header__text">Patient Records Management</span>
                            </h3>
                            <div className="macro">
                                <p className="header__description solution__macro">
                                    Streamline your workflows and reach new levels of efficiency and professionalism.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row g-3 pt-3">
                        <div className="col-md-12">
                            <section className="faq-section__lab">
                                <div className="container" data-aos="fade-up">
                                    <div className="row">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="accordion accordion-flush" id="faqlist1">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                                                            Perfect Practice Management Software for General Practice Services
                                                        </button>
                                                    </h2>
                                                    <div id="faq-content-1" className="accordion-collapse collapse show" data-bs-parent="#faqlist1">
                                                        <div className="accordion-body">
                                                            <p className="header__description">
                                                                To provide excellent General Practice services in the private sector, it is vital that you have a practice management platform that helps
                                                                you understand and meet your patients’ needs. <strong className="inspiration__span">MacroHealthPlus</strong> focuses on your clinic as a business, looking at patient acquisition,
                                                                service provision and patient retention.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                                                            Looking for ideal software for your general practice?
                                                        </button>
                                                    </h2>
                                                    <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                                        <div className="accordion-body">
                                                            <p className="header__description">We make your point-of-care tasks efficient so that you can focus on effective treatment for your patients. Primary Clinic Medical software presents a familiar, easy to use interface so you can start practising within minutes. You can also tailor the look of your screen to present only the minimum, essential features readily needed for your patient treatments</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                                            A comprehensive medical and practice management software for general practitioners
                                                        </button>
                                                    </h2>
                                                    <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                                        <div className="accordion-body">
                                                            <p className="header__description">Our Medical software can also be streamlined for more specialised uses. The software can be configured for Doctors specialising in long appointment consultations with chronic disease patients.</p>
                                                            <p className="header__description">Our purpose-built care plan templates are designed to facilitate these General Practice treatments by pre-filling sections and leaving only the necessary portions for General Practices to complete. These both maximise General Practice efficiency and effectiveness.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <div className="speciality__bg">
                <div className="speciality">
                    <h3 className="header__speciality__text">Specialty-Specific All-in-One Solution</h3>
                    <div className="container speciality__specific">
                        <div className="row g-3">
                            {
                                specialtySpecific.length > 0 && specialtySpecific.map((item, id) => {
                                    return (
                                        <div key={id} className="col-md-4">
                                            <div className="specialty__specific__card">
                                                <div className="card text-center">
                                                    <div className="card-body">
                                                        <h5 className="specialty__specific__card__title">{item.title}</h5>
                                                        <h3 className="specialty__specific__card__title__percent">{item.no_of_percentance}</h3>
                                                        {/* <p className="specialty__specific__card__discription">{item.description}</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <NewsLetter />
            </div>
        </>
    );
};

export default GeneralPractice;