import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import world__map from '../../images/world__map.png';


const Contact = () => {

    const [inputField, setInputField] = useState({
        first_name: '',
        last_name: '',
        email_address: '',
        contact_no: '',
        message: '',
        checkbox_message: false,
    });

    const inputsHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value })
    }

    const submitForm = (e) => {
        e.preventDefault();
        // alert(inputField.first_name);
        console.log("submittedData", inputField);
        setInputField({
            first_name: '',
            last_name: '',
            email_address: '',
            contact_no: '',
            message: '',
            checkbox_message: false,
        })
    }




    return (
        <div className="container">
            <div className="row g-3">
                <div className="col-12">
                    <div className="contact__world">
                        <div className="contact__world__map">
                            <img src={world__map} className="img-fluid" alt="world__map" border="0" />
                        </div>
                        <div className="row g-2 contact__world__overlay__div">
                            <div className="col-md-4 pe-md-4">
                                <div className="contact__address1">
                                    <h3 className="address__title">Bangladesh Office</h3>
                                    <div className="col-12">
                                        <div className="row g-1 contact__office__address">
                                            <div className="col-12">
                                                <span className="contact__footer">
                                                    <i className="fs-6 p-1 addr__icons__color fa-solid fa-phone"></i>
                                                    <p className="px-2 addr__text">Contact:</p>
                                                    <p className="address__description">+880-1855-777766</p>
                                                </span>
                                            </div>
                                            <div className="col-12">
                                                <span className="d-flex align-items-start" onClick={() => { window.location.href = 'mailto:info@macrohealthplus.org'; }}>
                                                    <i className="fs-6 p-1 addr__icons__color  fas fa-envelope"></i>
                                                    <p className="px-2 addr__text">E-mail:</p>
                                                    <p className="address__description">info@macrohealthplus.org</p>
                                                </span>
                                            </div>
                                            <div className="col-12">
                                                <span className="d-flex align-items-start ">
                                                    <i className="fs-6 p-1 addr__icons__color  fas fa-map-marker-alt"></i>
                                                    <p className="px-2 addr__text">Address:</p>
                                                    <p className="address__description ">House # 35, Road #  East Rampura,<br /> Dhaka, Bangladesh.</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4"></div>
                            <div className="col-md-4 ps-md-5">
                                <div className="contact__address2">
                                    <h3 className="address__title">Australia Office</h3>
                                    <div className="col-12">
                                        <div className="row g-1 contact__office__address">
                                            <div className="col-12">
                                                <span className="contact__footer">
                                                    <i className="fs-6 p-1 addr__icons__color fa-solid fa-phone"></i>
                                                    <p className="px-2 addr__text">Contact:</p>
                                                    <p className="address__description">+61-299-431072</p>
                                                </span>
                                            </div>
                                            <div className="col-12">
                                                <span className="d-flex align-items-start" onClick={() => { window.location.href = 'mailto:info@macrohealthplus.org'; }}>
                                                    <i className="fs-6 p-1 addr__icons__color  fas fa-envelope"></i>
                                                    <p className="px-2 addr__text">E-mail:</p>
                                                    <p className="address__description">info@macrohealthplus.org</p>
                                                </span>
                                            </div>
                                            <div className="col-12">
                                                <span className="d-flex align-items-start ">
                                                    <i className="fs-6 p-1 addr__icons__color  fas fa-map-marker-alt"></i>
                                                    <p className="px-2 addr__text">Address:</p>
                                                    <p className="address__description ">9 Westbrook Avenue Wahroonga,<br />NSW 2076, Australia.</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row g-3">
                <div className="col-md-12">
                    <h3 className="header__link mb-2 text-center">Contact Us</h3>
                    <p className="contact_us_description text-center">Let’s Talk And Transform Your Healthcare System!</p>
                </div>
                <div className="col-md-12">

                    <form className="row g-3 contact__us" onSubmit={submitForm}>
                        <div className="col-md-6">
                            <label htmlFor="InputFirstName" className="col-form-label col-form-label-sm">First Name <span className="text-danger">*</span></label>
                            <input className="form-control form-control-sm" id="InputFirstName"
                                type="text"
                                placeholder="Write your First Name..."
                                name="first_name"
                                value={inputField.first_name}
                                onChange={inputsHandler}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="InputLastName" className="col-form-label col-form-label-sm">Last Name</label>
                            <input className="form-control form-control-sm" id="InputLastName"
                                type="text"
                                placeholder="Write your Last Name..."
                                name="last_name"
                                value={inputField.last_name}
                                onChange={inputsHandler}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="InputEmailAddress" className="col-form-label col-form-label-sm">Email address <span className="text-danger">*</span></label>
                            <input className="form-control form-control-sm" id="InputEmailAddress"
                                type="email"
                                placeholder="Write your email address..."
                                name="email_address"
                                value={inputField.email_address}
                                onChange={inputsHandler}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="InputContactNumber" className="col-form-label col-form-label-sm">Contact Number <span className="text-danger">*</span></label>
                            <input className="form-control form-control-sm" id="InputContactNumber"
                                type="phone"
                                placeholder="Contact Number"
                                name="contact_no"
                                value={inputField.contact_no}
                                onChange={inputsHandler}
                            />
                        </div>
                        <div className="col-md-12 ">
                            <label htmlFor="InputComments" className="col-form-label col-form-label-sm">Message <span className="text-danger">*</span></label>
                            <textarea className="form-control form-control-sm" id="InputComments" rows="4"
                                type="text"
                                placeholder="Write your message here ..."
                                name="message"
                                value={inputField.message}
                                onChange={inputsHandler}
                            ></textarea>
                        </div>
                        <div className="col-md-12 mt-3">
                            <div className="form-check">
                                <input className="form-check-input" id="exampleCheck1"
                                    type="checkbox"
                                    name="checkbox_message"
                                    checked={inputField.checkbox_message===true}
                                    value={true}
                                    onChange={() => setInputField({ ...inputField, checkbox_message: !inputField.checkbox_message })}
                              />
                                <label className="form-check-label" htmlFor="exampleCheck1">I agree to the <Link to="privacy"style={{fontSize: " font-size: 14px !important",}}>Privacy Policy</Link> and <Link to="terms"style={{fontSize: " font-size: 14px !important",}}>Terms of Service</Link>.</label>
                            </div>
                        </div>
                        <div className="col-md-12 pt-3 text-center">
                            <button type="submit" className="btn btn__contact">Send Message</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;