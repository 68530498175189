

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EnrichMacrohPlausSoft from './EnrichMacrohPlausSoft';
import HealthEcosystem from './HealthEcosystem';
import NewsLetter from './NewsLetter';
import HealthEcosystemMobileDevice from './HealthEcosystemMobileDevice';
import RequestDemoSlider from './RequestDemoSlider';

import feature_img_2 from '../../images/feature_img_2.png';
import check_sign from '../../images/check_sign.png';
import patient_care from '../../images/patient_care.png';
import patient_money from '../../images/patient_money.png';
import patient_guide from '../../images/patient_guide.png';
import mims_img from '../../images/mims_img.png';
import ZT_LOGO_01 from '../../images/ZT_LOGO_01.png';
import Digi_Patient2 from '../../images/Digi_Patient2.png';
import Great_Doc1 from '../../images/Great_Doc1.png';
import Great_Lab1 from '../../images/Great_Lab1.png';
import Great_Pharma1 from '../../images/Great_Pharma1.png';
import telehealth1 from '../../images/telehealth1.png';
import Ecommerce_img from '../../images/Ecommerce_img.png';
import Lab_Agent1 from '../../images/Lab_Agent1.png';
import Marketplace1 from '../../images/Marketplace1.png';
import Home__banner from '../../images/Home__banner.jpg';
import testimonials_img1 from '../../images/testimonials_img1.jpg';
import comming_soon_img from '../../images/comming_soon_img.jpg';
import Complete_Vision_Logo from '../../images/Complete_Vision_Logo.png';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}


function OurWorldwidePartnersNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

function OurWorldwidePartnersPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

const Home = () => {
    const [healthEcoData, setHealthEcoData] = useState([
        {id: 1, title: "Patient"},
        {id: 2, title: "Doctors"},
        {id: 3, title: "Allied Healthcare Staff"},
        {id: 4, title: "Clinic"},
        {id: 5, title: "Hospitals"},
        {id: 6, title: "Pharmacy"},
        {id: 7, title: "Pharmaceutical Company"},
        {id: 8, title: "Insurance"},
        {id: 9, title: "Tele Medicine"},
        {id: 10, title: "Medical College"},
        {id: 11, title: "Technology Providers"},
        {id: 12, title: "Investor"},
        {id: 13, title: "Regulators"},
        {id: 14, title: "Government"},
    ]);

    const [solutionMacroHealth, setSolutionMacroHealth] = useState([
        {
            title: "DigiPatient", subtitle: "Electronic Health Records",
            description: "Manage appointments, upload investigation results, doctor’s reports, health documents, own previous medical history, various vitals, upload disease photos.",
            card_link: "digi-patient",
            images: Digi_Patient2, id: 1
        },
        {
            title: "GreatDoc", subtitle: "Great documents for great doctors.",
            description: "History and clinical examination in clicks User friendly design for minimal to low Computer literate clinician for great use. Access securely anywhere anytime.",
            card_link: "great-doc",
            images: Great_Doc1, id: 2
        },
        {
            title: "GreatClinic", subtitle: "Laboratory",
            description: "Lab Report entry, verification authentication and publish Report or automated import according to your needs. Send a report to your patient by printing, SMS, Email or via WhatsApp.",
            card_link: "great-lab",
            images: Great_Lab1, id: 3
        },
        {
            title: "GreatPharma", subtitle: "Pharmacy POS",
            description: "Empower your pharmacy with an advanced management System for inventory, Billing and POS in cloud from any Device like tablet, PCs or mobile phones.",
            card_link: "great-pharma",
            images: Great_Pharma1, id: 4
        },
        {
            title: "Telehealth", subtitle: "Telemedicine For Health",
            description: "Consult with your doctor from home at the right moment. Get appointments, Clinical advice, E scripts or any investigations  Orders via your mobiles",
            card_link: "telehealth",
            images: telehealth1, id: 5
        },
        {
            title: "Marketplace", subtitle: "Medicine, Doctor and Allied Health Staff",
            description: "All your needs for great care in a single place, where all your medical assistance like Medicine, Doctor and Allied Health Staff are  waiting for you.",
            card_link: "marketplace",
            images: Marketplace1, id: 6
        },
        {
            title: "e-Pharmacy", subtitle: "Medicine",
            description: "Open your door to the customers and sell your  Medicine and Medical products even before you wake up.",
            card_link: "e-commerce-medicine",
            images: Ecommerce_img, id: 7
            // images: Medicine, id: 6
        },
        {
            title: "LabAgent", subtitle: "Medical Lab Solutions",
            description: "Walk to your pathology collection centre next to you in one spot.",
            card_link: "lab-agent",
            images: Lab_Agent1, id: 8
        },

    ]);
    const [testimonials, setTestimonials] = useState([
        {
            title: "Dr. S KARIM", subtitle: "Ophthalmologist",
            descriptions: "GreatDoc is a smart program for all my needs in ophthalmology, very easy to upload data and easy to locate the buttons for my patient. Greater perspective for detail notes and all technical corners is covered in my practice. Being very busy it's always hard to look for drug interaction with medications, all now looked after by the software",
            images: testimonials_img1, id: 1
        },
        {
            title: "Prof Dr. Rafi Uddin Ahmed", subtitle: "Microbiologist",
            descriptions: "GreatLab is a simple to use program for the lab. We find it very easy to upload results. All the itemised tests are easy to navigate for Billing and a very simple way to upload results and deliver to doctor and patient. Lab Agent is an innovative idea and easy to manage agent for our company.",
            images: patient_care, id: 2
        },
        {
            title: "Prof Anwarul Islam", subtitle: "Neurosurgeon",
            descriptions: "I was always looking for easy to use software and keep my record handy, able to access from anywhere. Appointments to follow in various practices, it’s easy now. No more calls to find it anymore. As multiple users can do inputs. My register can do all earlier necessary inputs about the patient and i can do the consult very easily. GreatDoc is a very useful program.",
            images: patient_money, id: 3
        },
        {
            title: "Dr Masood ul Alam", subtitle: "Rheumatologist",
            descriptions: "In GreatDoc, a very detailed Musculo-skeletal examination profile made it easy to click the examination findings. As a whole in busy practice, it's a perfect fit. Very easy for the admin to do their job and made our practice life very productive.",
            images: patient_guide, id: 4
        },

    ]);
    const [medicalPracticeManagementSoftwareList, setMedicalPracticeManagementSoftwareList] = useState([
        { id: 1, images: check_sign, list_name: "Individual or clinic management, Pharmacy management." },
        { id: 2, images: check_sign, list_name: "Safe and secure practice with the protective features from MIMS." },
        { id: 3, images: check_sign, list_name: "Easy way to record clinical notes and workflow." },
        { id: 4, images: check_sign, list_name: "Keep track of all your patient data, recall and reminder system in every system." },
        { id: 5, images: check_sign, list_name: "Get your data analytical report for clinical or business needs." },
        { id: 6, images: check_sign, list_name: "Highest Security protocol in every solution." },
    ])
    const testimonialsSlider = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    const [ourWorldwidePartners, setOurWorldwidePartners] = useState([
        {
            images: mims_img, id: 1
        },
        {
            images: ZT_LOGO_01, id: 2
        },
        {
            images: Complete_Vision_Logo, id: 3
        },
        {
            images: comming_soon_img, id: 4
        },
        {
            images: comming_soon_img, id: 5
        },
        {
            images: comming_soon_img, id: 6
        },
        {
            images: comming_soon_img, id: 7
        },
        {
            images: comming_soon_img, id: 8
        },
        {
            images: comming_soon_img, id: 9
        },
        {
            images: comming_soon_img, id: 10
        },

    ]);
    const ourWorldwidePartnersSlider = {
        className: "center",
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 8,
        initialSlide: 0,
        nextArrow: <OurWorldwidePartnersNextArrow />,
        prevArrow: <OurWorldwidePartnersPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]

    };


    return (
        <>
            <div className="bg__body ">
                <div className="home__banner">
                    <div className="banner__img">
                        <img src={Home__banner} alt="image" />
                    </div>
                    <div className="banner__text">
                        <h2 className="banner__title2">Practice Management Software</h2>
                        <h3 className="banner__subtitle">for Better Quality of Care</h3>
                        <p className="banner__description">
                            MacroHealthPlus helps to deliver superior healthcare for your doctors, clinics, diagnostics and pharmacy.
                        </p>
                    </div>
                </div>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-sm-12">
                            <h3 className="text-center mb-2">
                                <span className="header__text">Solutions of MacroHealthPlus</span>
                            </h3>
                            <div className="macro">
                                <p className="header__description solution__macro">
                                    Deliver an end-to-end patient experience that you feel in real-time with your practice management system.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3 gx-sm-5 pt-4 d-flex justify-content-center">
                        {
                            solutionMacroHealth.length > 0 && solutionMacroHealth.map((item, id) => {
                                return (
                                    <div key={id} className="col-md-4">
                                        <Link to={`/${item.card_link}`} style={{ textDecoration: "none" }}>
                                            <div className="macrohealth__card">
                                                <div className="card text-center">
                                                    <div className="d-flex justify-content-center macrohealth__mt">
                                                        <img src={item.images} className="card-img-top" alt="images" />
                                                    </div>
                                                    <div className="card-body">
                                                        <h6 className="header__card__title">{item.title}</h6>
                                                        <h6 className="header__card__subtitle">{item.subtitle}</h6>
                                                        <p className="header__card__discription">{item.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="pt-5 mb-5">
                <RequestDemoSlider />
            </div>

            <div className="bg__body">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <EnrichMacrohPlausSoft />
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <div className="row g-3 medicale__practice pt-5">
                    <div className="col-md-5 order-last order-sm-first ">
                        <h3 className="mb-1">
                            <span className="header__text">Medical Practice Management Software</span>
                        </h3>
                        <h3 className="mb-1">
                            <span className="header__text__normal">All Your Patient Data in One Place</span>
                        </h3>
                        <div className="mpms__mt">
                            <p className="header__description">
                                Every healthcare facility is connected to each other with intelligence software with our solutions.
                                The solutions we create usually cover multiple needs: from managing the supplies, clinical management
                                to selling medication both online and offline and managing your business a whole.
                            </p>
                        </div>
                        <div className="medical__practice">
                            <ul className="list-unstyled pt-2">
                                {
                                    medicalPracticeManagementSoftwareList.length > 0 && medicalPracticeManagementSoftwareList.map((list, id) => {
                                        return (
                                            <li key={id} className="d-flex align-items-start ">
                                                <img src={list.images} className=" img-fluid " style={{ width: '20', height: '20px' }} alt="list_image" border="0" />
                                                <p className="ms-3">{list.list_name}</p>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="pt-4">
                            <a src="#" type="text" className="btn btn__read__more">Read More</a>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="feature__img__2">
                            <img src={feature_img_2} className="img-fluid" alt="img" border="0" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <div className="container margin__top">
                    <div className="row pt-4">
                        <div className="col-md-12">
                            <h3 className="text-center mb-3">
                                <span className="header__text">Improving the <span className="inspiration__span">Efficiency, Accuracy,</span> and <br /><span className="inspiration__span">Cost-effectiveness</span></span>
                            </h3>
                            <div className="pt-4">
                                <section className="faq-section__lab">
                                    <div className="container" data-aos="fade-up">
                                        <div className="row">
                                            <div className="col-md-10 offset-md-1">
                                                <div className="accordion accordion-flush" id="faqlist1">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                                                                Business Consistency
                                                            </button>
                                                        </h2>
                                                        <div id="faq-content-1" className="accordion-collapse collapse show" data-bs-parent="#faqlist1">
                                                            <div className="accordion-body">
                                                                <p className="header__description">
                                                                    To provide excellent services in the private sector or government facility, it is vital that you have a business management platform
                                                                    that helps you understand and meet your clients or patients’ needs.
                                                                    <strong className="inspiration__span"> MacroHealthPlus</strong>  focuses on your clinic/practice as a business, looking at patient
                                                                    acquisition, service provision and patient retention.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                                                                Central Management
                                                            </button>
                                                        </h2>
                                                        <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                                            <div className="accordion-body">
                                                                <p className="header__description">We make your point-of-care tasks efficient so that you can focus on effective treatment for your patients. Primary Clinic Medical software presents a familiar, easy to use interface so you can start practising within minutes. You can also tailor the look of your screen to present only the minimum, essential features readily needed for your patient treatments</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                                                Efficient Operations
                                                            </button>
                                                        </h2>
                                                        <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                                            <div className="accordion-body">
                                                                <p className="header__description">Our Medical software can also be streamlined for more specialised uses. The software can be configured for Doctors specialising in long appointment consultations with chronic disease patients.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>

                    <div className="row margin__top pt-5">
                        <div className="col-sm-12">
                            <div className="show__big__device">
                                <HealthEcosystem arr={healthEcoData} />
                            </div>
                            <div className="show__small__device">
                                <HealthEcosystemMobileDevice arr={healthEcoData}/>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row testi__margin__top">
                        <div className="col-md-12">
                            <h3 className="text-center mb-2 pt-5">
                                <span className="header__text">Testimonials</span>
                            </h3>
                            <div className="web__carousel">
                                <Slider {...testimonialsSlider}>
                                    {
                                        testimonials.length > 0 && testimonials.map((item, id) => {
                                            return (
                                                <div key={id} className="row g-4">
                                                    <div className="col-md-12">
                                                        <div className="testimonials__carousel__box__card">
                                                            <div className="card">
                                                                <div className="tesimonials_h pt-3">
                                                                    <p className="header__description testimonials">{item.descriptions}</p>
                                                                </div>
                                                                <div className="testimonials__inside__card pt-3">
                                                                    <div className="card2">
                                                                        <div className="row g-0">
                                                                            <div className="col-md-8">
                                                                                <div className="card-body">
                                                                                    <h6 className="testimonials__card__title">{item.title}</h6>
                                                                                    <p className="testimonials__card__subtitle">{item.subtitle}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <div className="testimonials__img">
                                                                                    <img src={item.images} className="img-fluid" alt="image" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div> */}

                    <div className="row g-3 margin__top pt-5">
                        <div className="col-md-12">
                            <h3 className="text-center mb-2 pt-2">
                                <span className="header__text">Our Partners</span>
                            </h3>
                            <div className="macro">
                                <p className="header__description solution__macro">
                                    Simplify the clinical operations in the most effective way with the <span className="inspiration__span">Health Management Software.</span>
                                </p>
                            </div>
                            <div className="world__partner__carousel pt-3 ">
                                <Slider {...ourWorldwidePartnersSlider}>
                                    {
                                        ourWorldwidePartners.length > 0 && ourWorldwidePartners.map((item, id) => {
                                            return (
                                                <div key={id} className="web__carousel__box__card">
                                                    <div className="card text-center">
                                                        <div className="pt-0">
                                                            <img src={item.images} className="img-fluid" style={{ width: "100%", height: "117px", padding: "25px" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                            <div className="macro margin__top our__mt">
                                <p className="header__description solution__macro">
                                    Implementing the best <span className="inspiration__span">Medical Practice Management Software.</span> for your business is highly
                                    recommended. In a highly technical and sensitive industry like health, it is extremely important to use the right software to
                                    improve management efficiency in hospitals, clinics and doctors practices and to satisfy patients with fast-track service.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-md-12">
                            <NewsLetter />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;

