import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import HowItWorkingProcess from './HowItWorkingProcess';
import NewsLetter from '../home/NewsLetter';
import ehr_img from '../../images/ehr_img.png';
import mpm_img from '../../images/mpm_img.png';
import youtube_arrow_icons from '../../images/youtube_arrow_icons.png';
import Agent from '../../images/Agent.jpg';
import order_payment__img from '../../images/order_payment__img.png';
import Sample_collection__img from '../../images/Sample_collection__img.png';
import Sample_sent_Lab__img from '../../images/Sample_sent_Lab__img.png';
import Report__img from '../../images/Report__img.png';
import Schedule_collectSample_img from '../../images/Schedule_collectSample_img.png';
import collect_testHome_img from '../../images/collect_testHome_img.png';



const LabAgent = () => {
    const howToProcessLabDoorSteps = [
        { id: 1, images: order_payment__img, title: "Order & Payment" },
        { id: 2, images: Schedule_collectSample_img, title: "Schedule of Collecting Sample" },
        { id: 3, images: collect_testHome_img, title: "Collect Sample From Home" },
        { id: 4, images: Report__img, title: "Report" },
    ];
    // console.log("arrData", howToProcessLabDoorSteps);

    const howToProcessNextPharmaShop = [
        { id: 1, images: order_payment__img, title: "Order & Payment" },
        { id: 2, images: Sample_collection__img, title: "Sample collect in Pharmacy" },
        { id: 3, images: Sample_sent_Lab__img, title: "Sample sent to Lab" },
        { id: 4, images: Report__img, title: "Report" },
    ];
    // console.log("arrData", howToProcessNextPharmaShop);


    const [specialtySpecific, setSpecialtySpecific] = useState([
        {
            id: 1,
            title: "Time Saved",
            no_of_percentance: "90%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 2,
            title: "Increase in the Productivity",
            no_of_percentance: "25%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 3,
            title: "Smart Workflow",
            no_of_percentance: "93%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
    ]);

    const [labAgent, setLabAgent] = useState([
        { id: 1, title: "Lab at your Door Steps", subtitle: "Simple. Fast. Accurate" },
        { id: 2, title: "Lab at your Next Pharma shop", subtitle: "Connect your patients to everywhere care" },
    ])

    return (
        <>
            <div className="bg__body">
                <div className="specialities__banner">
                    <div className="specialities__banner__img">
                        <img src={Agent} alt="image" />
                    </div>
                    <div className="specialities__banner__text__h">
                        <h3 className="specialities__banner__title">LabAgent</h3>
                        <div className="specialities__banner__text">
                            <p className="solution__banner__description">
                                Healthcare is changing. So should you
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container ">
                    <div className="row g-3  pt-5 mb-2">
                        <div className="col-md-6">
                            <div className="ehr">
                                <h3 className="mb-2">
                                    <span className="header__text">LabAgent</span>
                                </h3>
                                <div className="row g-3 gx-sm-5 pt-4 d-flex justify-content-center pe-md-5">
                                    {
                                        labAgent.length > 0 && labAgent.map((item, id) => {
                                            return (
                                                <div key={id} className="col-md-6">
                                                    <div className="lab__agent__card">
                                                        <div className="card text-center">
                                                            <div className="d-flex justify-content-center pt-4">
                                                            </div>
                                                            <div className="card-body">
                                                                <h6 className="lab__agent__text">{item.title}</h6>
                                                                <h6 className="inspiration__span">{item.subtitle}</h6>
                                                                {/* <p className="header__card__discription">{item.description}</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="pt-4">
                                    <Link to="/request-demo" type="text" className="btn btn__ehr">Free Demo</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ehr__img">
                                <img src={ehr_img} className="img-fluid" alt="image" border="0" />
                            </div>
                        </div>
                    </div>
                    <div className="row g-3  mpm__margin">
                        <div className="col-md-6 order-sm-first order-last">
                            <div className="mpm__img">
                                <img src={mpm_img} className="img-fluid" alt="image" border="0" />
                                <div className="mpm__overlay__text">
                                    <div className="mpm__youtube__icons">
                                        <img src={youtube_arrow_icons} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mpm">
                                <div className="mpm">
                                    <h3 className="text-center mb-2">
                                        <span className="header__text">How <strong className="inspiration__span">LabAgent</strong> System Works?</span>
                                    </h3>
                                    <p className="header__description text-center">
                                        Our system is Secured, User friendly and Ease to use.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row margin__top">
                        <div className="col-md-12">
                            <h3 className="text-center mb-2">
                                <span className="header__text">Lab at your Door Steps</span>
                            </h3>
                            <div className="lab__door">
                                <p className="header__description solution__macro">
                                    At-home sample collection provides access to lab-based diagnostic testing, on your patient’s schedule.
                                </p>
                                <HowItWorkingProcess arrData={howToProcessLabDoorSteps} />

                                {/* <div className="margin__top">
                                    <h3 className="text-center mb-2">
                                        <span className="header__text">Lab at your Next Pharma shop</span>
                                    </h3>
                                    <p className="header__description solution__macro">
                                        Convenient access to high-quality diagnostic testing aimed at minimal travel and better compliance and health outcomes
                                    </p>
                                    <HowItWorkingProcess arrData={howToProcessNextPharmaShop} />
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="speciality__bg">
                <div className="speciality">
                    <h3 className="header__speciality__text">Specialty-Specific All-in-One Solution</h3>
                    <div className="container speciality__specific">
                        <div className="row g-3">
                            {
                                specialtySpecific.length > 0 && specialtySpecific.map((item, id) => {
                                    return (
                                        <div key={id} className="col-md-4">
                                            <div className="specialty__specific__card">
                                                <div className="card text-center">
                                                    <div className="card-body">
                                                        <h5 className="specialty__specific__card__title">{item.title}</h5>
                                                        <h3 className="specialty__specific__card__title__percent">{item.no_of_percentance}</h3>
                                                        {/* <p className="specialty__specific__card__discription">{item.description}</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <NewsLetter />
            </div>
        </>
    );
};

export default LabAgent;