 import React, { useState } from 'react';
import NewsLetter from '../home/NewsLetter';

const WhyChooseUs = () => {
    const [improveWorkflow, setImproveWorkflow] = useState([
        {
            id: 1, description: " International standard integrated medical software to direct communication between patient-doctor in a secure platform",
        },
        {
            id: 2, description: "Electronic Health Record system with minimal data entry.",
        },
        {
            id: 3, description: "Incorporating World Class MIMS Drug database to protect doctors from error in a very busy practice.",
        },
        {
            id: 4, description: "Automatically alert doctors/health professionals about life saving Drug interactions, Drug-Disease interactions, Drug-Allergy Interactions, Drug-Lactation alert, Drug-Pregnancy alert, Drug- Duplicate alert during preparing prescriptions.",
        },
        {
            id: 5, description: "Advanced and effective patient screening facilities from booking to billing",
        },
        {
            id: 6, description: "Including immunisation records from birth to end of life. Various Clinical resources for the clinicians.",
        },
        {
            id: 7, description: "Any doctor can advise any patient remotely from anywhere in the world without the need for any paper or pencil just using a mobile phone.",
        },
        {
            id: 8, description: "Various Patient education material first time in Bengali language",
        },
        {
            id: 9, description: "Doctor’s Education from the Australian Clinician to enrich personalised education about Current international practice.",
        },
    ]);

    const [dataSafetyAndSecurity, setDataSafetyAndSecurity] = useState([
        { id: 1, title: "Cloud Integration", description: "Protects from online security threats All communication is protected with 256 bit secure encryption and 24 hours data backup" },
        { id: 2, title: "Permitted Browser Feature", description: "With this feature you can limit software usage for your employees to your user computers" },
        { id: 3, title: "Manage User Permission", description: "All users such as Doctor, Nurse and Allied Health professional are assigned permissions to limit their access to data" },
        { id: 4, title: "Continuous Data Protection", description: "All documents are stored with safety and security of google server or customised according to owner’s requirements. Database server and webserver are fully compliant with Global certification." },
    ]);
   
    return (
        <>
              <div className="bg__body">
                <div className="container margin__top">
                    <div className="row g-3  d-flex align-items-center">
                        <div className="col-md-12">
                            <h3 className="text-center mb-3">
                                <span className="header__unique__features">Improve Your Workflow with MacroHealthPlus Practice Management Software</span>
                            </h3>
                            <div className="unique__features__description">
                                <ul className="list-styled">
                                    {
                                        improveWorkflow.length > 0 && improveWorkflow.map((item, id) => {
                                            return (
                                                <li key={id}>{item.description}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row g-3  pt-4 mb-2">
                        <h3 className="text-center mb-3">
                            <span className="header__unique__features">Data Safety and Security</span>
                        </h3>
                        {
                            dataSafetyAndSecurity.length > 0 && dataSafetyAndSecurity.map((item, id) => {
                                return (
                                    <div key={id} className="col-md-6">
                                        <div className="data__safety__card">
                                            <div className="card">
                                                <div className="card-body p-4">
                                                    <h6 className="data__safety__card__title">{item.title}</h6>
                                                    <p className="data__safety__card__description">{item.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <NewsLetter />
            </div>
        </>
    );
};

export default WhyChooseUs;
