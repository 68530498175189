

import React from 'react';
import { useState } from 'react';
import Modal from 'react-modal';


const PsychoQuestionModal = ({ modalQRIsOpen, setModalQRIsOpen, data }) => {
    // // Consultation popup
    // const customStyles1 = {
    //     content: {
    //         marginTop: '70px',
    //         top: '35%',
    //         left: '25%',
    //         right: 'auto',
    //         bottom: 'auto',
    //         padding: '5px',
    //         marginRight: '-50%',
    //         transform: 'translate(-7%, -45%)',
    //         width: "55%",
    //         height: "530px",
    //         // background: "#ffffff",
    //     },
    //     overlay: { zIndex: 1000 }

    // };
    return (
        <div>
            <Modal
                isOpen={modalQRIsOpen}
                onRequestClose={() => setModalQRIsOpen(false)}
                // style={customStyles1}
                className="mymodal"
                overlayClassName="myoverlay"
                contentLabel="Example Modal"
            >
                <div className="modal__body">
                    <div className="card-body">
                        <div className="row g-3 mb-3">
                            <div className="col-12">
                                <div className="d-flex justify-content-between">
                                    <h6 className="h__modal__text">
                                        {data?.title}
                                    </h6>
                                    <p className="modal__close__btn" style={{ fontSize: "16px", cursor: "pointer" }}
                                        onClick={() => setModalQRIsOpen(false)}
                                    ><i className="fa-solid fa-xmark close_btn"></i>
                                    </p>
                                </div>
                                <hr />
                                <div className="psycho__quetion">
                                    <img src={data?.images} alt="" className="img-fluid" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </div>
    );
};

export default PsychoQuestionModal;