
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HowItWorkingProcess from './HowItWorkingProcess';
import NewsLetter from '../home/NewsLetter';

import GreatLab__img from '../../images/GreatLab__img.png';
import mpm_img from '../../images/mpm_img.png';
import youtube_arrow_icons from '../../images/youtube_arrow_icons.png';
import send_reports_img from '../../images/send_reports_img.jpg';
import qr_code_lab_report_img from '../../images/qr_code_lab_report_img.png';
import memoGraphy from '../../images/memoGraphy.png';

import WelcomeSMS from '../../images/final_icons/WelcomeSMS.png';
import BillSMS from '../../images/final_icons/BillSMS.png';
import ReportReadySMS from '../../images/final_icons/ReportReadySMS.png';
import ShareReport from '../../images/final_icons/ShareReport.png';

import microbiology from '../../images/microbiology.png';
import chemistry from '../../images/chemistry.png';
import hematology from '../../images/hematology.png';
import CTScan2 from '../../images/CTScan2.png';
import x_ray from '../../images/x_ray.png';

import LAb from '../../images/LAb.jpg';
import test_entry__img from '../../images/test_entry__img.png';
import Report__img from '../../images/Report__img.png';
import radiology__img from '../../images/radiology__img.png';
import pathology__img from '../../images/pathology__img.png';
import QRCode from 'react-qr-code';


const GreatLab = () => {
    const howToProcessGreatLab = [
        { id: 1, images: test_entry__img, title: "Test Entry" },
        { id: 2, images: pathology__img, title: "Pathology" },
        { id: 3, images: radiology__img, title: "Radiology" },
        { id: 4, images: Report__img, title: "Report" },
    ];
    // console.log("arrData", howToProcessGreatLab);
    const [ourServicesPathology, setourServicesPathology] = useState([
        {
            title: "Haematology",
            images: hematology, id: 1
        },
        {
            title: "Microbiology ",
            images: microbiology, id: 2
        },
        {
            title: "Chemistry & Immunoassay ",
            images: chemistry, id: 3
        },
    ]);
    const [ourServicesRadiology, setourServicesRadiology] = useState([
        {
            title: "X-Ray, CT-Scan, MRI",
            images: x_ray, id: 1
        },
        {
            title: "Ultrasound",
            images: CTScan2, id: 2
        },
        {
            title: "Mammography",
            images: memoGraphy, id: 3
        },

    ]);

    const [specialtySpecific, setSpecialtySpecific] = useState([
        {
            id: 1,
            title: "Time Saved",
            no_of_percentance: "90%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 2,
            title: "Increase in the Productivity",
            no_of_percentance: "25%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
        {
            id: 3,
            title: "Smart Workflow",
            no_of_percentance: "93%",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pretium ipsum, turpis ",
        },
    ]);

    const [keepPatients, setKeepPatients] = useState([
        { id: 1, image: WelcomeSMS, title: "Welcome SMS", description: "GreatClinic automatically sends welcome SMS to patients with your lab name and phone number." },
        { id: 2, image: BillSMS, title: "Bill SMS", description: "Sending bill info through SMS removes fear of losing physical bills, establishes trust and transparency with your patients." },
        { id: 3, image: ReportReadySMS, title: "Report ready SMS", description: "Don't keep your patients waiting. GreatClinic will automatically send report ready SMS when it is marked final." },
        { id: 3, image: ShareReport, title: "Browse/View Medical Reports On Any Device, Anywhere", description: "Don't keep your patients waiting. GreatClinic will automatically send report ready SMS when it is marked final." },
    ]);

    return (
        <>
            <div className="bg__body">
                <div className="specialities__banner">
                    <div className="specialities__banner__img">
                        <img src={LAb} alt="image" />
                    </div>
                    <div className="specialities__banner__text__h">
                        <h3 className="specialities__banner__title">GreatClinic</h3>
                        <div className="specialities__banner__text">
                            <p className="solution__banner__description">
                                Advanced Pathology-Radiology Lab & Diagnostic Centre Management Solution
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container ">
                    <div className="row g-3  pt-5 mb-2">
                        <div className="col-md-6">
                            <div className="laboratory">
                                <h3 className="mb-2">
                                    <span className="header__text">GreatClinic</span>
                                </h3>
                                <div className="pe-md-5">
                                    <p className="header__description">
                                        <strong className="green__color">GreatClinic</strong> is an all-in-one cloud-based healthcare diagnostics management software. It has all features for pathology and radiology laboratories needed to empower clinics, hospitals, and patients with a digital, collaborative and connected experience.
                                    </p>
                                    <p className="header__description">
                                        <strong className="green__color">GreatClinic</strong> lets you easily upload, review, permission and share lab reports with patients and doctors, on the medium of your choice. Apart from printing, reports can be delivered over email, SMS and WhatsApp
                                    </p>
                                </div>
                                <div className="pt-3">
                                    <Link to="/request-demo" type="text" className="btn btn__ehr">Free Demo</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ehr__img">
                                <img src={GreatLab__img} className="img-fluid" alt="image" border="0" />
                            </div>
                        </div>
                    </div>
                    <div className="row g-3  mpm__margin">
                        <div className="col-md-6 order-sm-first order-last">
                            <div className="mpm__img">
                                <img src={mpm_img} className="img-fluid" alt="image" border="0" />
                                <div className="mpm__overlay__text">
                                    <div className="mpm__youtube__icons">
                                        <img src={youtube_arrow_icons} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mpm">
                                <div className="mpm">
                                    <h3 className="text-center mb-2">
                                        <span className="header__text">How <strong className="inspiration__span">GreatClinic</strong> System Works?</span>
                                    </h3>
                                    <p className="header__description text-center">
                                        Our system is Secured, User friendly and Ease to use.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <HowItWorkingProcess arrData={howToProcessGreatLab} />
                        </div>
                    </div>


                    <div className="row pt-5">
                        <div className="col-sm-12">
                            <h3 className="text-center mb-2">
                                <span className="header__text">Software Services</span>
                            </h3>
                            <div className="macro">
                                <p className="header__description solution__macro">
                                    We provide a broad range of service modalities incorporated in our system to provide service provider routine, specialised and high complexity diagnostics services.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row g-3 gx-sm-5 pt-4 mb-3 d-flex justify-content-center">
                        <div className="col-md-3">
                            <div className="pathology__card">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <h3 className="pathology__htitle">Pathology</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="row g-3 gx-sm-5 d-flex justify-content-center">
                                {
                                    ourServicesPathology.length > 0 && ourServicesPathology.map((item, id) => {
                                        return (
                                            <div key={id} className="col-md-4">
                                                <div className="general__practice__card">
                                                    <div className="card text-center">
                                                        <div className="d-flex justify-content-center pt-4">
                                                            <div className="bg__card__img">
                                                                <img src={item.images} className="card-img-top" alt="images" />
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <h6 className="header__gp__card__subtitle">{item.title}</h6>
                                                            <p className="header__card__discription"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    </div>

                    <div className="row g-3 gx-sm-5 pt-4  d-flex justify-content-center">
                        <div className="col-md-3">
                            <div className="pathology__card">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <h3 className="pathology__htitle">Radiology</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="row g-3 gx-sm-5 d-flex justify-content-center">
                                {
                                    ourServicesRadiology.length > 0 && ourServicesRadiology.map((item, id) => {
                                        return (
                                            <div key={id} className="col-md-4">
                                                <div className="general__practice__card">
                                                    <div className="card text-center">
                                                        <div className="d-flex justify-content-center pt-4">
                                                            <div className="bg__card__img">
                                                                <img src={item.images} className="card-img-top" alt="images" />
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <h6 className="header__gp__card__subtitle">{item.title}</h6>
                                                            <p className="header__card__discription"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="book__app__bg">
                <div className="container book__app">
                    <h3 className="book__app__text">Keep your patients updated with Automated SMS & Report</h3>
                    <div className="row g-3 gx-md-4 pt-4 mb-4">
                        {
                            keepPatients.length > 0 && keepPatients.map((item, id) => {
                                return (
                                    <div key={id} className="col-md-3">
                                        <div className="keep__patients__card">
                                            <div className="card text-center">
                                                <div className="d-flex justify-content-center pt-4">
                                                    <div className="app__mangement__img">
                                                        <img src={item.image} className="card-img-top" alt="images" />
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <h6 className="hpatients__card__title">{item.title}</h6>
                                                    {/* <p className="header__card__discription">{item.description}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </div>
            <div className="bg__body">
                <div className="container">
                    <div className="row g-1 pt-5">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="text-center mb-2">
                                        <span className="header__text">Pathology Lab Management</span>
                                    </h3>
                                    <div className="macro">
                                        <p className="header__description solution__macro">
                                            <strong className="inspiration__span">GreatClinic</strong> generates reports for your monthly business and
                                            helps you gain more insights. You view the revenue generated for each type, get a graphical view of
                                            pathology collection or tests on each day. This data is kept private and only the account admin owner
                                            has access to this information.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row g-3 pt-5 inspiration__div">
                                <div className="col-md-6">
                                    <div className="laboratory__manage">
                                        <img src={send_reports_img} className="img-fluid" alt="images" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="laboratory__manage text-center ">
                                        <h3 className="text-center mb-4">
                                            <span className="header__text inspiration__span">QR coded lab reports</span>
                                        </h3>
                                        {/* <img src={qr_code_lab_report_img} className="img-fluid" alt="images" /> */}
                                        <QRCode
                                            size={256}
                                            style={{ height: "150px", maxWidth: "100%", width: "100%" }}
                                            value={`http://macrohealthplus.org/`}
                                            level={"Q"}
                                            viewBox={`0 0 256 256`}
                                        />
                                        <p className="inspiration__description pt-4">QR codes are printed on the lab reports. Patients can scan the QR code to get a PDF of the report. PDFs can be easily shared with doctors or anyone else interested for a second opinion etc.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="speciality__bg">
                <div className="speciality">
                    <h3 className="header__speciality__text">Specialty-Specific All-in-One Solution</h3>
                    <div className="container speciality__specific">
                        <div className="row g-3">
                            {
                                specialtySpecific.length > 0 && specialtySpecific.map((item, id) => {
                                    return (
                                        <div key={id} className="col-md-4">
                                            <div className="specialty__specific__card">
                                                <div className="card text-center">
                                                    <div className="card-body">
                                                        <h5 className="specialty__specific__card__title">{item.title}</h5>
                                                        <h3 className="specialty__specific__card__title__percent">{item.no_of_percentance}</h3>
                                                        {/* <p className="specialty__specific__card__discription">{item.description}</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="bg__body">
                <NewsLetter />
            </div>
        </>
    );
};

export default GreatLab;