import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import NewsLetter from '../home/NewsLetter';

import ticket_img from '../../images/ticket_img.png';
import bill__img from '../../images/bill__img.png';
import technical_support_img from '../../images/technical_support_img.png';
import help_plus_icon from '../../images/help_plus_icon.png';
import Polygon_icon from '../../images/Polygon_icon.png';
import Ellipse_icon from '../../images/Ellipse_icon.png';
import Subtract_icon from '../../images/Subtract_icon.png';

const HelpCentre = () => {
    const [helpCentreCard, setHelpCentreCard] = useState([
        {
            title: "Technical Support",
            email: "support@macrohealthplus.org",
            number: "+880-1855-777766",
            images: technical_support_img, id: 1
        },
        {
            title: "Billing & Subscription",
            email: "bill@macrohealthplus.org",
            number: "+880-1855-777766",
            images: bill__img, id: 2
        },
        {
            title: "Want to open a Ticket?",
            email: "ticket@macrohealthplus.org",
            number: "+880-1855-777766",
            images: ticket_img, id: 3
        },
    ]);
    
    return (
        <>
            <div className="container">
                <div className="row g-3 margin__top">
                    <div className="col-md-12">
                        <div className=" searcht__icon">
                            <div className="searcht__icon__left"> <img src={help_plus_icon} alt="" /></div>
                            <div className="searcht__icon__right">  <img src={Polygon_icon} alt="" /></div>
                        </div>
                        <h2 className="text-center mb-3 header-text">Hi! How can we help you?</h2>

                        <form action="" className="pt-3 helpc__searchbar">
                            <div className="text-center">
                                <TextField
                                    fullWidth
                                    id="standard-bare"
                                    variant="outlined"
                                    placeholder="Write your question"
                                    multiline={false}
                                    InputProps={{
                                        startAdornment: (
                                            <IconButton>
                                                <SearchOutlined />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </div>
                        </form>
                        <div className="">
                            <div className="d-flex justify-content-between align-items-center searchb__icon">
                                <div className=""> <img src={Ellipse_icon} alt="" /></div>
                                <div className="searchb__icon__right">  <img src={Subtract_icon} alt="" /></div>
                            </div>
                        </div>

                    </div>
                </div>

                <hr className="margin__top" />

                <div className="row g-3 margin__top">
                    <div className="col-md-12">
                        <h2 className="text-center">Ask us anything</h2>
                    </div>
                </div>
                <div className="row g-3 pt-2">
                    <div className="col-12 help__centre__accrodian">
                        <p className="helpc__description">
                            Starting a new project and have a few questions? Some of the most frequently asked questions
                            and answers about custom web application development are listed below.
                        </p>
                        <div className="accordion pt-3" id="faqlist">
                            <div className="accordion-item mb-3 border">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed helpc__description" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                                        What is Practice Management Software?
                                    </button>
                                </h2>
                                <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                    <div className="accordion-body">
                                        <p className="helpc__description">
                                            It is a set of computer programs which help to harmonize activities of the clinic and make practice of the clinic easier and more cost effective.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item mb-3 border">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed helpc__description" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                                        What are the main features of MacroHealthPlus?
                                    </button>
                                </h2>
                                <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                    <div className="accordion-body">
                                        <p className="helpc__description">
                                            MacroHealthPlus is an intuitive & fully-customizable Clinic Management System (CMS) with features like Video Consultation, Appointment Management, Digital Prescription, Electronic Health Records (EHR), and Dynamic Web Page for Doctor Profile, Billing & Payments, Patient Follow-ups, and Bulk SMS & Medicine Reminders for patients.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item mb-3 border">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed helpc__description" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                        Are all modules in MacroHealthPlus integrated?
                                    </button>
                                </h2>
                                <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                    <div className="accordion-body">
                                        <p className="helpc__description">
                                            Yes. We have built the solution in such a way that all modules in MacroHealthPlus talk to other modules. This results in significant reduction in staff effort for data entry and also reduces data entry errors and a seamless user experience.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item mb-3 border">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed helpc__description" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                                        Is the data secured? What are the steps taken on data security?
                                    </button>
                                </h2>
                                <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                    <div className="accordion-body">
                                        <p className="helpc__description">
                                            Data is secured both in transit and at rest. We will follow HIPAA guidelines to ensure security of the data. Application will be hosted in a HIPAA compliant infrastructure.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item mb-3 border">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed helpc__description" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-5">
                                        What should we do if we lose internet connection, can we update data offline? (Without Internet)?
                                    </button>
                                </h2>
                                <div id="faq-content-5" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                    <div className="accordion-body">
                                        <p className="helpc__description">
                                            MacroHealthPlus is a cloud-based solution. So internet connectivity is a must. Nowadays, internet services are available everywhere at a very reasonable cost. Since you are relying on MacroHealthPlus for your operations, we suggest that you have a backup internet service (from another service provider).
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item mb-3 border">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed helpc__description" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-6">
                                        What is the difference between a Doctor license ( with login) and without login?
                                    </button>
                                </h2>
                                <div id="faq-content-6" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                    <div className="accordion-body">
                                        <p className="helpc__description">
                                            Doctors with login licenses will have access to the software and Mobile App where they can manage their appointment, Patient Records etc. Doctor license without login can be added to the system for appointment and billing purposes. We have provided these types of licenses to reduce your overall cost of software.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item mb-3 border">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed helpc__description" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-7">
                                        Do I need to purchase all modules or can I start small and grow?
                                    </button>
                                </h2>
                                <div id="faq-content-7" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                    <div className="accordion-body">
                                        <p className="helpc__description">
                                            We understand that you may not need all modules at once. So, we have provided module wise purchasing options. Depending on the modules your license price will vary. Please contact us for further details.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item mb-3 border">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed helpc__description" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-8">
                                        How can I get support after I purchase the software?
                                    </button>
                                </h2>
                                <div id="faq-content-8" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                    <div className="accordion-body">
                                        <p className="helpc__description">
                                            We provide On-Call support and Email support. Our Support team will be available to take calls from Saturday to Thursday (10am- 5pm). You can also mail us at info@macrohealthplus.org. Also, you can whatsapp your issue to +88-01855-777766.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="row g-3 gx-sm-5 margin__top d-flex justify-content-center">
                    {
                        helpCentreCard.length > 0 && helpCentreCard.map((item, id) => {
                            return (
                                <div key={id} className="col-md-3">
                                    <div className="helpc__searchbar__card">
                                        <div className="card text-center">
                                            <div className="d-flex justify-content-center pt-4">
                                                <div className="bg__card__img">
                                                    <img src={item.images} className="card-img-top" alt="images" />
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <h6 className="heahelpc__card__subtitle">{item.title}</h6>
                                                <p className="heahelpc__card__discription">{item.number}</p>
                                                <p className="heahelpc__card__discription">{item.email}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div> */}

                <div className="row margin__top">
                    <div className="col-md-12">
                        <div className="ft__helthcentre">
                            <p className="me-md-3"  onClick={() => { window.location.href = 'mailto:info@macrohealthplus.org'; }}><i className="fa-regular fa-envelope fs-2"></i> </p>
                            <p className="me-md-3"> Can’t find your answer? </p>
                            <p className="help__ft__text"><Link to="/contact" className="text-decoration-none">Contact Us.</Link></p>
                        </div>
                    </div>
                </div> <hr />
            </div>

            <div className="bg__body">
                <NewsLetter />
            </div>
        </>
    );
};

export default HelpCentre;