import React from 'react';
import about__img from '../../images/about__img.png';

const About = () => {
    return (
        <div className="bg__body">
            <div className="container">
                <div className="row g-3 pt-4 mb-5">
                    <div className="col-md-6">
                        <div className="about__img">
                            <img src={about__img} className="img-fluid" alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="mb-1">
                            <span className="header__text">Our Mission</span>
                        </h3>
                        <p className="header__description mb-2">
                            We produce International Standard Medical Software for Clinical use by the Doctors, Nurses, and Allied Health professionals. We integrate public health care system to personal care and develop software for general people. Connecting people and health care professional through electronic platform.Educate Medical professional and general people though electronic media.
                        </p>
                        <h3 className="mb-1">
                            <span className="header__text">Our Vision</span>
                        </h3>
                        <p className="header__description mb-2">Error free highest quality of medical care through world class computer program, Guide the clinician now and in future with Artificial
                            Intelligence (AI) and deliver it with an affordable low cost.</p>

                        <h3 className="mb-1">
                            <span className="header__text">Our target market</span>
                        </h3>
                        <p className="header__description mb-2">Doctor, Nurses, Allied health professionals, Health administrator, Clinics, Private and Public Hospital in Bangladesh.General People of Bangladesh in large as user.Later stage similar group in other parts of world. </p>
                    </div>
                </div>
            </div>
        </div >

    );
};

export default About;

