import axios from "axios";
import React from "react";
import { useState } from "react";
import http from "../../http";
import toast, { Toaster } from "react-hot-toast";
const emptyObject = {
  options: "",
  first_name: "",
  last_name: "",
  email_address: "",
  contact_no: "",
  message: "",
  checkbox_message: false,
};

const RequestDemo = () => {
  const [inputField, setInputField] = useState({ ...emptyObject });

  const inputsHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };
  const [loading, setLoading] = useState(false);

  const submitForm = (e) => {
    setLoading(true);
    e.preventDefault();

    const data = {
      ...inputField,
      modifiedMessage: `
      ${inputField.message}
      Name: ${inputField.first_name} ${inputField.last_name}
      Contact Number: ${inputField.contact_no}
      `,
    };
    http
      .post("email/send", data)
      .then(function (response) {
        // console.log(response);
        setLoading(false);
        setInputField({ ...emptyObject });
        toast.success("Email sent successfully");
        e.target.reset();
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  return (
    <>
      <div className="bg__body">
        <Toaster />
        <div className="container">
          <div className="row pt-4">
            <div className="col-md-12 request__demo__div">
              <div className="request__demo">
                <h5 className="request__demo__text">
                  CALL US: +880-1855-777766{" "}
                </h5>
              </div>
            </div>
          </div>
          <div className="request__demo__hr pt-3">
            <hr />
          </div>
          <div className="row g-3 gx-md-3 pt-3 mb-5">
            <div className="col-md-6 bg__demo">
              <div className="row g-3 p-2">
                <div className="col-md-12">
                  <div className="request__demo__left">
                    <h4 className="header__demo__title mb-4 pt-2">
                      Thanks for your interest in MacroHealthPlus !!
                    </h4>
                    <p className="header__demo__description">
                      Every healthcare facility is connected to each other with
                      intelligence software with our solutions. The solutions we
                      create usually cover multiple needs: from managing the
                      supplies, clinical management to selling medication both
                      online and offline and managing your business a whole.
                    </p>
                    <div className="demo__list">
                      <ul className=" pt-2 ">
                        <li>
                          Individual or clinic management, Pharmacy management.
                        </li>
                        <li>
                          Safe and secure practice with the protective features
                          from MIMS.
                        </li>
                        <li>Easy way to record clinical notes and workflow.</li>
                        <li>
                          Keep track of all your patient data, recall and
                          reminder system in every system.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 ps-md-5 pe-md-5">
              <form className="contact__us" onSubmit={submitForm}>
                <div className="select__drop mb-1">
                  <label
                    htmlFor="inputState"
                    className="col-form-label col-form-label-sm"
                  >
                    Select Your Solution <span className="text-danger">*</span>
                  </label>
                  <select
                    id="inputState"
                    className="form-select form-select-sm "
                    onChange={inputsHandler}
                    name="options"
                    required
                  >
                    <option value={""}>Select</option>
                    <option value="DigiPatient">DigiPatient</option>
                    <option value="GreatDoc">GreatDoc</option>
                    <option value="GreatLab">GreatClinic</option>
                    <option value="GreatPharma">GreatPharma</option>
                    <option value="Telehealth">Telehealth</option>
                    <option value="e-Pharmacy">e-Pharmacy</option>
                    <option value="Marketplace">Marketplace</option>
                    <option value="LabAgent">LabAgent</option>
                  </select>
                </div>
                <div className="mb-1">
                  <label
                    htmlFor="InputFirstName"
                    className="col-form-label col-form-label-sm"
                  >
                    First Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control form-control-sm"
                    id="InputFirstName"
                    type="text"
                    required
                    placeholder="Write your First Name..."
                    name="first_name"
                    value={inputField.first_name}
                    onChange={inputsHandler}
                  />
                </div>
                <div className="mb-1">
                  <label
                    htmlFor="InputLastName"
                    className="col-form-label col-form-label-sm"
                  >
                    Last Name
                  </label>
                  <input
                    className="form-control form-control-sm"
                    id="InputLastName"
                    type="text"
                    required
                    placeholder="Write your Last Name..."
                    name="last_name"
                    value={inputField.last_name}
                    onChange={inputsHandler}
                  />
                </div>
                <div className="mb-1">
                  <label
                    htmlFor="InputEmailAddress"
                    className="col-form-label col-form-label-sm"
                  >
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control form-control-sm"
                    id="InputEmailAddress"
                    type="email"
                    placeholder="Write your email address..."
                    name="email_address"
                    required
                    value={inputField.email_address}
                    onChange={inputsHandler}
                  />
                </div>
                <div className="mb-1">
                  <label
                    htmlFor="InputContactNumber"
                    className="col-form-label col-form-label-sm"
                  >
                    Contact Number <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control form-control-sm"
                    id="InputContactNumber"
                    type="number"
                    required
                    placeholder="Write your contact number..."
                    name="contact_no"
                    value={inputField.contact_no}
                    onChange={inputsHandler}
                  />
                </div>
                <div className="mb-1 ">
                  <label
                    htmlFor="InputComments"
                    className="col-form-label col-form-label-sm"
                  >
                    Message <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control form-control-sm"
                    id="InputComments"
                    rows="4"
                    type="text"
                    required
                    placeholder="Write your message here ..."
                    name="message"
                    value={inputField.message}
                    onChange={inputsHandler}
                  ></textarea>
                </div>
                <div className="mt-2 form-check">
                  <input
                    className="form-check-input"
                    id="exampleCheck1"
                    type="checkbox"
                    name="checkbox_message"
                    checked={inputField.checkbox_message === true}
                    value={true}
                    onChange={() =>
                      setInputField({
                        ...inputField,
                        checkbox_message: !inputField.checkbox_message,
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    I agree to the Privacy Policy and Terms of Conditions.
                  </label>
                </div>
                <div className="pt-3">
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn btn__request"
                  >
                    {loading ? "Requesting..." : "Request Demo"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestDemo;
