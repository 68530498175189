import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import health_patient from '../../images/health_patient.png';
import http from '../../http';
import { useEffect } from 'react';

const Webinars = () => {
  const [webinars, setWebinars] = useState([
    {
      id: 1,
      title: 'Electronic Health Records',
      description:
        'Allow patients to manage appointments,upload clinical photos,  referrals and documents, fill in forms, and access health records dashboard.',
      images: health_patient,
    },
    {
      id: 2,
      title: 'Electronic Health Records',
      description:
        'Allow patients to manage appointments,upload clinical photos,  referrals and documents, fill in forms, and access health records dashboard.',
      images: health_patient,
    },
    {
      id: 3,
      title: 'Electronic Health Records',
      description:
        'Allow patients to manage appointments,upload clinical photos,  referrals and documents, fill in forms, and access health records dashboard.',
      images: health_patient,
    },
    {
      id: 4,
      title: 'Electronic Health Records',
      description:
        'Allow patients to manage appointments,upload clinical photos,  referrals and documents, fill in forms, and access health records dashboard.',
      images: health_patient,
    },
    {
      id: 5,
      title: 'Electronic Health Records',
      description:
        'Allow patients to manage appointments,upload clinical photos,  referrals and documents, fill in forms, and access health records dashboard.',
      images: health_patient,
    },
    {
      id: 6,
      title: 'Electronic Health Records',
      description:
        'Allow patients to manage appointments,upload clinical photos,  referrals and documents, fill in forms, and access health records dashboard.',
      images: health_patient,
    },
    {
      id: 7,
      title: 'Electronic Health Records',
      description:
        'Allow patients to manage appointments,upload clinical photos,  referrals and documents, fill in forms, and access health records dashboard.',
      images: health_patient,
    },
    {
      id: 8,
      title: 'Electronic Health Records',
      description:
        'Allow patients to manage appointments,upload clinical photos,  referrals and documents, fill in forms, and access health records dashboard.',
      images: health_patient,
    },
    {
      id: 9,
      title: 'Electronic Health Records',
      description:
        'Allow patients to manage appointments,upload clinical photos,  referrals and documents, fill in forms, and access health records dashboard.',
      images: health_patient,
    },
  ]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await http.get('webinars/get');

        if (response.status === 200) {
          setData(response.data?.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getData();
    return () => {
      //// cleanup
    };
  }, []);
  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <div className='container'>
          <div className='row g-3 pt-4'>
            <div className='col-md-12'>
              <h3 className='text-center mb-2'>
                <span className='header__text'>Webinars</span>
              </h3>
            </div>
          </div>
          <div className='row g-3 gy-sm-5 gx-sm-4 pt-4 d-flex justify-content-center'>
            {data.length > 0 &&
              data.map((item, id) => {
                return (
                  <div key={id} className='col-md-4'>
                    <Link
                      to={`/webinars/${item.id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <div className='webinars__card'>
                        <div className='card'>
                          <img
                            src={`${global.img_Url}${item?.thumbnail}`}
                            className='card-img-top'
                            alt='images'
                          />
                          <div className='card-body'>
                            <h6 className='webinars__card__title'>
                              {item.title}
                            </h6>
                            <p className='webinars__card__description'>
                              {item?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

const Loading = () => (
  <div
    style={{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div class='loader'></div>
  </div>
);

export default Webinars;
